<div class="container-fluid">

<div class="row">
  <div class="col-lg-4 col-md-4 col-sm-4 p-0">
    <nz-card  [nzBodyStyle]="{'padding': 0, 'height': '85vh'}"
              style="width: 100%" [nzLoading]="isLoadingData">

      <nz-page-header class="site-page-header">
        <nz-avatar nz-page-header-avatar
                   [nzSrc]="clientIcon"
                   (nzError)="noIconLoaded($event)"
                   style="width: 60px; height: 60%" nzShape="square"></nz-avatar>
        <nz-page-header-title>
          <h4 class="mt-1" nz-typography>{{client?.name}}</h4>
        </nz-page-header-title>
        <ng-template #pageHeaderTitle>
          <div class="row">
            <div class="col-3">
<!--              <nz-avatar nzIcon="user" [nzShape]="'square'"-->
<!--                         [nzSrc]="clientIcon" (nzError)="noIconLoaded($event)">-->
<!--              </nz-avatar>-->
              <img width="100%" height="100%" class="img-fluid" [src]="clientIcon" alt="" />
            </div>
            <div class="col-9 pt-1">

            </div>
          </div>
        </ng-template>

        <nz-page-header-content>
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">

                <nz-input-group [nzSuffix]="suffixIconSearch">
                  <input type="text"
                         nz-input placeholder="Site Search..." [nzSize]="'small'"
                         [(ngModel)]="siteSearchText" autocomplete="off" />
                  <ng-template #suffixIconSearch>
                    <i nz-icon nzType="search"></i>
                  </ng-template>
                </nz-input-group>
                <span nz-typography nzType="secondary" class="small">Use the search box to filter the list below.</span>
              </div>
            </div>
          </div>

        </nz-page-header-content>
      </nz-page-header>

      <nz-table #basicTable [nzData]="siteList"
                [nzPageSize]="250" [nzScroll]="{ y: '60vh' }"
                [nzShowPagination]="false">
        <tbody>

        <tr *ngFor="let data of basicTable.data| filter:siteSearchText; let i = index;"
            class="clickable-element"
            routerLink="/admin/devices/{{data?.legacy_id || data?._id}}">
          <td>
            {{ data.name }}
          </td>
          <td>
            <nz-skeleton-element [nzActive]="isLoadingAlarms" *ngIf="isLoadingAlarms" nzType="button"></nz-skeleton-element>
              <div class="progress"
                   *ngIf="!isLoadingAlarms
                 && alarms[0]?.critical_alarms !== undefined
                 && getSiteAlarm(data.legacy_id ?? data._id) !== -1">


                <div class="progress-bar bg-danger p-2" role="progressbar"

                     *ngIf="alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.critical_alarms !== 0"

                     [ngStyle]="{'width': ((alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.critical_alarms / (
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.critical_alarms
                   + alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.urgent_alarms +
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.warning_alarms +
                    alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.event_alarms
                   )) * 100) + '%'}"
                     aria-valuenow="15"
                     aria-valuemin="0" aria-valuemax="100">
                  {{alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.critical_alarms }}
                </div>

                <div class="progress-bar p-2"
                     role="progressbar"
                     *ngIf="alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.urgent_alarms !== 0"

                     [ngStyle]="{'width': ((alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.urgent_alarms / (
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.critical_alarms +
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.urgent_alarms +
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.warning_alarms +
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.event_alarms
                   )) * 100) + '%', 'background-color': '#ffa000'}"
                     aria-valuenow="30" aria-valuemin="0"
                     aria-valuemax="100">
                  {{alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.urgent_alarms }}
                </div>

                <div class="progress-bar p-2 bg-warning"
                     role="progressbar"

                     *ngIf="alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.warning_alarms !== 0"

                     [ngStyle]="{'width': ((alarms[i]?.warning_alarms / (
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.critical_alarms +
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.urgent_alarms +
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.warning_alarms +
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.event_alarms
                   )) * 100) + '%'}" aria-valuenow="20"
                     aria-valuemin="0" aria-valuemax="100">
                  {{alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.warning_alarms }}
                </div>

                <div class="progress-bar p-2 bg-primary"
                     role="progressbar"
                     *ngIf="alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.event_alarms !== 0"

                     [ngStyle]="{'width': ((alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.event_alarms / (
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.critical_alarms +
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.urgent_alarms +
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.warning_alarms +
                   alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.event_alarms
                   )) * 100) + '%'}" aria-valuenow="20"
                     aria-valuemin="0" aria-valuemax="100">
                  {{alarms[getSiteAlarm(data.legacy_id ?? data._id)]?.event_alarms }}
                </div>

              </div>

              <div class="progress" *ngIf="!isLoadingAlarms
                 && alarms[0]?.critical_alarms !== undefined
                 && getSiteAlarm(data.legacy_id ?? data._id) === -1">

                <div class="progress-bar"
                     role="progressbar"
                     aria-valuenow="0"
                     aria-valuemin="0"
                     aria-valuemax="100" style="width: 0%"></div>
              </div>


<!--            <ng-container *ngIf="!isLoadingAlarms">-->
<!--              <app-stacked-bar-chart [chartData]="{-->
<!--                            series_name: 'Active Alarms',-->
<!--                            series_data: [alarms[i]?.critical_alarms,-->
<!--                            alarms[i]?.urgent_alarms,-->
<!--                            alarms[i]?.warning_alarms,-->
<!--                            alarms[i]?.event_alarms],-->
<!--                            value_suffix: ''-->
<!--                            }" [optionsType]="'used_capacity'">-->

<!--              </app-stacked-bar-chart>-->
<!--            </ng-container>-->

          </td>

        </tr>
        </tbody>
      </nz-table>

    </nz-card>
  </div>
  <div class="col-lg-8 col-md-8 col-sm-8 p-0" *ngIf="!isLoadingAlarms">
    <nz-card  [nzBodyStyle]="{'padding': 0, 'height': '85vh'}">
      <div class="m-0 p-0" style="height: 100%">
        <app-site-map [mapData]="siteListWithAlarms"></app-site-map>
      </div>

    </nz-card>
  </div>
</div>

</div>


<!--<div class="container-fluid">-->

<!--  <div class="row">-->
<!--    <nz-card class="col-lg-4 col-md-4 col-sm-12 content-container" [nzBodyStyle]="{'padding': 0}">-->
<!--      <nz-table #basicTable [nzData]="siteList"-->

<!--                [nzPageSize]="250" [nzScroll]="{ y: '60vh' }"-->
<!--                [nzShowPagination]="false">-->
<!--        <tbody>-->

<!--        <tr *ngFor="let data of basicTable.data| filter:siteSearchText; let i = index;"-->
<!--            class="clickable-element"-->
<!--            routerLink="/admin/devices/{{data?.legacy_id}}">-->
<!--          <td colspan="2">{{ data.Site_name }}</td>-->

<!--          <td>-->
<!--            <nz-badge *ngIf="siteAlarmTotals !== undefined"-->
<!--                      nzCount="{{ siteAlarmTotals[i]?.num_alarms }}" nzShowZero style="color: #ffffff">-->
<!--              <button class="head-example" nz-button nzType="default"-->
<!--                      routerLink="/admin/active-alarms/{{data?.legacy_id}}">-->
<!--                <i nz-icon nzType="alert" nzTheme="twotone" nzTwotoneColor="#d32f2f"></i>-->
<!--                Active Alarms-->
<!--              </button>-->
<!--            </nz-badge>-->
<!--          </td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--      </nz-table>-->
<!--    </nz-card>-->

<!--    <div class="col-lg-8 col-md-8 col-sm-12 p-0">-->
<!--      <nz-card [nzBodyStyle]="{'height': '70vh', 'background': 'transparent'}">-->

<!--        <div class="bg-transparent p-0" >-->
<!--          <div class="grid-body bg-transparent  h-25 p-0">-->
<!--            <div class="mt-auto">-->
<!--              <app-site-map [coordinates]="coordinates" [sites]="siteList"></app-site-map>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->


<!--      </nz-card>-->

<!--    </div>-->

<!--  </div>-->
<!--  <ng-template #suffixIconSearch>-->
<!--    <i nz-icon nzType="search"></i>-->
<!--  </ng-template>-->
<!--</div>-->




<app-overlay-loader [isLoading]="isLoadingData" [loadingText]="'Loading Site List...'"></app-overlay-loader>
<!--<app-overlay-loader [isLoading]="true" [loadingText]="'Loading Site List...'"></app-overlay-loader>-->


