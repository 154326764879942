<div class="container-fluid">

    <nz-page-header class="" [nzTitle]="title" nzSubtitle="Alarm Notifications">
      <ng-template #title>
        <span>
          <i nz-icon nzType="bell" nzTheme="outline" class="button-icon"></i>
          Alarm Notifications
        </span>
      </ng-template>
      <nz-page-header-extra>
        <button nz-button nzType="default" (click)="clearNotifications()"
               ><i nz-icon nzType="clear" nzTheme="outline"></i> Clear Notifications</button>
      </nz-page-header-extra>
      <nz-page-header-content>
        <div class="row">
          <div class="col-6">
            <nz-input-group [nzSuffix]="suffixIconSearch">
              <input type="text" nz-input placeholder="Search..." [(ngModel)]="searchText" />
            </nz-input-group>
            <ng-template #suffixIconSearch>
              <i nz-icon nzType="search"></i>
            </ng-template>
          </div>
        </div>
      </nz-page-header-content>
    </nz-page-header>

</div>
<nz-divider></nz-divider>

<div class="container-fluid">
  <nz-table nzTemplateMode>
    <thead>
    <tr>
      <th
        *ngFor="let column of tableColumns"
        [nzSortOrder]="column.sortOrder"
        [nzSortFn]="column.sortFn"
        [nzSortDirections]="column.sortDirections"
        [nzFilterMultiple]="column.filterMultiple"
        [nzFilters]="column.listOfFilter"
        [nzFilterFn]="column.filterFn"
      >
        {{ column.name }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let alarm of currentAlarms | filter: searchText">
      <td><p>{{alarm?.description}}</p></td>
      <td><p>{{alarm?.site_name}}</p></td>
      <td>
        <nz-tag  class="bg-danger text-white" *ngIf="alarm?.priority === 'Critical' || alarm.priority === 'MPT Alarm'">
          Critical
        </nz-tag>
        <nz-tag class="bg-warning  text-white" *ngIf="alarm?.priority === 'Urgent'">
          Urgent
        </nz-tag>
        <nz-tag  class="bg-warning text-white" *ngIf="alarm?.priority === 'Warning'">
          Warning
        </nz-tag>
        <nz-tag  class="bg-info text-white" *ngIf="alarm?.priority === 'Event'">
          Event
        </nz-tag>

      </td>
      <td>
        <p>{{alarm?.device_name}}</p>
      </td>
<!--      <td>-->
<!--        <p>{{alarm?.device_type}}</p>-->
<!--      </td>-->
      <td>
        <p *ngIf="alarm?.is_cleared === true">
          Cleared
          <i nz-icon nzType="issues-close" class="text-success button-icon" nzTheme="outline"></i>
        </p>
        <p *ngIf="alarm?.is_cleared === false">
          Active
          <i nz-icon nzType="exclamation-circle" class="text-danger button-icon" nzTheme="outline"></i>
        </p>
      </td>
      <td>
        <p>{{alarm?.duration}}</p>
      </td>

      <td>
        <p>{{alarm?.triggered_at | date: 'medium'}}</p>
      </td>

      <td>
        <button nz-button nzType="text" nzDanger nzSize="small" *ngIf="!checkIfAlarmMuted(alarm)" (click)="muteAlarm(alarm)">
          <i nz-icon nzType="eye-invisible" nzTheme="outline"></i> Mute</button>

        <button nz-button nzType="text" nzSize="small" *ngIf="checkIfAlarmMuted(alarm)" (click)="unMuteAlarm(alarm)">
          <i nz-icon nzType="eye" nzTheme="outline"></i> Un Mute</button>
      </td>

    </tr>
    </tbody>
  </nz-table>
</div>



