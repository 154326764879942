import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Constants} from '../../utils/Contstants';
import {StorageService} from '../storage/storage.service';
import {Observable} from 'rxjs';
import {ISite} from '../../Models/ISite';
import {catchError, map} from 'rxjs/operators';
import {AuthenticationService} from '../authentication/authentication.service';
import {IUserSiteList} from '../../Models/IUserSiteList';

@Injectable({
  providedIn: 'root'
})
export class SitesService {

  private baseUrl = new Constants().clientZoneBackendUrl;
  private storageConstants = new Constants().storage;

  constructor(private http: HttpClient, private storageService: StorageService, private authService: AuthenticationService) { }

  public functionSwitchClient(){

  }

  public getCurrentSite(selectedSiteId: number): string{

    let siteName: string;

    const clientSiteList = JSON.parse(this.storageService.getStoredEncodedValue(this.storageConstants.clientSites));
    for(let i in clientSiteList){

      if(Number(clientSiteList[i].legacy_id) === selectedSiteId){

       siteName = clientSiteList[i].Site_name;
      }
    }



    return siteName;
  }

  getSpecificSiteDetails(id: string): Observable<ISite>{
    const token = this.storageService.getStoredEncodedValue(new Constants().storage.token);

    let endpoint = this.baseUrl;

    if(!isNaN(Number(id))){
      endpoint += `sites/legacy/${id}`;
    }
    else{
      endpoint +=`sites/${id}`;
    }

    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + token
    });

    return this.http.get<ISite>(
      endpoint,
      {
        headers: headers
      }
    );


  }

  getCurrentSiteDetails(id: any): ISite{

    let site: ISite;
    const clientSiteList = JSON.parse(this.storageService.getStoredEncodedValue(this.storageConstants.clientSites));

    for(let i in clientSiteList){

      if(Number(clientSiteList[i].legacy_id) === id){

        site = clientSiteList[i];
        break;
      }


    }

    return site;
  }


  public getClientSites(): Observable<ISite[]>{

    const token = this.storageService.getStoredEncodedValue(this.storageConstants.token);
    const client = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);
    const newClientID = this.storageService.getStoredEncodedValue(this.storageConstants.newClientId);

    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + token
    });
    //by_client/:client_id
    //     return this.http.get<ISite[]>(`${this.baseUrl}clientSites/token/${client}`);

    // let endPoint: string = `https://clientzonebackend.cloudbms.co.za/api/sites/`;
    let endPoint: string = `${this.baseUrl}sites/by_client/${newClientID}`;

    if(client === "32767"){
      endPoint = `${this.baseUrl}sites/`;
    }

    return this.http.get<ISite[]>(`${endPoint}`, {
      headers: headers
    }).pipe(
      map( response => {

        return response.filter((site: ISite) => site.is_visible === true);

      }),
      catchError( err => {
        return [];
      })
    );

  }

  getLegacySiteDetails(id: number): Observable<ISite>{

    const storageConstants = new Constants().storage;

    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.storageService.getStoredEncodedValue(storageConstants.token)
    });

    return this.http.get<ISite>(` https://clientzone.cloudbms.co.za/api/sites/legacy/${id}`, {
      headers: headers
    });

  }

  getUserSiteList(): Observable<IUserSiteList[]>{

    return this.authService.getSpecificUserDetails()
      .pipe(
        map( (data: any) => {
          return data['sites'].map((response: any) => (
            {
              client_id: {...response['client_id']},
              latitude: parseFloat(response['latitude']),
              longitude: parseFloat(response['longitude']),
              name: response['name'],
              _id: response['_id'],
              legacy_id: response['legacy_id']
            }
          ))
        })
      );

  }

}
