import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';

import { registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import en from '@angular/common/locales/en';
import {AntDesignModule} from './modules/AntDesign.module';

import { ClientSelectionComponent } from './pages/client-selection/client-selection.component';
import { SigninComponent } from './pages/signin/signin.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AdminComponent } from './layouts/admin/admin.component';
import { DevicesComponent } from './pages/devices/devices.component';
import { DeviceAlarmsListComponent } from './components/devices/device-alarms-list/device-alarms-list.component';
import { HvacInfoComponent } from './components/devices/hvac-info/hvac-info.component';
import { GeneratorInfoComponent } from './components/devices/generator-info/generator-info.component';
import { FuelInfoComponent } from './components/devices/fuel-info/fuel-info.component';
import { ChillerInfoComponent } from './components/devices/chiller-info/chiller-info.component';
import { BatteriesInfoComponent } from './components/devices/batteries-info/batteries-info.component';
import { UpsInfoComponent } from './components/devices/ups-info/ups-info.component';
import { RectifierInfoComponent } from './components/devices/rectifier-info/rectifier-info.component';
import { AcPowerMeterInfoComponent } from './components/devices/ac-power-meter-info/ac-power-meter-info.component';
import { DcPowerMeterInfoComponent } from './components/devices/dc-power-meter-info/dc-power-meter-info.component';
import { ChangeOverInfoComponent } from './components/devices/change-over-info/change-over-info.component';

import {NoCacheHeadersInterceptor} from './utils/CacheInterceptor';
import { OverlayLoaderComponent } from './components/common/overlay-loader/overlay-loader.component';
import { AlarmSummaryComponent } from './pages/alarm-summary/alarm-summary.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { SiteMapComponent } from './components/common/site-map/site-map.component';
import { ReadingsResultComponent } from './components/common/readings-result/readings-result.component';
import { DeviceTrendingComponent } from './pages/device-trending/device-trending.component';
import {InstanceDirective} from './pages/device-trending/instance.directive';


import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TemperatureSensorInfoComponent } from './components/devices/temperature-sensor-info/temperature-sensor-info.component';
import { TicketListComponent } from './pages/tickets/ticket-list/ticket-list.component';
import { CreateTicketComponent } from './pages/tickets/create-ticket/create-ticket.component';
import { ViewTicketComponent } from './pages/tickets/view-ticket/view-ticket.component';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';
import { AsyncPipe } from '@angular/common';
import {MessagingService} from './services/messaging/messaging.service';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { DistributionComponent } from './components/devices/distribution/distribution.component';
import { AdhockViewComponent } from './pages/tickets/adhock-view/adhock-view.component';
import { FuelConsumptionComponent } from './pages/reports/fuel-consumption/fuel-consumption.component';
import { GetCountryService } from './services/getcountry/get-country.service';
import {NotificationBarComponent} from './components/notifications/notification-bar/notification-bar.component';

import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more';
import * as exporting from 'highcharts/modules/exporting';
import * as exportData from 'highcharts/modules/export-data';
import * as drillDown from 'highcharts/modules/drilldown';
import * as data from 'highcharts/modules/data';
import * as accessibility from 'highcharts/modules/accessibility';
import * as solidgauge from 'highcharts/modules/solid-gauge';


import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { ClientAlarmSummaryComponent } from './pages/client-alarm-summary/client-alarm-summary.component';
import { DashboardStatisticsComponent } from './components/common/dashboard-statistics/dashboard-statistics.component';
import { PowerMeterDashboardComponent } from './components/dashboards/power-meter-dashboard/power-meter-dashboard.component';
import { ClientSiteListComponent } from './pages/client-site-list/client-site-list.component';
import { FuelDashboardsComponent } from './components/dashboards/fuel-dashboards/fuel-dashboards.component';
import { RectifierDashboardsComponent } from './components/dashboards/rectifier-dashboards/rectifier-dashboards.component';
import { GaugeChartComponent } from './components/charts/guage-chart/gauge-chart.component';
import { HvacDashboardComponent } from './components/dashboards/hvac-dashboard/hvac-dashboard.component';
import { UpsDashboardComponent } from './components/dashboards/ups-dashboard/ups-dashboard.component';
import { DashboardPageHeaderComponent } from './components/utils/dashboard-page-header/dashboard-page-header.component';
import { SolidGaugeChartComponent } from './components/charts/solid-guage-chart/solid-gauge-chart.component';
import { StackedBarChartComponent } from './components/charts/stacked-bar-chart/stacked-bar-chart.component';
import { RealtimeAlarmsComponent } from './components/common/realtime-alarms/realtime-alarms.component';
import { TemperatureReadingsComponent } from './pages/reports/temperature-readings/temperature-readings.component';
import { GeneratorReportComponent } from './pages/reports/generator-report/generator-report.component';
import { UnattendedNotificationsComponent } from './components/notifications/unattended-notifications/unattended-notifications.component';
import { SiteNotificationsListComponent } from './components/notifications/unattended-notifications/site-notifications-list/site-notifications-list.component';
import { SiteUnattendedNotificationsComponent } from './components/notifications/unattended-notifications/site-unattended-notifications/site-unattended-notifications.component';
import { NotificationListItemComponent } from './components/notifications/notification-list-item/notification-list-item.component';
import { CreateServiceDeskTicketComponent } from './components/tickets/create-service-desk-ticket/create-service-desk-ticket.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import {PowerSystemsComponent} from './pages/reports/power-systems/power-systems.component';
import {NzCommentModule} from 'ng-zorro-antd/comment';
import {NzTimelineModule} from 'ng-zorro-antd/timeline';
import {NzCarouselModule} from 'ng-zorro-antd/carousel';
import {NzPopoverModule} from 'ng-zorro-antd/popover';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzSpinModule} from 'ng-zorro-antd/spin';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import { TemperatureSensorDashboardComponent } from './components/dashboards/temperature-sensor-dashboard/temperature-sensor-dashboard.component';
import {NzTimePickerModule} from 'ng-zorro-antd/time-picker';
import { AlarmManagementPanelComponent } from './components/alarm-management-panel/alarm-management-panel.component';
import { AlarmListItemComponent } from './components/alarm-management-panel/alarm-list-item/alarm-list-item.component';
import { UserNotificationSettingsComponent } from './components/settings/user-notification-settings/user-notification-settings.component';
import { SettingsPanelComponent } from './components/settings/settings-panel/settings-panel.component';
import { PduComponent } from './components/devices/pdu/pdu.component';
import { WaterTankInfoComponent } from './components/devices/water-tank-info/water-tank-info.component';
import { SiteListComponent } from './pages/site-list/site-list.component';



registerLocaleData(en);

const ngZorroConfig: NzConfig = {
  notification: { nzMaxStack: 100, nzTop: "70px" }
};

@NgModule({
  declarations: [
    AppComponent,
    ClientSelectionComponent,
    SigninComponent,
    DashboardComponent,
    AdminComponent,
    DevicesComponent,
    DeviceAlarmsListComponent,
    HvacInfoComponent,
    GeneratorInfoComponent,
    FuelInfoComponent,
    ChillerInfoComponent,
    BatteriesInfoComponent,
    UpsInfoComponent,
    RectifierInfoComponent,
    AcPowerMeterInfoComponent,
    DcPowerMeterInfoComponent,
    ChangeOverInfoComponent,
    OverlayLoaderComponent,
    AlarmSummaryComponent,
    HeaderComponent,
    SiteMapComponent,
    ReadingsResultComponent,
    DeviceTrendingComponent,
    InstanceDirective,
    ClientAlarmSummaryComponent,
    TemperatureSensorInfoComponent,
    TicketListComponent,
    CreateTicketComponent,
    ViewTicketComponent,
    UserManagementComponent,
    DistributionComponent,
    AdhockViewComponent,
    FuelConsumptionComponent,
    ChangePasswordComponent,
    PageNotFoundComponent,
    HomeComponent,
    FooterComponent,
    DashboardStatisticsComponent,
    PowerMeterDashboardComponent,
    ClientSiteListComponent,
    FuelDashboardsComponent,
    RectifierDashboardsComponent,
    GaugeChartComponent,
    HvacDashboardComponent,
    UpsDashboardComponent,
    DashboardPageHeaderComponent,
    SolidGaugeChartComponent,
    StackedBarChartComponent,
    RealtimeAlarmsComponent,
    TemperatureReadingsComponent,
    GeneratorReportComponent,
    NotificationBarComponent,
    UnattendedNotificationsComponent,
    SiteNotificationsListComponent,
    SiteUnattendedNotificationsComponent,
    NotificationListItemComponent,
    CreateServiceDeskTicketComponent,
    PrivacyPolicyComponent,
    PowerSystemsComponent,
    TemperatureSensorDashboardComponent,
    AlarmManagementPanelComponent,
    AlarmListItemComponent,
    UserNotificationSettingsComponent,
    SettingsPanelComponent,
    PduComponent,
    WaterTankInfoComponent,
    SiteListComponent,

  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AntDesignModule,
        ReactiveFormsModule,
        Ng2SearchPipeModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        ChartModule,
        NzCommentModule,
        NzTimelineModule,
        NzCarouselModule,
        NzPopoverModule,
        NzBadgeModule,
        NzSpinModule,
        NzProgressModule,
        NzToolTipModule,
        NzTimePickerModule,

    ],
  providers: [
    { provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide : LocationStrategy ,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    },
    { provide: NZ_CONFIG,
      useValue: ngZorroConfig
    },
    AsyncPipe, MessagingService, GetCountryService,
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [ more, exporting, exportData, solidgauge, drillDown, data, accessibility]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
