import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetCountryService {

  constructor(private http: HttpClient) {}

  getCountry(lat: string, lng: string): Observable<any>{
    let passUrl = `https://api.tiles.mapbox.com/v4/geocode/mapbox.places-country-v1/${lat},${lng}.json?access_token=pk.eyJ1IjoicGxhZ2Fub21pY3MiLCJhIjoiY2s5OHFsMGVoMDh2NjNxcm84YnkydG9tYiJ9.UcTtQVnh0b0YNIZJavaTtw`;  
    return this.http.get<any>(passUrl);
  }
}
