import {Component, OnInit, Input, Output, EventEmitter, SimpleChange, OnChanges, SimpleChanges, OnDestroy} from '@angular/core';
import {IHvac} from '../../../Models/DeviceInstances/IHvac';
import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';
import {interval, Subscription} from 'rxjs';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';

@Component({
  selector: 'app-hvac-info',
  templateUrl: './hvac-info.component.html',
  styleUrls: ['./hvac-info.component.scss']
})

export class HvacInfoComponent implements OnInit, OnDestroy {

  hasReading: boolean;

  @Output() failedReading = new EventEmitter<boolean>();

  @Input() readings: IDeviceReadingsProps;

  requestInterval = interval(10000);
  intervalSubscriber: Subscription;
  currentDeviceReadings : IHvac;

  isLoadingData : boolean;

  constructor(private analogDeviceService: AnalogDevicesService) { }

  ngOnInit(): void {
    this.isLoadingData = true;

    this.getDeviceReadings();
    this.hasReading = this.readings != null;

  }

  emitReadingError(): void{
  this.failedReading.emit(true);

  }



  getDeviceReadings(): void{

    this.isLoadingData = true;

    this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId, this.readings.selectedDeviceTypeId, this.readings.controllerId, this.readings.instanceId
    ).subscribe( response => {

      this.currentDeviceReadings = response;


      if(response === null){
        this.hasReading = false;
      }


      this.isLoadingData = false;

    }, error => {
      console.warn('HVAC', error);
    } )


  }

  ngOnDestroy(): void {

    //this.intervalSubscriber.unsubscribe();
  }

}
