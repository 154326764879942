import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {io, Socket} from 'socket.io-client';
import {ClientsService} from '../clients/clients.service';
import {AuthenticationService} from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  socket: Socket;
  clientId: string;
  userID: string;

  constructor(private clientService: ClientsService, private authenticationService: AuthenticationService,) {
    // this.socket = io("ws://localhost:5110", { transports: ["websocket"] });
    this.socket = io("https://notifications.cloudbms.co.za", );
    this.authenticationService.getSignedInUserDetails().subscribe( response => {
      this.userID = response._id;
      // this.socket = io("https://notifications.cloudbms.co.za");

      this.emit('joinRoom', this.userID);
    }, error => {
      console.error('Failed to fetch user details');
    });


    //Ensure Reconnection to room
    this.socket.io.on('reconnect', (attempt) => {
     
      this.emit('joinRoom', this.userID);

    })

    // this.socket = io("ws://localhost:5110", { transports: ["websocket"] });
    // this.socket = io("https://notifications.cloudbms.co.za");
    // this.socket = io("https://notifications.cloudbms.co.za");

    //Get the new clientID
    // this.clientId = this.storageService.getStoredEncodedValue(new Constants().storage.newClientId);

    // this.clientId = this.clientService.getLegacyClientId();
    // this.emit('joinRoom', this.clientId);
  }



  listen(eventName: string): Observable<any>{

    return new Observable( (subscriber) =>{
      this.socket.on('message', (data =>{
        subscriber.next(data);
      }))
    });

  }

  emit(eventName: string, data: any){

    this.socket.emit(eventName, data);

  }

}
