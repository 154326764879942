import { Component, OnInit } from '@angular/core';
import {NotificationsService} from '../../../services/notifications/notifications.service';
import {ISiteAlarmNotificationCount, ISiteAlarmNotificationCountTotals} from '../../../Models/notifications/ISiteAlarmNotificationCount';

@Component({
  selector: 'app-unattended-notifications',
  templateUrl: './unattended-notifications.component.html',
  styleUrls: ['./unattended-notifications.component.scss']
})
export class UnattendedNotificationsComponent implements OnInit {

  showSiteList: boolean = true;
  selectedSiteDetails: ISiteAlarmNotificationCount;

  siteNotificationCounts: ISiteAlarmNotificationCountTotals;

  constructor(private notificationsService: NotificationsService,) { }

  ngOnInit(): void {

    this.notificationsService.getClientSiteUnattendedAlarmNotificationCounts()
      .subscribe(response =>{
        this.siteNotificationCounts = response;
      }, error => {
        console.error(error);
      });
  }

  handleSiteSelected(details: ISiteAlarmNotificationCount): void{
    this.showSiteList = false;
    this.selectedSiteDetails = details;
  }

  handleBackEvent():void{
    this.showSiteList = true;
    this.selectedSiteDetails = undefined;
  }

}
