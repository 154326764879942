import { Component, OnInit } from '@angular/core';
import {UserManagementService} from '../../services/userManagement/user-management.service';
import {ICloudBmsUser} from '../../Models/ICloudBmsUser';
import {FormBuilder, Validators} from '@angular/forms';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import {SitesService} from '../../services/sites/sites.service';
import {ISite} from '../../Models/ISite';
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  users: ICloudBmsUser[];
  isLoading: boolean;
  form: any;
  confirmModal?: NzModalRef;
  isInEditState: boolean;
  selectedUserId: number;

  selectedUserDetails: ICloudBmsUser;

  clientSites: ISite[];

  constructor(private userManagementService: UserManagementService,
              private formBuilder: FormBuilder,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private sitesService: SitesService) {

    this.form = this.formBuilder.group({
      full_name: [null, Validators.required],
      email: [null, Validators.required],
      role: [null, Validators.required],
      site_id: [null, Validators.required]
    });
  }

  ngOnInit(): void {

    this.isLoading = true;
    this.getUsers();
    this.getClientSites();

  }

  getClientSites(): void{
    this.sitesService.getClientSites().subscribe( response => {

      this.clientSites = response;

    }, error => {
      console.error(error);
    } )
  }

  getUsers(): void{

    this.userManagementService.getUsers().subscribe( (response : ICloudBmsUser[]) =>{

      this.users = response;

      this.isLoading = false;
    }, error => {
      console.error(error);
      this.isLoading = false;
    } );

  }

  submitForm(formData: any): void{

    if (this.form.dirty && this.form.valid) {

      if(!this.isInEditState){
        this.createNewUser(formData);
      }else{
        this.updateUserDetails(formData);
      }

    }else{

      this.notification.error(
        'Invalid Input!',
        'Please make sure that you fill and select all details necessary before submission.'
      );

    }
  }

  setEditDetails(userDetails: ICloudBmsUser): void{
    this.isInEditState = true;
    this.selectedUserId = userDetails.id;
    this.selectedUserDetails = userDetails;



    this.form.setValue({
      full_name: userDetails.full_name,
      email: userDetails.email,
      role: userDetails.role.toString(),
      site_id: userDetails.site_id
    });

  }

  deleteUser(id: number): void{

    this.userManagementService.deleteUser(id).subscribe( response => {

      this.notification.success(
        'Operation Successful!',
        response['message']

      );
      this.getUsers();
    } , error => {
      this.notification.error(
        'Operation Failed!',
        'An error occurred whilst deleting the user, please try again.'
      );
    });

  }

  showConfirm(id: number): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: 'Are you sure you Want to delete this user?',
      nzContent: 'If you click OK, the user will be completely deleted from our system',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          this.deleteUser(id);


          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.error('Oops errors!'))
    });
  }

  createNewUser(formData: ICloudBmsUser): void{


    let userDetails : ICloudBmsUser = {

      full_name: formData.full_name,
      email: formData.email,
      password: '',
      role: formData.role,
      client_id: null,
      site_id: formData.site_id
    };

    this.userManagementService.createUser(userDetails).subscribe( response => {


      this.notification.success(
        response['message'],
        'The new user has been created and an email has been sent to notify them.'
      );

      this.getUsers();
      this.form.reset();

    }, error => {
      this.notification.error(
        'Operation Failed!',
        `${error.error.error}`
      );
    })

  }

  updateUserDetails(formData): void{

    let userDetails  = {
      full_name: formData.full_name,
      email: formData.email,
      role: formData.role,
      site_id: formData.site_id
    };


    this.userManagementService.updateUser(this.selectedUserId, userDetails).subscribe( response => {

      this.notification.success(
        response['message'],
        ''
      );
      this.form.reset();
      this.getUsers();
      this.isInEditState = false;

    }, error => {
      console.error(error);
      this.notification.error(
        'Operation Failed!',
        'An error occurred whilst Updating the user, please try again.'
      );
    } );

  }

}
