import {Component, Input, OnInit} from '@angular/core';
import {IDynamicChartData} from '../../../Models/Charts/IDynamicChartData';
import {Chart} from 'angular-highcharts';

@Component({
  selector: 'app-stacked-bar-chart',
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss']
})
export class StackedBarChartComponent implements OnInit {

  @Input() chartData: IDynamicChartData;
  @Input() optionsType: string;

  chart: Chart;
  chartOptions;

  constructor() { }

  ngOnInit(): void {

    this.renderChart();

  }

  renderChart(): void{

    //Number(this.chartData.series_data)

    this.chart = new Chart( {
      chart: {
        type: 'bar',
        width: 250,
        height: 80,
        margin: 0,
      },
      exporting: {
        enabled: false
      },
      title: null,
      xAxis: {
        labels:{
          enabled: false
        },
        categories: ['Apples']
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        labels: {
          enabled: false,
        },
        title: null
      },
      legend: {
        enabled: false,
        reversed: true
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      series: [{
        name: 'Critical',
        //@ts-ignore
        data: [5]
      }, {
        name: 'Urgent',
        //@ts-ignore
        data: [2]
      }, {
        name: 'Warning',
        //@ts-ignore
        data: [3]
      }, {
        name: 'Event',
        //@ts-ignore
        data: [3]
      }]
    });

  }

}
