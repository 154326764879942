import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {IUserActiveAlarmSite} from '../../Models/IUserActiveAlarmSite';
import {IUserSiteList} from '../../Models/IUserSiteList';
import {SitesService} from '../../services/sites/sites.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import {Router} from '@angular/router';
import {count} from 'rxjs/operators';

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss']
})

export class SiteListComponent implements OnInit {

  isLoading: boolean = true;
  siteAlarmsList: IUserActiveAlarmSite[];

  siteList: IUserSiteList[];

  searchText: string;

  sessionModalVisible: boolean = false;
  count = 10;

  constructor(private http: HttpClient, private authService: AuthenticationService, private siteService: SitesService, private router: Router,) { }

  ngOnInit(): void {

    this.siteService.getUserSiteList().subscribe( response => {
      this.siteList = response;
      this.siteList = this.siteList.sort( (a, b) => a.name.localeCompare(b.name) );

    }, error => {

      if(error.error.msg === 'Token is not valid'){
       this.sessionModalVisible = true;

        setInterval(() => {this.count -= 1;}, 1000);

        setTimeout(() => {
          this.authService.signOut();
          // modal.close();
          this.sessionModalVisible = false;
          this.router.navigate(['/login']);
        }, 10000);
      }
      else{
        this.siteList = [];
      }
    } )

    this.authService.getSpecificUserDetails().subscribe( userData => {
      this.getSiteAlarmList(userData['_id']);
    }, error => {
      console.error('Failed to get user details');
    } );

    // this.getUserDetails();

  }

  getSiteAlarmList(user_id: string) : void{

    this.http.get(`https://noderedinternal.cloudbms.co.za/api/alarms/user_site_active?user_id=${user_id}`)
      .subscribe( (response: IUserActiveAlarmSite[]) => {

        this.siteAlarmsList = response
          .sort( (a, b) => a.site_name.localeCompare(b.site_name) );

        this.isLoading = false;

      }, error => {
        console.error('Failed to get user site alarm list');
      });
  }

  determinePriorityColor(id: string) : string{

    let siteAlarmList: IUserActiveAlarmSite;
    let priorities: number[] = [];

    try{
      siteAlarmList = this.siteAlarmsList.find( (data) => data._id === id );
      priorities = [siteAlarmList.critical_alarms, siteAlarmList.urgent_alarms, siteAlarmList.warning_alarms, siteAlarmList.event_alarms];
    }
    catch(e){
      priorities = [0,0,0,0];
    }


    const highestNumberIndex = priorities.indexOf(Math.max(...priorities));
    const pList = ['Critical', 'Urgent', 'Warning', 'Event'];

    let priority =  pList[highestNumberIndex];

    if(highestNumberIndex !== 0 && highestNumberIndex !== 1){ // 0 being Critical , 1 Urgent --- Mapped to pList[]

      if(priorities[0] != 0 && priorities[1] < priorities[0]){ // If Critical is not 0 and greater that Warning
        priority = pList[0];
      }

      if(priorities[1] != 0 && priorities[0] == 0 ){
        priority = pList[1];
      }

    }
    else{
      priority = pList[highestNumberIndex];
    }

    let result = '';

    switch (priority){
      case pList[0]:
        result = '#B71C1CFF';
        break;
      case pList[1]:
        result = '#FF6F00FF'
        break;
      case pList[2]:
        result = '#FFAB40FF';
        break;
      case pList[3]:
        result = '#1976D2FF';
        break;
      default:
        result = 'lightgrey';
    }

    return result;

  }

  getSiteActiveAlarms(id: string): string{

    try{
      return this.siteAlarmsList.find( (data) => data._id === id ).active_alarms.toString();
    }
    catch(e){
      return '0';
    }

  }

}
