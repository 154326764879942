<div class="container-fluid">

  <div class="row">
    <app-dashboard-page-header
      class="col-lg-12 col-md-12 col-sm-12"
      [config]="headerConfig" (selectedSiteChange)="handleSiteChangeEmitter($event)">
    </app-dashboard-page-header>
  </div>

  <div class="row mb-1">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <nz-spin nzTip="Loading..." [nzSpinning]="isLoadingDeviceData" style="width: 100%;">
        <figure class="highcharts-figure" id="figure">
          <div [chart]="siteTemperatureReadingsChart" style="width: 90vw"></div>
        </figure>
      </nz-spin>
    </div>
  </div>



</div>
