import { Component, OnInit } from '@angular/core';
import {ISite} from '../../../Models/ISite';
import {GenericHelpers} from '../../../utils/GenericHelpers';
import {SitesService} from '../../../services/sites/sites.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {DomSanitizer} from '@angular/platform-browser';
import {PowerSystemsService} from '../../../services/reports/power-systems.service';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {StorageService} from '../../../services/storage/storage.service';
import {ClientsService} from '../../../services/clients/clients.service';
import {IClient} from '../../../Models/IClient';
import {IUserSiteList} from '../../../Models/IUserSiteList';

@Component({
  selector: 'app-power-systems',
  templateUrl: './power-systems.component.html',
  styleUrls: ['./power-systems.component.scss']
})
export class PowerSystemsComponent implements OnInit {
  selectedSiteId: string;
  selectedSiteName: string;
  clientName: string;

  siteList: IUserSiteList[];
  dateFrom: string;
  dateTo: any;

  fileDownloaded: boolean = false;
  hasErrorDownloading: boolean = false;

  isLoadingPage: boolean = true;
  isLoadingFileData: boolean = false;
  fileUrl: any;

  fileUrls: any[] = [];
  selectedDeviceNamesForFile: string[] = [];

  selectedDeviceType: string;

  date: any;

  clientID: number;

  selectedClientID: number;

  constructor(private siteService: SitesService,
              private message: NzMessageService,
              private sanitizer: DomSanitizer,
              private storageService: StorageService,
              private powerSystemsService: PowerSystemsService,
              private clientsService: ClientsService) { }

  ngOnInit(): void {

    this.siteService.getUserSiteList().subscribe( response => {
      this.siteList = response;
      this.isLoadingPage = false;
    } , error => {
      this.siteList = [];
      this.isLoadingPage = false;
    });

    this.clientID = Number(this.storageService.getStoredEncodedValue("clientId"));
    this.clientName = this.storageService.getStoredEncodedValue("selectedClientName");
  }

  onSiteSelected(site: any) : void{

    let client_id: string = '';

    let data : IUserSiteList;

    if(typeof site === 'string'){
      data = this.siteList.find( (site: IUserSiteList) => site._id === this.selectedSiteName );
    }

    if(typeof site === 'number'){
      data = this.siteList.find( (site: IUserSiteList) => site.legacy_id === Number(this.selectedSiteName) );
    }

    client_id = (data.client_id.client_id !== undefined) ? data.client_id.client_id.toString() : data.client_id._id;

    this.clientName = data.client_id.name;
    this.selectedClientID = Number(client_id);

  }

  generateReport(): void{

    this.fileUrls = [];
    this.selectedDeviceNamesForFile = [];
    this.isLoadingFileData = true;
    this.fileDownloaded = false;

    if(this.selectedDeviceType !== undefined && this.selectedDeviceType === 'Combined'){
      this.selectedSiteName = 'All Sites';
      this.selectedClientID = this.clientID;
    }



    if(this.selectedSiteName !== undefined && this.dateFrom !== undefined && this.selectedDeviceType !== undefined){


      if(this.selectedDeviceType === 'Changeover'){

        this.powerSystemsService.getChangeoverReport(this.dateFrom, this.dateTo, this.selectedClientID, this.selectedSiteName, this.clientName )
          .subscribe( (response: string) => {
            this.hasErrorDownloading = false;

            this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${ encodeURI(response)}`) ;
            this.fileDownloaded = true;
            this.isLoadingFileData = false;

          } , error => {
            console.error(error);
            this.isLoadingFileData = false;
            this.hasErrorDownloading = true;
            this.fileDownloaded = true;
          });

      }
      else{
        this.powerSystemsService.getPowerSystemPDF(this.dateFrom, this.dateTo, this.selectedDeviceType, this.selectedClientID, this.selectedSiteName)
          .subscribe(response => {
            this.hasErrorDownloading = false;

            this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64,${ encodeURI(response)}`) ;
            this.fileDownloaded = true;
            this.isLoadingFileData = false;

          }, error => {
            console.error(error);
            this.isLoadingFileData = false;
            this.hasErrorDownloading = true;
            this.fileDownloaded = true;
          });
      }

    }else{
      this.message.error('You have not selected the appropriate fields');
    }

  }

  onDateChange(result: Array<Date>): void {

    if(result !== null){

      let startDate = new Date(result[0].getFullYear(), result[0].getMonth(), result[0].getDate())
      let endDate = new Date(result[1].getFullYear(), result[1].getMonth(), result[1].getDate());
      this.dateFrom = new GenericHelpers().formatDate(startDate);
      this.dateTo = new GenericHelpers().formatDate(endDate);

    }
  }

  disabledEndDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) > 0;
  };

  clearForm(): void{

    setTimeout( () => {
      this.selectedDeviceType = undefined;
      this.selectedSiteName = undefined;
      this.dateTo = undefined;
      this.dateFrom = undefined;
      this.hasErrorDownloading = false;
      this.isLoadingFileData = false;
      this.fileDownloaded = false;
      this.fileUrl = undefined;
      this.date = undefined;

    }, 4000);

  }

}
