<div class="container-fluid mt-3">

  <div class="row">
    <nz-card class="col-lg-12 col-md-12 col-sm-12" [nzBodyStyle]="{'padding': 0, 'height': '150px'}">
      <nz-page-header nzBackIcon nzTitle="Temperature Readings">

        <nz-page-header-extra>

        </nz-page-header-extra>

        <nz-page-header-subtitle>

          <p>
            <i nz-icon nzType="info-circle" nzTheme="twotone" class="button-icon text-info"></i>
            Select a <i class="font-weight-bold">site</i>
            as well as the <i class="font-weight-bold">start</i> date
          </p>

        </nz-page-header-subtitle>

        <nz-breadcrumb nz-page-header-breadcrumb>
          <nz-breadcrumb-item>Reports</nz-breadcrumb-item>
          <nz-breadcrumb-item>
            Temperature Readings
          </nz-breadcrumb-item>
        </nz-breadcrumb>

        <nz-page-header-content>

          <div class="row">
            <div class="col-md-3 col-lg-3">
              <nz-select nzShowSearch nzAllowClear
                         nzPlaceHolder=" * Select a site"
                         [(ngModel)]="selectedSiteId" style="width: 100%">

                <nz-option *ngFor="let site of siteList"
                           [nzLabel]="site.name"
                           [nzValue]="site.legacy_id || site._id"></nz-option>

              </nz-select>
            </div>
            <div class="col-md-6 col-lg-6">
              <nz-date-picker
                [(ngModel)]="dateFrom" (ngModelChange)="onDateChange($event)"
                [nzFormat]="'yyyy-MM-dd'"
                nzPlaceHolder="Start Date"
                class="mr-4" [nzDisabledDate]="disabledEndDate"></nz-date-picker>


              <button nz-button nzType="primary"
                      (click)="getTemperatureCSV(); getTemperatureJson()"
                      [nzLoading]="isLoadingCsvData">
                Generate Report
                <i nz-icon nzType="right" class="button-icon"></i>
              </button>

            </div>
            <div class="col-md-3">
              <a nz-button nzDanger [href]="fileUrl"
                 download="{{dateFrom}}_TemperatureReport.csv" *ngIf="!hasErrorDownloading && csvDownloaded && !hasFailed">
                <i nz-icon nzType="download" nzTheme="outline" class="button-icon"></i>

                Download CSV
              </a>
            </div>
          </div>



        </nz-page-header-content>

      </nz-page-header>
    </nz-card>
  </div>

  <div class="row mt-2">
    <nz-card nzBordered="false" [nzLoading]="isLoadingCsvData" class="col-12">

      <nz-result nzStatus="info"
                 nzTitle="No report has been generated"
                 nzSubTitle="Please select a site and select a start date to generate a report"
                 *ngIf="!csvDownloaded">

      </nz-result>

      <nz-result nzStatus="error"
                 nzTitle="No data available"
                 nzSubTitle="There is no data available for this site and selected desired date range."
                 *ngIf="hasFailed && csvDownloaded">

      </nz-result>

      <div class="results-container" *ngIf="!hasErrorDownloading && csvDownloaded">
        <figure>
          <div [chart]="siteTemperatureReadingsChart"></div>
        </figure>



      </div>



    </nz-card>
  </div>

</div>


<!--<div>-->
<!--  <nz-result-->
<!--    nzStatus="success"-->
<!--    nzTitle="Your CSV File has been generated!"-->
<!--    nzSubTitle=""  *ngIf="!hasErrorDownloading && csvDownloaded"-->
<!--  >-->
<!--    <div nz-result-extra>-->
<!--      <a nz-button nzType="primary" [href]="fileUrl"-->
<!--         download="{{dateFrom}}_TemperatureReport.csv">-->
<!--        <i nz-icon nzType="file-excel" nzTheme="outline" class="button-icon"></i>-->
<!--        Download-->
<!--      </a>-->
<!--    </div>-->
<!--  </nz-result>-->

<!--  <nz-result nzStatus="warning"-->
<!--             nzTitle="Operation Failed"-->
<!--             nzSubTitle="CloudBMS Failed to generate the csv report. Please try again."-->
<!--             *ngIf="hasErrorDownloading && csvDownloaded">-->

<!--  </nz-result>-->
<!--</div>-->
