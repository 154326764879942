<header id="home" class="hero-area-2">
  <div class="overlay"></div>
  <nav class="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar" style="background: #020d26;">
    <div class="container">

      <p class="navbar-brand font-weight-bold">
        <img src="assets/round_bms_icon.png" alt="Cloud BMS"
             class="img-fluid d-inline-block align-top" height="30" width="30">
        Cloud BMS
      </p>

      <button class="navbar-toggler" type="button" data-toggle="collapse"
              data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
              aria-label="Toggle navigation">
        <i class="lni-menu"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">

        <ul class="navbar-nav mr-auto w-100 justify-content-end">

          <li class="nav-item">
            <a class="nav-link disabled" href="javascript:void(0)">
              <i nz-icon nzType="phone" nzTheme="fill" style="color: #60c6c4;"
                 class="button-icon mr-2"></i>
              <span>+27 11 792 7230</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="javascript:void(0)" routerLink="/privacy-policy">
              <i nz-icon nzType="lock" nzTheme="fill" style="color: #60c6c4;"
                 class="button-icon mr-2"></i>
              <span>Privacy Policy</span>
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link disabled" href="javascript:void(0)">
              <i nz-icon nzType="mail" nzTheme="fill" style="color: #60c6c4;"
                 class="button-icon mr-2"></i>
              <span>sales@kva.co.za</span>
            </a>
          </li>

          <li class="nav-item" [hidden]="!hasAgreedToPOPIA">
            <a class="nav-link page-scroll font-weight-bold" routerLink="/login">
              <i nz-icon nzType="login" nzTheme="outline" class="button-icon"></i>
              Login
            </a>
          </li>
        </ul>

      </div>
    </div>
  </nav>
  <div class="container bg-inverse">

    <div class="row space-100">
      <div class="col-lg-7 col-md-12 col-xs-12">
        <div class="contents">
          <h2 class="head-title">Remote Monitoring<br> With
            <span style="color: #60c6c4" class="shadow">Cloud BMS</span>
          </h2>
          <p class="carousel-holder">
            <nz-carousel [nzEffect]="effect" nzAutoPlay [nzAutoPlaySpeed]="8000"
                         [nzDotPosition]="'left'">

              <div nz-carousel-content *ngFor="let index of mainText">
                <h6 class="mb-4 font-weight-bold">
                  <i nz-icon [nzType]="index.icon" nzTheme="outline" class="button-icon"></i>
                  {{index.heading}}
                </h6>
                <span>{{index.text}}</span>
              </div>
            </nz-carousel>
          </p>
<!--          <div class="header-button">-->
<!--            <a href="#" class="btn btn-border-filled">Launch Demo</a>-->
<!--          </div>-->
        </div>
      </div>
      <nz-card nzBordered="false" [nzLoading]="sendingMessage"
               style="background: transparent !important;height: 100%;"
               class="col-lg-5 col-md-12 col-xs-12">
        <div class="intro-img pl-4">

          <nz-alert nzType="success" [nzMessage]="contactSuccessMessage"
                    class="mb-3" nzShowIcon nzCloseable *ngIf="contactMessageSent"></nz-alert>

          <nz-alert nzType="error" nzMessage="All fields must be filled in!"
                    class="mb-3" nzShowIcon nzCloseable *ngIf="contactFormHasError"
                    (nzOnClose)="contactFormHasError = false;"></nz-alert>

          <h6 class="text-white border-left pl-3">
            Get in touch with us
            <i nz-icon nzType="mail" nzTheme="fill" class="button-icon ml-2" style="color: #60c6c4"></i>
          </h6>

          <form nz-form [formGroup]="contactForm" (ngSubmit)="sendContactInformation(contactForm.value)">

                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="fullName" nzRequired>
                    <span class="text-white">Full Name</span>
                  </nz-form-label>
                  <nz-form-control [nzSpan]="24" nzErrorTip="Provide your full name">
                    <input nz-input type="text" placeholder="Full Name" formControlName="full_name">
                  </nz-form-control>

                </nz-form-item >


                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="email" nzRequired>
                    <span class="text-white">Email</span></nz-form-label>
                  <nz-form-control [nzSpan]="24"
                                   class="text-white" nzErrorTip="Provide your Email Address">
                    <input nz-input type="email" placeholder="Email Address" formControlName="email">
                  </nz-form-control>
                </nz-form-item >

              <nz-form-item>
                <nz-form-label [nzSpan]="24" nzFor="message" nzRequired>
                  <span class="text-white">Message</span>
                </nz-form-label>
                <nz-form-control [nzSpan]="24" nzErrorTip="Provide a Message">
                <textarea rows="3" nz-input formControlName="message"
                          placeholder="Please provide some detail on how we may help you."></textarea>
                </nz-form-control>
              </nz-form-item >

            <button type="submit" class="btn btn-block btn-border text-white">Submit</button>

          </form>
        </div>

      </nz-card>
    </div>
  </div>
</header>

<!-- features Section Start -->
<div id="app-features" class="section">
  <div class="container">
    <div class="section-header">
      <h3 class="font-weight-bold">Features</h3>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-12 col-xs-12">
        <div class="content-left text-right">
          <div class="box-item left">

            <div class="text">
              <h4>Insightful Alarms</h4>
              <p>
                Informed decision-making is a major benefit of <a class="text-dark" href="https://kva.co.za/building-management-system.php" rel="nofollow" target="_blank">Master Power Technologies' Remote Monitoring Solution.</a>
              </p>
            </div>
          </div>
          <div class="box-item left">

            <div class="text">
              <h4>Realtime Monitoring</h4>
              <p>
                Our customers have the benefit of real-time system performance
                information that can be accessed at any time.
              </p>
            </div>
          </div>
          <div class="box-item left">

            <div class="text">
              <h4>Data Trending</h4>
              <p>
               Trends on data collected on site can be accessed to provide useful insights, analytics and predictions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-xs-12">
        <div class="show-box">
          <img [src]="'assets/website/functions.svg'" alt="">
        </div>
      </div>
      <div class="col-lg-4 col-md-12 col-xs-12">
        <div class="content-right text-left">
          <div class="box-item right">

            <div class="text">
              <h4>Reporting</h4>
              <p>Alarming reports can be accessed and exported to CSV through Cloud BMS among other reports.</p>
            </div>
          </div>
          <div class="box-item right">

            <div class="text">
              <h4>Continuous Monitoring</h4>
              <p>
                Monitor all alarms as well as the status of secure power equipment in critical areas such as data centres.
              </p>
            </div>
          </div>
          <div class="box-item right">

            <div class="text">
              <h4>Remote Monitoring</h4>
              <p>
                <a class="text-dark" href="https://kva.co.za/life-net-remote.php" target="_blank" rel="nofollow">Remote monitoring</a>
                ensures optimal functioning of electrical equipment and pre-empts potential supply interruptions,
                while keeping human intervention to the absolute minimum.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- features Section End -->


<!-- Features Section Start -->
<div id="features-two" class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="img-thumb wow fadeInLeft" data-wow-delay="0.3s">
          <img class="img-fluid" src="assets/website/remote_monitoring.svg" alt="">
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="text-wrapper wow fadeInRight" data-wow-delay="0.6s">
          <div>
            <h3>Remote Monitoring</h3>
            <p>
              Cloud BMS enables teams to monitor all alarms as well as the status of
              <a class="text-dark" href="https://kva.co.za/universal-controller.php" rel="nofollow" target="_blank">secure power equipment</a>
              in critical areas such as data centres.
              This includes environmental monitoring of indicators such as humidity and temperature,
              as well as detection of water leaks and intrusions.
            </p>

          </div>
        </div>
      </div>
    </div>
    <div class="row mt">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="text-wrapper wow fadeInRight" data-wow-delay="0.9s">
          <div>
            <h3>Informed Decision-Making</h3>
            <p>A major benefit of <a class="text-dark" href="https://kva.co.za" rel="nofollow" target="_blank">MPT’s</a> Cloud BMS remote monitoring solution is Informed Decision Making.
              Our customers have the benefit of real-time system performance information that can be accessed through
              a browser at any time,
              as well as longer term trends that become evident when analysing the equipment log
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <div class="img-thumb wow fadeInLeft" data-wow-delay="1.2s">
          <img class="img-fluid" src="assets/website/decision_making.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Features Section End -->


<!-- Clients Section -->
<div id="clients" class="section">
  <!-- Container Ends -->
  <h3 class="text-center font-weight-bold">Our Clients</h3>
  <div class="container client-carousel-holder">

    <nz-carousel [nzEffect]="effect" nzAutoPlay [nzAutoPlaySpeed]="5000">
      <div nz-carousel-content *ngFor="let index of clientsArray">
        <div class="row" id="clients-scroller">
          <div class="client-item-wrapper col" *ngFor="let client of index"
               nz-popover [nzPopoverContent]="client.name">
            <img [src]="client.image" alt="{{client.image}}" width="200">
          </div>
        </div>
      </div>
    </nz-carousel>

  </div>
</div>
<!-- Client Section End -->

<!-- Subcribe Section Start -->
<div id="subscribe" class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-7 col-md-12 col-xs-12">
        <div class="subscribe-form">
          <div class="form-wrapper">
            <div class="sub-title text-center">
              <h3>Continuous Monitoring In The Cloud</h3>
              <p>Continuous monitoring allows proactive and corrective maintenance;
                remote monitoring introduces the kind of flexibility that an unpredictable world demands.
              </p>
<!--              <a href="#" class="btn btn-border-filled mt-3">Launch Demo</a>-->
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Subcribe Section End -->

<!-- download Section Start -->
<section id="download">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="download-thumb wow fadeInLeft" data-wow-delay="0.2s">
          <img class="img-fluid" [src]="'assets/website/mobile.svg'" alt="Mobile Applications">
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="download-wrapper wow fadeInRight" data-wow-delay="0.2s">
          <div>
            <div class="download-text">
              <h4 class="font-weight-bold">Download Our Mobile Application</h4>

            </div>

            <a href="https://play.google.com/store/apps/details?id=kva.co.za.mobile_BMS" target="_blank"
               nz-button nzType="default" class="mr-4">
              <i nz-icon nzType="android" nzTheme="outline" class="button-icon"></i>
              PlayStore
            </a>
            <a href="https://apps.apple.com/us/app/id1484321889" target="_blank"
               nz-button nzType="default">
              <i nz-icon nzType="apple" nzTheme="outline" class="button-icon"></i>
              AppStore
            </a>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- download Section Start -->



<!-- Copyright Start  -->
<div class="copyright">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="site-info float-left">
          <p>&copy; {{currentYear}}
            <a href="http://www.kva.co.za" rel="nofollow">Master Power Technologies | Cloud BMS Remote Monitoring Solution</a> All rights reserved.</p>
        </div>
        <div class="float-right">
          <ul class="footer-social">
            <li>
              <a class="facebook" href="https://web.facebook.com/masterpowertechnologies/" target="_blank">
                <i class="lni lni-facebook-filled"></i>
              </a>
            </li>

            <li>
              <a class="linkedin" href="http://www.linkedin.com/company/118985?trk=tyah&trkInfo=tarId%3A1401959351751%2Ctas%3Amaster%20power%20techn%2Cidx%3A1-1-1" target="_blank">
                <i class="lni lni-linkedin-original"></i>
              </a>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Copyright End -->

