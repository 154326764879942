import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ISite} from '../../../Models/ISite';
import {SitesService} from '../../../services/sites/sites.service';
import {ITicket} from '../../../Models/Tickets/ITicket';
import {TableColumnItem} from '../../../Models/IAlarmTableInterfaces';
import { ITicketTableItem } from '../../../Models/ITicketTableInterfaces';
import {TicketService} from '../../../services/tickets/ticket.service';
import {GenericHelpers} from '../../../utils/GenericHelpers';
@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss']
})
export class TicketListComponent implements OnInit {

  selectedSiteId: number;
  currentSiteDetails: ISite;

  loadingTickets: boolean;
  loadingText: string;

  tickets: ITicket[];

  ticketStatusList = new GenericHelpers().ticketStatus;

  searchText: string;

  totalCriticalTickets: number = 0;
  totalOpenTickets: number = 0;


  listOfColumns: TableColumnItem[] = [
    // {
    //   name: ''
    // },
    {
      name: 'Reference',
      sortOrder: null,
      sortFn: (a: ITicketTableItem, b: ITicketTableItem) => a.reference.localeCompare(b.reference),
    },
    {
      name: 'Subject',
      sortOrder: null,
      sortFn: (a: ITicketTableItem, b: ITicketTableItem) =>
        a.subject.localeCompare(b.subject),
    },
    {
      name: 'Site',
      sortOrder: null,
      sortFn: (a: ITicketTableItem, b: ITicketTableItem) =>
        a.site_name.localeCompare(b.site_name),
    },
    {
      name: 'Urgency',
      sortOrder: null,
      sortFn: (a: ITicketTableItem, b: ITicketTableItem) =>
        a.urgency.localeCompare(b.urgency),
      filterMultiple: true,
      listOfFilter: [
        { text: 'Critical', value: 'Critical'},
        { text: 'Urgent', value: 'Urgent'},
        { text: 'Warning', value: 'Warning'},
        { text: 'Event', value: 'Event' }
      ],
      filterFn: (list: string[], item: ITicketTableItem) =>
      {

        for(let i in list){

          if(item.urgency === list[i]){
            return true;
          }

        }

        return false;
      }
    },
    {
      name: 'Status',
      sortOrder: null,
      sortFn: (a: ITicketTableItem, b: ITicketTableItem) =>
        a.urgency.localeCompare(b.status),
      filterMultiple: true,
      listOfFilter: [
        { text: this.ticketStatusList.unAssigned , value: this.ticketStatusList.unAssigned},
        { text: this.ticketStatusList.assigned, value: this.ticketStatusList.assigned},
        { text: this.ticketStatusList.rejected, value: this.ticketStatusList.rejected},
        { text: this.ticketStatusList.inProgress, value: this.ticketStatusList.inProgress },
        { text: this.ticketStatusList.closed, value: this.ticketStatusList.closed },
        { text: this.ticketStatusList.review, value: this.ticketStatusList.review },

      ],
      filterFn: (list: string[], item: ITicketTableItem) =>
      {

        for(let i in list){

          if(item.status === list[i]){
            return true;
          }

        }

        return false;
      }
    },
    {
      name: 'Due Date',
      sortOrder: null,
      sortFn: (a: ITicketTableItem, b: ITicketTableItem) => a.due_date.toString().localeCompare(b.due_date.toString())
    },
    {
      name: 'Assigned User',
     // sortOrder: null,
      //sortFn: (a: ITicketTableItem, b: ITicketTableItem) => a.assigned_full_name.localeCompare(b.assigned_full_name)
    },
    {
      name: 'Date Created ',
      sortOrder: 'descend',
      sortFn: (a: ITicketTableItem, b: ITicketTableItem) => a.created_at.toString().localeCompare(b.created_at.toString()),
      sortDirections: ['ascend', 'descend']
    },

    {
      name: '',
    },
  ];

  expandSet = new Set<number>();
  onExpandChange(id: number, checked: boolean): void {



    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }


  constructor(private route: ActivatedRoute,
              private siteService: SitesService,
              private ticketService: TicketService) {

    // this.route.params.subscribe( params => {
    //   this.selectedSiteId = parseInt(params.siteId);
    //
    //   this.currentSiteDetails = this.siteService.getCurrentSiteDetails(this.selectedSiteId);
    //
    // } );

  }


  ngOnInit(): void {

    this.loadingTickets = true;
    this.loadingText = "Fetching Tickets...";

    this.ticketService.clientTickets().subscribe( (response: ITicket[]) => {


      let formattedResponse : ITicket[] = [];

      for(let i in response){

        let currentTicket: ITicket = response[i];
         currentTicket.created_at =  new Date(currentTicket.created_at.toString().replace('.000000Z', ''));

        formattedResponse.push(currentTicket);

        if(currentTicket.urgency === 'Critical'){
          this.totalCriticalTickets += 1;
        }

        if(currentTicket.status === 'Unassigned'){
          this.totalOpenTickets += 1;
        }

      }

      this.tickets = formattedResponse;

      this.loadingTickets = false;


    }, error => {
      console.error(error);
      this.loadingTickets = false;
    } );

  }

}
