import { Injectable } from '@angular/core';
import {Constants} from '../../../utils/Contstants';
import {HttpClient} from '@angular/common/http';
import {StorageService} from '../../storage/storage.service';
import {Observable} from 'rxjs';
import {ISiteUpsData} from '../../../Models/Dashboards/ISiteUpsData';

@Injectable({
  providedIn: 'root'
})
export class UpsDashboardService {

  private baseUrl = new Constants().noderedInternal;
  private storageConstants = new Constants().storage;

  constructor(private http: HttpClient, private storageService: StorageService) { }

  public getSiteUpsData(site_id: string): Observable<ISiteUpsData>{

    // return this.http.get<ISiteUpsData>(`${this.baseUrl}dashboards/ups/siteUpsData/${site_id}`);
    return this.http.get<ISiteUpsData>(`${this.baseUrl}dashboards/ups/site_ups?site_id=${site_id}`);

  }
}
