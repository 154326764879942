<nz-card  [nzLoading]="isLoadingData" nzBordered="false"
          [nzBodyStyle]="{'padding': 'auto 0'}" style="width: 100%">
  <nz-page-header class="mb-0 p-0">
    <nz-page-header-title>
      <div class="media">
        <img [src]="deviceTypeImage" alt="Device Icon" width="50" height="50" class="mr-3">
        <div class="media-body">
          <h2 nz-typography class="mt-0">{{config.device_group}} DASHBOARD</h2>

        </div>

      </div>
    </nz-page-header-title>

    <nz-page-header-subtitle>
      <h4 nz-typography>{{selectedSite?.name}}</h4>
    </nz-page-header-subtitle>

    <nz-page-header-extra>


    </nz-page-header-extra>

    <nz-page-header-content>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <nz-select nzShowSearch nzAllowClear
                       [nzPlaceHolder]="(selectedSiteIndex !== null) ? selectedSite?.name : 'Select a client'"
                       [nzLoading]="isLoadingData"
                       [(ngModel)]="selectedSite"
                       (ngModelChange)="emitSiteChange($event)"
                       style="width: 100%;">
              <nz-option *ngFor="let site of siteList; let i = index;"
                         nzLabel="{{site.name}}"
                         [nzValue]="site"
                         (click)="selectedSiteIndex = i"
              ></nz-option>
            </nz-select>
          </div>

          <div class="col-md-4 col-lg-4 col-sm-4">
            <nz-button-group>
              <button nz-button nzType="default"
                      nzSize="default" class="mr-2"
                      (click)="previousSite()"
                      [disabled]="selectedSiteIndex === 0">
                <i nz-icon nzType="left" class="button-icon"></i>
                Prev
              </button>
              <button nz-button nzType="default"
                      nzSize="default" (click)="nextSite()"
                      [disabled]="selectedSiteIndex === siteList?.length">
                Next
                <i nz-icon nzType="right" class="button-icon"></i>
              </button>
            </nz-button-group>
          </div>

        </div>
      </div>
    </nz-page-header-content>

  </nz-page-header>
</nz-card>
<nz-divider></nz-divider>
