<!-- Copyright Start  -->
<div class="copyright mt-0">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="site-info float-left">
          <p>&copy; {{currentYear}}
            <a href="http://www.kva.co.za" rel="nofollow">Master Power Technologies.</a> All rights reserved.</p>
        </div>
        <div class="float-right">

        </div>
      </div>
    </div>
  </div>
</div>
<!-- Copyright End -->
