<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-sm-12 col-lg-6">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input type="text" nz-input placeholder="Search here..." [(ngModel)]="searchText"/>
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
    </div>
  </div>
</div>
<nz-divider></nz-divider>
<ul class="list-group">
  <li class="list-group-item d-flex justify-content-between align-items-center"
      *ngFor="let site of siteNotificationCounts?.sites| filter: searchText" (click)="selectSite(site)">
    {{site.site_name}}
    <nz-badge [nzCount]="site.total" [nzOverflowCount]="1000"></nz-badge>
  </li>
</ul>
