import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ClientsService} from '../../services/clients/clients.service';
import {IClient} from '../../Models/IClient';
import {GenericHelpers} from '../../utils/GenericHelpers';
import {StorageService} from '../../services/storage/storage.service';
import {Constants} from '../../utils/Contstants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

  isLoadingData: boolean = false;
  currentClient: IClient;
  clientIcon: string;
  private storageConstants = new Constants().storage;
  constructor(private clientService: ClientsService,
              private storageService: StorageService) {
  }

  ngOnInit(): void {

    //this.currentClient = this.clientService.getCurrentClient();
    this.clientService
      .getClient(this.storageService
        .getStoredEncodedValue(this.storageConstants.newClientId)).subscribe( response =>{
      this.currentClient = response;
      this.clientIcon = new GenericHelpers().clientIcon(Number(this.currentClient.client_id));
    }, error => {
      console.error(error);
    });


  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  noIconLoaded(data): void{

    this.clientIcon = 'assets/round_bms_icon.png';
  }



}
