<div class="container-fluid mt-3">
  <div class="row">
    <nz-card class="col-lg-6 col-md-6 col-sm-12" [nzBodyStyle]="{'padding': 0, 'height': '150px'}">
      <nz-page-header nzBackIcon>
        <nz-page-header-title>Ticket</nz-page-header-title>
        <nz-page-header-subtitle>

        </nz-page-header-subtitle>
        <nz-page-header-extra>

          <!--      <button nz-button nzType="primary" >Assign User</button>-->
          <div *ngIf="isAdminUser">
            <button nz-button nzType="default" *ngIf="isClosable" (click)="closeTicket()" class="mr-3">Close Ticket</button>
            <button nz-button nzType="danger" *ngIf="canReject" (click)="openDrawer()">Reject Ticket</button>
          </div>
        </nz-page-header-extra>

      </nz-page-header>
    </nz-card>
    <nz-card class="col-lg-6 col-md-6 col-sm-12" [nzBodyStyle]="{'height': '150px'}">
      <div class="row">
        <div class="col-12">
          <nz-input-group nzSearch *ngIf="isAdminUser && ticket?.status !== 'Closed' ">
            <nz-select nzShowSearch nzPlaceHolder="Select a user to assign" style="width: 60%"
                       [(ngModel)]="selectedUser"
                       (ngModelChange)="initiateUserChange($event)" nzAllowClear>
              <nz-option  *ngFor="let user of userList"
                          [nzLabel]="user.full_name.toUpperCase()" [nzValue]="user"></nz-option>
            </nz-select>

            <button nz-button nzType="primary" class="ml-2"
                    (click)="assignUser()" >Assign User</button>

          </nz-input-group>
        </div>
      </div>
    </nz-card>
  </div>

  <div class="row">
    <nz-card class="col-lg-6 col-md-6 col-sm-12" [nzBodyStyle]="{'padding': 0, 'height': '60vh'}">
      <nz-page-header nzTitle="Details">
      </nz-page-header>
      <div class="table-responsive">
        <table class="table info-table table-striped">
          <tbody>
          <tr>
            <th scope="row">Reference #</th>
            <td>{{ticket?.reference}}</td>
          </tr>
          <tr>
            <th scope="row">Urgency</th>
            <td>
              <nz-tag nzColor="red" *ngIf="ticket?.urgency === 'Critical'">
                Critical
              </nz-tag>
              <nz-tag nzColor="#ffa000" *ngIf="ticket?.urgency === 'Urgent'">
                Urgent
              </nz-tag>

              <nz-tag nzColor="warning" *ngIf="ticket?.urgency === 'Warning'">
                Warning
              </nz-tag>
              <nz-tag nzColor="blue" *ngIf="ticket?.urgency === 'Event'">
                Event
              </nz-tag>
            </td>
          </tr>
          <tr>
            <th scope="row">Subject</th>
            <td>{{ticket?.subject}}</td>
          </tr>
          <tr>
            <th scope="row">Status</th>
            <td>
              <nz-tag nzColor="#ffa000" *ngIf="ticket?.status === 'Unassigned'">
                Unassigned
              </nz-tag>
              <nz-tag nzColor="red" *ngIf="ticket?.status === 'Rejected'">
                Rejected
              </nz-tag>

              <nz-tag nzColor="warning" class="priority" *ngIf="ticket?.status === 'Re-Opened'">
                {{ ticket?.status }}
              </nz-tag>

              <nz-tag nzColor="blue" *ngIf="ticket?.status === 'Assigned'">
                Assigned
              </nz-tag>
              <nz-tag nzColor="blue" *ngIf="ticket?.status === 'In Progress'">
                In Progress
              </nz-tag>
              <nz-tag nzColor="success" *ngIf="ticket?.status === 'Closed'">
                Closed
              </nz-tag>
            </td>
          </tr>
          <tr>
            <th scope="row">Description</th>
            <td>{{ticket?.description}}</td>
          </tr>
          <tr>
            <th scope="row">Due Date</th>
            <td>{{ticket?.due_date}}</td>
          </tr>
          <tr>
            <th scope="row">Assigned User</th>
            <td>{{ (ticket?.assigned_full_name === null) ? 'N/A' : ticket?.assigned_full_name  | uppercase}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </nz-card>

    <nz-card class="col-lg-3 col-md-3 col-sm-12">
      <nz-page-header nzTitle="Comments">
      </nz-page-header>
      <div class="mt-2">
        <nz-comment>
          <nz-avatar nz-comment-avatar nzIcon="user"></nz-avatar>
          <nz-comment-content>
            <nz-form-item>
              <textarea [(ngModel)]="inputValue" nz-input rows="3"></textarea>
            </nz-form-item>
            <nz-form-item>
              <button nz-button nzType="primary" [nzLoading]="submitting" [disabled]="!inputValue" (click)="handleSubmit()">
                Add Comment
              </button>
            </nz-form-item>
          </nz-comment-content>
        </nz-comment>
        <nz-divider></nz-divider>
        <span style="font-weight: bold;">Comments : ({{ticketComments?.length}}) - <span class="x-small-text">Scroll to view more</span></span>
        <nz-list *ngIf="ticketComments.length" [nzDataSource]="ticketComments"
                 [nzRenderItem]="item"
                 [nzItemLayout]="'horizontal'" style="height: 30vh;" class="scrollable-div">
          <ng-template #item let-item>
            <nz-comment nzAuthor="{{item?.created_by | uppercase}}" nzDatetime="{{item?.created_at | date : 'medium'}}">
              <nz-avatar nz-comment-avatar nzIcon="user"
                         nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
              <nz-comment-content>
                <p>
                  {{item?.comment}}
                </p>
              </nz-comment-content>
            </nz-comment>
          </ng-template>
        </nz-list>
      </div>
    </nz-card>

    <nz-card class="col-lg-3 col-md-3 col-sm-12" [nzBodyStyle]="{'height': '70vh', 'overflow-y': 'scroll'}">
      <nz-page-header>
        <nz-page-header-title>Activity Log</nz-page-header-title>
        <nz-page-header-content>
          <div class="mt-4 pt-3 vertical-scrollable-div">
            <nz-timeline>
              <nz-timeline-item class="mt-2"
                                *ngFor="let log of ticketLogs" [nzColor]="log.status_colour">
                {{log.description}}
              </nz-timeline-item>

            </nz-timeline>
          </div>
        </nz-page-header-content>

      </nz-page-header>

    </nz-card>

  </div>

</div>


<nz-drawer [nzClosable]="false" [nzVisible]="rejectDrawerVisible"
           [nzPlacement]="'bottom'" nzTitle="Ticket Rejection - Are you sure?" (nzOnClose)="closeDrawer()">

  <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
    <input type="text" nz-input placeholder="Type Your Reason" [(ngModel)]="rejectionReason" />
  </nz-input-group>

  <ng-template #suffixButton>
    <button nz-button nzType="default" nzSize="large"  (click)="performRejection()"
            nzSearch>
      Reject Ticket
    </button>
  </ng-template>

</nz-drawer>

<app-overlay-loader [isLoading]="loadingTicket" [loadingText]="'Retrieving Ticket Information...'"></app-overlay-loader>
