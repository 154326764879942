import {IActiveAlarm} from '../Models/IActiveAlarm';
import {toBase64String} from '@angular/compiler/src/output/source_map';
import {HttpClient} from '@angular/common/http';

export class GenericHelpers {


  constructor() {
  }

  public AlarmPriority = {
    Event : 0,
    Warning : 1,
    Urgent : 2,
    Critical : 3,
    MPT : 4,
    Comms : 5,
    Cleared : -1,
    Unknown : -2,
    Warmup : 100,
    Warmup_cleared : -100
  };

  public ticketStatus = {
    unAssigned: 'Unassigned',
    assigned: 'Assigned',
    rejected: 'Rejected',
    inProgress: 'In Progress',
    closed: 'Closed',
    review: 'Review',

  };

  public formatDate(date: Date): string{

    const dd = date.getDate();
    const mm = date.getMonth()+1;
    const yyyy = date.getFullYear();

    let day = (dd < 10) ?  `0${dd}` : dd;
    let month = (mm<10) ? `0${mm}`: mm;

    return `${yyyy}-${month}-${day}`;

  }

  public getAlarmPriorityTypes(){

    const Event = 0,
      Warning = 1,
      Urgent = 2,
      Critical = 3,
      MPT = 4,
      Comms = 5,
      Cleared = -1,
      Unknown = -2,
      Warmup = 100,
      Warmup_cleared = -100;
  }

  public getAlarmDuration(startDate){

    const today : any = new Date();
    let start : any = new Date(startDate);
    let diffMs = (today - start); // milliseconds between now & Christmas
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

   return diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes";

  }

  public deviceImages(device_id: number) : string{

    let path = 'assets/icons/Devices/';
    return `${path}${device_id}.png`;

  }

  public getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result!.toString()));
    reader.readAsDataURL(img);
  }

  public clientIcon(id: number): string{

    return `assets/icons/client_logos/client_${id}.png`;

  }

  public removeDuplicateObject(list: Array<any>): Array<any>{

    const unique = new Set(list.map(item => JSON.stringify(item)));
    return Array.from(unique).map(e => JSON.parse(e));


  }

  public getDeviceNameFromType(type: number): string{

    const deviceMappings = [
      {
        "value": 0,
        "name": "Universal Controller"
      },
      {
        "value": 1,
        "name": "Utility"
      },
      {
        "value": 2,
        "name": "Generator"
      },
      {
        "value": 3 ,
        "name": "Change Over"
      },
      {
        "value": 4 ,
        "name": "Distribution"
      },
      {
        "value": 5 ,
        "name": "HVAC"
      },
      {
        "value": 6 ,
        "name": "UPS"
      },
      {
        "value": 7 ,
        "name": "Rectifier"
      },
      {
        "value": 8 ,
        "name": "Power Meter"
      },
      {
        "value": 9,
        "name": "DC Power Meter"
      },
      {
        "value": 10,
        "name": "Batteries"
      },
      {
        "value": 11,
        "name": "Mobile Generator"
      },
      {
        "value": 12,
        "name": "Building Management"
      },
      {
        "value": 13,
        "name": "Fire Panel",
      },
      {
        "value": 14 ,
        "name": "Temperature Sensor"
      },

      {
        "value": 15,
        "name": "Liquid Sensor",
      },
      {
        "value": 16,
        "name": "Security",
      },
      {
        "value": 17,
        "name": "Door",
      },
      {
        "value": 18,
        "name": "Motion Sensor",
      },
      {
        "value": 19,
        "name": "Inverter",
      },
      {
        "value": 20,
        "name": "Fuel",
      },
      {
        "value": 21,
        "name": "Site Management",
      },
      {
        "value": 22,
        "name": "AC Load Bank",
      },
      {
        "value": 23,
        "name": "Solar Panel",
      },
      {
        "value": 24,
        "name": "DC Load Bank",
      },
      {
        "value": 25,
        "name": "Battery Pack",
      },
      {
        "value": 26,
        "name": "Weather Station",
      },
      {
        "value": 27,
        "name": "Rotary UPS",
      },
      {
        "value": 28,
        "name": "Gas Generator"
      },
      {
        "value": 29,
        "name": "PDU"
      },
      {
        "value": 30,
        "name": "Breaker"
      },
      {
        "value": 31,
        "name": "Pepper Spray"
      },
      {
        "value": 32,
        "name": "MPT Mills" //Actually MPT Bills
      },
      {
        "value": 33,
        "name": "Camera"
      },
      {
        "value": 34,
        "name": "Chiller",
      },
      {
        "value": 35,
        "name": "Water Tank",
      },
      {
        "value": 50,
        "name": "Utility Controller",
      },

    ];

    let index = deviceMappings.findIndex((item: any) => item.value === type);
    return index !== -1 ? deviceMappings[index].name : 'Unknown Device';
  }

}
