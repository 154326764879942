import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StorageService} from '../storage/storage.service';
import {Constants} from '../../utils/Contstants';
import {Observable} from 'rxjs';
import {ISiteTemperatureReadings} from '../../Models/ISiteTemperatureReadings';

@Injectable({
  providedIn: 'root'
})
export class TemperatureService {

  private baseUrl = new Constants().noderedInternal;

  constructor(private http: HttpClient, private storageService: StorageService) { }

  getTemperatureValuesCSV(dateFrom: string, siteId: number): Observable<any>{

    let baseUrl: string = `${this.baseUrl}analytics/temperatures_avarage_csv?`;
    return this.http.get(`${baseUrl}site_id=${siteId}&date_from=${dateFrom}`,
      {responseType: 'text'});

  }

  getSiteTemperatureJsonValues(dateFrom: string, siteId: string): Observable<ISiteTemperatureReadings[]>{

    let baseUrl: string = `${this.baseUrl}analytics/temperatures_avarage?`;
    return this.http.get<ISiteTemperatureReadings[]>(`${baseUrl}site_id=${siteId}&date_from=${dateFrom}`);

  }
}
