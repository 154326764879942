import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';
import {interval, Subscription} from 'rxjs';
import {IWaterTank} from '../../../Models/DeviceInstances/IWaterTank';
import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';


@Component({
  selector: 'app-water-tank-info',
  templateUrl: './water-tank-info.component.html',
  styleUrls: ['./water-tank-info.component.scss']
})
export class WaterTankInfoComponent implements OnInit {

  hasReading: boolean;

  @Output() failedReading = new EventEmitter<boolean>();

  @Input() readings : IDeviceReadingsProps;

  requestInterval = interval(10000);
  intervalSubscriber: Subscription;
  currentDeviceReadings : IWaterTank;

  isLoadingData : boolean;

  tankPercentage: number;

  constructor(private analogDeviceService: AnalogDevicesService) { }

  ngOnInit(): void {
    this.isLoadingData = true;
    this.getDeviceReadings();
    this.hasReading = this.readings != null;
  }

  emitReadingError(): void{

    this.failedReading.emit(true);

  }

  getDeviceReadings(): void{

    this.isLoadingData = true;

    this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId, this.readings.selectedDeviceTypeId, this.readings.controllerId, this.readings.instanceId
    ).subscribe( response => {

      this.currentDeviceReadings = response;
      if(response.timestamp === null){
        this.currentDeviceReadings.timestamp = new Date();
      }

      let litresInTank: number = parseFloat(this.currentDeviceReadings.litres_in_tank.split(' ')[0]);
      let tankCapacity: number = parseFloat(this.currentDeviceReadings.capacity.split(' ')[0]);


      this.tankPercentage = Number(((litresInTank/tankCapacity) * 100).toFixed(0));
      console.info('Percentage: ', this.tankPercentage);

      this.isLoadingData = false;

    }, error => {
      console.warn('Water Tank', error);
    }, () => {
    });


  }

  ngOnDestroy(): void {
    //this.intervalSubscriber.unsubscribe();
  }
}
