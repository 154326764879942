import { Injectable } from '@angular/core';
import {Constants} from '../../utils/Contstants';
import {HttpClient} from '@angular/common/http';
import {StorageService} from '../storage/storage.service';
import {Observable} from 'rxjs';
import {ICloudBmsUser} from '../../Models/ICloudBmsUser';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  private baseUrl = new Constants().baseUrl;
  private storageConstants = new Constants().storage;

  constructor(private http: HttpClient, private storageService: StorageService) { }





  public getUser(id: number): Observable<ICloudBmsUser>{

    return this.http.get<ICloudBmsUser>(`${this.baseUrl}bms_user/${id}`);

  }

  public getUsers(): Observable<ICloudBmsUser[]>{

    const client = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    return this.http.get<ICloudBmsUser[]>(`${this.baseUrl}bms_users/${client}`);

  }

  public createUser(userDetails: ICloudBmsUser): Observable<JSON>{

    const client = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    userDetails.password = userDetails.full_name;
    userDetails.client_id = Number(client);

    return this.http.post<JSON>(`${this.baseUrl}bms_users`, userDetails);

  }

  public updateUser(id : number, userDetails: any): Observable<JSON>{

    return this.http.put<JSON>(`${this.baseUrl}bms_users/${id}`, userDetails);

  }

  public deleteUser(id: number): Observable<JSON>{

    return this.http.delete<JSON>(`${this.baseUrl}bms_users/${id}`);

  }

}
