import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {StorageService} from '../../services/storage/storage.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form;
  uid: any;

  isInvalidRequest: boolean = false;
  isSuccessful: boolean = false;
  isSendingPasswordChangeRequest: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private notification: NzNotificationService,
              private authenticationService: AuthenticationService,
              private storageService: StorageService,
              private activatedRoute: ActivatedRoute, private router : Router) {


  }


  ngOnInit(): void {

    this.activatedRoute.params.subscribe( params => {
      this.uid = params['uid']
    } );

    this.form = this.formBuilder.group({
      code: [null, Validators.required],
      password: [null, [Validators.required, Validators.minLength(8)]],
      validatePassword: [null, [Validators.required,  Validators.minLength(8)]],
    });


  }

  changePassword(formData): void{

    if (this.form.dirty && this.form.valid) {

      if(formData.password !== formData.validatePassword){

        this.notification.error(
          'Password Mismatch!',
          'Ensure your passwords match! '
        );
      }else{

        if(this.authenticationService.testValidPassword(formData.password)){

          this.isSendingPasswordChangeRequest = true;

          //Valid password
          this.authenticationService.changePassword({
            'uid' : this.uid,
            'password': formData.password,
            'code': Number(formData.code)

          }).subscribe( response => {

            if(response !== null){
              this.isSuccessful = true;
              this.notification.success(
                'Password Reset Successfully',
                ''
              );

              this.isSendingPasswordChangeRequest = false;
              this.form.reset();
              setTimeout( () => {

                this.router.navigate(['']);

              }, 5000 );


            }else{

              this.isSendingPasswordChangeRequest = false;

              this.notification.error(
                'An error occurred during this process , please contact support at support@cloudbms.co.za',
                ''
              );

            }


          }, error => {

            this.isSendingPasswordChangeRequest = false;

            this.notification.error(
              'Invalid Code!',
              error.error.error
            );

            this.form.reset();

            this.isInvalidRequest = true;
          } );


        }else{

          // Invalid Password
          this.notification.error(
            'Invalid Password',
            'Your Password must be 8 Characters in length, it must contain at least 1 Uppercase letter and must contain at least 1 special character'
          );
        }


      }

    }else {

      // Form not valid

      this.notification.error(
        'Invalid Input Provided',
        'All fields must be filled and all passwords must have a minimum of 8 Characters'
      );
    }

  }

}
