import * as Highcharts from 'highcharts';

export class ChartConfigurations{


  public PowerMeterVoltageChartOptions = {
    chart: {
      type: 'solidgauge'
    },

    title: null,

    pane: {
      center: ['50%', '85%'],
      size: '140%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc'
      }
    },

    exporting: {
      enabled: false
    },

    tooltip: {
      enabled: false
    },

    // the value axis
    yAxis: {
      stops: [
        [0.4, '#DF5353'], // red
        [0.6, '#55BF3B'], // green
        [0.8, '#DDDF0D'], // yellow
        [0.9, '#DF5353'] // red
      ],
      // minColor: '#ff0000',
      // maxColor: '#ff0000',
      min: 0,
      max: 500,
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70
      },
      labels: {
        y: 16
      }
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true
        }
      }
    }
  };

  public PowerMeterCurrentChartOptions =  {
    chart: {
      type: 'solidgauge'
    },

    title: null,

    pane: {
      center: ['50%', '85%'],
      size: '140%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc'
      }
    },

    exporting: {
      enabled: false
    },

    tooltip: {
      enabled: false
    },

    // the value axis
    yAxis: {
      // stops: [
      //   [0.1, '#55BF3B'], // green
      //   [0.3, '#DDDF0D'], // yellow
      //   [0.45, '#DF5353'] // red
      // ],
      minColor: '#55BF3B',
      maxColor: '#55BF3B',
      min: 0,
      max: 2500,
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70
      },
      labels: {
        y: 16
      }
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true
        }
      }
    }
  };

  public RectifierOutputVoltageChartOptions = {

    chart: {
      type: 'gauge',
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false
    },

    title: {
      text: 'Output Voltage'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [{
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#FFF'],
            [1, '#333']
          ]
        },
        borderWidth: 0,
        outerRadius: '109%'
      }, {
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#333'],
            [1, '#FFF']
          ]
        },
        borderWidth: 1,
        outerRadius: '107%'
      }, {
        // default background
      }, {
        backgroundColor: '#DDD',
        borderWidth: 0,
        outerRadius: '105%',
        innerRadius: '103%'
      }]
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 60.0,

      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',

      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        step: 2,
        //@ts-ignore
        rotation: 'auto'
      },
      title: {
        text: 'V'
      },
      plotBands: [
        {
          from: 0,
          to: 42.0,
          color: '#DF5353' // red
        },
        {
          from: 42.0,
          to: 45.0,
          color: '#DDDF0D' // yellow
        },
        {
          from: 45.0,
          to: 58.0,
          color: '#55BF3B' // green
        },
        {
          from: 58.0,
          to: 60.0,
          color: '#DDDF0D' // yellow
        },
      ]
    }
  };

  public RectifierOutputCurrentChartOptions = {

    chart: {
      type: 'gauge',
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false
    },

    title: {
      text: 'Used Capacity'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [{
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#FFF'],
            [1, '#333']
          ]
        },
        borderWidth: 0,
        outerRadius: '109%'
      }, {
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#333'],
            [1, '#FFF']
          ]
        },
        borderWidth: 1,
        outerRadius: '107%'
      }, {
        // default background
      }, {
        backgroundColor: '#DDD',
        borderWidth: 0,
        outerRadius: '105%',
        innerRadius: '103%'
      }]
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 100.0,

      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',

      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        step: 2,
        //@ts-ignore
        rotation: 'auto'
      },
      title: {
        text: '%'
      },
      plotBands: [

        {
          from: 0,
          to: 70.0,
          color: '#55BF3B' // green
        },
        {
          from: 70.1,
          to: 85,
          color: '#DDDF0D' // yellow
        },
        {
          from: 85,
          to: 100,
          color: '#DF5353' // red
        },
      ]
    }
  };

  public HVACTemperatureDegreesChartOptions = {

    chart: {
      type: 'gauge',
      spacingBottom: 0,
      spacingTop: 0,
      spacingLeft: 0,
      spacingRight: 0,
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false
    },

    title: {
      text: 'Temperature'
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [{
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#FFF'],
            [1, '#333']
          ]
        },
        borderWidth: 0,
        outerRadius: '109%'
      }, {
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#333'],
            [1, '#FFF']
          ]
        },
        borderWidth: 1,
        outerRadius: '107%'
      }, {
        // default background
      }, {
        backgroundColor: '#DDD',
        borderWidth: 0,
        outerRadius: '105%',
        innerRadius: '103%'
      }]
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 60.0,

      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',

      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        step: 2,
        //@ts-ignore
        rotation: 'auto'
      },
      title: {
        text: "°C"
      },
      plotBands: [

        {
          from: 0,
          to: 24.0,
          color: '#55BF3B' // green
        },
        {
          from: 24.1,
          to: 27.0,
          color: '#DDDF0D' // yellow
        },
        {
          from: 27.1,
          to: 60,
          color: '#DF5353' // red
        },
      ]
    }
  };

  public UpsChartOptions = {
    chart: {
      type: 'solidgauge'
    },

    title: {
      text: ''
    },

    pane: {
      center: ['50%', '85%'],
      size: '140%',
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
        innerRadius: '60%',
        outerRadius: '100%',
        shape: 'arc'
      }
    },

    exporting: {
      enabled: false
    },

    tooltip: {
      enabled: false
    },

    // the value axis
    yAxis: {
      stops: [
        [0.1, '#55BF3B'], // green
        [0.5, '#DDDF0D'], // yellow
        [0.9, '#DF5353'] // red
      ],
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: null,
      tickAmount: 2,
      title: {
        y: -70
      },
      labels: {
        y: 16
      }
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true
        }
      }
    }
  };

  upsVoltageChartOptions = {
    chart: {
      type: 'gauge',
      plotBorderWidth: 0,

      /*             plotBackgroundColor: {
                      linearGradient: {
                          x1: 0,
                          y1: 0,
                          x2: 0,
                          y2: 1
                      },
                      stops: [
                          [0, '#FFF4C6'],
                          [0.3, '#FFFFFF'],
                          [1, '#FFF4C6']
                      ]
                  }, */
      plotBackgroundImage: null,
      height: 200
    },

    title: {
      text: 'System Input Voltage'
    },

    pane: [{
      startAngle: -45,
      endAngle: 45,
      background: null,
      center: ['20%', '145%'],
      size: 300
    }, {
      startAngle: -45,
      endAngle: 45,
      background: null,
      center: ['50%', '145%'],
      size: 300
    }, {
      startAngle: -45,
      endAngle: 45,
      background: null,
      center: ['80%', '145%'],
      size: 300
    }],
    credits:{
      enabled: false
    },
    exporting: {
      enabled: false
    },

    tooltip: {
      enabled: false
    },

    yAxis: [{
      min: 0,
      max: 400,
      minorTickPosition: 'outside',
      tickPosition: 'outside',
      labels: {
        rotation: 'auto',
        distance: 20
      },
      plotBands: [{
        from: 330,
        to: 400,
        color: '#C02316',
        innerRadius: '100%',
        outerRadius: '105%'
      }],
      pane: 0,
      title: {
        text: 'Voltage A',
        y: -40
      }
    }, {
      min: 0,
      max: 400,
      minorTickPosition: 'outside',
      tickPosition: 'outside',
      labels: {
        rotation: 'auto',
        distance: 20
      },
      plotBands: [{
        from: 330,
        to: 400,
        color: '#C02316',
        innerRadius: '100%',
        outerRadius: '105%'
      }],
      pane: 1,
      title: {
        text: 'Voltage B',
        y: -40
      }
    }, {
      min: 0,
      max: 400,
      minorTickPosition: 'outside',
      tickPosition: 'outside',
      labels: {
        rotation: 'auto',
        distance: 20
      },
      plotBands: [{
        from: 330,
        to: 400,
        color: '#C02316',
        innerRadius: '100%',
        outerRadius: '105%'
      }],
      pane: 2,
      title: {
        text: 'Voltage C',
        y: -40
      }
    }],

    plotOptions: {
      gauge: {
        dataLabels: {
          enabled: true,
          format: '{y} V'
        },
        dial: {
          radius: '100%'
        }
      }
    },

    // series: [{
    //   name: 'Channel A',
    //   data: [230],
    //   yAxis: 0
    // }, {
    //   name: 'Channel B',
    //   data: [270],
    //   yAxis: 1
    // }, {
    //   name: 'Channel C',
    //   data: [298],
    //   yAxis: 2
    // }]

  }

}
