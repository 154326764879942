<div style="background: #ffffff; padding: 30px;">

  <div nz-row>

    <div nz-col nzSpan="12">
      <h6 class="mb-3">
        <span class="text-lead" style="font-size: small;">Last Reading</span><br>
        {{currentDeviceReadings?.timestamp |  date:'medium' }}
      </h6>
    </div>

    <div nz-col nzSpan="12">
      <button nz-button nzType="primary" class="float-right" (click)="getDeviceReadings()" >
        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
        Get Current Readings
      </button>
    </div>

  </div>

  <nz-divider></nz-divider>

  <div class="container" *ngIf="!hasReading">
    <nz-result nzStatus="404" nzTitle="404"
               nzSubTitle="Sorry, it seems as though there is no data available for this device.">

    </nz-result>
  </div>

  <nz-card class="full-width" [nzLoading]="isLoadingData" nzBordered="false">
    <nz-row [nzGutter]="16" *ngIf="hasReading">
      <nz-col [nzSpan]="6">
        <nz-statistic [nzValue]="(currentDeviceReadings?.voltage !== undefined ?
                      currentDeviceReadings?.voltage : 'Unavailable') "
                      [nzTitle]="'Voltage'"
                      [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
      </nz-col>

      <nz-col [nzSpan]="6">
        <nz-statistic [nzValue]="(currentDeviceReadings?.current)!"
                      [nzTitle]="'Current'"
                      [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
      </nz-col>

      <nz-col [nzSpan]="6">
        <nz-statistic [nzValue]="(currentDeviceReadings?.power !== undefined ?
                      currentDeviceReadings?.power : 'Unavailable')!"
                      [nzTitle]="'Power'"
                      [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
      </nz-col>

      <nz-col [nzSpan]="6">
        <nz-statistic [nzValue]="(currentDeviceReadings?.energy !== undefined ?
                      currentDeviceReadings?.energy : 'Unavailable')!"
                      [nzTitle]="'Energy'"
                      [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
      </nz-col>

    </nz-row>
  </nz-card>



</div>
