import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {SitesService} from '../../services/sites/sites.service';
import {ActivatedRoute} from '@angular/router';
import {AlarmsService} from '../../services/alarms/alarms.service';
import {IActiveAlarm} from '../../Models/IActiveAlarm';
import {ISiteAlarmsCount} from '../../Models/ISiteAlarmsCount';
import {NzTableFilterList} from 'ng-zorro-antd/table';
import {TableColumnItem, TableDataItem} from '../../Models/IAlarmTableInterfaces';
import {GenericHelpers} from '../../utils/GenericHelpers';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {IActiveAlarmHistoryCsvData} from '../../Models/Reports/IActiveAlarmHistoryCsvData';
import { DomSanitizer } from '@angular/platform-browser';
import {ISite} from '../../Models/ISite';
import {Constants} from '../../utils/Contstants';
import {StorageService} from '../../services/storage/storage.service';
import {interval, Subscription} from 'rxjs';


@Component({
  selector: 'app-alarm-summary',
  templateUrl: './alarm-summary.component.html',
  styleUrls: ['./alarm-summary.component.scss']
})
export class AlarmSummaryComponent implements OnInit, AfterViewInit, OnDestroy {

  pageLoading: boolean;
  loaderText: string;
  loadingAlarms: boolean;
  selectedSiteId: number;
  siteName: string;

  tableSearchText: string;

  activeAlarms : IActiveAlarm[];
  alarmTypeCounts: ISiteAlarmsCount;

  activeAlarmsHistory: IActiveAlarm[];
  loadingAlarmHistory: boolean;
  deviceNames: NzTableFilterList;

  dateFrom : string;
  dateTo: string;

  historySearchText: string = '';

  currentDate: Date = new Date();

  showHistory = false;
  placement = 'left';

  genericHelper = new GenericHelpers();
  hasGeneratedAlarmHistory: boolean = false;
  isGeneratingCsvReport: boolean = false;
  hasGeneratedCsvReport: boolean = false;
  csv: any;
  hasClickedDownload: boolean = false;

  disabledDate = (current: Date): boolean => {

    return differenceInCalendarDays(current, new Date()) > 0;
  };

  listOfColumns: TableColumnItem[] = [
    {
      name: 'Device Group',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) => {

        return 1
      },
    },
    {
      name: 'Priority',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) =>
        a.priority.localeCompare(b.priority),
      filterMultiple: true,
      listOfFilter: [
        { text: 'Critical', value: 'Critical'},
        { text: 'Urgent', value: 'Urgent'},
        { text: 'Warning', value: 'Warning'},
        { text: 'Event', value: 'Event' }
      ],
      filterFn: (list: string[], item: TableDataItem) =>
      {

        for(let i in list){

          if(item.priority === list[i]){
            return true;
          }

        }

        return false;
      }
    },
    {
      name: 'Description'
    },
    {
      name: 'Date Triggered',
      sortOrder: 'descend',
      sortFn: (a: TableDataItem, b: TableDataItem) => a.triggered_at.localeCompare(b.triggered_at)
    },
    // {
    //   name: 'Date Cleared',
    //   sortOrder: null,
    //   sortFn: (a: TableDataItem, b: TableDataItem) => a.cleared_at.localeCompare(b.cleared_at)
    // },
    {
      name: 'Duration',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) => a.duration.localeCompare(b.duration)
    },
    // {
    //   name: 'Status',
    //   sortOrder: null,
    //   sortFn: (a: TableDataItem, b: TableDataItem) => a.is_cleared.localeCompare(b.is_cleared)
    // },
  ];

  historyColumns: TableColumnItem[] = [
    {
      name: 'Device Group',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) => {

        return 1
      },
    },
    {
      name: 'Priority',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) =>
        a.priority.localeCompare(b.priority),
      filterMultiple: true,
      listOfFilter: [
        { text: 'Critical', value: 'Critical'},
        { text: 'Urgent', value: 'Urgent'},
        { text: 'Warning', value: 'Warning'},
        { text: 'Event', value: 'Event' }
      ],
      filterFn: (list: string[], item: TableDataItem) =>
      {

        for(let i in list){

          if(item.priority === list[i]){
            return true;
          }

        }

        return false;
      }
    },
    {
      name: 'Description'
    },
    {
      name: 'Date Triggered',
      sortOrder: 'descend',
      sortFn: (a: TableDataItem, b: TableDataItem) => a.triggered_at.localeCompare(b.triggered_at)
    },
    {
      name: 'Date Cleared',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) => a.cleared_at.localeCompare(b.cleared_at)
    },
    {
      name: 'Duration',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) => a.duration.localeCompare(b.duration)
    },
    {
      name: 'Status',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) => a.is_cleared.localeCompare(b.is_cleared)
    },
  ];

  timer = interval(60000);
  subscription: Subscription;

  constructor(private route: ActivatedRoute,
              private siteService: SitesService,
              private alarmsService: AlarmsService,
              private notification: NzNotificationService,
              private domSanitizer: DomSanitizer,
              private storageService: StorageService,
              ) {
    this.route.params.subscribe( params => {
      this.selectedSiteId = params.id;

      this.changeSite(params.id);
      let sites: ISite[] = JSON.parse(this.storageService.getStoredEncodedValue(new Constants().storage.clientSites));
      let siteDetails = sites.find(site => Number(site.legacy_id) === Number(params.id) || site._id === params.id);
      this.siteName = siteDetails.name;

    } );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {

    this.siteService.getSpecificSiteDetails(this.selectedSiteId.toString()).subscribe( response => {

      this.siteName = response[0].name;
    }, error => {
      console.error('Failed to get Site Details ', error);
    })

    this.subscription = this.timer.subscribe(x => {

      this.getActiveAlarms();
      // this.getAlarmsCounts();
    }, error => {
      console.warn("Error in timing subscription", error);
    })
  }

  changeSite(siteId: number){
    this.pageLoading = true;
    this.selectedSiteId = siteId;
    this.loaderText = 'Retrieving Active Alarms';
    this.siteName = this.siteService.getCurrentSite(this.selectedSiteId);
    this.getActiveAlarms();
    // this.getAlarmsCounts();
  }

  ngOnInit(): void {

  }

  getActiveAlarms() : void{
    this.loadingAlarms = true;
    this.alarmsService.siteActiveAlarms(this.selectedSiteId).subscribe( response =>{

      let filters = [];

      try{
        this.activeAlarms = response["active_alarms"];

        let deviceNames = [ ...new Set( response["active_alarms"].map(x => x.device_name) ) ];

        this.alarmTypeCounts = {
          Critical: response.critical_alarms,
          Urgent: response.urgent_alarms,
          Warning: response.warning_alarms,
          Event: response.event_alarms
        };

        for(let i in deviceNames){

          filters.push({
            text: deviceNames[i],
            value: deviceNames[i]
          });

        }

        this.deviceNames = filters;
        this.loadingAlarms = false;
        this.pageLoading = false;
      }
      catch (exception){
        this.deviceNames = filters;
        this.loadingAlarms = false;
        this.pageLoading = false;
      }

    }, error => {
      console.error(error);
      this.loadingAlarms = false;
    } )
  }

  onDateChange(date : Date, type: number): void{

    if(date !== undefined && date !== null){

      if(type === 0){
        this.dateFrom = new GenericHelpers().formatDate(date);
      }
      else{
        this.dateTo = new GenericHelpers().formatDate(date);
      }
    }

  }

  // checkIfString(date): boolean {
  //   console.log(typeof date);
  //   return (typeof date) === 'string';
  //
  // }

  getSiteAlarmHistory() : void{


    if(this.dateFrom !== undefined && this.dateTo !== undefined ){
      this.loadingAlarmHistory = true;
      //this.showHistory = true;
      this.alarmsService.siteAlarmHistory(this.selectedSiteId, this.dateFrom, this.dateTo).subscribe( (response: any) => {

        this.activeAlarmsHistory = response["alarm_logs"];

        this.loadingAlarmHistory = false;
        this.hasGeneratedAlarmHistory = true;

      }, error =>{
        console.error(error);
        this.loadingAlarms = false;
      } )

    }else {

      this.notification.error(
        'Invalid Date Selected',
        'Please select a Date before attempting to get the history',
        { nzPlacement: 'topLeft' }
      );

    }

  }

  getSiteAlarmHistoryCSV(): void{

    this.isGeneratingCsvReport = true;

    let data : IActiveAlarmHistoryCsvData[] = [];

    for(let i = 0; i < this.activeAlarmsHistory.length; i++){

      data.push({
        device_name: this.activeAlarmsHistory[i].device_name,
        description: this.activeAlarmsHistory[i].description,
        triggered_at: this.activeAlarmsHistory[i].triggered_at,
        cleared_at: this.activeAlarmsHistory[i].cleared_at,
        duration: this.activeAlarmsHistory[i].duration,
        priority: this.activeAlarmsHistory[i].priority,
        is_cleared: this.activeAlarmsHistory[i].is_cleared.toString()
      });

    }

    this.alarmsService.getActiveAlarmHistoryCsv(data).subscribe( (response: string) => {

      this.isGeneratingCsvReport = false;
      this.hasGeneratedCsvReport = true;

      this.csv = this.domSanitizer.bypassSecurityTrustUrl(`data:text/csv;charset=utf-8,${ encodeURI(response)}`) ;

    }, error => {
      console.error(error.error);
    } );


  }

  csvDownloadClicked(): void{



    setTimeout(() => {
      this.hasGeneratedCsvReport = false;
      this.showHistory = false;
      this.hasClickedDownload = false;
      this.hasGeneratedAlarmHistory = false;
    }, 3000);

  }

}

