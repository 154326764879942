<header id="home" class="hero-area-2" style="height: 100%; overflow-y: scroll;">
  <div class="overlay"></div>
  <nav class="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar"
       style="background: #020d26; z-index: 0">
    <div class="container">

      <p class="navbar-brand font-weight-bold" routerLink="/">
        <img src="assets/round_bms_icon.png" alt="Cloud BMS"
             class="img-fluid d-inline-block align-top" height="30" width="30">
        Cloud BMS
      </p>

<!--      <button class="navbar-toggler" type="button" data-toggle="collapse">-->
<!--        <i class="lni-menu"></i>-->
<!--      </button>-->
      <div class="collapse navbar-collapse" id="navbarCollapse">

        <ul class="navbar-nav mr-auto w-100 justify-content-end">

          <li class="nav-item">
            <a class="nav-link disabled" href="javascript:void(0)">
              <i nz-icon nzType="phone" nzTheme="fill" style="color: #60c6c4;"
                 class="button-icon mr-2"></i>
              <span>+27 11 792 7230</span>
            </a>
          </li>

        </ul>

      </div>
    </div>
  </nav>
  <div class="container">

    <div class="row space-100">
      <div class="col-lg-7 col-md-12 col-xs-12">
        <div class="contents">
          <h2 class="head-title">Remote Monitoring<br> With
            <span style="color: #60c6c4" class="shadow">Cloud BMS</span>
          </h2>
<!--          <img src="assets/website/login.svg" alt="" style="width: 90%">-->
          <img src="assets/website/secure_login.svg" alt="" style="width: 90%">
        </div>
      </div>
      <nz-card nzBordered="false"
               style="background: transparent !important;height: 100%;"
               class="col-lg-5 col-md-12 col-xs-12">
        <div class="intro-img pl-4">

          <nz-alert
            nzType="success"
            nzMessage="Request submitted successfully!"
            nzDescription="An email containing a link to change your password has been sent to your email address"
            nzShowIcon *ngIf="passwordResetRequestSent"
          >
          </nz-alert>

          <nz-card style="width:100%;background-color: transparent" [nzBordered]="false">
            <h5 class="display-6 text-white text-uppercase">Login</h5>

            <p class="text-white"> Enter your credentials to gain access</p>
            <br>
            <form class="text-light" [formGroup]="form" (ngSubmit)="performLogin(form.value)">
              <label> <span class="text-danger">*</span> Email Address</label>
              <nz-input-group  [nzPrefix]="prefixTemplateUser">

                <input type="text" nz-input placeholder="Email Address"
                       formControlName="email"  />
              </nz-input-group>
              <br>

              <label> <span class="text-danger">*</span> Password</label>
              <nz-input-group  [nzPrefix]="prefixTemplateLock">
                <input type="password" nz-input placeholder="Password"
                       autocomplete formControlName="password"/>
              </nz-input-group>
              <button nz-button type="submit" [nzLoading]="isPerformingLogin"
                      nzType="primary"
                      class="mb-3">Login
              </button>
              <br>

              <a class="text-light mt-2" href="javascript:void(0)" (click)="openPasswordModal()">  Forgot Password?</a>
              <!--            <a class="text-light mt-2" href="javascript:void(0)">  Forgot Password?</a>-->
              <ng-template #prefixTemplateUser><i nz-icon nzType="user"></i></ng-template>
              <ng-template #prefixTemplateLock><i nz-icon nzType="lock"></i></ng-template>

            </form>
          </nz-card>

        </div>

      </nz-card>
    </div>
  </div>

</header>

<!-- Copyright Start  -->
<!--<div class="copyright mt-0">-->
<!--  <div class="container">-->
<!--    <div class="row">-->
<!--      <div class="col-md-12">-->
<!--        <div class="site-info float-left">-->
<!--          <p>&copy; 1999 - 2020-->
<!--            <a href="http://www.kva.co.za" rel="nofollow">Master Power Technologies.</a> All rights reserved.</p>-->
<!--        </div>-->
<!--        <div class="float-right">-->
<!--          <ul class="footer-social">-->
<!--            <li>-->
<!--              <a class="facebook" href="https://web.facebook.com/masterpowertechnologies/" target="_blank">-->
<!--                <i class="lni lni-facebook-filled"></i>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a class="twitter" href="https://twitter.com/MasterPowerTech" target="_blank">-->
<!--                <i class="lni lni-twitter-filled"></i>-->
<!--              </a>-->
<!--            </li>-->
<!--            <li>-->
<!--              <a class="linkedin" href="http://www.linkedin.com/company/118985?trk=tyah&trkInfo=tarId%3A1401959351751%2Ctas%3Amaster%20power%20techn%2Cidx%3A1-1-1" target="_blank">-->
<!--                <i class="lni lni-linkedin-original"></i>-->
<!--              </a>-->
<!--            </li>-->

<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!-- Copyright End -->


<nz-modal [(nzVisible)]="isVisible"
          nzTitle="Tickets" (nzOnCancel)="handleCancel()" [nzFooter]="null">

  <form class="text-light" [formGroup]="ticketForm" (ngSubmit)="getTicketInfo(ticketForm.value)">
    <label class="text-dark"> <span class="text-danger">*</span> Reference #</label>
    <nz-input-group  [nzPrefix]="prefixTemplateUser">

      <input type="text" nz-input placeholder="Ticket Reference Number"
             formControlName="reference" value="" />
    </nz-input-group>
    <br>

    <label class="text-dark"> <span class="text-danger">*</span> Email Address</label>
    <nz-input-group  [nzPrefix]="prefixTemplateLock">
      <input type="email" nz-input placeholder="Email"
             formControlName="email"/>
    </nz-input-group>
    <button nz-button type="submit"
            nzType="primary"
            class="mb-3">Get Ticket Details
    </button>
    <br>

  </form>

</nz-modal>


<nz-modal [(nzVisible)]="passwordModalIsVisible"
          nzTitle="Request Password Change"
          (nzOnCancel)="closePasswordModal()"
          [nzFooter]="null" class="p-0">

  <nz-card class="full-width" nzBordered="false" [nzLoading]="isSendingPasswordResetRequest">

    <nz-alert nzType="error"
              nzMessage="Invalid Details"
              nzDescription="A user with the provided email address does not exist."
              nzShowIcon *ngIf="passwordResetUserNotFound" class="mb-3">
    </nz-alert>

    <nz-alert
      nzType="success"
      nzMessage="Request Successful"
      nzDescription="An email containing a code as well as the link to change your password has been sent."
      nzShowIcon *ngIf="passwordResetRequestSent" class="mb-3"
    >
    </nz-alert>

    <form class="text-light" [formGroup]="resetPasswordForm" (ngSubmit)="requestPasswordChange(resetPasswordForm.value)">

      <label class="text-dark"> <span class="text-danger">*</span> Email Address</label>
      <nz-input-group  [nzPrefix]="prefixTemplateLock">
        <input type="email" nz-input placeholder="Email"
               formControlName="email"/>
      </nz-input-group>
      <button nz-button type="submit"
              nzType="primary"
              class="mb-3">Submit Request
      </button>
      <br>

    </form>





  </nz-card>



</nz-modal>
