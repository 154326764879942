<div style="width: 100%; height: 100%">

  <div nz-row>

    <div nz-col nzSpan="12">
      <h6 class="mb-3">
        <span class="text-lead" style="font-size: small;">Last Reading</span><br>
        {{currentDeviceReadings?.timestamp |  date:'medium' }}
      </h6>
    </div>

    <div nz-col nzSpan="12">
      <button nz-button nzType="primary" class="float-right" (click)="getDeviceReadings()" >
        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
        Get Current Readings
      </button>
    </div>

  </div>

  <nz-divider></nz-divider>

  <div class="container" *ngIf="!hasReading">
    <nz-result nzStatus="404" nzTitle="404"
               nzSubTitle="Sorry, it seems as though there is no data available for this device.">

    </nz-result>
  </div>

  <nz-card class="full-width" [nzLoading]="isLoadingData" nzBordered="false">
    <div class="container" *ngIf="hasReading">
      <nz-row class="mb-5">
        <nz-col nzSpan="6">
          <nz-statistic
            [nzValue]="(currentDeviceReadings?.temperature)"
            [nzTitle]="'Temperature'"

            [nzValueStyle]="{ color: '#3F8600' }"
          >
          </nz-statistic>
          <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
        </nz-col>
        <nz-col nzSpan="6">
          <nz-statistic
            [nzValue]="currentDeviceReadings?.humidity"
            [nzTitle]="'Humidity'"
            [nzValueStyle]="{ color: '#3F8600' }"
          >
          </nz-statistic>
          <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
        </nz-col>


      </nz-row>
    </div>
  </nz-card>



</div>
