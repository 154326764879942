<div class="container-fluid">

  <div class="row">
    <nz-card [nzBordered]="false"
             class="col-lg-12 col-md-12 col-sm-12 mt-3"
             [nzLoading]="isLoadingData"
             [nzTitle]="cardTitle"
             >

      <div class="row mt-3">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <nz-card-tab>
            <nz-tabset nzType="card">

              <nz-tab nzTitle="Fuel Readings">
                <ng-template nz-tab>
                  <app-fuel-dashboards></app-fuel-dashboards>
                </ng-template>

              </nz-tab>

              <nz-tab nzTitle="Power Meters">
                <ng-template nz-tab>
                  <app-power-meter-dashboard></app-power-meter-dashboard>
                </ng-template>

              </nz-tab>

              <nz-tab nzTitle="Rectifiers">
                <ng-template nz-tab>
                  <app-rectifier-dashboards></app-rectifier-dashboards>
                </ng-template>
              </nz-tab>

              <nz-tab nzTitle="HVACS">
                <ng-template nz-tab>
                  <app-hvac-dashboard></app-hvac-dashboard>
                </ng-template>
              </nz-tab>

              <nz-tab nzTitle="UPS">
                <ng-template nz-tab>
                  <app-ups-dashboard></app-ups-dashboard>
                </ng-template>
              </nz-tab>

              <nz-tab nzTitle="Temperature">
                <ng-template nz-tab>
                  <app-temperature-sensor-dashboard></app-temperature-sensor-dashboard>
                </ng-template>
              </nz-tab>

<!--              <nz-tab nzTitle="Tab 3">-->
<!--                Content of Tab Pane 3-->
<!--              </nz-tab>-->

            </nz-tabset>
          </nz-card-tab>
        </div>
      </div>



    </nz-card>

    <ng-template #cardTitle>
      <nz-page-header>
        <nz-page-header-title>
          <div class="row">
            <div class="col-3">
              <nz-avatar nzIcon="user" [nzShape]="'square'"
                         [nzSrc]="clientIcon" (nzError)="noIconLoaded($event)">
              </nz-avatar>

            </div>
            <div class="col-9">
              <h3 nz-typography>Dashboard</h3>
            </div>
          </div>

        </nz-page-header-title>
      </nz-page-header>
    </ng-template>
  </div>



</div>




<app-overlay-loader [isLoading]="isLoadingData" [loadingText]="'Loading...'"></app-overlay-loader>
<!--<app-overlay-loader [isLoading]="true" [loadingText]="'Loading Site List...'"></app-overlay-loader>-->


