<div class="row p-2">
  <div class="col-4">
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input type="text" nz-input placeholder="Search here..." [(ngModel)]="searchText"/>
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
  <div class="col-3">
    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Priority"
               [(ngModel)]="priorityFilter"
               style="width: 100%"
               (ngModelChange)="filterByPriority($event)">
      <nz-option nzLabel="All" nzValue="All"></nz-option>
      <nz-option nzLabel="Critical" nzValue="Critical"></nz-option>
      <nz-option nzLabel="Urgent" nzValue="Urgent"></nz-option>
      <nz-option nzLabel="Warning" nzValue="Warning"></nz-option>
      <nz-option nzLabel="Event" nzValue="Event"></nz-option>
    </nz-select>
  </div>

  <div class="col-3">
    <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Status"
               [(ngModel)]="statusFilter"
               style="width: 100%"
               (ngModelChange)="filterByStatus($event)">
      <nz-option nzLabel="All" nzValue="All"></nz-option>
      <nz-option nzLabel="Active" nzValue="Active"></nz-option>
      <nz-option nzLabel="Cleared" nzValue="Cleared"></nz-option>
    </nz-select>
  </div>

  <div class="col-2 text-right">
    <button nz-button (click)="clearFilters()" nzType="link" nzDanger>
      <i nz-icon nzType="close-circle"
         nzTheme="outline" class="button-icon"></i>
      Clear Filters
    </button>
  </div>
</div>
<nz-divider></nz-divider>

<ng-container *ngIf="notifications?.length !== 0 && notifications !== undefined">
  <nz-card nzBordered="false" *ngFor="let notification of notifications| filter: searchText"
           [nzActions]="[actionSetting, actionEdit, actionEllipsis]"
           [nzBodyStyle]="{'padding': 0}">

    <div class="notification-list-item-container m-0">
      <div [ngStyle]="{'background-color': getAlarmColor(notification?.alarm_priority)}"></div>
      <div class="card mb-2 border-0">
        <div class="row no-gutters">
          <div class="col-md-12">
            <div class="card-body">
              <div class="d-flex w-100 justify-content-between mb-0">
                <h5 class="h6 card-title mb-0">{{notification?.alarm_name}}</h5>
                <h5 class="h6 card-title mb-0"> {{notification?.device_name}}</h5>
              </div>
              <div class="d-flex w-100 justify-content-between mt-0 mb-0">
                <span class="card-text mb-0">{{notification?.site_name}}</span>
                <small class="card-text mb-0 mr-0"><nz-tag class="float-right" [ngStyle]="{'color': (!notification?.active)? 'green': 'red'}">{{!notification?.active ? 'CLEARED': 'ACTIVE'}}</nz-tag></small>
              </div>

              <small class="text-muted card-text">{{notification?.triggered_time | date: 'medium'}}</small>
            </div>


          </div>
        </div>
      </div>
    </div>

    <ng-template #actionSetting>
      <a href="javascript:void(0)"
         (click)="acknowledgeNotification(notification)"
         nz-button nzType="link" [nzLoading]="isAcknowledging">
        <i class="lni lni-checkmark-circle"></i>
        Acknowledge
      </a>
    </ng-template>

    <ng-template #actionEdit>
      <a href="javascript:void(0)" (click)="openCreateTicketModal(notification)">
        <i class="lni lni-ticket"></i>
        Generate Ticket
      </a>
    </ng-template>
    <ng-template #actionEllipsis>

      <a href="javascript:void(0)"
         (click)="muteNotification(notification)" *ngIf="!notification.is_muted">
        <i class="lni lni-volume-mute"></i>
        Mute
      </a>


      <a href="javascript:void(0)"
         *ngIf="notification.is_muted"
         (click)="unmuteNotification(notification)">
        <i class="lni lni-volume-mute"></i>
        UnMute
      </a>

    </ng-template>

  </nz-card>
</ng-container>

<ng-container *ngIf="notifications?.length === 0 || notifications === undefined">
  <nz-empty
    nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    [nzNotFoundContent]="contentTpl"
  >
    <ng-template #contentTpl>
      <span> There are no Alarms </span>
    </ng-template>
  </nz-empty>
</ng-container>

<!--
*********
CREATE TICKET MODAL
**********
-->

<nz-modal [(nzVisible)]="showCreateTicketModal"
          [nzTitle]="createTicketModalTitle"
          [nzFooter]="null"
          (nzOnOk)="closeTicketModal()"
          (nzOnCancel)="closeTicketModal()" nzWidth="60%">

  <ng-template #createTicketModalTitle>
    <h5>Generate Ticket</h5>
  </ng-template>

  <ng-container *ngIf="showCreateTicketModal">
    <app-create-service-desk-ticket
      [selectedNotification]="selectedNotification"
      (ticketCreatedEvent)="closeTicketModal()"></app-create-service-desk-ticket>
  </ng-container>
</nz-modal>
