import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {StorageService} from '../../services/storage/storage.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../utils/Contstants';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  hasAgreedToPOPIA: boolean = true;

  mainText = [
    {
      icon: 'cloud-server',
      heading: "Web Based BMS",
      text: "Master Power Technologies' Web-Based Building Monitoring System " +
        "enables monitoring, reporting, insights & statistics into your existing BMS system."
    },
    {
      icon: 'cloud-sync',
      heading: "Real-Time Monitoring",
      text: "Continuous monitoring allows proactive and corrective maintenance; " +
        "remote monitoring introduces the kind of flexibility that an unpredictable world demands."
    },
    {
      icon: 'heat-map',
      heading: "Insightful Alarming",
      text: "Be informed of issues on your sites, before escalation reaches critical points. " +
        "Monitor all alarms and the status of secure power equipment in critical areas."
    },
    {
      icon: 'eye',
      heading: "Transparency",
      text: "Total transparency to your data centre secure power equipment as well as the environment " +
        "so you can make the right decisions every time"
    }
  ];
  clientsArray = [
    [
      {
        name: "Vodacom",
        image: "assets/website/clients/vodacom.jpg"
      },
      {
        name: "MTN",
        image: "assets/website/clients/mtn.jpg"
      },
      {
        name: "FNB",
        image: "assets/website/clients/fnb.jpg"
      },
      {
        name: "ABSA",
        image: "assets/website/clients/absa.jpg"
      },
      {
        name: "Momentum",
        image: "assets/website/clients/momentum.jpg"
      },
    ],
    [
      {
        name: "ATNS",
        image: "assets/website/clients/atns.jpg"
      },
      {
        name: "Bidvest",
        image: "assets/website/clients/bidvest_facilities_management.jpg"
      },
      {
        name: "Business Connection",
        image: "assets/website/clients/business_connection.jpg"
      },
      {
        name: "City Of Tshwane",
        image: "assets/website/clients/city_of_tshwane.jpg"
      },
      {
        name: "Department Of Home Affairs",
        image: "assets/website/clients/department_of_home_affairs.jpg"
      },
    ],
    [
      {
        name: "Multichoice",
        image: "assets/website/clients/multichoice.jpg"
      },
      {
        name: "Nampak",
        image: "assets/website/clients/nampak.jpg"
      },
      {
        name: "NEC",
        image: "assets/website/clients/nec.jpg"
      },
      {
        name: "Telkom",
        image: "assets/website/clients/telkom.jpg"
      },
      {
        name: "Tsogo Sun",
        image: "assets/website/clients/tsogo_sun.jpg"
      },
    ]
  ];
  effect = 'scrollx';

  contactForm: FormGroup;

  contactFormHasError: boolean = false;
  contactMessageSent: boolean = false;
  sendingMessage: boolean = false;

  contactSuccessMessage: string;

  currentYear = new Date().getFullYear();

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private storageService: StorageService,
              private http: HttpClient, private modal: NzModalService) { }

  ngOnInit(): void {

    //this.checkForPOPIA();

    this.contactForm = this.formBuilder.group({
      full_name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      message: [null, Validators.required]
    });

  }

  checkForPOPIA(): void{
    let cookie = this.storageService.getCookie("POPIA_AGREEMENT");

    this.hasAgreedToPOPIA = cookie !== "";

  }

  sendContactInformation(data: any): void{

    if(this.contactForm.valid && this.contactForm.dirty && this.contactForm.touched){
      this.sendingMessage = true;
      this.contactFormHasError = false;

      let FormData = {
        full_name: data.full_name,
        email: data.email,
        message: data.message
      };

      this.http.post(`${new Constants().baseUrl}contact_form`, FormData).subscribe( (response: any) => {

        this.contactSuccessMessage = response.message;
        this.contactMessageSent = true;
        this.sendingMessage = false;
        this.contactForm.reset();

      }, error => {
        console.error(error);
        this.sendingMessage = false;
      } )

    }else{
      this.contactFormHasError = true;
    }

  }

  initiateDemoMode(): void{



  }

}
