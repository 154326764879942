import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IRectifier} from '../../../Models/DeviceInstances/IRectifier';
import {interval, Subscription} from 'rxjs';
import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';

@Component({
  selector: 'app-rectifier-info',
  templateUrl: './rectifier-info.component.html',
  styleUrls: ['./rectifier-info.component.scss']
})
export class RectifierInfoComponent implements OnInit, OnDestroy   {

  hasReading: boolean;

  @Output() failedReading = new EventEmitter<boolean>();

  @Input() readings : IDeviceReadingsProps;
  requestInterval = interval(10000);
  intervalSubscriber: Subscription;
  currentDeviceReadings : IRectifier;

  isLoadingData : boolean;

  constructor(private analogDeviceService: AnalogDevicesService) { }

  ngOnInit(): void {

    this.isLoadingData = true;

    this.hasReading = this.readings != null;
    this.getDeviceReadings();
  }

  emitReadingError(): void{

    this.failedReading.emit(true);
  }

  getDeviceReadings(): void{

    this.isLoadingData = true;

    this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId, this.readings.selectedDeviceTypeId, this.readings.controllerId, this.readings.instanceId
    ).subscribe( response => {

      this.currentDeviceReadings = response;
      this.isLoadingData = false;

    }, error => {
      console.warn('Rectifier', error);
    } )


  }

  ngOnDestroy(): void {

    //this.intervalSubscriber.unsubscribe();
  }

}
