import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SignInResponse} from '../../Models/SignInResponse';
import {IUserDetails} from '../../Models/IUserDetails';
import * as jwt_decode from 'jwt-decode';
import {StorageService} from '../storage/storage.service';
import {Constants} from '../../utils/Contstants';
import {IBmsUser} from '../../Models/IBmsUser';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private baseUrl = new Constants().clientZoneBackendUrl;

  constructor(private http: HttpClient, private storageService: StorageService) { }

  public getSpecificUserDetails() : Observable<any>{
    const token = this.storageService.getStoredEncodedValue(new Constants().storage.token);
    const decoded = jwt_decode(token);
    const userID = decoded['user']['id'];


    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + token
    });

    return this.http.get<any[]>(`${this.baseUrl}users/${userID}`,
      {
        headers: headers
      }
    );

  }
  public getToken(credentials): Observable<SignInResponse>{
    // return this.http.post<SignInResponse>( `${this.baseUrl}authenticate`,
    //   credentials
    // );

    return this.http.post<SignInResponse>( `${this.baseUrl}auth`,
      credentials
    );

    // return this.http.post<SignInResponse>( `http://localhost:5100/api/authentication/sign_in`,
    //   credentials
    // );
  }

  public getSignedInUserDetails() : any{
    const token = this.storageService.getStoredEncodedValue(new Constants().storage.token);


    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + token
    });

    return this.http.get(`${this.baseUrl}auth`,
      {
        headers: headers
      }
      );

    // return  jwt_decode(token)['user'];
  }

  public signOut(): void{

    this.storageService.clearStorage();

  }

  public getBmsSiteUsers(siteId: number): Observable<IBmsUser[]>{

    let clientId = this.storageService.getStoredEncodedValue(new Constants().storage.selectedClient);

    return this.http.get<IBmsUser[]>(`${this.baseUrl}bmsSiteUser/${siteId}/${clientId}`);

  }

  public getClientId(): number {

    return Number(this.storageService.getStoredEncodedValue(new Constants().storage.selectedClient))

  }

  public forgotPassword(email: string): Observable<any>{

    return this.http.post<any>(`${this.baseUrl}/users/resetpass/`, {
      email_address: email
    })

  }

  /*
  * Tests a password with regex
  * Password must contain at least 1 Uppercase , 1 Special Character and 1 numeric value
  * */

  public testValidPassword(password: string): boolean{

    const regex = new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,})$") ;

    return regex.test(password);

  }

  public changePassword(data: any): Observable<any>{

    return this.http.post<any>(`${this.baseUrl}changePassword`, data);

  }

}
