import { Component, OnInit } from '@angular/core';
import {ISite} from '../../../Models/ISite';
import {ITicket} from '../../../Models/Tickets/ITicket';
import {GenericHelpers} from '../../../utils/GenericHelpers';
import {ActivatedRoute, Router} from '@angular/router';
import {SitesService} from '../../../services/sites/sites.service';
import {TicketService} from '../../../services/tickets/ticket.service';
import { formatDistance } from 'date-fns';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {IBmsUser} from '../../../Models/IBmsUser';

import {RejectedTicket} from '../../../Models/Tickets/RejectedTicket';
import {ClosedTicket} from '../../../Models/Tickets/ClosedTicket';
import {TicketComment} from '../../../Models/Tickets/TicketComment';
import {ICloudBmsUser} from '../../../Models/ICloudBmsUser';
import {UserManagementService} from '../../../services/userManagement/user-management.service';
import {TicketLog} from '../../../Models/Tickets/TicketLog';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.scss']
})
export class ViewTicketComponent implements OnInit {

  ticketId: number;
  siteId: number;
  currentSiteDetails: ISite;

  currentBmsUser: IBmsUser;

  loadingTicket : boolean;


  ticket: ITicket;
  selectedUser : ICloudBmsUser;

  rejectionReason: string;
  rejectDrawerVisible: boolean;

  isClosable: boolean;
  canReject: boolean;
  isAdminUser: boolean;

  userList: ICloudBmsUser[];

  ticketComments: TicketComment[] = [];
  submitting = false;

  inputValue = '';

  ticketLogs: TicketLog[];

  handleSubmit(): void {
    this.submitting = true;
    const content = this.inputValue;
    this.inputValue = '';

    let comment : TicketComment ={
      ticket_id: this.ticketId,
      user_id: Number(this.currentBmsUser.idnew_bms_users),
      created_by: this.currentBmsUser.user_user,
      comment: content
    };

    this.ticketService.createComment(comment).subscribe( response => {


      this.notification.success(
        'Done!',
        response['message'],
        { nzPlacement: 'topRight' }
      );
      this.submitting = false;

      this.ticketService.getComments(this.ticketId).subscribe( response=> {

        this.ticketComments = response;

      }, error => {
        console.error(error);
      } );

    }, error => {
      console.error(error);
    } );

  }

  constructor(private route: ActivatedRoute,
              private siteService: SitesService,
              private ticketService: TicketService,
              private authenticationService: AuthenticationService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private router: Router, private userManagementService: UserManagementService) {
    this.route.params.subscribe( params => {
      this.ticketId = parseInt(params.id);
      //this.siteId = parseInt(params.siteId);

     // this.currentSiteDetails = this.siteService.getCurrentSiteDetails(this.selectedSiteId);

    } );

  }

  ngOnInit(): void {
    this.loadingTicket = true;
    this.ticketService.getTicketDetails(this.ticketId).subscribe( response => {

      this.ticket = response;
      this.configurePage();


    }, error => {
      this.loadingTicket = false;
      console.error(error);
    } );


    this.ticketService.getComments(this.ticketId).subscribe( response=> {

      this.ticketComments = response;

    }, error => {
      console.error(error);
    } );

    this.ticketService.getTicketLogs(this.ticketId).subscribe( (response) => {

      this.ticketLogs = response;

    }, error => {
      console.error(error);
    } )


  }

  configurePage():void{

    this.loadingTicket = true;

    let userData = this.authenticationService.getSignedInUserDetails();
    this.currentBmsUser = userData['data'];

    // // let userRole = userData..filter( data => data.role.name === "Administrator" )
    //

    //
    // if(Number(this.currentBmsUser) <= 1){
    //   this.isAdminUser = true;
    // }

    if(this.ticket.status !== 'Closed' && this.ticket.status !== 'Rejected' ){
      this.isClosable = true;
    }

    if(this.ticket.status !== 'Accepted'
      && this.ticket.status !== 'Rejected'
      && this.ticket.status !== 'Closed'
      && this.ticket.status !== 'In Progress'){

      this.canReject = true;

    }


    this.getUsers();
    this.loadingTicket = false;

  }

  getUsers():void{

    this.userManagementService.getUsers().subscribe( (response: ICloudBmsUser[]) => {

      let filteredUsers = [];

      for(let i in response){

        if(response[i].site_id === this.ticket.site_id || response[i].site_id === 0){
            filteredUsers.push(response[i]);
        }

      }

      this.userList = filteredUsers;

    }, error => {
      console.error(error);
    } );


  }

  initiateUserChange(user: ICloudBmsUser):void{
    this.selectedUser = user;
  }

  assignUser():void{

    if(this.selectedUser !== null && this.selectedUser !== undefined) {

      let data = {
        assigned_user_id: this.selectedUser.id,
        assigned_full_name: this.selectedUser.full_name,
        status: 'In Progress',
      };

      this.ticketService.update(this.ticketId, data).subscribe(response => {



        this.notification.success(
          'Ticket Assigned!',
          response['message'],
          { nzPlacement: 'topRight' }
        );

        this.router.navigate([`/admin/tickets`]);

      }, error => {
        console.error(error);
      });

    }else{
      this.notification.blank(
        'Invalid User Selection!',
        'Make sure to select a user before attempting to assign.',
        { nzPlacement: 'topRight' }
      );
    }

  }

  performRejection(){

    if(this.rejectionReason !== undefined && this.rejectionReason !== null){
      let rejectedTicket : RejectedTicket = {
        ticket_id: this.ticketId,
        user_id: Number(this.currentBmsUser.idnew_bms_users),
        created_by: this.currentBmsUser.user_user,
        reason: this.rejectionReason
      };

      this.ticketService.reject(rejectedTicket, this.ticketId).subscribe( response => {

        this.notification.success(
          'Done!',
           response['message'],
          { nzPlacement: 'topRight' }
        );

        this.router.navigate([`/admin/tickets`]);

      }, error => {
        console.error(error);
      } )

    }else{
      this.notification.warning(
        'Invalid Reason provided!',
        'Make sure to add a reason before attempting to reject this ticket',
        { nzPlacement: 'topRight' }
      );
    }



  }

  closeTicket():void{

    let closedTicket : ClosedTicket ={
      ticket_id: this.ticketId,
      user_id: Number(this.currentBmsUser.idnew_bms_users),
      closed_by: this.currentBmsUser.user_user,
      is_resolved: 'Yes',
      quality: 'Great',
      timeliness: 'On Time',
      comment: 'Closed',
      created_at: new Date
    };

    this.ticketService.close(closedTicket, this.ticketId).subscribe( response => {
      this.notification.success(
        'Done!',
        response['message'],
        { nzPlacement: 'topRight' }
      );
      this.router.navigate([`/admin/tickets`]);

    }, error => {
      console.error(error);
    } )

  }

  openDrawer(): void {
    this.rejectDrawerVisible = true;
  }

  closeDrawer(): void {
    this.rejectDrawerVisible = false;
  }

}
