import { NgModule } from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import {SigninComponent} from './pages/signin/signin.component';
import {ClientSelectionComponent} from './pages/client-selection/client-selection.component';
import {AdminComponent} from './layouts/admin/admin.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {DevicesComponent} from './pages/devices/devices.component';
import {AlarmSummaryComponent} from './pages/alarm-summary/alarm-summary.component';
import {DeviceTrendingComponent} from './pages/device-trending/device-trending.component';
import {TicketListComponent} from './pages/tickets/ticket-list/ticket-list.component';
import {CreateTicketComponent} from './pages/tickets/create-ticket/create-ticket.component';
import {ViewTicketComponent} from './pages/tickets/view-ticket/view-ticket.component';
import {UserManagementComponent} from './pages/user-management/user-management.component';
import {AdhockViewComponent} from './pages/tickets/adhock-view/adhock-view.component';
import {FuelConsumptionComponent} from './pages/reports/fuel-consumption/fuel-consumption.component';
import {ChangePasswordComponent} from './pages/change-password/change-password.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {HomeComponent} from './pages/home/home.component';
import {ClientAlarmSummaryComponent} from './pages/client-alarm-summary/client-alarm-summary.component';
import {ClientSiteListComponent} from './pages/client-site-list/client-site-list.component';
import {TemperatureReadingsComponent} from './pages/reports/temperature-readings/temperature-readings.component';
import {GeneratorReportComponent} from './pages/reports/generator-report/generator-report.component';
import {UnattendedNotificationsComponent} from './components/notifications/unattended-notifications/unattended-notifications.component';
import {SiteNotificationsListComponent} from './components/notifications/unattended-notifications/site-notifications-list/site-notifications-list.component';
import {SiteUnattendedNotificationsComponent} from './components/notifications/unattended-notifications/site-unattended-notifications/site-unattended-notifications.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import {PowerSystemsComponent} from './pages/reports/power-systems/power-systems.component';
import {SiteListComponent} from './pages/site-list/site-list.component';


const routes: Routes = [
  {
    path : '',
    component: HomeComponent
  },
  {
    path : 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path : 'login',
    component: SigninComponent
  },
  {
    path: 'auth-client',
    component: ClientSelectionComponent
  },
  {
    path: 'tickets/:id/:user_id',
    component: AdhockViewComponent
  },
  {
    path: 'password-change/:uid',
    component: ChangePasswordComponent
  },



  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: '',
        // component: ClientSiteListComponent
        component: SiteListComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'devices/:id',
        component: DeviceTrendingComponent
      },
      {
        path: 'active-alarms/:id',
        component: AlarmSummaryComponent
      },
      {
        path: 'device-trends/:id',
        component: DeviceTrendingComponent
      },
      {
        path: 'tickets',
        component: TicketListComponent,
      },
      {
        path: 'tickets/create',
        component: CreateTicketComponent
      },
      {
        path: 'tickets/:id',
        component: ViewTicketComponent
      },
      {
        path: 'user-management',
        component: UserManagementComponent
      },
      {
        path: 'reports/fuel-consumption',
        component: FuelConsumptionComponent
      },
      {
        path: 'reports/temperature-readings',
        component: TemperatureReadingsComponent
      },
      {
        path: 'reports/generator-report',
        component: GeneratorReportComponent
      },
      {
        path: 'reports/summary-report',
        component: PowerSystemsComponent
      },
      {
        path: 'alarm-summary',
        component: ClientAlarmSummaryComponent
      },


    ]
  },

  {
    path: '**',
    component: PageNotFoundComponent
  },
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  // ...any other options you'd like to use
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
