import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Event, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {Constants} from '../../utils/Contstants';

import {AuthenticationService} from '../../services/authentication/authentication.service';


 interface RouteInfo {
  path: string;
  title: string;
  breadcrumbs: Array<string>
}
export const ROUTES: RouteInfo[] = [
  //{ path: '/admin', title: 'Dashboard' },
  {
    path: '/admin/devices',
    title: 'Devices',
    breadcrumbs: ['Devices']
  },
  {
    path: '/admin/active-alarms',
    title: 'Active Alarms',
    breadcrumbs: ['Active Alarms']
  },
  {
    path: '/tickets',
    title: 'Tickets',
    breadcrumbs: ['Tickets']
  },
  {
    path: '/admin/tickets',
    title: 'View Ticket',
    breadcrumbs: ['Tickets', 'View Ticket']
  },
];

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  isCollapsed = true;
  menuItems: RouteInfo[];

  currentPage: string;
  breadCrumbs: Array<string>;

  message;

  constants = new Constants();
  canShowUserManagement: boolean = false;

  sideNavVisible: boolean = false;

  sessionModalVisible: boolean = false;
  count: number = 10;

  constructor(private router: Router, private authenticationService: AuthenticationService) {
    this.menuItems = ROUTES;

    router.events.subscribe( (event: Event) => {

      if (event instanceof NavigationStart) {
        // Show loading indicator

      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator


        for (let i in this.menuItems){
          if(event.url.includes(this.menuItems[i].path)){
            this.currentPage = this.menuItems[i].title;
            this.breadCrumbs = this.menuItems[i].breadcrumbs;
            break;
          }

          this.currentPage = "Dashboard";
        }

      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
      }
    });



  }

  ngOnInit(): void {
    this.getCurrentUserRole();
  }


  getCurrentUserRole(): void{
    this.authenticationService.getSignedInUserDetails().subscribe(response => {

      let role = response.role.name;
      if(role === 'Administrator'){
        this.canShowUserManagement = true;
      }

    }, error =>{
      console.error('Could not get current user role : ', error);
      this.sessionModalVisible = true;

      setInterval(() => {this.count -= 1;}, 1000);

      setTimeout(() => {
        this.authenticationService.signOut();
        // modal.close();
        this.sessionModalVisible = false;
        this.router.navigate(['/login']).then( () => {
          console.clear();
        } );
      }, 10000);

    });

  }

  toggleSideNav(): void{

    this.sideNavVisible = true;
  }

  closeSideNav(): void{
    this.sideNavVisible = false;
  }

}
