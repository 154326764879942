<div class="active-alarms-container">
  <div class="priority-indicator"
       [ngStyle]="{
       'background-color': getAlarmColor(alarm?.priority)
       }">
  </div>

  <nz-card
    [nzBordered]="false"
    [nzActions]="canEscalate ? [actionSetting, actionEdit, actionEllipsis] : [actionEdit, actionEllipsis]"
  >
    <nz-card-meta
      [nzTitle]="alarmCardTitle">
    </nz-card-meta>

    <ng-template #alarmCardTitle>
      <div class="d-flex w-100 justify-content-between mt-0 mb-0">
        <span class="card-text mb-0">{{alarm.description}}</span>
        <small class="card-text mb-0 mr-0">
          <nz-tag class="float-right"
                  [ngStyle]="{'color': alarm.is_cleared ? 'darkblue' : 'crimson' }">
            {{alarm.is_cleared ? 'Cleared' : 'Active' }}
          </nz-tag>
        </small>
      </div>
    </ng-template>

    <div class="d-flex w-100 justify-content-between mt-0 mb-0">
      <span class="card-text mb-0">{{alarm.device_name}}</span>
      <span class="text-muted card-text mb-0">
        <small><i class="lni lni-map-marker"></i></small>
        {{alarm.site_name}}
      </span>
    </div>
<!--    <div class="d-flex w-100 justify-content-between mt-0 mb-0">-->
<!--      <span class="card-text mb-0">{{alarm.}}</span>-->
<!--      <span class="text-muted card-text mb-0">-->
<!--        <small><i class="lni lni-map-marker"></i></small>-->
<!--        {{alarm.site_name}}-->
<!--      </span>-->
<!--    </div>-->

    <div class="d-flex w-100 justify-content-between mt-1 mb-0">
      <small class="text-muted card-text"><i class="lni lni-alarm-clock"></i> {{ alarm.triggered_at | date: 'medium'}}</small>
      <small class="mr-2 text-muted" *ngIf="isSilenced"><i class="lni lni-volume-mute mr-1"></i> this notification has been silenced and will not appear as a popup</small>
    </div>

    <div class="d-flex w-100 justify-content-between mt-1 mb-0" *ngIf="hasCount">
      <small class="text-info card-text"><i class="lni lni-list"></i> This alarm has occurred a total of <span class="font-weight-bold">{{numberOfOccurrences}} times</span></small>

    </div>

  </nz-card>

</div>

<ng-template #actionSetting>
  <button
    *ngIf="canEscalate"
    nz-button
    nzType="link"
    nzDanger
    nzBlock
    (click)="createTask()"
    class="text-left"><i class="lni lni-angle-double-up mr-1"></i> Escalate Alarm</button>
</ng-template>

<ng-template #actionEdit>
  <button nz-button nzType="link" nzBlock (click)="acknowledgeAlarm()" class="text-left"><i class="lni lni-checkbox mr-1"></i> Acknowledge</button>
</ng-template>

<ng-template #actionEllipsis>
  <button nz-button

          nzType="link"
          nzBlock
          (click)="silenceAlarm()" class="text-left"
  ><i class="lni lni-volume-mute mr-1"></i> {{isSilenced ? 'Resume active notifications' : 'Silence this Alarm'}}</button>
</ng-template>

<!--          <nz-dropdown-menu #alarmActions="nzDropdownMenu">-->
<!--            <ul nz-menu>-->
<!--              <li nz-menu-item class="shakeable" *ngIf="canEscalate">-->
<!--                <button-->
<!--                  nz-button-->
<!--                  nzType="link"-->
<!--                  nzDanger-->
<!--                  nzBlock-->
<!--                  (click)="createTask()"-->
<!--                  class="text-left"><i class="lni lni-angle-double-up mr-1"></i> Escalate Alarm</button>-->
<!--              </li>-->
<!--              <li nz-menu-item>-->
<!--                <button nz-button nzType="link" nzBlock (click)="acknowledgeAlarm()" class="text-left"><i class="lni lni-checkbox mr-1"></i> Acknowledge</button>-->
<!--              </li>-->
<!--              <li nz-menu-item>-->
<!--                <button nz-button-->

<!--                        nzType="link"-->
<!--                        nzBlock-->
<!--                        (click)="silenceAlarm()" class="text-left"-->
<!--                        ><i class="lni lni-volume-mute mr-1"></i> {{isSilenced ? 'Resume active notifications' : 'Silence this Alarm'}}</button>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </nz-dropdown-menu>-->
