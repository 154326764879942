<div class="container-fluid">

  <div class="row">
    <app-dashboard-page-header
      class="col-lg-12 col-md-12 col-sm-12"
      [config]="headerConfig" (selectedSiteChange)="handleSiteChangeEmitter($event)">
    </app-dashboard-page-header>
  </div>

  <div class="row" *ngIf="!hasNoData && !isLoading">
    <nz-page-header class="mb-0 border-bottom p-0 col-lg-12 col-md-12 col-sm-12">
      <nz-page-header-content class="mb-0">
        <div class="container-fluid">
          <div class="row justify-content-end">
            <div class="col-lg-8 col-md-8 col-sm-12">
              <!-- TOTALS-->
              <div class="row">

                <div class="col-lg-4 col-md-4 col-sm-12">
                  <div class="container">
                    <nz-statistic [nzValueTemplate]="currentTotalActivePowerValue"
                                  [nzTitle]="currentTotalActivePowerTitle">
                    </nz-statistic>

                    <ng-template #currentTotalActivePowerTitle>
                      <h5>Total Active Power</h5>
                    </ng-template>

                    <ng-template #currentTotalActivePowerValue>

                      <h3 nz-typography>
                        <nz-badge nzStatus="processing"></nz-badge>
                        {{currentTotalActivePower}}
                        <span><small>KW</small></span></h3>
                    </ng-template>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12">
                  <div class="container">
                    <nz-statistic [nzValueTemplate]="currentApparentPowerValue"
                                  [nzTitle]="currentApparentPowerTitle">
                    </nz-statistic>

                    <ng-template #currentApparentPowerTitle>
                      <h5>Total Apparent Power</h5>
                    </ng-template>

                    <ng-template #currentApparentPowerValue>
                      <h3 nz-typography>
                        <nz-badge nzStatus="processing"></nz-badge>
                        {{currentTotalApparentPower}}
                        <span><small>KVA</small></span></h3>
                    </ng-template>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12">
                  <div class="container">
                    <nz-statistic [nzValueTemplate]="currentPowerFactorValue"
                                  [nzTitle]="currentPowerFactorTitle">
                    </nz-statistic>

                    <ng-template #currentPowerFactorTitle>
                      <h5>Average Power Factor</h5>
                    </ng-template>

                    <ng-template #currentPowerFactorValue>
                      <h3 nz-typography>
                        <nz-badge [nzStatus]="(currentTotalPowerFactor >= 1) ? 'error' : 'warning' "></nz-badge>
                        {{currentTotalPowerFactor}}
                        <span><small>u</small></span></h3>
                    </ng-template>
                  </div>
                </div>

              </div>
              <!--END TOTALS-->
            </div>
          </div>
        </div>
      </nz-page-header-content>
    </nz-page-header>
  </div>


  <div class="row mt-0">
    <nz-card class="col-md-12 col-lg-12 col-sm-12"
             nzBordered="false"
             [nzLoading]="isLoading" [nzBodyStyle]="{'padding': '0 5'}">

      <div class="row" *ngIf="hasNoData; else ContentContainer">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <nz-result nzStatus="404" nzTitle="No Data Found"
                     nzSubTitle="It seems as though there is no data for this site">
            <div nz-result-extra>
              <button nz-button nzType="primary"
                      (click)="handleSiteChangeEmitter(selectedSite)">
                Try Again
              </button>
            </div>
          </nz-result>
        </div>
      </div>

      <ng-template #ContentContainer>
        <div class="row justify-content-center mt-1">
          <div class="col-lg-4 col-md-4 col-sm-12 border-right p-0">
            <div class="container-fluid pr-0 pt-0">
              <nz-table #basicTable [nzData]="sitePowerMeterValues?.devices"

                        [nzPageSize]="250" [nzScroll]="{ y: '50vh' }"
                        [nzShowPagination]="false">
                <tbody>

                <tr *ngFor="let data of basicTable.data; let i = index;"
                    class="clickable-element" (click)="displayDeviceData(i)"
                    [ngClass]="{'selected-device-type': selectedDeviceIndex === i}">
                  <td class="font-weight-bold" [colSpan]="2">
                    <span>{{ data.device_name }}</span>
                  </td>
<!--                  <td>-->
<!--                    <span *ngIf="!data.state">-->
<!--                      <nz-tag nzColor="error">-->
<!--                        Not Running-->
<!--                      </nz-tag>-->
<!--                    </span>-->
<!--                    <span *ngIf="data.state">-->
<!--                      <nz-tag nzColor="processing">-->
<!--                        Running-->
<!--                      </nz-tag>-->
<!--                    </span>-->
<!--                  </td>-->
                </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-7 pt-0 pl-0">
            <div class="container-fluid p-0">
              <h5 class="text-center font-weight-bold mt-2">
                <i nz-icon nzType="thunderbolt" nzTheme="outline"
                   class="button-icon text-warning"></i>
                {{sitePowerMeterValues?.devices[selectedDeviceIndex].device_name}}
              </h5>
              <nz-divider></nz-divider>
              <!--Voltage-->
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <figure class="highcharts-figure">
                    <div [chart]="l1VoltageChart"  class="chart-container"></div>
                  </figure>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <figure class="highcharts-figure">
                    <div [chart]="l2VoltageChart"  class="chart-container"></div>
                  </figure>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <figure class="highcharts-figure">
                    <div [chart]="l3VoltageChart"  class="chart-container"></div>
                  </figure>
                </div>
              </div>

              <!--Current-->
              <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <figure class="highcharts-figure">
                    <div [chart]="l1CurrentChart"  class="chart-container"></div>
                  </figure>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <figure class="highcharts-figure">
                    <div [chart]="l2CurrentChart"  class="chart-container"></div>
                  </figure>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <figure class="highcharts-figure">
                    <div [chart]="l3CurrentChart"  class="chart-container"></div>
                  </figure>
                </div>
              </div>

            </div>
          </div>

        </div>
      </ng-template>
    </nz-card>
  </div>

</div>
