import {Component, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {StorageService} from './services/storage/storage.service';
import { GetCountryService } from './services/getcountry/get-country.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'couldbms';
  lat: any;
  lng: any;

  popiaModalVisible: boolean = false;
  hasAgreedToPOPIA: boolean = false;

  constructor(private router: Router, private storageService: StorageService) {
  }

  ngOnInit(): void {

  }

  checkForPOPIA(): void{
    let cookie = this.storageService.getCookie("POPIA_AGREEMENT");
    this.popiaModalVisible = cookie === "";
  }

  handlePOPIAConfirm(): void{
    document.cookie = "POPIA_AGREEMENT=false";
    this.popiaModalVisible = false;
    window.location.reload();
  }
}
