<div class="container-fluid">

  <div class="row">
    <nz-card class="col-lg-5 col-md-5" nzBordered="false"
             [nzBodyStyle]="{'padding': 0, 'min-height': '85vh'}">
      <nz-page-header  class="p-3" nzBackIcon>
        <nz-page-header-title>Devices and Instances</nz-page-header-title>
        <nz-page-header-subtitle class="font-weight-bold" *ngIf="currentSiteDetails?.name !== undefined">
          <i class="mdi mdi-map-marker-radius"></i>
          {{currentSiteDetails?.name}}
        </nz-page-header-subtitle>
        <nz-page-header-extra>
          <button nz-button
                  nzType="default" routerLink="/admin/active-alarms/{{selectedSiteId}}">
            <i nz-icon nzType="alert" nzTheme="twotone" nzTwotoneColor="#d32f2f"></i>
            Alarms
          </button>
        </nz-page-header-extra>
      </nz-page-header>

      <nz-divider></nz-divider>

      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 col-md-6 p-0 border-right full-heght-tables">

            <nz-table #nzTable [nzData]="devices" nzTableLayout="fixed"
                      [nzPageSize]="1000" [nzScroll]="{ y: '70vh' }"
                      [nzShowPagination]="false"
                      [nzLoading]="loadingDevices" class="no-scroll-bar">

              <tbody>
              <ng-container>
                <tr  *ngFor="let data of nzTable.data; let i = index" class="clickable-element"
                     [ngClass]="{'selected-device-type': selectedDeviceIndex === i}"
                     (click)="selectedDeviceIndex = i;
                           selectedDeviceTypeId = data?.device_type_id;
                           setInstances(data?.instances);">
                  <td *ngIf="!loadingDevices"  class="image_tr">
                    <nz-avatar nzIcon="node-index"
                               [nzSrc]="deviceImage(data?.device_type_id)" nzSize="30">

                    </nz-avatar>

                  </td>
                  <td class="device-tr">{{ data?.device_type }}
                  </td>
                  <td><nz-badge [nzStyle]="{ height: '10px', width: '10px' }"
                                [nzStatus]="getStatusColour(data.status)"></nz-badge></td>
                  <td> <i nz-icon nzType="right" nzTheme="outline"></i></td>
                </tr>

              </ng-container>
              </tbody>
            </nz-table>

          </div>

          <div class="col-lg-6 col-md-6 col-sm-12 p-0">

            <nz-card nzBordered="false"
                     *ngIf="deviceInstances !== undefined"
                     [nzBodyStyle]="{ 'padding': 0 }">
              <nz-table #nzTable [nzData]="deviceInstances" nzTableLayout="fixed"
                        [nzPageSize]="1000" [nzScroll]="{ y: '70vh' }"
                        [nzShowPagination]="false"
                        [nzLoading]="loadingDevices" class="no-scroll-bar">

                <tbody>
                <ng-container>
                  <tr  *ngFor="let data of nzTable.data; let i = index" class="clickable-element"
                       [ngClass]="{'selected-device-type': selectedInstanceIndex === i}"
                       (click)="showDeviceInstanceInfo(data); selectedInstanceIndex = i;" style="height: 65px;">
                    <td class="instance_tr">{{ data?.instance_name }}</td>
                    <td>
                      <nz-badge [nzStyle]="{ height: '10px', width: '10px' }"
                                [nzStatus]="getStatusColour(data.status)"></nz-badge>
                    </td>
                    <td>
                      <i nz-icon nzType="sliders" nzTheme="outline"></i>
                    </td>
                  </tr>

                </ng-container>
                </tbody>
              </nz-table>
            </nz-card>

          </div>
        </div>
      </div>

    </nz-card>

    <nz-card class="col-lg-7 col-md-7" [nzBodyStyle]="{'padding': 0}" nzBordered="false">
      <nz-page-header  class="p-3 pb-0">
        <nz-page-header-title *ngIf="selectedInstanceName != undefined">
          {{selectedInstanceName}}
        </nz-page-header-title>

        <nz-page-header-extra>
          <button nz-button nzType="default" *ngIf="showTrendingTab"
                  (click)="openTrendingDrawer = true">
            <i nz-icon nzType="bar-chart" nzTheme="outline"></i>
            Show Device Trend
          </button>
        </nz-page-header-extra>
      </nz-page-header>

      <nz-divider></nz-divider>

      <nz-card style="width:100%;"
               [nzBordered]="false"
               [nzBodyStyle]="{ 'padding': 0 }" [nzLoading]="loadingInstanceData">

        <nz-empty

          [nzNotFoundContent]="contentTpl" *ngIf="selectedInstanceName === undefined"
          class="full-width readings-empty"
        >
          <ng-template #contentTpl>
            <span>Select a Device Instance in order to view Readings in this pane</span>
          </ng-template>

        </nz-empty>

        <nz-tabset [hidden]="selectedInstanceName === undefined"
                   style="border: none; width: 100%"
                   [nzType]="'card'"
                   (nzSelectedIndexChange)="selectedInstanceTabIndex = $event;
                    showDeviceInstanceInfo(deviceInstances[selectedInstanceIndex]);
                    tabInstanceChange($event,deviceInstances[selectedInstanceIndex] )"
                   [nzSize]="'small'" [(nzSelectedIndex)]="selectedInstanceTabIndex" >
          <nz-tab nzTitle="Readings">

            <ng-template instance-host  style="width: 100%; padding: 5px;"></ng-template>
            <nz-result nzStatus="warning"
                       nzTitle="There do not seem to be any readings from this device at the moment"
                       nzSubTitle="Please contact support if this problem persists"

                       *ngIf="emptyReading">

            </nz-result>

          </nz-tab>

          <nz-tab nzTitle="Alarms">
            <ng-template nz-tab>
              <nz-result nzStatus="404" nzTitle="There seems to be no data"
                         *ngIf="activeAlarms.length === 0 && !loadingAlarms">
                <div nz-result-extra>

                </div>
              </nz-result>
              <nz-spin [nzSpinning]="loadingAlarms" style="height: 500px; width: 100%;">
                <app-device-alarms-list style="height: 500px; width: 100%;"
                                        [readings]="activeAlarms"
                                        *ngIf="activeAlarms?.length > 0">

                </app-device-alarms-list>
              </nz-spin>

            </ng-template>

          </nz-tab>

        </nz-tabset>

      </nz-card>

    </nz-card>

  </div>
</div>



<nz-drawer [nzClosable]="false"
           [nzVisible]="openTrendingDrawer"
           [nzPlacement]="'left'"
           (nzOnClose)="openTrendingDrawer = false; hasRetrievedExcelFile = false;"
           [nzWidth]="'90%'" [nzBodyStyle]="{'padding': 0}">

  <ng-container *nzDrawerContent>
    <nz-page-header class="p-3 border-bottom">
      <nz-page-header-title class="mt-1">
        <h4 nz-typography>
          <i nz-icon nzType="environment" nzTheme="outline" class="button-icon"></i>
          {{currentSiteDetails?.name}}
        </h4>
      </nz-page-header-title>
      <nz-page-header-subtitle class="mt-1">
        <h5 nz-typography>

          {{selectedInstanceName}}
        </h5>
      </nz-page-header-subtitle>

      <nz-page-header-content class="trend-time-picker">

        <div class="range-selection-container">
          <nz-form-item>
            <nz-form-label>From</nz-form-label>
            <nz-form-control>

              <nz-date-picker [nzDisabledDate]="disabledDate" [(ngModel)]="startRange" (ngModelChange)="onStartRangeChange($event)"></nz-date-picker>

            </nz-form-control>
          </nz-form-item >

          <nz-form-item>
            <nz-form-label>To</nz-form-label>
            <nz-form-control>

              <nz-date-picker [nzDisabledDate]="disabledDate" [(ngModel)]="endRange" (ngModelChange)="onEndRangeChange($event)"></nz-date-picker>

            </nz-form-control>
          </nz-form-item >

          <nz-form-item [ngStyle]="{'margin-left': '30px'}">
            <nz-form-control>
              <button nz-button nzType="primary" (click)="setTrendTimeRange()">Apply Date Range</button>
            </nz-form-control>
          </nz-form-item>

        </div>

<!--        <nz-form-item>-->
<!--          <nz-form-label [nzSpan]="24">Quick Range</nz-form-label>-->
<!--          <nz-form-control [nzSpan]="24">-->
<!--            <nz-select nzPlaceHolder="Select a range"-->
<!--                       [(ngModel)]="selectedTrendTimeRange"-->
<!--                       (ngModelChange)="changeDeviceTrendRange()">-->
<!--              <nz-option *ngFor="let range of trendTimeRanges"-->
<!--                         [nzLabel]="range.name"-->
<!--                         [nzValue]="range"></nz-option>-->
<!--              <nz-option nzLabel="This month so far" nzValue="This month so far" nzHide></nz-option>-->
<!--            </nz-select>-->
<!--          </nz-form-control>-->
<!--        </nz-form-item >-->



        <div></div>

        <ng-template #datePrefix>
          <i nz-icon nzType="calendar" nzTheme="outline"></i>
        </ng-template>
      </nz-page-header-content>

    </nz-page-header>

    <ng-container *ngIf="openTrendingDrawer">


      <div class="trend-iframe-container">
        <div *ngFor="let url of iframeUrls">
          <iframe
            [src]="url" frameborder="0"
            allowtransparency
            style=".parameter-container{display: none};.css-1vzus6i-Icon{display: none}"></iframe>
        </div>
      </div>

    </ng-container>
  </ng-container>




</nz-drawer>

<nz-modal [(nzVisible)]="showExportModal"
          nzTitle="Excel Report"
          (nzOnCancel)="showExportModal = false; hasRetrievedExcelFile = false;" nzFooter="null" [nzWidth]="800">
  <ng-container>
    <nz-progress [nzPercent]="loadingFileProgress" nzStatus="active" [hidden]="!loadingTrendFile"></nz-progress>
    <nz-card [nzLoading]="loadingTrendFile"
             nzBordered="false"
             [nzBodyStyle]="{'padding': 0}"
             class="container-fluid">

      <div class="row justify-content-center" *ngIf="!hasRetrievedExcelFile">
        <div class="col-md-12 col-lg-12 col-sm-12">
          <h3 class="text-secondary">Select a Start and End Date</h3>

          <nz-date-picker
            [(ngModel)]="dateFrom" (ngModelChange)="onFromChange($event)"
            [nzFormat]="'yyyy-MM-dd'"
            nzPlaceHolder="Start Date"
            class="mr-4" [nzDisabledDate]="disabledEndDate"></nz-date-picker>

          <nz-date-picker [(ngModel)]="dateTo" (ngModelChange)="onToChange($event)"
                          [nzFormat]="'yyyy-MM-dd'"
                          nzPlaceHolder="End Date"
                          class="mr-4" [nzDisabledDate]="disabledEndDate"></nz-date-picker>

          <button nz-button nzType="primary"
                  (click)="getTrendFile()"
                  [nzLoading]="loadingTrendFile">
            <i nz-icon nzType="build" nzTheme="outline" class="button-icon"></i>
            Generate Excel File
<!--            <i nz-icon nzType="right" class="button-icon"></i>-->
          </button>

        </div>
      </div>

      <div class="row justify-content-center" *ngIf="hasRetrievedExcelFile">
        <div class="col-md-12 col-lg-12 col-sm-12">
          <nz-result
            nzStatus="success"
            nzTitle="Your Excel Report has Generated"
            nzSubTitle="Click the button below to download"
          >
            <div nz-result-extra>
              <a href="{{excelFile}}" download="report.xlsx" nz-button nzType="primary">
                <i nz-icon nzType="download" nzTheme="outline" class="button-icon"></i>
                Download Excel File</a>
            </div>
          </nz-result>

        </div>
      </div>
    </nz-card>
  </ng-container>
</nz-modal>

<app-overlay-loader [isLoading]="loadingDevices" [loadingText]="'Retrieving Devices...'"></app-overlay-loader>
