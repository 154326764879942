<nz-page-header nzBackIcon nzTitle="Summary Report">

  <nz-breadcrumb nz-page-header-breadcrumb>
    <nz-breadcrumb-item>Reports</nz-breadcrumb-item>
    <nz-breadcrumb-item>
      Summary Report
    </nz-breadcrumb-item>
  </nz-breadcrumb>

</nz-page-header>
<nz-divider></nz-divider>

<div class="data-selection-container">

  <div class="form-container">
    <nz-alert
      nzType="info"
      nzMessage="Informational Notes"
      nzDescription="Complete the form below and click 'Generate Report' to generate a report"
      nzShowIcon
    ></nz-alert>

    <form nz-form>
      <nz-form-item>
        <nz-form-label [nzSpan]="24" [nzRequired]="true">Select A Report Type</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <nz-radio-group [(ngModel)]="selectedDeviceType" [ngModelOptions]="{standalone: true}">
            <label nz-radio nzValue="Site Availability">Site Availability Report</label>
            <label nz-radio nzValue="Power Meter">Power Meter Active Power</label>
            <label nz-radio nzValue="Rectifier">Rectifier Used Capacity</label>
            <label nz-radio nzValue="UPS">UPS System Output Load</label>
            <label nz-radio nzValue="Temperature">Temperature Sensors</label>
            <label nz-radio nzValue="FuelLevels">Fuel Levels</label>
<!--            <label nz-radio nzValue="Combined" *ngIf="clientID !== 32767">Combined Report <i>(UPS, Rectifier, Fuel, Temperature)</i></label>-->
            <label nz-radio nzValue="Changeover">Changeover Report</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24" [nzRequired]="true">Select a Site</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <nz-select nzShowSearch nzAllowClear
                     nzPlaceHolder=" * Select a site"
                     [(ngModel)]="selectedSiteName"
                     [ngModelOptions]="{standalone: true}"
                     [nzDisabled]="selectedDeviceType === 'Combined'"
                     (ngModelChange)="onSiteSelected($event)">

<!--            <nz-option nzLabel="All Sites" nzValue="All Sites" *ngIf="selectedDeviceType !== 'Changeover'"></nz-option>-->
            <nz-option *ngFor="let site of siteList"
                       [nzLabel]="site.name"
                       [nzValue]="site.legacy_id || site._id"></nz-option>

          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24" [nzRequired]="true">Pick a Date Range</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <nz-range-picker [(ngModel)]="date"
                           (ngModelChange)="onDateChange($event)"
                           [nzFormat]="'yyyy-MM-dd'"
                           [nzDisabledDate]="disabledEndDate"
                           [ngModelOptions]="{standalone: true}"></nz-range-picker>
        </nz-form-control>
      </nz-form-item>

      <button nz-button nzType="default"
              (click)="generateReport()"
              [nzLoading]="isLoadingFileData">
        Generate Report
        <i nz-icon nzType="right" class="button-icon"></i>
      </button>

    </form>


  </div>

  <div class="result-container">
<!--    <ng-template #indicatorTemplate><i nz-icon nzType="loading" class="large-loader"></i></ng-template>-->
<!--    <nz-spin [nzSpinning]="isLoadingFileData" [nzIndicator]="indicatorTemplate">-->
<!--    </nz-spin>-->

    <div class="large-loader" *ngIf="isLoadingFileData"></div>

    <h5 class="loading-text" *ngIf="isLoadingFileData">This might take some time, please be patient as your report is being generated</h5>


    <nz-result nzStatus="info"
               nzTitle="No report has been generated"
               nzSubTitle="Please select a site and select a start date to generate a report"
               *ngIf="!fileDownloaded && !isLoadingFileData">

    </nz-result>

    <nz-result
      nzStatus="success"
      nzTitle="Your {{selectedDeviceType}} report has been generated"
      nzSubTitle=""  *ngIf="!hasErrorDownloading && fileDownloaded"
    >
      <div nz-result-extra>
        <a nz-button nzType="primary" [href]="fileUrl"
           download="{{selectedDeviceType}}_{{dateFrom}}.pdf" (click)="clearForm()">
          <i nz-icon nzType="file-excel" nzTheme="outline" class="button-icon"></i>
          Download
        </a>
      </div>
    </nz-result>

    <nz-result nzStatus="warning"
               nzTitle="Operation Failed"
               nzSubTitle="CloudBMS Failed to generate your report. Please try again."
               *ngIf="hasErrorDownloading && fileDownloaded">

    </nz-result>
  </div>


</div>

<nz-divider></nz-divider>
