
<div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" style="height: 100%" class="border-right border-left">
  <div nz-col class="gutter-row" [nzSpan]="2"></div>
  <div nz-col class="gutter-row" [nzSpan]="20">
    <nz-page-header nzBackIcon>
      <nz-avatar nz-page-header-avatar nzSrc="assets/round_bms_icon.png"></nz-avatar>
      <nz-page-header-title>Ticket</nz-page-header-title>
      <nz-page-header-subtitle>{{ticket?.reference}}</nz-page-header-subtitle>
      <nz-page-header-tags>
        <nz-tag [nzColor]="'blue'">{{ticket?.site_name}}</nz-tag>
      </nz-page-header-tags>
    </nz-page-header>
    <nz-divider class="mb-5"></nz-divider>

    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" >
      <div nz-col class="gutter-row" [nzSpan]="16">
        <nz-card class="full-width" [nzBordered]="false" [nzBodyStyle]="{'padding' : 0}" [nzLoading]="loadingTickets" style="height: 500px;">

          <nz-descriptions nzBordered nzLayout="vertical">
            <nz-descriptions-item nzTitle="Reference #">{{ticket?.reference}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Urgency" [nzSpan]="3">
              <nz-tag [nzColor]="'#f50'" class="bg-danger priority" *ngIf="ticket?.urgency === 'Critical'">
                Critical
              </nz-tag>
              <nz-tag [nzColor]="'#f50'" class="bg-warning priority" *ngIf="ticket?.urgency === 'Urgent'">
                Urgent
              </nz-tag>
              <nz-tag [nzColor]="'#f50'" class="bg-warning priority" *ngIf="ticket?.urgency === 'Warning'">
                Warning
              </nz-tag>
              <nz-tag [nzColor]="'#f50'" class="bg-info priority" *ngIf="ticket?.urgency === 'Event'">
                Event
              </nz-tag>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Subject">{{ticket?.subject}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Status" [nzSpan]="3">
              <nz-tag [nzColor]="'#f50'" class="bg-danger priority" *ngIf="ticket?.status === 'Unassigned'">
                Unassigned
              </nz-tag>
              <nz-tag [nzColor]="'#f50'" class="bg-danger priority" *ngIf="ticket?.status === 'Rejected'">
                Rejected
              </nz-tag>
              <nz-tag [nzColor]="'#f50'" class="bg-warning priority" *ngIf="ticket?.status === 'Assigned'">
                Assigned
              </nz-tag>
              <nz-tag [nzColor]="'#f50'" class="bg-info priority" *ngIf="ticket?.status === 'In Progress'">
                In Progress
              </nz-tag>
              <nz-tag [nzColor]="'#f50'" class="bg-success priority" *ngIf="ticket?.status === 'Closed'">
                Closed
              </nz-tag>
            </nz-descriptions-item>
            <nz-descriptions-item nzTitle="Description" [nzSpan]="12">
              {{ticket?.description}}
            </nz-descriptions-item>

            <nz-descriptions-item nzTitle="Due Date">{{ticket?.due_date}}</nz-descriptions-item>
            <nz-descriptions-item nzTitle="Assigned User" [nzSpan]="3">
              {{ (ticket?.assigned_full_name === null) ? 'N/A' : ticket?.assigned_full_name  | uppercase}}
            </nz-descriptions-item>

          </nz-descriptions>

        </nz-card>
      </div>
      <div nz-col class="gutter-row" [nzSpan]="8" style="height: 60vh;">
        <span>Add a Comment</span>
        <nz-comment>
          <nz-avatar nz-comment-avatar nzIcon="user"></nz-avatar>
          <nz-comment-content>
            <nz-form-item>
              <textarea [(ngModel)]="comment" nz-input rows="3"></textarea>
            </nz-form-item>
            <nz-form-item>
              <button nz-button nzType="primary" [nzLoading]="isSubmitting" [disabled]="!comment" (click)="handleSubmit()">
                Add Comment
              </button>
            </nz-form-item>
          </nz-comment-content>
        </nz-comment>
        <nz-divider></nz-divider>
        <span>Comments : ({{ticketComments?.length}}) - <span class="x-small-text">Scroll to view more</span></span>
        <nz-list *ngIf="ticketComments?.length" [nzDataSource]="ticketComments"
                 [nzRenderItem]="item"
                 [nzItemLayout]="'horizontal'" style="height: 30vh;" class="scrollable-div">
          <ng-template #item let-item>
            <nz-comment nzAuthor="{{item?.created_by | uppercase}}" nzDatetime="{{item?.created_at | date : 'medium'}}">
              <nz-avatar nz-comment-avatar nzIcon="user"
                         nzSrc="//zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"></nz-avatar>
              <nz-comment-content>
                <p>
                  {{item?.comment}}
                </p>
              </nz-comment-content>
            </nz-comment>
          </ng-template>
        </nz-list>

      </div>
    </div>

  </div>
  <div nz-col class="gutter-row" [nzSpan]="2"></div>

</div>

