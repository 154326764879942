<div class="container-fluid">

  <div class="row">
    <app-dashboard-page-header
      class="col-lg-12 col-md-12 col-sm-12"
      [config]="headerConfig" (selectedSiteChange)="handleSiteChangeEmitter($event)">
    </app-dashboard-page-header>
  </div>

  <div class="row">
    <nz-card nzBordered="false" [nzBodyStyle]="{'padding': 0}"
             class="col-lg-12 col-md-12 col-sm-12" style="width: 100%;"
             [nzLoading]="isLoadingRectifierData">
      <div class="row" *ngIf="!hasRectifierData && !isInitialising">
        <nz-card nzBordered="false"
                 [nzBodyStyle]="{'padding': 0}"
                 class="col-lg-12 col-md-12 col-sm-12"
                 [nzLoading]="isLoadingRectifierData">

          <div class="col-lg-12 col-md-12 col-sm-12">
            <nz-result nzStatus="404" [nzTitle]="'No Data Found For '+ selectedSite?.name"
                       nzSubTitle="Click the button below to try again">
              <div nz-result-extra>
                <button nz-button nzType="primary"
                        (click)="fetchSiteDeviceReadings(selectedSite?.legacy_id.toString() || selectedSite._id)">Retry</button>
              </div>
            </nz-result>
          </div>
        </nz-card>

      </div>

      <div class="row mt-2" *ngIf="hasInitialised && hasRectifierData">
        <nz-card class="col-lg-12 col-md-12 col-sm-12"
                 [nzLoading]="isLoadingRectifierData"
                 nzBordered="false">

          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 border-right">
                <nz-card nzBordered="false"
                         [nzBodyStyle]="{'padding': 0}"
                         *ngFor="let device of rectifierData?.devices;"
                         class="border-bottom mb-2" [nzLoading]="isLoadingRectifierData">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <nz-descriptions [nzTitle]="device.device_name"
                                         nzBordered [nzSize]="'small'" nzLayout="vertical">

                          <nz-descriptions-item nzTitle="Output Voltage" [nzSpan]="1">
                            <h3 nz-typography>
                              <nz-badge
                                [nzStatus]="(device.device_values?.voltage?.value !== '0.00') ? 'processing' : 'error'">
                              </nz-badge>
                              {{device?.device_values?.voltage.value}}
                              <span>
                              <small>{{device.device_values?.voltage?.unit}}</small>
                            </span>
                            </h3>
                          </nz-descriptions-item>
                          <nz-descriptions-item nzTitle="Output Current" [nzSpan]="1">
                            <h3 nz-typography>
                              <nz-badge nzStatus="processing"></nz-badge>
                              {{device.device_values?.current?.value}}
                              <span>
                              <small>{{device.device_values?.current?.unit}}</small>
                            </span>
                            </h3>
                          </nz-descriptions-item>

                          <nz-descriptions-item nzTitle="Battery Voltage" [nzSpan]="1">
                            <h3 nz-typography>
                              <nz-badge nzStatus="processing"></nz-badge>
                              {{device.device_values?.battery_voltage?.value}}
                              <span>
                              <small>{{device.device_values?.battery_voltage?.unit}}</small>
                            </span>
                            </h3>
                          </nz-descriptions-item>

                          <nz-descriptions-item nzTitle="Battery Current" [nzSpan]="1">
                            <h3 nz-typography>
                              <nz-badge nzStatus="processing"></nz-badge>
                              {{device.device_values?.battery_current?.value}}
                              <span>
                              <small>{{device.device_values?.battery_current?.unit}}</small>
                            </span>
                            </h3>
                          </nz-descriptions-item>

                          <nz-descriptions-item nzTitle="Battery Mode" [nzSpan]="1">
                            <h3 nz-typography>
                              <nz-tag nzColor="error"
                                      *ngIf="device.device_values?.battery_mode?.value === 1">
                                Unknown
                              </nz-tag>
                              <nz-tag nzColor="processing"
                                      *ngIf="device.device_values?.battery_mode?.value === 2">
                                Float Charging
                              </nz-tag>
                              <nz-tag nzColor="warning"
                                      *ngIf="device.device_values?.battery_mode?.value === 3">
                                Short Test
                              </nz-tag>
                              <nz-tag nzColor="warning"
                                      *ngIf="device.device_values?.battery_mode?.value === 4">
                                Boost Charging For Test
                              </nz-tag>
                              <nz-tag nzColor="warning"
                                      *ngIf="device.device_values?.battery_mode?.value === 5">
                                Manual Testing
                              </nz-tag>
                              <nz-tag nzColor="warning"
                                      *ngIf="device.device_values?.battery_mode?.value === 6">
                                Plan Testing
                              </nz-tag>
                              <nz-tag nzColor="warning"
                                      *ngIf="device.device_values?.battery_mode?.value === 7">
                                AC Fail Testing
                              </nz-tag>
                              <nz-tag nzColor="error"
                                      *ngIf="device.device_values?.battery_mode?.value === 8">
                                AC Fail
                              </nz-tag>

                              <nz-tag nzColor="warning"
                                      *ngIf="device.device_values?.battery_mode?.value === 9">
                                Manual Boost Charging
                              </nz-tag>
                              <nz-tag nzColor="processing"
                                      *ngIf="device.device_values?.battery_mode?.value === 10">
                                Auto Boost Charging
                              </nz-tag>
                              <nz-tag nzColor="warning"
                                      *ngIf="device.device_values?.battery_mode?.value === 11">
                                Cyclic Boost Charging
                              </nz-tag>
                              <nz-tag nzColor="warning"
                                      *ngIf="device.device_values?.battery_mode?.value === 12">
                                Master Boost Charging
                              </nz-tag>
                              <nz-tag nzColor="processing"
                                      *ngIf="device.device_values?.battery_mode?.value === 13">
                                Master Battery Testing
                              </nz-tag>

                            </h3>
                          </nz-descriptions-item>

                          <nz-descriptions-item nzTitle="System Status" [nzSpan]="1">
                            <h3 nz-typography>
                              <nz-tag nzColor="processing"
                                      *ngIf="device.device_values?.system_status?.value === 2">
                                Normal
                              </nz-tag>
                              <nz-tag nzColor="warning"
                                      *ngIf="device.device_values?.system_status?.value === 3">
                                Warning
                              </nz-tag>
                              <nz-tag nzColor="error"
                                      *ngIf="device.device_values?.system_status?.value === 6">
                                Critical
                              </nz-tag>

                            </h3>
                          </nz-descriptions-item>

                        </nz-descriptions>
                      </div>

                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="container-fluid p-0">
                          <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-5 col-sm-12 p-0 m-0">
                              <ng-container *ngIf="hasRectifierData === true">
                                <app-gauge-chart
                                  [chartData]="{
                            series_name: 'Voltage',
                            series_data: device.device_values?.voltage?.value,
                            value_suffix: device.device_values?.voltage?.unit
                            }"

                                  [optionsType]="'voltage'"></app-gauge-chart>
                              </ng-container>

                            </div>

                            <div class="col-lg-5 col-md-5 col-sm-12 p-0 m-0">
                              <ng-container *ngIf="hasRectifierData === true">
                                <app-gauge-chart
                                  [chartData]="{
                            series_name: 'Used Capacity',
                            series_data: device.device_values?.used_capacity?.value,
                            value_suffix: device.device_values?.used_capacity?.unit
                            }"

                                  [optionsType]="'used_capacity'"></app-gauge-chart>
                              </ng-container>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </nz-card>
              </div>

            </div>
          </div>

        </nz-card>
      </div>

    </nz-card>
  </div>
</div>
