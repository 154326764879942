import { Component, OnInit } from '@angular/core';
import {PowerMeterDashboardsService} from '../../../services/dashboards/powerMeters/power-meter-dashboards.service';
import {Chart} from 'angular-highcharts';
import {ISite} from '../../../Models/ISite';
import {IClientSitePowerTotals} from '../../../Models/Dashboards/IClientSitePowerTotals';
import {ChartConfigurations} from '../../../utils/ChartConfigurations';
import * as Highcharts from 'highcharts';
import * as solidgauge from "highcharts/modules/solid-gauge";
import {IDashboardPageHeaderConfig} from '../../../Models/IDashboardPageHeaderConfig';
import {Constants} from '../../../utils/Contstants';
import {DeviceDashboardSignature} from '../../../utils/ClassSignatures/DeviceDashboardSignature';
import {IUserSiteList} from '../../../Models/IUserSiteList';

@Component({
  selector: 'app-power-meter-dashboard',
  templateUrl: './power-meter-dashboard.component.html',
  styleUrls: ['./power-meter-dashboard.component.scss']
})
export class PowerMeterDashboardComponent implements OnInit, DeviceDashboardSignature {

  isLoading: boolean = false;
  hasNoData: boolean = false;

  // selectedSite: ISite;
  selectedSite: IUserSiteList;
  sitePowerMeterValues: IClientSitePowerTotals;

  selectedDeviceIndex: number;

  currentSiteName: string;
  currentTotalActivePower: number;
  currentTotalApparentPower: number;
  currentTotalPowerFactor: number;

  //Voltage Charts
  l1VoltageChart: Chart;
  l2VoltageChart: Chart;
  l3VoltageChart: Chart;

  //Current Charts
  l1CurrentChart: Chart;
  l2CurrentChart: Chart;
  l3CurrentChart: Chart;


  voltageChartOptions;
  currentChartOptions;

  Highcharts: typeof Highcharts = Highcharts;

  headerConfig: IDashboardPageHeaderConfig = {
    device_type: new Constants().deviceTypeIds.power_meter,
    device_group: 'POWER METER',
    statistics: []
  }

  constructor(private powerMeterDashboardsService: PowerMeterDashboardsService,) {

    this.voltageChartOptions = new ChartConfigurations().PowerMeterVoltageChartOptions;
    this.currentChartOptions = new ChartConfigurations().PowerMeterCurrentChartOptions;
  }

  ngOnInit(): void {

    this.isLoading = true;

  }

  displaySiteDetails(): void{
    this.currentSiteName = this.sitePowerMeterValues.site.site_name;
    this.animateActivePowerValue(0, this.sitePowerMeterValues.site.total_kw, 2000);
    this.animateApparentPowerValue(0, this.sitePowerMeterValues.site.total_kva, 2000);
    this.currentTotalPowerFactor = this.sitePowerMeterValues.site.avg_pf;
  }

  displayDeviceData(index: number): void{

    this.selectedDeviceIndex = index;
    this.renderVoltageCharts(index);
    this.renderCurrentCharts(index);


  }

  renderVoltageCharts(index: number): void{

    this.l1VoltageChart = new Chart(Highcharts.merge(this.voltageChartOptions, {
      yAxis: {
        title: {
          text: 'L1 Voltage'
        },
        //color: '#ff0000'
      },

      credits: {
        enabled: false
      },

      series: [{
        name: 'L1 Voltage',
        type: 'solidgauge',
        color: 'red',
        data: [Number(this.sitePowerMeterValues.devices[index].device_values.l1_voltage.value)],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y}</span><br/>' +
            '<span style="font-size:12px;opacity:0.4">V</span>' +
            '</div>'
        },
      }]
    }));

    this.l2VoltageChart = new Chart(Highcharts.merge(this.voltageChartOptions, {
      yAxis: {
        title: {
          text: 'L2 Voltage'
        }
      },

      credits: {
        enabled: false
      },

      series: [{
        name: 'L2 Voltage',
        type: 'solidgauge',
        color: 'red',
        data: [Number(this.sitePowerMeterValues.devices[index].device_values.l2_voltage.value)],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y}</span><br/>' +
            '<span style="font-size:12px;opacity:0.4">V</span>' +
            '</div>'
        },
      }]
    }));

    this.l3VoltageChart = new Chart(Highcharts.merge(this.voltageChartOptions, {
      yAxis: {
        title: {
          text: 'L3 Voltage'
        }
      },

      credits: {
        enabled: false
      },

      series: [{
        name: 'L3 Voltage',
        type: 'solidgauge',
        color: 'red',
        data: [Number(this.sitePowerMeterValues.devices[index].device_values.l3_voltage.value)],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y}</span><br/>' +
            '<span style="font-size:12px;opacity:0.4">V</span>' +
            '</div>'
        },
      }]
    }));

  }

  renderCurrentCharts(index: number): void{

    this.l1CurrentChart = new Chart(Highcharts.merge(this.currentChartOptions, {
      yAxis: {
        title: {
          text: 'L1 Current'
        },
        //color: '#ff0000'
      },

      credits: {
        enabled: false
      },

      series: [{
        name: 'L1 Current',
        type: 'solidgauge',
        data: [Number(this.sitePowerMeterValues.devices[index].device_values.l1_current.value)],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y}</span><br/>' +
            '<span style="font-size:12px;opacity:0.4">A</span>' +
            '</div>'
        },
      }]
    }));

    this.l2CurrentChart = new Chart(Highcharts.merge(this.currentChartOptions, {
      yAxis: {
        title: {
          text: 'L2 Current'
        },
        //color: '#ff0000'
      },

      credits: {
        enabled: false
      },

      series: [{
        name: 'L2 Current',
        type: 'solidgauge',
        data: [Number(this.sitePowerMeterValues.devices[index].device_values.l2_current.value)],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y}</span><br/>' +
            '<span style="font-size:12px;opacity:0.4">A</span>' +
            '</div>'
        },
      }]
    }));

    this.l3CurrentChart = new Chart(Highcharts.merge(this.currentChartOptions, {
      yAxis: {
        title: {
          text: 'L3 Current'
        },
        //color: '#ff0000'
      },

      credits: {
        enabled: false
      },

      series: [{
        name: 'L3 Current',
        type: 'solidgauge',
        data: [Number(this.sitePowerMeterValues.devices[index].device_values.l3_current.value)],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y}</span><br/>' +
            '<span style="font-size:12px;opacity:0.4">A</span>' +
            '</div>'
        },
      }]
    }));

  }

  animateActivePowerValue(start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      this.currentTotalActivePower = Math.floor(progress * (end + start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  animateApparentPowerValue(start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      this.currentTotalApparentPower = Math.floor(progress * (end + start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  fetchSiteDeviceReadings(site_id: string): void {

    this.isLoading = true;
    this.powerMeterDashboardsService.clientSitePowerTotals(site_id).subscribe( (response: IClientSitePowerTotals) =>{

      if(response !== null){

        this.sitePowerMeterValues = response;
        this.sitePowerMeterValues.devices = this.sitePowerMeterValues.devices.sort(function(a, b) {
          return a.device_name.localeCompare(b.device_name, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });

        this.isLoading = false;

        this.displaySiteDetails();
        this.displayDeviceData(0);
        this.isLoading = false;
        this.hasNoData = false;

      }else{
        this.hasNoData = true;
        this.isLoading = false;
      }

    }, error => {

      console.error('Power Meter Dashboards: ', error);
      this.isLoading = false;
      this.hasNoData = true;
    } );

  }

  handleSiteChangeEmitter(site: IUserSiteList): void {

    this.selectedSite = site;
    if(site.legacy_id !== undefined){
      this.fetchSiteDeviceReadings(site.legacy_id.toString());
    }
    else{
      this.fetchSiteDeviceReadings(site._id);
    }


  }



}
