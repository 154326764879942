import { Component, OnInit } from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';
import {UserManagementService} from '../../../services/userManagement/user-management.service';
import {TicketService} from '../../../services/tickets/ticket.service';
import {ICloudBmsUser} from '../../../Models/ICloudBmsUser';
import {ITicket} from '../../../Models/Tickets/ITicket';
import {TicketComment} from '../../../Models/Tickets/TicketComment';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzModalService} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-adhock-view',
  templateUrl: './adhock-view.component.html',
  styleUrls: ['./adhock-view.component.scss']
})
export class AdhockViewComponent implements OnInit {

  ticketId: number;
  userId: number;

  currentUserDetails: ICloudBmsUser;

  ticket: ITicket;
  ticketComments: TicketComment[];

  comment: string;

  isSubmitting: boolean = false;
  loadingTickets: boolean = false;

  constructor(private route: ActivatedRoute,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private router: Router,
              private userManagementService: UserManagementService,
              private ticketService: TicketService,) {

    this.route.params.subscribe( params => {
      this.ticketId = parseInt(params.id);
      this.userId = parseInt(params.user_id);

    } );

  }

  ngOnInit(): void {
    this.loadingTickets = true;

    this.ticketService.getTicketDetails(this.ticketId).subscribe( response => {

      this.ticket = response;
      this.loadingTickets = false;
    }, error => {
      console.error(error);
    } );

    this.ticketService.getComments(this.ticketId).subscribe( response=> {

      this.ticketComments = response;

    }, error => {
      console.error(error);
    } );

    this.userManagementService.getUser(this.userId).subscribe( response  => {

      this.currentUserDetails = response;

    }, error => {
      console.error(error);
    })

  }

  handleSubmit(): void {

    this.isSubmitting = true;
    const content = this.comment;
    this.comment = '';

    let comment : TicketComment ={
      ticket_id: this.ticketId,
      user_id: Number(this.currentUserDetails.id),
      created_by: this.currentUserDetails.full_name,
      comment: content,
    };

    this.ticketService.createComment(comment).subscribe( (response) => {

      this.notification.success(
        'Done!',
        response['message'],
        { nzPlacement: 'topRight' }
      );
      this.isSubmitting = false;

      this.ticketService.getComments(this.ticketId).subscribe( response=> {

        this.ticketComments = response;

      }, error => {
        console.error(error);
      } );

    }, error => {
      console.error(error);
    } );

  }

}
