<nz-spin [nzSpinning]="isLoading">
  <div class="create-task-container">

    <nz-page-header style="padding: 0" [nzGhost]="false">
      <nz-page-header-title>
        <span>Escalate Alarm</span>
      </nz-page-header-title>
      <nz-page-header-content>
        <nz-divider style="margin-top: 0"></nz-divider>
        <div class="task-alarm-attachment">
          <app-alarm-list-item
            [alarm]="selectedNotification"
            [canAction]="false"></app-alarm-list-item>
        </div>

      </nz-page-header-content>
    </nz-page-header>

    <nz-alert *ngIf="hasTask"
              class="mt-2 ml-2 mr-2"
              nzType="info"
              [nzMessage]="alertTitle"
              [nzDescription]="taskInfoTemplate"
    ></nz-alert>

    <ng-template #alertTitle>
      <span class="font-weight-bold">This alarm has already been escalated</span>
    </ng-template>

    <ng-template #taskInfoTemplate>
      <div class="d-flex w-100 justify-content-between mt-0 mb-1">
        <span>Assignee: </span>
        <span><i class="lni lni-user mr-1"></i> {{taskDetails?.assigned_to.user_name}}</span>
      </div>
      <div class="d-flex w-100 justify-content-between mt-0 mb-1">
        <span>Current Status: </span>
        <span><nz-tag>{{taskDetails?.status}}</nz-tag></span>
      </div>
      <div class="d-flex w-100 justify-content-between mt-0 mb-1">
        <span>Date of escalation: </span>
        <span> <i class="lni lni-calendar mr-1"></i> {{ taskDetails?.created_at | date: 'medium' }}</span>
      </div>

    </ng-template>

    <div class="d-flex w-100 justify-content-between mt-1 mb-0 ml-2 mr-2" *ngIf="hasTask">
      <small class="text-secondary">Although already escalated, you may still send a message on any new findings below</small>
    </div>

    <nz-divider></nz-divider>

    <form [formGroup]="taskForm" (ngSubmit)="onFormSubmitted()">

      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzFor="description" nzRequired>Notes <small class="ml-1">this note will appear as a message in the task</small></nz-form-label>
        <nz-form-control [nzSpan]="24" nzExtra="Provide additional information on this escalation">
              <textarea rows="3" nz-input
                        name="description"
                        formControlName="description" placeholder="e.g This alarm occurred multiple times today over very short spaces of time..."></textarea>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="!hasTask">
        <nz-form-label [nzSpan]="24" nzFor="priority" nzRequired>Select who should be notified ?</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <nz-select nzShowSearch
                     nzAllowClear
                     nzPlaceHolder="Search for a person..."
                     formControlName="assigned_to">
            <nz-option *ngFor="let user of users" [nzLabel]="user.name + ' ' + user.surname"
                       [nzValue]="user" nzCustomContent>
              <span><i class="lni lni-user mr-1"></i> {{user.name + ' ' + user.surname}}</span>
              <div class="d-flex w-100 justify-content-between mt-0 mb-0">
                <span class="text-secondary">{{user.email_address}}</span>
                <span class="text-secondary">{{user.role.name}}</span>
              </div>

            </nz-option>

          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <button nz-button nzDanger nzType="default" (click)="ticketCreatedEvent.emit(true)" class="mr-2">Cancel</button>
      <button nz-button nzType="primary" type="submit" [disabled]="!taskForm?.valid " *ngIf="!hasTask">Send and Close</button>
      <button nz-button nzType="primary" type="submit" [disabled]="taskForm.value.description === null || taskForm.value.description === '' " *ngIf="hasTask">Send and Close</button>

    </form>

    <nz-divider></nz-divider>
  </div>
</nz-spin>


