import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Constants} from '../../utils/Contstants';
import {StorageService} from '../../services/storage/storage.service';
import {ISite} from '../../Models/ISite';
import {AnalogDevicesService} from '../../services/analogDevices/analog-devices.service';
import {IAnalogDevice} from '../../Models/IAnalogDevice';
import {IAnalogDeviceInstance} from '../../Models/IAnalogDeviceInstance';
import { NzTabPosition } from 'ng-zorro-antd/tabs';
import {AlarmsService} from '../../services/alarms/alarms.service';
import {IActiveAlarm} from '../../Models/IActiveAlarm';
import {stringify} from 'querystring';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {

  private storageConstants = new Constants().storage;

  clientSiteList: ISite[];
  selectedSiteId: number;
  selectedSiteName : string;
  devices: IAnalogDevice[];
  deviceInstances: Array<IAnalogDeviceInstance[]>;

  selectedDevice: number;
  selectedDeviceName: string;
  selectedDeviceTypeId: number;

  selectedDeviceInstances: IAnalogDeviceInstance[];
  selectedInstance: number;
  selectedInstanceName: string;

  deviceInstanceReadings;

  showReadings: boolean = false;

  deviceAlarms: IActiveAlarm[];

  //BMS Specific
  deviceTypes;

  //Page Specific
  loadingDevices: boolean;
  isLoadingInstanceInfo: boolean = false;
  isLoadingAlarms: boolean = true;
  instanceReadingsTab : number;
  emptyResponse : boolean;


  selectedTab(event): void{
    this.instanceReadingsTab = event;
  }


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private analogDeviceService: AnalogDevicesService,
    private alarmsService: AlarmsService) {

    this.route.params.subscribe( params => {
      this.selectedSiteId = parseInt(params.id);

    } );

  }

  ngOnInit(): void {

    this.clientSiteList = JSON.parse(this.storageService.getStoredEncodedValue(this.storageConstants.clientSites));
    this.changeSite();

    this.getADevicesAndInstances();
    this.deviceTypes = new Constants().deviceTypeIds;

    this.instanceReadingsTab = 0;

  }

  changeSite(): void{
    for(let i in this.clientSiteList){

      if(Number(this.clientSiteList[i].legacy_id) === this.selectedSiteId){

        this.selectedSiteName = this.clientSiteList[i].name;
      }
    }
  }

  getADevicesAndInstances(): void{

    this.loadingDevices = true;
    this.isLoadingAlarms = true;

    this.analogDeviceService.siteDevicesAndInstances(this.selectedSiteId)
      .subscribe( response => {
        this.loadingDevices = false;

        let filteredDevices = [];
        for(let i in response){
          if(response[i].device_type !== 'Universal Controller' && response[i].device_type !== 'Utility' ){
            filteredDevices.push(response[i]);
          }
        }

        this.devices = filteredDevices;

        //Extract Instances
        this.deviceInstances = [];
        for (let i in this.devices){
          this.deviceInstances.push(this.devices[i].instances);
        }



      }, error => {
        console.error(error);
    } )

  }

  setDeviceInstances(index: number){

    this.showReadings = false;
    this.isLoadingAlarms = true;

    this.selectedDevice = index;
    this.selectedDeviceName = this.devices[index].device_type;
    this.selectedDeviceTypeId = Number(this.devices[index].device_type_id) ;


    this.selectedDeviceInstances = this.deviceInstances[index];

  }

  getInstanceInfo(index: number): void{

    //clear readings
    this.deviceInstanceReadings = null;

    this.isLoadingInstanceInfo = true;
    this.isLoadingAlarms = true;

    this.selectedInstance = index;
    this.selectedInstanceName = this.selectedDeviceInstances[index].instance_name;

    let instance = this.selectedDeviceInstances[index];


    this.analogDeviceService.deviceInstanceReadings(
      this.selectedSiteId, this.selectedDeviceTypeId, instance.controller_id, instance.instance_id
    ).subscribe( response => {

      this.deviceInstanceReadings = response;

      let checkEmpty = this.isEmpty(response);


      if(checkEmpty === true){
        this.emptyResponse =  true;
        this.showReadings = false;
        this.isLoadingInstanceInfo = false;
      }else{
        this.emptyResponse = false;
      }

      if(response !== undefined){

        if(!checkEmpty){

          if(Array.isArray(response)){

            if(response.length !== 0){
              this.showReadings = true;
              this.isLoadingInstanceInfo = false;
            }

          }else{
            this.showReadings = true;
            this.isLoadingInstanceInfo = false;
          }

        }

      }


    }, error => {
      console.error(error);
    } );

    this.alarmsService.instanceActiveAlarms(this.selectedSiteId, instance.controller_id, instance.instance_id,
      this.selectedDeviceTypeId).subscribe(response => {


        this.deviceAlarms = response;
        this.isLoadingAlarms = false;


    }, error => {
        console.error(error);
    })

  }

  isEmpty(obj) {
    for(let prop in obj) {
      if(obj.hasOwnProperty(prop))
        return false;
    }

    return true;
  }

  get deviceImages() : string{

    let path = 'assets/icons/Devices/';
    return `${path}${this.selectedDeviceTypeId}.png`;

  }

  failedReadingHandler(failedReading: boolean): void{

    if(failedReading){
      this.getInstanceInfo(this.selectedInstance);
    }

  }

  emptyResponseHandler(): void{


    this.getInstanceInfo(this.selectedInstance);

  }


}
