import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {IBattery, IBatteryData} from '../../../Models/DeviceInstances/IBattery';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';
import {interval, Subscription} from 'rxjs';

import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';

//ChART
import {Chart} from "angular-highcharts";
import {IChartData} from '../../../Models/Charts/IDynamicChartData';

@Component({
  selector: 'app-batteries-info',
  templateUrl: './batteries-info.component.html',
  styleUrls: ['./batteries-info.component.scss']
})
export class BatteriesInfoComponent implements OnInit, OnDestroy {

  hasReading: boolean;

  @Output() failedReading = new EventEmitter<boolean>();
  @Input() readings: IDeviceReadingsProps;

  requestInterval = interval(10000);
  intervalSubscriber: Subscription;
  currentDeviceReadings : IBattery;

  isLoadingData : boolean;

  batteryTemperaturesChart: Chart;
  batteryVoltagesChart: Chart;
  batterySOHChart: Chart;
  batterySOCChart: Chart;
  batteryInternalResistanceChart: Chart;
  batteryInternalResistanceDeviationChart: Chart;

  panels = [
    {
      active: true,
      name: 'Temperature',
      disabled: false
    },
    {
      active: false,
      disabled: false,
      name: 'Voltage'
    },
    {
      active: false,
      disabled: true,
      name: 'State of Charge'
    },
    {
      active: false,
      disabled: false,
      name: 'State of Health'
    },
    {
      active: false,
      disabled: false,
      name: 'Internal Resistance'
    },
    {
      active: false,
      disabled: false,
      name: 'Internal Resistance Deviation'
    }
  ];

  showBatteryBankModal: boolean = false;

  temperatureThreshold: number = 28.7;
  voltageThreshold: number =  13.8;
  voltageLowerThreshold: number = 10.5;

  //updates the voltageThreshold and voltageLowerThreshold
  updateVoltageThresholds(battery_type: number): void {
    // Assuming or12vor2v is a string that can be parsed to an integer
    let batteryVoltage = battery_type;
    console.log("battery_type log" + battery_type);
    //potentially expand this in the future
    if(batteryVoltage > 2){
      batteryVoltage = 12;
    }
    else{
      batteryVoltage = 2;
    }

    // Set default ratios based on 12v thresholds
    const ratioUpperThreshold = 1.15;
    const ratioLowerThreshold = 0.875;

    // Calculate thresholds based on actual battery voltage
    this.voltageThreshold = batteryVoltage * ratioUpperThreshold;
    this.voltageLowerThreshold = batteryVoltage * ratioLowerThreshold;
  }

  constructor(private analogDeviceService: AnalogDevicesService) { }

  ngOnInit(): void {

    this.isLoadingData = true;
    this.getDeviceReadings();
    this.hasReading = this.readings != null;

  }

  emitReadingError(): void{

    this.failedReading.emit(true);

  }

  getDeviceReadings(): void{

    this.isLoadingData = true;

    this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId, this.readings.selectedDeviceTypeId, this.readings.controllerId, this.readings.instanceId
    ).subscribe( (response: IBattery) => {

      if(response !== null){
        this.currentDeviceReadings = response;
        this.updateVoltageThresholds(response.battery_type);
        this.convertDataForChartAndRender(response.battery_data);
        this.hasReading = true;
        this.isLoadingData = false;

      }else{
        this.hasReading = false;
        this.isLoadingData = false;
      }

    }, error => {
      console.warn('Battery', error);
      this.hasReading = false;
      this.isLoadingData = false;
    } );


  }

  convertDataForChartAndRender(responseData: IBatteryData[]): void{

    let hasAllData: boolean = (responseData[0].SOH !== undefined);

    let temperatureSeries: IChartData = {
      name: "Temperatures",
      data: [],
      color: '#303F9F'
    };

    let voltageSeries: IChartData = {
      name: "Voltage",
      data: [],
      color: '#303F9F'
    };

    let SOHSeries: IChartData = {
      name: "Voltage",
      data: [],
      color: '#303F9F'
    };

    let SOCSeries: IChartData = {
      name: "Voltage",
      data: [],
      color: '#303F9F'
    };

    let IRSeries: IChartData = {
      name: "Voltage",
      data: [],
      color: '#303F9F'
    };

    let IRDSeries: IChartData = {
      name: "Voltage",
      data: [],
      color: '#303F9F'
    };

    responseData.forEach( (item: IBatteryData) =>{

      temperatureSeries.data.push(
        {
          name: "Battery " + (item.alarm_BMS_element + 1).toString(),
          y: parseFloat(item.temperature.split(' ')[0]),
          color: ( parseFloat(item.temperature.split(' ')[0]) >= this.temperatureThreshold ? '#a21219' : temperatureSeries.color),
          x: (item.alarm_BMS_element + 1)
        }
      );

      voltageSeries.data.push(
        {
          name: "Battery " + (item.alarm_BMS_element + 1).toString(),
          y: parseFloat(item.voltage.split(' ')[0]),
          color: (
            parseFloat(item.voltage.split(' ')[0]) >= this.voltageThreshold ||
            parseFloat(item.voltage.split(' ')[0]) <= this.voltageLowerThreshold
            ? '#a21219'
            : voltageSeries.color
          ),
          x: (item.alarm_BMS_element + 1)
        }
      );

      if(hasAllData){
        SOHSeries.data.push(
          {
            name: "Battery " + (item.alarm_BMS_element + 1).toString(),
            y: parseFloat(item.SOH.split(' ')[0]),
            x: (item.alarm_BMS_element + 1)
          }
        );

        SOCSeries.data.push(
          {
            name: "Battery " + (item.alarm_BMS_element + 1).toString(),
            y: parseFloat(item.SOC.split(' ')[0]),
            x: (item.alarm_BMS_element + 1)
          }
        );

        IRSeries.data.push(
          {
            name: "Battery " + (item.alarm_BMS_element + 1).toString(),
            y: parseFloat(item.internal_resistance.split(' ')[0]),
            x: (item.alarm_BMS_element + 1)
          }
        );

        IRDSeries.data.push(
          {
            name: "Battery " + (item.alarm_BMS_element + 1).toString(),
            y: parseFloat(item.internal_resistance_deviation.split(' ')[0]),
            x: (item.alarm_BMS_element + 1)
          }
        );
      }



    });


    // this.renderTemperatureChart(temperatureSeries);
    // this.renderVoltageChart(voltageSeries);

    this.renderChart(temperatureSeries, 'Batteries', 'Temperature (˚C)', '˚C', 'Temperature');
    this.renderChart(voltageSeries, 'Batteries', 'Voltage (V)', 'V', 'Voltage');

    if(hasAllData){
      this.renderChart(SOHSeries, 'Batteries', 'SOH (%)', '%', 'SOH');
      this.renderChart(SOCSeries, 'Batteries', 'SOC (%)', '%', 'SOC');
      this.renderChart(IRSeries, 'Batteries', 'Internal Resistance (mOhm)', 'mOhm', 'IR');
      this.renderChart(IRDSeries, 'Batteries', 'Internal Resistance Deviation (%)', '%', 'IRD');
    }else{
      this.panels.splice(2);
    }

  }

  renderChart(data: IChartData, category: string, yAxisName: string, uom: string, type: string): void{

    let chartOptions = {
      chart: {
        type: 'column',
        height: 180,
        styledMode: false,
        zoom: {
          enabled: true
        },
        zoomType: 'y'
      },

      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      title: {
        text: undefined
      },
      xAxis: {
        // categories: [
        //   category,
        // ],
        type: category,
        labels: {
          step: 0
        },
        crosshair: true,
        tickmarkPlacement: 'on',


      },
      yAxis: {
        min: 0,
        endOnTick: false,
        // max: 30,
        title: {
          text: yAxisName
        },

      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} '+ uom +'</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          // pointWidth: 25
        },
        series: {
          pointPlacement: 'on'
        }

      },
      legend: {
        enabled: false
      },
      //@ts-ignore
      series: [data]
    };

    switch (type){
      case 'Temperature':{

        chartOptions.yAxis['max'] = 40;
        chartOptions['yAxis']['plotLines'] = [{
          value: 28.7,
          color: 'red',
          width: 1,
          label: {
            text: 'Temperature Threshold: 28.7 ˚C',
            align: 'center',
            style: {
              color: 'gray'
            }
          }
        }];

        // @ts-ignore
        this.batteryTemperaturesChart = new Chart(chartOptions);
        break;
      }
      case 'Voltage':{

        chartOptions.yAxis['max'] = Math.ceil(this.voltageThreshold * 10 * 1.45)/10;

        chartOptions['yAxis']['plotLines'] = [{
          value: this.voltageThreshold,
          color: 'red',
          width: 1,
          label: {
            text: 'Voltage Upper Threshold: '+Math.ceil(this.voltageThreshold * 10)/10+' V',
            align: 'center',
            style: {
              color: 'gray'
            }
          }
        },
          {
            value: this.voltageLowerThreshold,
            color: 'red',
            width: 1,
            label: {
              text: undefined,
              align: 'center',
              style: {
                color: 'gray'
              }
            }
          }];
        // @ts-ignore
        this.batteryVoltagesChart = new Chart(chartOptions);
        break;
      }
      case 'SOH':{
        // @ts-ignore
        this.batterySOHChart = new Chart(chartOptions);
        break;
      }
      case 'SOC':{
        // @ts-ignore
        this.batterySOCChart = new Chart(chartOptions);
        break;
      }
      case 'IR':{
        // @ts-ignore
        this.batteryInternalResistanceChart = new Chart(chartOptions);
        break;
      }
      case 'IRD':{
        // @ts-ignore
        this.batteryInternalResistanceDeviationChart = new Chart(chartOptions);
        break;
      }
    }
  }

  openModal(): void{
    this.showBatteryBankModal = true;
    console.log(this.currentDeviceReadings);
  }

  closeModal(): void{
    this.showBatteryBankModal = false;
  }

  checkVoltage(voltage: string){

    return parseFloat(voltage) > 0;

  }


  ngOnDestroy(): void {

    //this.intervalSubscriber.unsubscribe();
  }
}
