import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/messaging';
import {AngularFireDatabase} from '@angular/fire/database';
import {StorageService} from '../storage/storage.service';
import {Constants} from '../../utils/Contstants';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  currentMessage =  new BehaviorSubject(null);
  notifications = this.currentMessage.asObservable();
  constants = new Constants().storage;

  private bmsRestfulService : string = "https://cloudbms.co.za/restful/api.php/records/user_fcm";

  constructor(private angularFireMessaging: AngularFireMessaging,
              private angularFireDatabase: AngularFireDatabase,
              private storageService: StorageService, private http: HttpClient) {

    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        //Check if token exists
        let currentToken = this.storageService.getLocalStorage(this.constants.firebaseMessagingToken);


        if(currentToken != null){
          //Check if the token is not the same
          if(currentToken != token){
            this.saveTokenToRestOnBMS(token);
            this.saveTokenToFirebase(token);
            this.storageService.setLocalStorage(this.constants.firebaseMessagingToken, token);
          }

        }else{
          // No token exists send the token and have it saved to firebase - once done save to localstorage
          this.saveTokenToFirebase(token);
          this.saveTokenToRestOnBMS(token);
          this.storageService.setLocalStorage(this.constants.firebaseMessagingToken, token);
        }

      },
      (err) => {
        //console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.currentMessage.next(payload);
      });
  }

  private saveTokenToFirebase(token): void{
    const clientId = this.storageService.getStoredEncodedValue(this.constants.selectedClient);
     this.angularFireDatabase.database.ref('/fcmTokens').push({ id: token , client_id: clientId});
  }

  private saveTokenToRestOnBMS(token): void {

    const clientId = this.storageService.getStoredEncodedValue(this.constants.selectedClient);

    let payload = {
      user_id: 0,
      fcm_token: token,
      client_id: clientId,
      site_id: 0
    };

    this.http.post(this.bmsRestfulService, payload);

  }

}
