<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-lg-6 col-sm-12">
      <h6 class="mb-3" *ngIf="hasReading">
        <span class="text-lead" style="font-size: small;">Status</span><br>
        {{currentDeviceReadings?.lbl_tco_state_text | uppercase}}
      </h6>
    </div>
    <div class="col-md-6 col-lg-6 col-sm-12 text-sm-center">
      <button nz-button nzType="primary" class="float-md-right float-lg-right float-sm-none" (click)="getDeviceReadings()" >
        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
        Get Current Readings
      </button>
    </div>
  </div>

  <div style="background: #ffffff; padding: 30px;overflow-x: scroll">

    <nz-divider></nz-divider>

    <div class="container" *ngIf="!hasReading">
      <nz-result nzStatus="404" nzTitle="404"
                 nzSubTitle="Sorry, it seems as though there is no data available for this device.">

      </nz-result>
    </div>
    <nz-card class="full-width" [nzLoading]="isLoadingData" nzBordered="false">
      <canvas #canvas width="700" height="350" [hidden]="!hasReading"></canvas>
    </nz-card>

  </div>

</div>

