import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange} from '@angular/core';
import {IAcPowerMeter} from '../../../Models/DeviceInstances/IAcPowerMeter';
import {interval, Subscription} from 'rxjs';
import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';

@Component({
  selector: 'app-ac-power-meter-info',
  templateUrl: './ac-power-meter-info.component.html',
  styleUrls: ['./ac-power-meter-info.component.scss']
})
export class AcPowerMeterInfoComponent implements OnInit, OnDestroy {

  hasReading: boolean;

  @Output() failedReading = new EventEmitter<boolean>();

  @Input() readings : IDeviceReadingsProps;
  requestInterval = interval(10000);
  intervalSubscriber: Subscription;
  currentDeviceReadings : IAcPowerMeter;

  isLoadingData : boolean;

  constructor(private analogDeviceService: AnalogDevicesService) { }


  ngOnInit(): void {

    this.isLoadingData = true;
    this.getDeviceReadings();
    this.hasReading = this.readings != null;
    this.getDeviceReadings();
  }

  emitReadingError(): void{

    this.failedReading.emit(true);

  }

  getDeviceReadings(): void{

    this.isLoadingData = true;
    this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId, this.readings.selectedDeviceTypeId, this.readings.controllerId, this.readings.instanceId
    ).subscribe( response => {

      this.currentDeviceReadings = response;
      if(response.timestamp === null){
        this.currentDeviceReadings.timestamp = new Date();
      }
      this.isLoadingData = false;

    }, error => {
      console.warn('AC Power Meter', error);
    } )


  }

  ngOnDestroy(): void {

    //this.intervalSubscriber.unsubscribe();
  }


}
