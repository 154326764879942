import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClientsService} from "../../../services/clients/clients.service";
import {IClient} from "../../../Models/IClient";
import {Chart} from "angular-highcharts";
import {FuelConsumptionService} from "../../../services/reports/fuel-consumption.service";
import {IClientSiteTankLevel} from "../../../Models/Reports/IClientSiteTankLevel";
import {DomSanitizer} from '@angular/platform-browser';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {RedashService} from '../../../services/redash/redash.service';
import {IRedashQueryGeneration} from '../../../Models/Redash/IRedashQueryGeneration';
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import setHours from "date-fns/differenceInCalendarDays";
import {GenericHelpers} from '../../../utils/GenericHelpers';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {DisabledTimeFn} from 'ng-zorro-antd/date-picker';


@Component({
  selector: 'app-fuel-dashboards',
  templateUrl: './fuel-dashboards.component.html',
  styleUrls: ['./fuel-dashboards.component.scss']
})
export class FuelDashboardsComponent implements OnInit {

  client: IClient;
  isLoading: boolean;
  isLoadingSiteData: boolean;
  hasErrorLoading: boolean = false;
  //CHARTS
  clientSiteFuelLevelsChart: Chart;
  siteFuelFuelTankLevelsChart: Chart;

  selectedSiteId: number;
  selectedSiteDetails: IClientSiteTankLevel;
  showSiteFuelModal: boolean;

  currentRedashSrc: any;

  excelFile;
  loadingTrendFile: boolean = false;
  hasRetrievedExcelFile: boolean = false;

  dateFrom: string;
  dateTo: string;
  disabledEndDate = (current: Date): boolean => {

    return differenceInCalendarDays(current, new Date()) > 0;
  };


  today = new Date();
  timeDefaultValue = setHours(new Date(), 0);

  fuelSpecificDate: Date = new Date();
  fuelSpecificTime: Date = new Date();
  canSetTime: boolean = false;

  canDisplaySpecificReading: boolean = false;
  specificFuelReading: number;

  formattedDateForDisplay: string = new GenericHelpers().formatDate(this.fuelSpecificDate);

  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  disabledDateTime: DisabledTimeFn = () => ({

    nzDisabledHours: () => {
      // console.log(this.fuelSpecificDate)
      // if(this.fuelSpecificDate.getDay() === this.today.getDay()){
      //
      //   console.log('Current Hour:::', new Date().getHours())
      //   let startCount = new Date().getHours() + 1;
      //   let deleteCount = 24 - startCount;
      //   return this.range(0, 24).splice(startCount, deleteCount);
      // }else{
      //
      // }

      return []
    },
    nzDisabledMinutes: () => {

      // if(this.fuelSpecificDate.getMinutes() === this.today.getMinutes()){
      //   let startCount = new Date().getMinutes() + 1;
      //   let deleteCount = 60 - startCount;
      //   return this.range(0, 60).splice(startCount, deleteCount);
      // }
      // else{
      //
      // }
      return [];
    },
    nzDisabledSeconds: () => []
  });

  fuelTimeDateChange(date){
    console.log(date.toISOString());
    this.fuelSpecificDate = date;
    this.formattedDateForDisplay = new GenericHelpers().formatDate(date);
    console.log(this.formattedDateForDisplay);
    this.canSetTime = true;
  }


  selectedTrendTimeRange: {
    name: string,
    from: string,
    to: string
  } = {
    name: "This month so far",
    from: "now/M",
    to: "now"
  };

  trendTimeRanges: Array<{
    name: string,
    from: string,
    to: string
  }> = [
    {
      name: "Last 12 Hours",
      from: "now-12h",
      to: "now"
    },
    {
      name: "Last 7 days",
      from: "now-7d/d",
      to: "now"
    },
    {
      name: "This month so far",
      from: "now/M",
      to: "now"
    },
    {
      name: "Last month",
      from: "now-1M/M",
      to: "now-1M/M"
    },
    {
      name: "Last 1 year",
      from: "now-1y",
      to: "now"
    },
  ];

  constructor(private clientService: ClientsService,
              private fuelConsumptionService: FuelConsumptionService,
              private sanitizer: DomSanitizer,
              private authenticationService: AuthenticationService,
              private redashService: RedashService,
              private nzMessageService: NzMessageService,
              private nzNotificationService: NzNotificationService) { }

  ngOnInit(): void {

    this.isLoading = true;

    this.authenticationService.getSignedInUserDetails().subscribe(response => {
      this.clientService.getClient(response.client_id).subscribe(response =>{
        this.client = response;
        this.initializeComponent();
      })
    }, error => {
      console.error('Failed to retrieve Signed in user details: ', error);
    });

  }


  getSpecificFuelTimeConsumptionValue(): void {
    this.canDisplaySpecificReading = false;
    let date = this.fuelSpecificDate.toISOString();
    this.fuelConsumptionService.getSpecificTimeConsumptionForSite( this.selectedSiteId,date )
      .subscribe(response => {

        this.specificFuelReading = response.value;
        console.log(response);
        this.canDisplaySpecificReading = true;

    }, error => {
      console.error(error)
    });

  }

  copyFunction(){
    navigator.clipboard.writeText(this.specificFuelReading.toString());
    this.nzNotificationService.success('Value Coppied', '', {
      nzPlacement: 'bottomLeft'
    });
  }

  disposeSpecificFuelData(): void{
    this.specificFuelReading = undefined;
    this.canDisplaySpecificReading = false;
  }

  initializeComponent(): void{

    this.fuelConsumptionService.getUserSiteFuelTotals().subscribe( response => {
      this.renderFuelLevelsChart(response);
      this.isLoading = false;
    }, error => {
      console.error(error);
      this.hasErrorLoading = true;
      this.isLoading = false;
    });

    // this.fuelConsumptionService.getClientFuelLevels().subscribe( response => {
    //
    //   this.renderFuelLevelsChart(response);
    //   this.isLoading = false;
    //
    // }, error => {
    //   console.error(error);
    //   this.hasErrorLoading = true;
    //   this.isLoading = false;
    // } );
  }

  getSiteFuelTankLevels(site_id: number): void{
    this.isLoadingSiteData = true;
    this.fuelConsumptionService.getClientSiteTankLevels(site_id).subscribe( (data: IClientSiteTankLevel) => {


      this.selectedSiteDetails = data;
      this.showSiteFuelModal = true;
      this.setIframeURL();
      this.renderSiteTanksData();
      this.isLoadingSiteData = false;

    }, error => console.error('Error retrieving site tank level data', error) );

  }

  renderFuelLevelsChart(data: Array<{site_id: number, site_name: string, site_total_fuel: number}>): void{

    let categories = [];
    let siteIds: number[] = [];
    let levels: number[] = [];
    let totalFuel: number = 0;

    for(let i in data){
      siteIds.push(data[i].site_id);
      categories.push(data[i].site_name);
      levels.push(data[i].site_total_fuel);
      totalFuel += data[i].site_total_fuel;
    }

    this.clientSiteFuelLevelsChart = new Chart({
      chart: {
        type: 'column',
        spacingBottom: 0,
        spacingTop: 15,
        spacingLeft: 0,
        spacingRight: 0,
        // width: '800',
        height: 500
      },
      title: {
        text: 'Current Fuel Levels',
        style: {
          fontWeight: 'bolder',
          textAlign: 'left'
        }
      },
      subtitle: {
        text: `${totalFuel} L Available`
      },
      xAxis: {
        categories: categories,
        labels:{
          rotation: 0
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Litres (l)',

        }
      },

      tooltip: {
        pointFormat: '<b>{point.y:,.0f} L'
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions:{
        column: {
          dataLabels:{
            enabled: true,
          },
          colorByPoint: true,
          colors: ['#1976D2', '#303F9F', '#D32F2F'],
        },
        series: {
          cursor: 'pointer',
          events: {
            click: (event) => {

              this.selectedSiteId = siteIds[event.point.index];
              this.getSiteFuelTankLevels(this.selectedSiteId);

            }
          }
        }
      },
      series: [{
        name: 'Fuel Levels',
        // @ts-ignore
        // data: levels,
        data: levels,
        color: '#1976D2'
      }],

    });

  }

  renderSiteTanksData(): void{
    let categories: string[] =[];
    let litres: number[] =[];

    for(let i in this.selectedSiteDetails.tanks){
      categories.push(this.selectedSiteDetails.tanks[i].tank_name);
      litres.push(Number(this.selectedSiteDetails.tanks[i].litres));
    }

    this.siteFuelFuelTankLevelsChart = new Chart({
      chart: {
        type: 'column',
        spacingBottom: 0,
        spacingTop: 15,
        spacingLeft: 0,
        spacingRight: 0,
        width: 800,
        height: 500
      },
      title: {
        text: this.selectedSiteDetails.site.site_name + ' Tank Levels',
        style: {
          fontWeight: 'bolder',
          textAlign: 'left'
        }
      },
      xAxis: {
        categories: categories,
        labels:{
          rotation: 0
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Litres (l)',

        }
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.0f} L'
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions:{
        column: {
          dataLabels:{
            enabled: true,
          },
          colorByPoint: true,
          colors: ['#1976D2', '#303F9F', '#D32F2F'],
        },
      },
      series: [{
        name: 'Fuel Levels',
        // @ts-ignore
        // data: levels,
        data: litres,
        color: '#1976D2'
      }],
    });
  }

  changeDeviceTrendRange(): void{

    // let url = `https://grafana.cloudbms.co.za/d-solo/TL1IZ75nz/total-fuel-trends?orgId=1&var-client_id=${this.client.client_id}&var-site_id=${this.selectedSiteDetails.site.site_id}&from=${this.selectedTrendTimeRange.from}&to=${this.selectedTrendTimeRange.to}&theme=light&panelId=3`;
    let url = `https://grafana.cloudbms.co.za/d-solo/TL1IZ75nz/total-fuel-trends?orgId=1&var-site_id=${this.selectedSiteDetails.site.site_id}&from=${this.selectedTrendTimeRange.from}&to=${this.selectedTrendTimeRange.to}&theme=light&panelId=3`;
    // let url = this.rawIframeUrl + `from=${this.selectedTrendTimeRange.from}&to=${this.selectedTrendTimeRange.to}`;

    this.currentRedashSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);


  }
  setIframeURL(): void{
    // let url = `https://grafana.cloudbms.co.za/d-solo/TL1IZ75nz/total-fuel-trends?orgId=1&var-client_id=${this.client.client_id}&var-site_id=${this.selectedSiteDetails.site.site_id}&from=${this.selectedTrendTimeRange.from}&to=${this.selectedTrendTimeRange.to}&theme=light&panelId=3`;
    let url = `https://grafana.cloudbms.co.za/d-solo/TL1IZ75nz/total-fuel-trends?orgId=1&var-site_id=${this.selectedSiteDetails.site.site_id}&from=${this.selectedTrendTimeRange.from}&to=${this.selectedTrendTimeRange.to}&theme=light&panelId=3`;

    this.currentRedashSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);

  }

  onFromChange(result: Date): void {

    if(result !== null){

      let convertedDate = new Date(result.getFullYear(), result.getMonth(), 1);
      this.dateFrom = new GenericHelpers().formatDate(convertedDate);

    }else{

    }


  }

  onToChange(result: Date): void {

    if(result !== null){
      this.dateTo = new GenericHelpers().formatDate(result);

    }else{

    }

  }

  generateReport(): void{
    const dashId: any = 38;


    if(this.dateTo !== undefined && this.dateFrom !== undefined){
      let isSuccessResponse: number = 0;

      this.loadingTrendFile = true;

      this.redashService.generateSimpleQuery(dashId,
        this.selectedSiteDetails.site.site_id,
        this.dateFrom,
        this.dateTo).subscribe( response => {
        let queryId: string = response.job.id;
        let process = setInterval(() => {
          this.redashService.initiateQueryJob(queryId).subscribe( (response: IRedashQueryGeneration) => {


            isSuccessResponse = response.job.status;


            if(response.job.status === 3){

              this.excelFile = `https://redash.cloudbms.co.za/api/query_results/${response.job.query_result_id}.xlsx?api_key=4hjOcnZQELVq1jS1VywrNpLJfqiEpko4rVuhlRO4`
              this.loadingTrendFile = false;
              clearInterval(process);
              this.hasRetrievedExcelFile = true;
              this.nzMessageService.success('Report Generated successfully');
            }

          }, error => {
            console.error(error);
            clearInterval(process);
            this.nzNotificationService.error(
              'Operation Failed',
              'An error occurred in generating your report, please try again',
              {
                nzPlacement: 'topLeft'
              }
            );
            this.loadingTrendFile = false;
          });
        }, 2500);

      },error => {
        console.error(error);
      });
    }else{
      this.nzMessageService.error('You must provide the start and end dates to generate a report');

    }

  }

  reloadReportView(): void{

    this.hasRetrievedExcelFile = false;
    this.dateFrom = undefined;
    this.dateTo = undefined;
  }


}


// systemoutputapparentpower
// systemoutputpower
