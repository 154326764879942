<div style="background: #ffffff; padding: 30px;">

  <div nz-row>

    <div nz-col nzSpan="12">
      <h6 class="mb-3">
        <span class="text-lead" style="font-size: small;">Last Reading</span><br>
        {{currentDeviceReadings?.timestamp |  date:'medium' }}
      </h6>
    </div>

    <div nz-col nzSpan="12">
      <button nz-button nzType="primary" class="float-right" (click)="getDeviceReadings()" >
        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
        Get Current Readings
      </button>
    </div>

  </div>

  <nz-divider></nz-divider>

  <nz-card class="full-width" [nzLoading]="isLoadingData" nzBordered="false">

    <div class="container" *ngIf="!hasReading">
      <nz-result nzStatus="404" nzTitle="404"
                 nzSubTitle="Sorry, it seems as though there is no data available for this device.">

      </nz-result>
    </div>
    <div class="row"  *ngIf="hasReading">
      <div class="col-lg-4 col-md-12">
        <nz-row [nzGutter]="8">
          <nz-col [nzSpan]="16">
            <nz-statistic
              [nzValue]="(currentDeviceReadings?.outside_air_temperature)!"
              [nzTitle]="'Outside Air Temperature'" [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
          </nz-col>
        </nz-row>
      </div>
      <div class="col-lg-8 col-md-12">
        <nz-row [nzGutter]="16">

          <nz-col [nzSpan]="12">
            <nz-statistic
              [nzValue]="(currentDeviceReadings?.fan_circuit_1_demand)!"
              [nzTitle]="'Fan Circuit 1 Demand'"
              [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
          </nz-col>

          <nz-col [nzSpan]="12">
            <nz-statistic
              [nzValue]="currentDeviceReadings?.fan_circuit_2_demand"
              [nzTitle]="'Fan Circuit 2 Demand'"
              [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
          </nz-col>
        </nz-row>
        <br>
        <nz-row [nzGutter]="16">

          <nz-col [nzSpan]="12">
            <nz-statistic
              [nzValue]="(currentDeviceReadings?.outlet_temperature)!"
              [nzTitle]="'Outlet Temperature'" [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
          </nz-col>

          <nz-col [nzSpan]="12">
            <nz-statistic
              [nzValue]="(currentDeviceReadings?.inlet_temperature)!"
              [nzTitle]="'Inlet Temperature'" [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
          </nz-col>
        </nz-row>
      </div>
    </div>

  </nz-card>
</div>

<ng-template #Circuit1>
  <nz-progress nzPercent="{{ currentDeviceReadings?.fan_circuit_1_demand}}" nzType="circle" [nzWidth]="100"></nz-progress>
</ng-template>

<ng-template #Circuit2>

  <nz-progress nzPercent="{{ currentDeviceReadings?.fan_circuit_2_demand }}" nzType="circle" [nzWidth]="100"></nz-progress>
</ng-template>
