<div class="row">

  <div class="col-lg-6 col-md-6 col-sm-12">
    <nz-card nzBordered="false">
      <nz-page-header>
        <nz-page-header-title>Power</nz-page-header-title>
      </nz-page-header>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <nz-statistic [nzValue]="(1949101 | number)!" [nzTitle]="'Total Active Power'"></nz-statistic>
          </div>
          <div class="col">
            <nz-statistic [nzValue]="(1949101 | number)!" [nzTitle]="'Total Apparent Power'"></nz-statistic>
          </div>
        </div>

        <div class="row">
          <figure class="highcharts-figure">
            <div [chart]="chart" style="width: 100vh;"></div>
          </figure>
        </div>

      </div>

    </nz-card>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12">

  </div>


</div>
