import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ISiteAlarmNotificationCount, ISiteAlarmNotificationCountTotals} from '../../../../Models/notifications/ISiteAlarmNotificationCount';
import {NotificationsService} from '../../../../services/notifications/notifications.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-site-notifications-list',
  templateUrl: './site-notifications-list.component.html',
  styleUrls: ['./site-notifications-list.component.scss']
})
export class SiteNotificationsListComponent implements OnInit {

  searchText: string;

  @Output() siteIsSelected: EventEmitter<ISiteAlarmNotificationCount> = new EventEmitter<ISiteAlarmNotificationCount>();

  siteNotificationCounts: ISiteAlarmNotificationCountTotals;

  constructor(private notificationsService: NotificationsService,) { }

  ngOnInit(): void {

    this.notificationsService.getClientSiteUnattendedAlarmNotificationCounts()
      .subscribe(response =>{
        this.siteNotificationCounts = response;
        this.siteNotificationCounts.sites.sort((a,b) => {
          return a.site_name.toLowerCase().localeCompare(b.site_name.toLowerCase())
        });
      }, error => {
        console.error(error);
      });
  }

  selectSite(data: ISiteAlarmNotificationCount): void{
    this.siteIsSelected.emit(data);
  }

}
