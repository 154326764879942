<div class="row">
  <div class="col-lg-4 col-md-12 border-right main-container" >
    <nz-page-header  nzBackIcon>
      <nz-page-header-title>{{selectedSiteName}}</nz-page-header-title>
      <nz-page-header-extra>
        <!--        <button nz-button (click)="window.print()">Print Table</button>-->
                <button nz-button
                        nzType="default" routerLink="/admin/active-alarms/{{selectedSiteId}}">
                  <i nz-icon nzType="alert" nzTheme="twotone" nzTwotoneColor="#d32f2f"></i>
                  Alarms
                </button>
      </nz-page-header-extra>
      <nz-page-header-tags>
        <nz-tag nzColor="blue" class="float-right">{{devices?.length}} Devices</nz-tag>
      </nz-page-header-tags>
      <nz-page-header-content>

      </nz-page-header-content>
    </nz-page-header>
    <nz-divider></nz-divider>
    <div style="padding: 0 3px; margin-top: 0px;">

      <nz-table #nzTable [nzData]="devices" nzTableLayout="fixed"
                [nzPageSize]="50" [nzScroll]="{ y: '50vh' }"
                [nzShowPagination]="false"
                [nzLoading]="loadingDevices"
               >
        <thead>
        <tr>
          <th>Device Type</th>
          <th># Instances</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <ng-container>
          <tr  *ngFor="let data of nzTable.data; let i = index"
               [ngClass]="{'selected-device-type':selectedDevice === i}">
            <td>{{ data?.device_type }}</td>
            <td>{{ data?.instances.length }}</td>
            <td><button nz-button nzType="default" (click)="setDeviceInstances(i)">View Instances</button></td>
          </tr>

        </ng-container>
        </tbody>
      </nz-table>

      <ng-template #devicesTableFooter>
       <p style="font-size: small"># Device Types : {{devices?.length}}</p>
      </ng-template>
    </div>

    <ng-template #prefixTplTwo><i nz-icon nzType="arrow-down"></i></ng-template>
  </div>

  <div class="col-lg-2 col-md-12 border-right main-container m-0">

    <div class="full-width" *ngIf="selectedDeviceInstances !== undefined">
      <nz-page-header class="pr-0 pl-0">
        <nz-avatar nz-page-header-avatar
                   [nzSrc]="deviceImages" class="avatar"> </nz-avatar>

        <nz-page-header-title>
          {{selectedDeviceName}}
          <nz-badge
            [nzCount]="selectedDeviceInstances?.length"
            class="site-badge-count-4"
            [nzStyle]="{ backgroundColor: '#108ee9',  boxShadow: '0 0 0 1px #d9d9d9 inset' }"
          ></nz-badge>

        </nz-page-header-title>

        <nz-page-header-content>
          <div class="content-link">
            <a>
              <i nz-icon nzType="info-circle" nzTheme="outline"></i>
              Click an instance
            </a>
          </div>
        </nz-page-header-content>
      </nz-page-header>
      <nz-divider></nz-divider>
    </div>

    <div class="full-width m-0">
      <nz-table #basicTable [nzData]="selectedDeviceInstances"
                nzTableLayout="fixed" [nzPageSize]="50" [nzScroll]="{ y: '45vh' }"
                [nzShowPagination]="false" [nzNoResult]="empty">

        <tbody>
        <tr *ngFor="let data of basicTable.data; let i = index" class="instance_tr"
            (click)="getInstanceInfo(i)" [ngClass]="{'selected-device-type':selectedInstance === i}">
          <td>{{data.instance_name}}</td>
        </tr>
        </tbody>
      </nz-table>
    </div>

    <ng-template #empty>
      <nz-empty
        nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        [nzNotFoundContent]="contentTpl"
      >
        <ng-template #contentTpl>
          <span>Select a Device Type</span>
        </ng-template>
      </nz-empty>
    </ng-template>

  </div>


  <div class="col-lg-6 col-md-12"
       style="background-color: #ffffff; height: 65vh" >

    <div class="container mt-4" *ngIf="emptyResponse">
      <nz-result nzStatus="warning" nzTitle="A problem occurred in retrieving the device readings"
                 nzSubTitle="If this problem persists please contact support">
        <div nz-result-extra>
          <button nz-button nzType="primary"
                  (click)="emptyResponseHandler()"
                  *ngIf="!isLoadingInstanceInfo">
            Try Again
          </button>

          <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
          <nz-spin nzSimple [nzIndicator]="indicatorTemplate" *ngIf="isLoadingInstanceInfo"> </nz-spin>
        </div>
      </nz-result>
    </div>

    <nz-spin [nzSpinning]="isLoadingInstanceInfo" *ngIf="showReadings">



    <nz-page-header class="">
      <nz-avatar nz-page-header-avatar
                 [nzSrc]="deviceImages" class="avatar"> </nz-avatar>
      <nz-page-header-title>{{selectedInstanceName}}</nz-page-header-title>
      <nz-page-header-subtitle>Details</nz-page-header-subtitle>
      <nz-page-header-content>

      </nz-page-header-content>
    </nz-page-header>
      <nz-card class="full-width" [nzBordered]="false" [nzLoading]="isLoadingInstanceInfo">
        <nz-card-tab>
          <nz-tabset style="width: 100%;" (nzSelectedIndexChange)="selectedTab($event)">
            <nz-tab nzTitle="Device Readings">

            </nz-tab>
            <nz-tab [nzTitle]="alarmsTemplate">
              <ng-template #alarmsTemplate>
                Active Alarms and Events <nz-badge [nzCount]="deviceAlarms?.length"></nz-badge>
              </ng-template>

            </nz-tab>
          </nz-tabset>
        </nz-card-tab>



        <div *ngIf="instanceReadingsTab === 0 && !emptyResponse">
          <app-hvac-info
            *ngIf="selectedDeviceTypeId == deviceTypes.hvac"
            [readings]="deviceInstanceReadings" (failedReading)="failedReadingHandler($event)"></app-hvac-info>

          <app-generator-info *ngIf="selectedDeviceTypeId == deviceTypes.generator"
                              [readings]="deviceInstanceReadings"
                              (failedReading)="failedReadingHandler($event)"></app-generator-info>

          <app-fuel-info *ngIf="selectedDeviceTypeId == deviceTypes.fuel"
                         [readings]="deviceInstanceReadings"
                         (failedReading)="failedReadingHandler($event)"></app-fuel-info>

          <app-chiller-info *ngIf="selectedDeviceTypeId == deviceTypes.chiller"
                            [readings]="deviceInstanceReadings"
                            (failedReading)="failedReadingHandler($event)"></app-chiller-info>

          <app-batteries-info *ngIf="selectedDeviceTypeId == deviceTypes.batteries"
                              [readings]="deviceInstanceReadings"
                              (failedReading)="failedReadingHandler($event)"></app-batteries-info>

          <app-ups-info *ngIf="selectedDeviceTypeId == deviceTypes.ups"
                        [readings]="deviceInstanceReadings"
                        (failedReading)="failedReadingHandler($event)"></app-ups-info>

          <app-rectifier-info *ngIf="selectedDeviceTypeId == deviceTypes.rectifier"
                              [readings]="deviceInstanceReadings"
                              (failedReading)="failedReadingHandler($event)"></app-rectifier-info>

          <app-ac-power-meter-info *ngIf="selectedDeviceTypeId == deviceTypes.power_meter"
                                   [readings]="deviceInstanceReadings"
                                   (failedReading)="failedReadingHandler($event)"></app-ac-power-meter-info>

          <app-dc-power-meter-info *ngIf="selectedDeviceTypeId == deviceTypes.dc_meter"
                                   [readings]="deviceInstanceReadings"
                                   (failedReading)="failedReadingHandler($event)"></app-dc-power-meter-info>

          <app-change-over-info *ngIf="selectedDeviceTypeId == deviceTypes.change_over"
                                [readings]="deviceInstanceReadings"
                                (failedReading)="failedReadingHandler($event)"></app-change-over-info>


        </div>

        <div *ngIf="instanceReadingsTab === 1">
          <h6>Active Alarms</h6>
          <div class="row" *ngIf="!isLoadingAlarms">
            <div class="col-lg-12 col-md-12">
              <app-device-alarms-list style="height: 500px" [readings]="deviceAlarms"></app-device-alarms-list>

            </div>
          </div>
        </div>

      </nz-card>



    </nz-spin>



    <nz-empty

      [nzNotFoundContent]="contentTpl" *ngIf="!showReadings" [hidden]="emptyResponse"  class="full-width readings-empty"
    >
      <ng-template #contentTpl>
        <span>Select a Device Instance in order to view Readings in this pane</span>
      </ng-template>

    </nz-empty>

  </div>

</div>



