import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IUps} from '../../../Models/DeviceInstances/IUps';
import {NzTabPosition} from 'ng-zorro-antd/tabs';
import {interval, Subscription} from 'rxjs';
import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';

@Component({
  selector: 'app-ups-info',
  templateUrl: './ups-info.component.html',
  styleUrls: ['./ups-info.component.scss']
})
export class UpsInfoComponent implements OnInit, OnDestroy, AfterViewInit {

  hasReading: boolean;

  @Output() failedReading = new EventEmitter<boolean>();

  nzTabPosition: NzTabPosition = 'top';

  @Input() readings : IDeviceReadingsProps;

  requestInterval = interval(10000);
  intervalSubscriber: Subscription;
  currentDeviceReadings : IUps;

  isLoadingData : boolean;

  constructor(private analogDeviceService: AnalogDevicesService) { }

  ngOnInit(): void {
    this.isLoadingData = true;
    this.getDeviceReadings();
    this.hasReading = this.readings != null;


  }


  emitReadingError(): void{

    this.failedReading.emit(true);

  }

  getDeviceReadings(): void{

    this.isLoadingData = true;

    this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId, this.readings.selectedDeviceTypeId, this.readings.controllerId, this.readings.instanceId
    ).subscribe( response => {

      this.currentDeviceReadings = response;

      // if(response.analog_datetime == null){
      //   this.getDeviceReadings();
      // }

      this.isLoadingData = false;

    }, error => {
      console.warn('UPS', error);
    } )


  }

  ngOnDestroy(): void {

    //this.intervalSubscriber.unsubscribe();
  }

  ngAfterViewInit(): void {
    console.clear();
  }

}
