import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ISite} from '../../../Models/ISite';
import {SitesService} from '../../../services/sites/sites.service';
import {GenericHelpers} from '../../../utils/GenericHelpers';

import {IDashboardPageHeaderConfig} from '../../../Models/IDashboardPageHeaderConfig';
import {IUserSiteList} from '../../../Models/IUserSiteList';
import {AuthenticationService} from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-dashboard-page-header',
  templateUrl: './dashboard-page-header.component.html',
  styleUrls: ['./dashboard-page-header.component.scss']
})
export class DashboardPageHeaderComponent implements OnInit {

  @Input() config: IDashboardPageHeaderConfig;
  @Output() selectedSiteChange = new EventEmitter();

  isLoadingData: boolean;
  deviceTypeImage: string;

  // siteList: ISite[];
  selectedSiteIndex: number;
  // selectedSite: ISite;
  selectedSite: IUserSiteList;

  siteList: IUserSiteList[];

  constructor(private sitesService: SitesService,  private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.isLoadingData = true;
    let genericHelpers = new GenericHelpers();
    this.deviceTypeImage = genericHelpers.deviceImages(this.config.device_type);

    this.authService.getSpecificUserDetails().subscribe( userData => {

      // this.siteList =
      this.siteList = userData['sites'].map( (data: any) =>
        (
          {
            client_id: {...data['client_id']},
            latitude: parseFloat(data['latitude']),
            longitude: parseFloat(data['longitude']),
            name: data['name'],
            _id: data['_id'],
            legacy_id: data['legacy_id']
          }
        )
      );

      this.siteList = this.siteList.sort( (a, b) => a.name.localeCompare(b.name) );
      this.selectedSiteIndex = 0;
      this.selectedSite = this.siteList[0];
      this.emitSiteChange(this.selectedSite);
      this.isLoadingData = false;
    }, error => {
      console.error('Failed to get user details');
    } );

    // this.sitesService.getClientSites().subscribe( (sites: ISite[]) =>{
    //   this.siteList = sites;
    //   this.selectedSiteIndex = 0;
    //   this.selectedSite = this.siteList[0];
    //
    //   this.emitSiteChange(this.selectedSite);
    //   this.isLoadingData = false;
    //
    // }, error => console.error('Error retrieving site list: ', error) );

  }

  // emitSiteChange(site: ISite){
  emitSiteChange(site: IUserSiteList){
    this.selectedSite = site;
    this.selectedSiteChange.emit(site);

  }

  nextSite(): void{
    if(this.selectedSiteIndex !== (this.siteList.length -1)){
      this.selectedSiteIndex++;
      this.emitSiteChange(this.siteList[this.selectedSiteIndex]);
    }
  }

  previousSite(): void{
    if(this.selectedSiteIndex !== 0){
      this.selectedSiteIndex--;
      this.emitSiteChange(this.siteList[this.selectedSiteIndex]);
    }
  }

}
