import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public encodeAndStore(storageName: string, value: string){
    sessionStorage.setItem(storageName, encodeURI(value));
    return true;
  }

  public getStoredEncodedValue(storageName: string){
    return decodeURI(sessionStorage.getItem(storageName));
  }

  public clearStorage() : void{
    sessionStorage.clear();
  }

  public setCookie(storageName,value) {
    let d = new Date();
    d.setTime(d.getTime() + (30*24*60*60*1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = storageName + "=" + value + ";" + expires + ";path=/";
  }

  public getCookie(storageName) {
    let name = storageName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";

  }

  public setLocalStorage(storageName: string, value: string){
    localStorage.setItem(storageName, value);
  }

  public getLocalStorage(storageName: string){
    return decodeURI(localStorage.getItem(storageName));

  }


}
