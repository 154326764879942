import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IChangeOver} from '../../../Models/DeviceInstances/IChangeOver';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';
import {interval, Subscription} from 'rxjs';
import {IHvac} from '../../../Models/DeviceInstances/IHvac';
import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';

@Component({
  selector: 'app-change-over-info',
  templateUrl: './change-over-info.component.html',
  styleUrls: ['./change-over-info.component.scss']
})
export class ChangeOverInfoComponent implements OnInit {

  hasReading: boolean;

  @Output() failedReading = new EventEmitter<boolean>();

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;
  imgWidth = 80;
  imgHeight = 80;

  @Input() readings: IDeviceReadingsProps;

  requestInterval = interval(10000);
  intervalSubscriber: Subscription;
  currentDeviceReadings : IChangeOver;

  isLoadingData : boolean;

  constructor(private analogDeviceService: AnalogDevicesService) { }

  ngOnInit(): void {

    this.isLoadingData = true;


    this.getDeviceReadings();

    this.ctx = this.canvas.nativeElement.getContext('2d');


    this.hasReading = this.readings != null;


  }


 circuitBreaker(status, x_pos, y_pos, color): void{
    let start_x = x_pos + (this.imgWidth / 2) / 2;
    let end_x = (x_pos + (this.imgWidth/ 2));

   this.ctx.fillStyle = color;
   this.ctx.strokeStyle = color;

    if(status === false){
      start_x = (x_pos + (this.imgWidth/ 2)); //Closed Breaker
      this.ctx.fillStyle = color;
      this.ctx.strokeStyle =  color;

      // if(color !== '#9E9E9E' ){
      //
      // }


    }
    // else{
    //   this.ctx.fillStyle = 'red';
    //   this.ctx.strokeStyle =  'red';
    // }



    const end_y = y_pos + (this.imgHeight / 2);


   this.ctx.font = "100 25px Arial";
   this.ctx.fillText("x", (x_pos + (this.imgWidth/ 2)) - 6.5 , (y_pos + 6) );

    this.ctx.lineWidth = 2;
    this.ctx.beginPath();
    this.ctx.moveTo(start_x, y_pos);
    this.ctx.lineTo(end_x, end_y);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.arc(end_x, end_y, 5, 0, 2 * Math.PI );
    this.ctx.fill();
    this.ctx.stroke();

   this.ctx.fillStyle = this.currentDeviceReadings.line_sps_vert_colour;
   this.ctx.strokeStyle = this.currentDeviceReadings.line_sps_vert_colour;

    this.ctx.beginPath();
    this.ctx.moveTo(end_x, end_y);
    this.ctx.lineTo( end_x, (end_y + (this.imgHeight / 2)) );
    this.ctx.stroke();




 }

  circuitLine(colour,x_pos, y_pos): void{

    const line_x = ( x_pos + (this.imgWidth/ 2));
    const y = (y_pos +  this.imgHeight);


    this.ctx.strokeStyle = colour ;
    this.ctx.lineWidth = 4;
    this.ctx.beginPath();
    this.ctx.moveTo(line_x , y );
    this.ctx.lineTo(line_x, y + (this.imgHeight / 2));
    this.ctx.stroke();

  }

  bottomCircuit(status,x_pos, y_pos): void{
    let x = x_pos + (this.imgWidth / 2);
    let y = y_pos + this.imgHeight * 2 + (this.imgHeight / 2);

    let x_end = x_pos + (this.imgWidth * 3);


    this.ctx.fillStyle = this.currentDeviceReadings.line_sps_utility_colour;
    this.ctx.strokeStyle = this.currentDeviceReadings.line_sps_utility_colour;
    this.ctx.lineWidth = 4;
    this.ctx.beginPath();
    this.ctx.moveTo(x, y);

    this.ctx.lineTo(x_end, y);
    this.ctx.stroke();
  }

  lagCircuit(status, x_pos, y_pos): void{
    let x = x_pos + this.imgWidth;
    let y = y_pos + (this.imgHeight / 2);

    this.ctx.fillStyle = this.currentDeviceReadings.line_gen_sec_2_colour;
    this.ctx.strokeStyle = this.currentDeviceReadings.line_gen_sec_2_colour;

    this.ctx.lineWidth = 2;
    this.ctx.beginPath();
    this.ctx.moveTo(x, y);

    x = x + (this.imgWidth * 2);
    this.ctx.lineTo(x, y);
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.arc(x, y, 5, 0, 2 * Math.PI );
    this.ctx.fill();
    this.ctx.stroke();

    let breaker_x = x;

    if(status === 'open'){
      breaker_x = x - (this.imgWidth / 2) + 10;
    }

    this.ctx.beginPath();
    this.ctx.moveTo(x, y);
    this.ctx.lineTo(breaker_x, (y - (this.imgHeight/ 2) ) );
    this.ctx.stroke();
    this.ctx.font = "100 25px Arial";
    this.ctx.fillText("x", x - 6.5 , (y - (this.imgHeight/ 2) + 7 ));
    this.ctx.beginPath();
    this.ctx.moveTo(x, (y - (this.imgHeight/ 2) ));
    this.ctx.lineTo(x, (y - this.imgHeight) + 4);
    this.ctx.stroke();



  }

  render(): void {

    let utilityImg = new Image();
    let generator1Img = new Image();
    let generator2Img = new Image();

    let acPowerMeterImg1 = new Image();
    let acPowerMeterImg2 = new Image();
    let acPowerMeterImg3 = new Image();

    utilityImg.src = 'assets/icons/Device_types/UTILITY.png';
    generator1Img.src = 'assets/icons/Devices/2.png';
    generator2Img.src = 'assets/icons/Devices/2.png';

    acPowerMeterImg1.src = 'assets/icons/Device_pic/PowerMeter.png';
    acPowerMeterImg2.src = 'assets/icons/Device_pic/PowerMeter.png';
    acPowerMeterImg3.src = 'assets/icons/Device_pic/PowerMeter.png';

    utilityImg.onload = () => {

      const x_pos = 0;
      const y_pos = 0;

      this.ctx.drawImage(utilityImg, x_pos, y_pos, this.imgWidth, this.imgHeight);
      let color = this.currentDeviceReadings.line_utility_colour;

      this.circuitLine(color, x_pos, y_pos);

    };

    generator1Img.onload = () => {
      const x_pos = 200;
      const y_pos = 0;
      let color = this.currentDeviceReadings.line_gen_pri_colour;
      this.ctx.drawImage(generator1Img, x_pos, y_pos, this.imgWidth, this.imgHeight);
      this.circuitLine(color, x_pos, y_pos);


    };

    generator2Img.onload = () => {

      const x_pos: number = 600;
      const y_pos: number = 0;
      this.ctx.drawImage(generator2Img, x_pos, y_pos, this.imgWidth, this.imgHeight);
    };


    acPowerMeterImg1.onload =  () =>{
      const x_pos: number = 0;
      const y_pos: number = 115;

      const breaker_y : number = (y_pos +  this.imgHeight) + (this.imgHeight / 2);

      let color = this.currentDeviceReadings.line_utility_colour;

      this.ctx.drawImage(acPowerMeterImg1, x_pos, y_pos, this.imgWidth, this.imgHeight);
      this.circuitLine(color, x_pos,y_pos);

      let breakerStatus = this.currentDeviceReadings.pbx_cb_utility_open;

      this.circuitBreaker(breakerStatus, x_pos, breaker_y, ( this.currentDeviceReadings.pbx_cb_utility_colour === '#FFFFFF') ? '#9E9E9E' : this.currentDeviceReadings.pbx_cb_gen_pri_colour);

      this.bottomCircuit(status, x_pos, y_pos);
    };

    acPowerMeterImg2.onload =  () =>{
      const x_pos: number = 200;
      const y_pos: number = 115;

      const breaker_y : number = (y_pos +  this.imgHeight) + (this.imgHeight / 2);

      let color = this.currentDeviceReadings.line_gen_pri_colour;

      let breakerStatus = this.currentDeviceReadings.pbx_cb_gen_pri_open;

      this.ctx.drawImage(acPowerMeterImg2, x_pos, y_pos, this.imgWidth, this.imgHeight);
      this.circuitLine(color, x_pos,y_pos);


      this.circuitBreaker(breakerStatus, x_pos, breaker_y,
        ( this.currentDeviceReadings.pbx_cb_gen_pri_colour === '#FFFFFF') ? '#9E9E9E' : this.currentDeviceReadings.pbx_cb_gen_pri_colour );

      this.bottomCircuit(this.currentDeviceReadings.line_utility_status, x_pos, y_pos);
    };

    acPowerMeterImg3.onload =  () => {
      const x_pos: number = 400;
      const y_pos: number = 115;

      const breaker_y : number = (y_pos +  this.imgHeight) + (this.imgHeight / 2);
      let color = this.currentDeviceReadings.line_gen_sec_colour;
      let breakerStatus = this.currentDeviceReadings.pbx_cb_gen_sec_open;


      this.ctx.drawImage(acPowerMeterImg3, x_pos, y_pos, this.imgWidth, this.imgHeight);
      this.circuitLine(color,x_pos,y_pos);

      this.circuitBreaker(breakerStatus, x_pos, breaker_y,
        ( this.currentDeviceReadings.pbx_cb_gen_sec_colour === '#FFFFFF') ? '#9E9E9E' : this.currentDeviceReadings.pbx_cb_gen_sec_colour);

      let crossStatus = this.currentDeviceReadings.pbx_cb_cross_status;


      this.lagCircuit(crossStatus, x_pos,y_pos);
    };



  }

  emitReadingError(): void{

    this.failedReading.emit(true);

  }

  getDeviceReadings(): void{

    this.isLoadingData = true;

    this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId, this.readings.selectedDeviceTypeId, this.readings.controllerId, this.readings.instanceId
    ).subscribe( response => {



      this.currentDeviceReadings = response;

      try{
        this.hasReading = this.currentDeviceReadings != null;


        this.currentDeviceReadings.alarm_name = this.currentDeviceReadings.alarm_name.replace(/_/g, " ");


        this.render();
      }catch (e) {
        console.error(e.message);
      }

      this.isLoadingData = false;

    }, error => {
      console.warn('HVAC', error);
    } )


  }

  ngOnDestroy(): void {

    //this.intervalSubscriber.unsubscribe();
  }

}
