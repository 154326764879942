<div class="main-container">
  <div class="list-section">
    <div>
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input type="text"
               nz-input placeholder="Site Search..." [nzSize]="'small'"
               [(ngModel)]="searchText" autocomplete="off" />
        <ng-template #suffixIconSearch>
          <i nz-icon nzType="search"></i>
        </ng-template>
      </nz-input-group>
      <span class="small ml-1 text-info">Use the search box find a site by name.</span>
    </div>

    <div class="list-container border-0">

      <div class="card border-0">
        <ul class="list-group list-group-flush border-0">

          <li class="list-group-item d-flex justify-content-between align-items-center site-details-container"
              [ngStyle]="{
                'border-left-color':(siteAlarmsList !== undefined && siteAlarmsList !== null) ?
                 determinePriorityColor(data?.legacy_id || data?._id) : ''
              }"
              *ngFor="let data of siteList| filter:searchText; let i = index;"
              routerLink="/admin/devices/{{data?.legacy_id || data?._id}}">
            <div class="site-details">
              <nz-avatar *ngIf="siteAlarmsList !== undefined && siteAlarmsList !== null"
                         [nzText]="getSiteActiveAlarms(data?._id)"
                         class="mr-2" [ngStyle]="{'background-color': '#0e1627'}"></nz-avatar>
              <span>{{ data?.name }}</span>
            </div>
            <span>
              <i nz-icon nzType="right" nzTheme="outline"></i>
            </span>
          </li>
        </ul>
      </div>

    </div>

  </div>

  <div class="map-section">

    <div  *ngIf="isLoading" class="map-loading-indicator">
          <nz-result
            [nzIcon]="'loading-outline'"
            nzTitle="Loading Map"
            nzStatus="info"
            nzSubTitle="Please be patient">
            <div nz-result-extra class="map-loading-indicator">
              <i nz-icon nzType="loading" nzTheme="outline"
                 style="font-size: 50px;"></i>
            </div>
          </nz-result>
      <div>

      </div>
    </div>
    <app-site-map [mapData]="siteAlarmsList" *ngIf="siteAlarmsList !== undefined && siteAlarmsList !== null"></app-site-map>
  </div>
</div>

<!--<app-overlay-loader [isLoading]="isLoading" [loadingText]="'Loading Site List...'"></app-overlay-loader>-->

<nz-modal [(nzVisible)]="sessionModalVisible" nzClosable="fallse" [nzFooter]="null"
          nzTitle="Session Expired">
  <ng-container *nzModalContent>
    <p>Your session has expired you will be redirected to Login {{count}} seconds </p>
  </ng-container>
</nz-modal>
