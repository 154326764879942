<div class="container-fluid">

  <div class="row">
    <app-dashboard-page-header
      class="col-lg-12 col-md-12 col-sm-12"
      [config]="headerConfig" (selectedSiteChange)="handleSiteChangeEmitter($event)">
    </app-dashboard-page-header>
  </div>

  <div class="row">
    <nz-card nzBordered="false" [nzBodyStyle]="{'padding': 0}"
             class="col-lg-12 col-md-12 col-sm-12" [nzLoading]="isLoadingHvacData">

      <div class="row" *ngIf="!hasLoadedHvacData && !isLoadingData && !isLoadingHvacData">
        <nz-card nzBordered="false"
                 [nzBodyStyle]="{'padding': 0}"
                 class="col-lg-12 col-md-12 col-sm-12"
                 [nzLoading]="isLoadingData">

          <div class="col-lg-12 col-md-12 col-sm-12">
            <nz-result nzStatus="404" [nzTitle]="'No Data Found For '+ selectedSite?.name"
                       nzSubTitle="Click the button below to try again">
              <div nz-result-extra>
                <button nz-button nzType="primary"
                        (click)="fetchSiteDeviceReadings(selectedSite?.legacy_id.toString() || selectedSite._id)">Retry</button>
              </div>
            </nz-result>
          </div>
        </nz-card>

      </div>

      <div class="row mt-2" *ngIf="hasLoadedHvacData && !isLoadingData">
        <nz-card class="col-lg-12 col-md-12 col-sm-12"
                 nzBordered="false"
                 [nzBodyStyle]="{'padding': 0}" [nzLoading]="isLoadingHvacData">

          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 border-right">
                <nz-card nzBordered="false"
                         [nzBodyStyle]="{'padding': 0}"
                         *ngFor="let device of hvacData?.devices;"
                         class="border-bottom mb-2" [nzLoading]="isLoadingHvacData">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-lg-7 col-md-7 col-sm-12">
                        <nz-descriptions [nzTitle]="device.device_name"
                                         nzBordered [nzSize]="'small'" nzLayout="vertical">

                          <nz-descriptions-item nzTitle="Return Humidity" [nzSpan]="1">
                            <h3 nz-typography>
                              <nz-badge
                                [nzStatus]="(device.device_values?.return_humidity?.value !== '0.00') ? 'processing' : 'error'">
                              </nz-badge>
                              {{device.device_values?.return_humidity?.value}}
                              <span>
                              <small>{{device.device_values?.return_humidity?.unit}}</small>
                            </span>
                            </h3>
                          </nz-descriptions-item>
                          <nz-descriptions-item nzTitle="Free Cooling Demand" [nzSpan]="1">
                            <h3 nz-typography>
                              <nz-badge nzStatus="processing"></nz-badge>
                              {{device.device_values?.free_cooling_demand?.value}}
                              <span>
                              <small>{{device.device_values?.free_cooling_demand?.unit}}</small>
                            </span>
                            </h3>
                          </nz-descriptions-item>

                          <nz-descriptions-item nzTitle="Airflow" [nzSpan]="1">
                            <h3 nz-typography>
                              <nz-badge nzStatus="processing"></nz-badge>
                              {{device.device_values?.airflow?.value}}
                              <span>
                              <small>{{device.device_values?.airflow?.unit}}</small>
                            </span>
                            </h3>
                          </nz-descriptions-item>

                          <nz-descriptions-item nzTitle="Ambient Temperature" [nzSpan]="1">
                            <h3 nz-typography>
                              <nz-badge nzStatus="processing"></nz-badge>
                              {{device.device_values?.ambient_temperature?.value}}
                              <span>
                              <small>{{device.device_values?.ambient_temperature?.unit}}</small>
                            </span>
                            </h3>
                          </nz-descriptions-item>
                          <!--                    <nz-descriptions-item nzTitle="Digital States" [nzSpan]="1">-->
                          <!--                      <h3 nz-typography>-->
                          <!--                        <nz-badge nzStatus="processing"></nz-badge>-->
                          <!--                        {{device.device_values.digitalstates.value}}-->
                          <!--                        <span>-->
                          <!--                              <small>{{device.device_values.digitalstates.unit}}</small>-->
                          <!--                            </span>-->
                          <!--                      </h3>-->
                          <!--                    </nz-descriptions-item>-->


                        </nz-descriptions>
                      </div>

                      <div class="col-lg-5 col-md-5 col-sm-12">
                        <div class="container-fluid p-0">
                          <div class="row justify-content-center">
                            <div class="col-lg-5 col-md-5 col-sm-12 p-0 m-0">
                              <ng-container *ngIf="hasLoadedHvacData === true">
                                <app-gauge-chart
                                  [chartData]="{
                            series_name: 'Return Temperature',
                            series_data: device.device_values?.return_temperature?.value,
                            value_suffix: device.device_values?.return_temperature?.unit
                            }"

                                  [optionsType]="'hvac_temperature_percentage'"></app-gauge-chart>
                              </ng-container>

                            </div>

                            <div class="col-lg-5 col-md-5 col-sm-12 p-0 m-0">
                              <ng-container *ngIf="hasLoadedHvacData === true">
                                <app-gauge-chart
                                  [chartData]="{
                            series_name: 'Supply Temperature',
                            series_data: device.device_values?.supply_temperature?.value,
                            value_suffix: device.device_values?.supply_temperature?.unit
                            }"

                                  [optionsType]="'hvac_temperature_percentage'"></app-gauge-chart>
                              </ng-container>

                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </nz-card>
              </div>

            </div>
          </div>

        </nz-card>
      </div>

    </nz-card>
  </div>



</div>
