import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthenticationService} from '../authentication/authentication.service';
import {Observable} from 'rxjs';
import {IUser} from '../../Models/IUser';
import {StorageService} from '../storage/storage.service';
import {Constants} from '../../utils/Contstants';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  _baseURL: string = `https://clientzone.cloudbms.co.za/api/users/`;

  constructor(private http: HttpClient, private authService: AuthenticationService, private storageService: StorageService, ) { }

  public getClientUsers(clientID: string) : Observable<IUser[]>{

  const storageConstants = new Constants().storage;

    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.storageService.getStoredEncodedValue(storageConstants.token)
    });

    return this.http.get<IUser[]>(`${this._baseURL}/byClient/${clientID}`, {
      headers: headers
    });
  }

  // public getUsers(): Observable<IUser[]>{
  //   let token: string = this.storageService.getStoredEncodedValue(new Constants().storage.token);
  //   let header: HttpHeaders = new HttpHeaders({
  //     Authorization: 'Bearer ' + token
  //   });
  //
  //   return this.http.get<IUser[]>(`https://clientzonebackend.cloudbms.co.za/api/users/`, {
  //    headers: header
  //   });
  //
  // }

}
