import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {IGenerator} from '../../../Models/DeviceInstances/IGenerator';
import {interval, Subscription} from 'rxjs';
import {ITemperatureSensor} from '../../../Models/DeviceInstances/ITemperatureSensor';
import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';

@Component({
  selector: 'app-generator-info',
  templateUrl: './generator-info.component.html',
  styleUrls: ['./generator-info.component.scss']
})
export class GeneratorInfoComponent implements OnInit, OnDestroy {

  hasReading: boolean;

  @Output() failedReading = new EventEmitter<boolean>();

  @Input() readings : IDeviceReadingsProps;

  requestInterval = interval(10000);
  intervalSubscriber: Subscription;
  currentDeviceReadings : IGenerator;

  isLoadingData : boolean;

  url;

  constructor(private analogDeviceService: AnalogDevicesService) { }

  ngOnInit(): void {

      this.getDeviceReadings();
      this.hasReading = this.readings != null;
      this.isLoadingData = true;

  }

  emitReadingError(): void{
    this.failedReading.emit(true);

  }

  getDeviceReadings(): void{

    this.isLoadingData = true;

    this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId, this.readings.selectedDeviceTypeId, this.readings.controllerId, this.readings.instanceId
    ).subscribe( response => {

      this.currentDeviceReadings = response;
     //
     // let convertAlternatorChargeVoltage = this.currentDeviceReadings.Alternator_charge_voltage.replace(/ V/g, "");
     // let convertToNumber = parseInt(convertAlternatorChargeVoltage);
     //
     // let convertedValue = (convertToNumber / 10);
     //
     //
     // this.currentDeviceReadings.Alternator_charge_voltage = `${convertedValue} V`;

      this.isLoadingData = false;

    }, error => {
      console.warn('Generator', error);
    } )


  }

  ngOnDestroy(): void {

    //this.intervalSubscriber.unsubscribe();
  }

}
