<nav class="bms-navbar">
  <div class="navbar-brand">
    <img src="assets/round_bms_icon.png" alt="Cloud BMS"
         class="img-fluid d-inline-block align-top" height="30" width="30">
    Cloud BMS
  </div>

  <ul class="nav left-nav">
    <li class="nav-item">
      <a class="nav-link text-white" routerLink="/admin">Home</a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-white" routerLink="/admin/dashboard">Dashboards</a>
    </li>
    <li class="nav-item">
      <a class="nav-link text-white" routerLink="/admin/alarm-summary">Alarm Summary</a>
    </li>


    <li class="nav-item">
      <a nz-dropdown nzTrigger="click" class="nav-link text-white" [nzDropdownMenu]="menu">
        Reports
        <i nz-icon nzType="down" class="button-icon"></i>
      </a>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <!--          <li nz-menu-item routerLink="/admin/reports/generator-report">Generator Report</li>-->
          <li nz-menu-item routerLink="/admin/reports/fuel-consumption">Fuel Consumption</li>
          <li nz-menu-item routerLink="/admin/reports/temperature-readings">Temperature</li>
          <li nz-menu-item routerLink="/admin/reports/summary-report">Summary Report</li>
        </ul>
      </nz-dropdown-menu>
    </li>
  </ul>
  <ul class="nav justify-content-end right-nav">

    <li class="nav-item">
      <!--      <img [src]="clientLogo"-->
      <!--           class="img-fluid" alt="..." width="40px" height="40px">-->

      <nz-avatar nz-page-header-avatar
                 [nzSrc]="clientLogo"
                 (nzError)="noIconLoaded($event)" class="img-fluid"
                 style="width: 60px; height: 60%; margin: auto" nzShape="square"></nz-avatar>
    </li>
    <li class="nav-item">
      <a class="nav-link disabled text-white">{{selectedClient?.name}}</a>
    </li>

<!--    <li class="nav-item">-->
<!--      <a class="nav-link disabled" href="javascript:void(0)">-->
<!--        <i nz-icon nzType="user" nzTheme="outline" style="color: #60c6c4;"-->
<!--           class="button-icon mr-2"></i>-->
<!--        <span class="text-white">{{currentUser?.name | titlecase}} {{currentUser?.surname | titlecase}}</span>-->
<!--      </a>-->
<!--    </li>-->


    <li class="nav-item">
      <nz-badge [nzCount]="notificationCounts" [nzOverflowCount]="1000">
        <a class="nav-link head-example" href="javascript:void(0)" (click)="open()" style="color: #FFFFFF">
          <i nz-icon nzType="bell" nzTheme="outline" class="button-icon mr-2"></i>
        </a>
      </nz-badge>

    </li>

    <li class="nav-item">
      <a class="nav-link" href="javascript:void(0)" (click)="removeAllNotifications()"
         data-toggle="tooltip" data-placement="bottom" title="Clear Alarm popups">
        <i nz-icon nzType="clear" nzTheme="outline" style="color: #c70039;" class="button-icon mr-2"></i>
        <span class="text-white">Clear Alerts</span>
      </a>
    </li>

<!--    <li class="nav-item">-->
<!--      <a class="nav-link" href="javascript:void(0)"-->
<!--         data-toggle="tooltip" data-placement="bottom" title="Enable / Disable popups" (click)="disableAlarmNotificationPopups()">-->
<!--        <i nz-icon nzType="eye-invisible" nzTheme="outline" style="color: #FFFFFF;" class="button-icon mr-2" *ngIf="!muteAllNotifications"></i>-->
<!--        <span class="text-white" *ngIf="!muteAllNotifications">Disable Popups</span>-->
<!--        <i nz-icon nzType="eye" nzTheme="outline" style="color: #FFFFFF;" class="button-icon mr-2" *ngIf="muteAllNotifications"></i>-->
<!--        <span class="text-white" *ngIf="muteAllNotifications">Enable Alerts</span>-->
<!--      </a>-->
<!--    </li>-->

    <li class="nav-item">
      <a class="nav-link" href="javascript:void(0)" (click)="toggleSettingsPanel()">
        <i nz-icon nzType="setting"
           nzTheme="outline"
           style="color: #FFFFFF;"
           class="button-icon mr-2"></i>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link disabled" href="javascript:void(0)">
        <i nz-icon nzType="user" nzTheme="outline" style="color: #60c6c4;"
           class="button-icon mr-2"></i>
        <span class="text-white">{{currentUser?.name | titlecase}} {{currentUser?.surname | titlecase}}</span>
      </a>
    </li>

    <li class="nav-item">
      <a class="nav-link" href="javascript:void(0)" (click)="signOut()">
        <i nz-icon nzType="logout" nzTheme="outline" style="color: #c70039;"
           class="button-icon mr-2"></i>
        <span class="text-white">Logout</span>
      </a>
    </li>

  </ul>

</nav>

<ng-template #notificationBox let-alarm="data">

  <nz-alert
    nzShowIcon
    [nzIconType]="'bell'"
    [nzType]="getNotificationColor(alarm?.priority)"
    [nzMessage]="notificationMessage"
    [nzDescription]="notificationDescription"
  ></nz-alert>

  <ng-template #notificationMessage>
    <span>{{alarm?.description}}</span>
  </ng-template>

  <ng-template #notificationDescription>
    <div>
      <div class="d-flex w-100 justify-content-between mb-2">
        <h5 class="h6 card-title mb-0"> {{alarm?.device_name}}</h5>
        <span class="card-text mb-0">{{alarm?.site_name}}</span>
      </div>
<!--      <div class="d-flex w-100 justify-content-between mb-2">-->
<!--        <h5 class="h6 card-title mb-0">{{alarm?.description}}</h5>-->
<!--        <h5 class="h6 card-title mb-0"> {{alarm?.device_name}}</h5>-->
<!--      </div>-->
<!--      <div class="d-flex w-100 justify-content-between mt-0 mb-1">-->
<!--        <span class="card-text mb-0">{{alarm?.site_name}}</span>-->
<!--        <small class="card-text mb-0 mr-0"><nz-tag class="float-right" [ngStyle]="{'color': (alarm?.is_cleared)? 'green': 'red'}">{{alarm?.is_cleared ? 'CLEARED': 'ACTIVE'}}</nz-tag></small>-->
<!--      </div>-->

<!--      <div class="d-flex w-100 justify-content-between mt-0 mb-0">-->
<!--        <small class="text-muted card-text">{{alarm?.triggered_at | date: 'medium'}}</small>-->
<!--        &lt;!&ndash;                <button nz-button nzSize="small" (click)="muteNotification(alarm)"><i nz-icon nzType="eye-invisible" nzTheme="outline"></i> Mute</button>&ndash;&gt;-->
<!--      </div>-->


    </div>
  </ng-template>
<!--  -->
<!--  <nz-card nzBordered="false"-->
<!--           [nzBodyStyle]="{'padding': 0}">-->
<!--    -->

<!--    <div class="notification-list-item-container m-0">-->
<!--      <div [ngStyle]="{'background-color': getAlarmColor(alarm?.priority)}"></div>-->
<!--      <div class="card mb-2 border-0">-->
<!--        <div class="row no-gutters">-->
<!--          <div class="col-md-12">-->
<!--            <div class="card-body">-->
<!--              <div class="d-flex w-100 justify-content-between mb-2">-->
<!--                <h5 class="h6 card-title mb-0">{{alarm?.description}}</h5>-->
<!--                <h5 class="h6 card-title mb-0"> {{alarm?.device_name}}</h5>-->
<!--              </div>-->
<!--              <div class="d-flex w-100 justify-content-between mt-0 mb-1">-->
<!--                <span class="card-text mb-0">{{alarm?.site_name}}</span>-->
<!--                <small class="card-text mb-0 mr-0"><nz-tag class="float-right" [ngStyle]="{'color': (alarm?.is_cleared)? 'green': 'red'}">{{alarm?.is_cleared ? 'CLEARED': 'ACTIVE'}}</nz-tag></small>-->
<!--              </div>-->

<!--              <div class="d-flex w-100 justify-content-between mt-0 mb-0">-->
<!--                <small class="text-muted card-text">{{alarm?.triggered_at | date: 'medium'}}</small>-->
<!--&lt;!&ndash;                <button nz-button nzSize="small" (click)="muteNotification(alarm)"><i nz-icon nzType="eye-invisible" nzTheme="outline"></i> Mute</button>&ndash;&gt;-->
<!--              </div>-->

<!--            </div>-->


<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </nz-card>-->
</ng-template>


<app-alarm-management-panel [isOpen]="visible"
                            (closePanel)="close()"
                            [alarms]="notifications"
                            [clientDetails]="selectedClient"
                            [currentUser]="currentUser"
                            (onAlarmAcknowledged)="handleAlarmAcknowledgedEvent($event)">

</app-alarm-management-panel>

<app-settings-panel [isOpen]="settingsPanelOpen" (closePanel)="toggleSettingsPanel()"
                    [currentUser]="currentUser"
                    (toggleNotifications)="handleToggleNotificationPopupsEvent($event)"></app-settings-panel>
