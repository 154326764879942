import {Component, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import {IActiveAlarm} from '../../../Models/IActiveAlarm';
import {TableDataItem} from '../../../Models/IAlarmTableInterfaces';

interface DataItem {
  priority: string;
  occurrence: string;
  duration: string;
}

interface ColumnItem {
  name: string;
  sortOrder?: NzTableSortOrder;
  sortFn?: NzTableSortFn;
  listOfFilter?: NzTableFilterList;
  filterFn?: NzTableFilterFn;
  filterMultiple?: boolean;
  sortDirections?: NzTableSortOrder[];
}

@Component({
  selector: 'app-device-alarms-list',
  templateUrl: './device-alarms-list.component.html',
  styleUrls: ['./device-alarms-list.component.scss']
})
export class DeviceAlarmsListComponent implements OnInit, OnChanges {

  listOfColumns: ColumnItem[] = [
    {
      name: 'Device Group',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) => {

        return 1
      },
    },
    {
      name: 'Priority',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) =>
        a.priority.toString().localeCompare(b.priority.toString()),
      filterMultiple: true,
      listOfFilter: [
        { text: 'Critical', value: 'Critical'},
        { text: 'Urgent', value: 'Urgent'},
        { text: 'Warning', value: 'Warning'},
        { text: 'Event', value: 'Event' }
      ],
      filterFn: (list: string[], item: DataItem) =>
      {

        for(let i in list){

          if(item.priority === list[i]){
            return true;
          }

        }

        return false;
      }
    },
    {
      name: 'Description',
      sortOrder: null,
    },
    {
      name: 'Occurrence',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a['alarm_trigger_time'].localeCompare(b['alarm_trigger_time'])
    },
    {
      name: 'Duration',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a['alarm_cleared_time'].localeCompare(b['alarm_cleared_time'])
    }
  ];

  alarms : IActiveAlarm[];
  expandSet = new Set<number>();

  @Input()
  set readings(data) {
    this.alarms = data;

  }
  get readings() {

    return this.alarms;
  };

  ngOnChanges(changes: {[propKey: string]: SimpleChange}){

    this.alarms = changes.readings.currentValue;

  }



  constructor() { }

  ngOnInit(): void {

  }

}
