<div class="overlay"  [style.display]="'block'" *ngIf="isLoading" style="background: #020d26;">
  <div class="overlay__inner">
    <div class="overlay__content text-center">
      <img src="assets/in_progress.svg" style="width: 40%; height: 400px" alt="">
      <br>
      <span class="spinner"></span>
      <br>
      <p class="text-white">{{loadingText}}</p>

    </div>
  </div>
</div>
