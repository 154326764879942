import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {TableColumnItem} from '../../../Models/IAlarmTableInterfaces';
import { IActiveAlarm } from 'src/app/Models/IActiveAlarm';

@Component({
  selector: 'app-realtime-alarms',
  templateUrl: './realtime-alarms.component.html',
  styleUrls: ['./realtime-alarms.component.scss']
})
export class RealtimeAlarmsComponent implements OnInit, OnChanges, OnDestroy {

  currentAlarms: IActiveAlarm[] = [];
  @Output() alarmEvent = new EventEmitter();
  @Input() alarms: IActiveAlarm[];

  @Input() mutedAlarms: IActiveAlarm[];
  @Output() alarmMutedEvent = new EventEmitter();
  @Output() alarmUnMutedEvent = new EventEmitter();

  searchText: string;

  tableColumns: TableColumnItem[]= [
    {
      name: 'Message',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: IActiveAlarm, b: IActiveAlarm) => a.description.length - b.description.length,
    },
    {
      name: 'Site',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: IActiveAlarm, b: IActiveAlarm) => a.site_name.length - b.site_name.length,
    },
    {
      name: 'Priority',
      sortOrder: 'descend',
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: IActiveAlarm, b: IActiveAlarm) => a.priority.length - b.priority.length,
      filterMultiple: false,
      listOfFilter: [
      { text: 'Critical', value: 'Critical' },
      { text: 'Urgent', value: 'Urgent' },
      { text: 'Warning', value: 'Warning' },
      { text: 'Event', value: 'Event' }
    ],
      filterFn: (priority: string, item: IActiveAlarm) => item.priority.indexOf(priority) !== -1
    },
    {
      name: 'Device',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: IActiveAlarm, b: IActiveAlarm) => a.device_name.length - b.device_name.length,
    },
    // {
    //   name: 'Device Type',
    //
    // },
    {
      name: 'Status',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: IActiveAlarm, b: IActiveAlarm) => (a.is_cleared === b.is_cleared) ? 0 :  a.is_cleared? 1: -1,
      filterMultiple: false,
      // listOfFilter: [
      //   { text: 'Cleared', value: false },
      //   { text: 'Active', value: true },
      // ],
      // filterFn: (priority: string, item: IActiveAlarm) => item.is_cleared.toString().indexOf(priority) !== -1
    },
    {
      name: 'Active Duration',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: IActiveAlarm, b: IActiveAlarm) => (a.duration > b.duration) ? 1 : 0,
    },
    {
      name: 'Occurrence',
      sortOrder: 'descend',
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: IActiveAlarm, b: IActiveAlarm) => (a.triggered_at > b.triggered_at) ? 1 : 0,
    },
    {
      name: 'Actions'
    }
  ];


  constructor() { }

  ngOnInit(): void {
    this.currentAlarms = [...this.alarms];
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!changes.alarms.firstChange){
      this.currentAlarms = [...changes.alarms.currentValue];
    }
  }


  checkIfAlarmMuted(alarm: IActiveAlarm): boolean{

    let foundAlarm = this.mutedAlarms.find( a =>
      a.client_id === alarm.client_id
      && a.site_id === alarm.site_id
      && a.description === alarm.description
      && a.device_name === alarm.device_name
      && a.is_cleared === alarm.is_cleared);

    return foundAlarm !== undefined;


  }

  ngOnDestroy() {

  }

  clearNotifications(): void{
    this.currentAlarms = [];
    this.alarmEvent.emit(this.currentAlarms.length);
  }

  muteAlarm(alarm: IActiveAlarm): void{
    this.alarmMutedEvent.emit(alarm);
  }

  unMuteAlarm(alarm: IActiveAlarm): void{
    this.alarmUnMutedEvent.emit(alarm);
  }


}
