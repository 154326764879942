import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StorageService} from '../../services/storage/storage.service';
import {AnalogDevicesService} from '../../services/analogDevices/analog-devices.service';
import {AlarmsService} from '../../services/alarms/alarms.service';
import {DomSanitizer} from '@angular/platform-browser';
import {IClient} from '../../Models/IClient';
import {ClientsService} from '../../services/clients/clients.service';
import {SitesService} from '../../services/sites/sites.service';
import {ISite} from '../../Models/ISite';
import {IAnalogDevice} from '../../Models/IAnalogDevice';
import {IAnalogDeviceInstance} from '../../Models/IAnalogDeviceInstance';
import {InstanceDirective} from './instance.directive';
import {InstanceComponentListItem} from '../../Models/InstanceComponentList';
import {InstanceComponentList} from '../../utils/InstanceComponentList';
import {IActiveAlarm} from '../../Models/IActiveAlarm';
import {Constants} from '../../utils/Contstants';
import {interval, Subscription} from 'rxjs';
import {IDeviceStatusAlarm} from '../../Models/IDeviceStatusAlarm';
import {GenericHelpers} from '../../utils/GenericHelpers';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import {RedashService} from '../../services/redash/redash.service';
import {IRedashQueryGeneration} from '../../Models/Redash/IRedashQueryGeneration';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzMessageService} from 'ng-zorro-antd/message';


@Component({
  selector: 'app-device-trending',
  templateUrl: './device-trending.component.html',
  styleUrls: ['./device-trending.component.scss']
})
export class DeviceTrendingComponent implements OnInit {

  deviceTypeIds = new Constants().deviceTypeIds;

  deviceComponentList : InstanceComponentListItem[] = new InstanceComponentList().getDeviceList();

  selectedSiteId : number | string;
  currentSiteDetails : ISite;
  currentClient: IClient;

  devices: IAnalogDevice[];
  deviceInstances: IAnalogDeviceInstance[];

  selectedDeviceTypeId : number;
  selectedDeviceIndex: number;

  selectedInstanceIndex: number;
  selectedInstanceName: string;

  selectedInstanceTabIndex : number;

  loadingDevices : boolean;
  loadingInstanceData : boolean;
  emptyReading: boolean;

  // Alarms
  activeAlarms: IActiveAlarm[] = [];
  $deviceActiveAlarm: IDeviceStatusAlarm[];
  loadingAlarms: boolean = false;


  // Trending
  showTrendingTab : boolean = false;
  openTrendingDrawer: boolean;


  trendingDeviceInfo : {
    query_id: number,
    site_id: number | string,
    controller_id: number,
    instance_id: number,
    dateStart: string,
    dateEnd: string
  };

  dateFrom: string;
  dateTo: string;

  excelFile: any;
  hasRetrievedExcelFile: boolean = false;

  loadingFileProgress: number = 0;

  iframeUrls: Array<any>;

  selectedTrendTimeRange: {
    name: string,
    from: string,
    to: string
  } = {
    name: "This month so far",
    from: "now/M",
    to: "now"
  };

  trendTimeRanges: Array<{
    name: string,
    from: string,
    to: string
  }> = [
    {
      name: "Last 12 Hours",
      from: "now-12h",
      to: "now"
    },
    {
      name: "Last 7 days",
      from: "now-7d/d",
      to: "now"
    },
    {
      name: "This month so far",
      from: "now/M",
      to: "now"
    },
    {
      name: "Last month",
      from: "now-1M/M",
      to: "now-1M/M"
    },
    {
      name: "Last 1 year",
      from: "now-1y",
      to: "now"
    },
  ];

  disabledEndDate = (current: Date): boolean => {

    return differenceInCalendarDays(current, new Date()) > 0;
  };

  loadingTrendFile: boolean = false;

  showExportModal: boolean = false;

  @ViewChild(InstanceDirective, {static: true}) instanceHost: InstanceDirective;
  componentRef;

  iframeUrl : any;
  rawIframeUrl: string;
  grafanaPanels: Array< string> = [];

  setInstances(instances: IAnalogDeviceInstance[]){

    this.deviceInstances = instances;
    this.deviceInstances.sort( (a, b) => a.instance_name.localeCompare(b.instance_name) );
    this.selectedInstanceIndex = 0;

    this.showDeviceInstanceInfo(this.deviceInstances[this.selectedInstanceIndex]);

  }

  requestInterval = interval(2000);
  intervalSubscriber: Subscription;
  currentDeviceReadings;


  deviceListDrawerVisible: boolean = false;

  showDeviceListDrawer(){
    this.deviceListDrawerVisible = true;
  }

  closeDeviceListDrawer(){
    this.deviceListDrawerVisible = false;
  }

  changeDeviceTrendRange(): void{

    let url = this.rawIframeUrl + `var-device_instance=${this.trendingDeviceInfo.instance_id}&var-site_id=${this.selectedSiteId}&var-controller_id=${this.trendingDeviceInfo.controller_id}&`
      + `from=${this.selectedTrendTimeRange.from}&to=${this.selectedTrendTimeRange.to}`;


    let urls = [];
    for(let i in this.grafanaPanels){
      let newUrl = this.sanitizer
        .bypassSecurityTrustResourceUrl(url + `${this.grafanaPanels[i]}`);
      urls.push(newUrl);

    }
    this.iframeUrls = urls;

  }

  startRange: Date = new Date(new Date().setDate(new Date().getDate() -7));
  endRange: Date = new Date();

  onStartRangeChange(e): void{
    this.startRange = e;
  }

  onEndRangeChange(e: Date): void{
    this.endRange = e;
  }
  setTrendTimeRange(): void{

    const start = this.convertDateToEpoch(this.startRange);
    //Add 1 day to end
    const end = this.convertDateToEpoch(new Date(this.endRange.setDate(this.endRange.getDate() + 1)), true);

    let url = this.rawIframeUrl + `var-device_instance=${this.trendingDeviceInfo.instance_id}&var-site_id=${this.selectedSiteId}&var-controller_id=${this.trendingDeviceInfo.controller_id}&`
      + `from=${start}&to=${end}`;


    let urls = [];
    for(let i in this.grafanaPanels){
      let newUrl = this.sanitizer
        .bypassSecurityTrustResourceUrl(url + `${this.grafanaPanels[i]}`);
      urls.push(newUrl);

    }
    this.iframeUrls = urls;
  }

  disabledDate = (current: Date): boolean =>

    differenceInCalendarDays(current, new Date()) > 0;

  convertDateToEpoch(d: Date, dontSetHours?: boolean): number{

    let converted: Date;

    if(dontSetHours){
       converted = d;
    }
    else{
      converted = new Date(d.setHours(0,0,0,0));
    }


    return Math.floor(converted.getTime());
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private storageService: StorageService,
              private analogDeviceService: AnalogDevicesService,
              private alarmsService: AlarmsService,
              private sanitizer: DomSanitizer,
              private clientService: ClientsService,
              private siteService: SitesService,
              private cvRef: ViewContainerRef,
              private resolver: ComponentFactoryResolver,
              private redashService: RedashService,
              private nzNotificationService: NzNotificationService,
              private nzMessageService: NzMessageService) {

          this.route.params.subscribe( params => {
            // this.selectedSiteId = parseInt(params.id);
            this.selectedSiteId = params.id;

            this.siteService.getSpecificSiteDetails(this.selectedSiteId.toString()).subscribe( response => {


              if(response[0] === undefined){
                this.currentSiteDetails = response;
              }
              else{
                this.currentSiteDetails = response[0];
              }


            }, error => {
              console.error('Failed to get Site Details ');
            })

          } );
  }

  ngOnInit(): void {

    this.loadingDevices = true;
    this.currentClient = this.clientService.getCurrentClient();
    this.analogDeviceService.siteDevicesAndInstances(this.selectedSiteId).subscribe( response => {

      try{

        this.devices = this.analogDeviceService.filterDevices(response);
        this.devices.sort( (a, b) => a.device_type.localeCompare(b.device_type) );


        let hasDistributionDevice: boolean = false;

        for(let i in this.devices){

          if(Number(this.devices[i].device_type_id) === 4){

            this.selectedDeviceTypeId = Number(this.devices[i].device_type_id);
            this.selectedDeviceIndex = Number(i);
            hasDistributionDevice = true;
            this.showDeviceInstanceInfo(this.devices[i].instances[0]);
            this.selectedInstanceIndex = 0;

            break;
          }

        }

        if(!hasDistributionDevice){
          this.selectedDeviceTypeId = Number(this.devices[0].device_type_id);
          this.selectedDeviceIndex = 0;
          this.showDeviceInstanceInfo(this.devices[0].instances[0]);
          this.selectedInstanceIndex = 0;
        }

        this.setInstances(this.devices[this.selectedDeviceIndex].instances);
        this.loadingDevices = false;

      }
      catch (e) {
        console.warn(e);
        console.info("There are no devices for this site");
        this.loadingDevices = false;
      }


    }, (error : Error) => {

      this.loadingDevices = false;
    } );
  }

  showDeviceInstanceInfo(instance : IAnalogDeviceInstance){

      this.selectedInstanceTabIndex = 0;


      this.loadingInstanceData = true;
      this.selectedInstanceName = instance.instance_name;

      /*
      * Use for Debugging

      *  */

      if(
        this.selectedDeviceTypeId != this.deviceTypeIds.fire_panel &&
        this.selectedDeviceTypeId != this.deviceTypeIds.distribution &&
        this.selectedDeviceTypeId != this.deviceTypeIds.change_over &&
        this.selectedDeviceTypeId != this.deviceTypeIds.universal_controller){
        this.showTrendingTab = true;
      }else{
        this.showTrendingTab = false;
      }

      switch (this.selectedInstanceTabIndex) {
        case 0: {
          this.showRealtimeReadings(instance);
          break;
        }
        case 1: {

          this.showActiveAlarms(instance);
          break;
        }
        // case 2: {
        //   this.showInstanceTrend(instance);
        //   break;
        // }
        case undefined:{
          this.selectedInstanceTabIndex = 0;
          this.showRealtimeReadings(instance);
          break;
        }
        default : {
          this.showRealtimeReadings(instance);
          break;
        }
      }
      this.showInstanceTrend(instance);

    }

  tabInstanceChange(data, instance): void{

      if(data === 1){
        this.showActiveAlarms(instance);
      }
    }

  showRealtimeReadings (instance: IAnalogDeviceInstance): void{


    //   this.selectedDeviceTypeId, 'instance Info', instance);


    let currentComponent = this.getCurrentComponent();

    if(currentComponent.component !== undefined && currentComponent.component !== null){
      this.emptyReading = false;

      const componentFactory = this.resolver.resolveComponentFactory(currentComponent.component);
      const viewContainerRef = this.instanceHost.viewContainerRef;
      viewContainerRef.clear();

      this.componentRef = viewContainerRef.createComponent(componentFactory);
      (this.componentRef.instance).readings = {

        selectedSiteId: this.selectedSiteId,
        selectedDeviceTypeId: Number(this.selectedDeviceTypeId),
        controllerId: Number(instance.controller_id),
        instanceId: Number(instance.instance_id),
        deviceName: this.selectedInstanceName
      };

      this.loadingInstanceData = false;

    }else{
      this.loadingInstanceData = false;
      this.instanceHost.viewContainerRef.clear();
      console.warn('NO Component');
      this.emptyReading = true;
    }

    //  this.intervalSubscriber =  this.requestInterval.subscribe( () => {
    //
    //   this.analogDeviceService.deviceInstanceReadings(
    //     this.selectedSiteId, Number(this.selectedDeviceTypeId),
    //     Number(instance.controller_id), Number(instance.instance_id)
    //   ).subscribe( ( response ) => {
    //
    //

    //     if(!this.checkEmptyResponse(response)){
    //
    //       this.currentDeviceReadings = response;
    //
    //
    //     }else{
    //       console.warn('Empty Readings Response!');
    //       this.instanceHost.viewContainerRef.clear();
    //       this.loadingInstanceData = false;
    //       this.emptyReading = true;
    //     }
    //
    //   }, (error) => {
    //     console.error(error);
    //     this.instanceHost.viewContainerRef.clear();
    //     this.loadingInstanceData = false;
    //   } );
    //
    // }, error => {
    //   console.warn('An error occurred polling the server : ', error);
    // } );

  }

  showActiveAlarms(instance: IAnalogDeviceInstance): void{
    this.loadingAlarms = true;
    this.activeAlarms = [];

    this.alarmsService.instanceActiveAlarms(this.selectedSiteId,
      Number(instance.controller_id), Number(instance.instance_id), Number(this.selectedDeviceTypeId)).
      subscribe( (response) => {

        if(response !== null && response.length !== 0){
          this.activeAlarms = response;
          this.loadingAlarms = false;

          this.loadingInstanceData = false;

        }else{

          this.loadingInstanceData = false;
          this.loadingAlarms = false;
        }

    }, (error) => {

      this.loadingInstanceData = false;
      this.loadingAlarms = false;
    } );

  }

  showInstanceTrend(instance: IAnalogDeviceInstance): void{

    let elementId = 0;

    let currentComponent = this.getCurrentComponent();
    let deviceId = currentComponent.trendName;
    let payloadParams
      =`${this.selectedSiteId}${instance.controller_id}${instance.instance_id}${elementId}`;

    let stringPayLoad = `{ "${deviceId}": [ "${payloadParams}" ] , "site_id" : "${this.selectedSiteId}" }`;

    let params = JSON.parse(stringPayLoad);

    let payload = {
      resource: { dashboard: currentComponent.metaBaseDashboard },
      params: params,
      exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
    };

    this.trendingDeviceInfo = {
      site_id: this.selectedSiteId,
      instance_id: instance.instance_id,
      controller_id: instance.controller_id,
      dateStart: "",
      dateEnd: "",
      query_id: currentComponent.query_id
    };

    try{

//       //let METABASE_SITE_URL = "https://cloudbms.co.za:8443";
//       let METABASE_SITE_URL = "https://meta.cloudbms.co.za";
//
//       const metaBaseKey: any = '64bfdc6309b13464ef7fe778ece30a32ec04f10998f3cab47f021c9f766bd824';
// ​
//       let token = jsonwebtoken.sign(payload, metaBaseKey);
//       ​
//       let iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";

      // let iframeUrl = `https://redash.cloudbms.co.za/public/dashboards/LVLJGrZ0EQ98SfuKTu843V8ng54sXgyx1pEA6BaB?org_slug=default&p_c_id=${instance.controller_id}&p_i_id=${instance.instance_id}&p_site_id=${this.selectedSiteId}&p_e_id=${elementId}&refresh=60`;

      this.trendingDeviceInfo = {
        site_id: this.selectedSiteId,
        instance_id: instance.instance_id,
        controller_id: instance.controller_id,
        dateStart: "",
        dateEnd: "",
        query_id: currentComponent.query_id
      };

      // let iframeUrl =
      //   `https://redash.cloudbms.co.za/public/dashboards/${currentComponent.redashDashboard}?org_slug=default&p_c_id=${instance.controller_id}&p_i_id=${instance.instance_id}&p_site_id=${this.selectedSiteId}&p_e_id=${elementId}&refresh=60`;

      // let iframeUrl = currentComponent.grafana_url +
      //   `&var-device_instance=1&var-site_id=63&var-controller_id=3&` +
      //   `from=${this.selectedTrendTimeRange.from}&to=${this.selectedTrendTimeRange.to}&theme=light&panelId=2`;

      const start = this.convertDateToEpoch(this.startRange);
      const end = this.convertDateToEpoch(this.endRange, true);

      let iframeUrl = currentComponent.grafana_url +
        `var-device_instance=${instance.instance_id}&var-site_id=${this.selectedSiteId}&var-controller_id=${instance.controller_id}&`+
        `from=${start}&to=${end}`;

      let urls = [];
      for(let i in currentComponent.grafana_panels){
        let url =this.sanitizer
          .bypassSecurityTrustResourceUrl(iframeUrl + `${currentComponent.grafana_panels[i]}`);
        urls.push(url);

      }
      this.grafanaPanels = currentComponent.grafana_panels;
      this.iframeUrls = urls;

      this.rawIframeUrl = currentComponent.grafana_url;
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
      this.loadingInstanceData = false;
    }
    catch (e) {
      console.error('METABASE:::::::');

    }

  }

  getCurrentComponent() : InstanceComponentListItem{

    let currentComponent;

    for (let i in this.deviceComponentList){

      if( Number(this.selectedDeviceTypeId) === this.deviceComponentList[i].id){
        currentComponent = this.deviceComponentList[i];
        break;
      }

    }

    return currentComponent;
  }

  checkEmptyResponse(response): boolean{

    let result = false;

    if (Array.isArray(response)){

      if(response.length === 0){
        result = true;
      }

    }

    if(response === null || response === undefined){
      result = true;
    }

    return result;

  }

  deviceImage(deviceId: number) : string{

    let path = 'assets/icons/Devices/';

    if(deviceId === 29 || deviceId === 54) return `${path}unknown_device.png`;
    else
    return `${path}${deviceId}.png`;

  }

  getStatusColour(status: string): string{

    let response = '';

    switch (status) {
      case 'Critical':
        response = 'error';
        break;
      case 'Urgent':
        response = 'warning';
        break;
      case 'Warning':
        response = 'warning';
        break;
      case 'Event':
        response = 'processing';
        break;
      case "Normal":
        response = "default";
    }
    return response;
  }

  onFromChange(result: Date): void {

    if(result !== null){

      let convertedDate = new Date(result.getFullYear(), result.getMonth(), 1);
      this.dateFrom = new GenericHelpers().formatDate(convertedDate);

    }else{

    }


  }

  onToChange(result: Date): void {

    if(result !== null){
      this.dateTo = new GenericHelpers().formatDate(result);

    }else{

    }

  }

 getTrendFile(): void{

    this.loadingFileProgress = 0;
    this.trendingDeviceInfo.dateStart = this.dateFrom;
    this.trendingDeviceInfo.dateEnd = this.dateTo;



    if(this.dateTo !== undefined && this.dateFrom !== undefined){
      this.loadingTrendFile = true;

      let loadingMessage = this.nzMessageService.loading('The report is being generated please be patient.', {
        nzDuration: 0
      });
      //Generate the query in redash
      this.loadingFileProgress += 10;
      this.redashService.generateQuery(
        this.trendingDeviceInfo.query_id,
        this.trendingDeviceInfo.site_id,
        this.trendingDeviceInfo.controller_id,
        this.trendingDeviceInfo.instance_id, this.trendingDeviceInfo.dateStart,
        this.trendingDeviceInfo.dateEnd).subscribe( (response: IRedashQueryGeneration) => {


        let queryId: string = response.job.id;
        this.loadingFileProgress += 20;
        let isSuccessResponse: number = 0;

        let jobStatus1Count = 1;
        let jobStatus2Count = 0;

        let process = setInterval(() => {
          this.redashService.initiateQueryJob(queryId).subscribe( (response: IRedashQueryGeneration) => {

            isSuccessResponse = response.job.status;


            if(response.job.status === 1 && jobStatus1Count === 0){
              this.loadingFileProgress += 20;
              jobStatus1Count = 1;
            }

            if(response.job.status === 2 && jobStatus2Count === 0){
              this.loadingFileProgress += 20;
              jobStatus2Count = 1;
            }

            if(response.job.status === 3){
              this.loadingFileProgress += 30;
              // let currentComponent = this.getCurrentComponent();
              this.excelFile = `https://redash.cloudbms.co.za/api/query_results/${response.job.query_result_id}.xlsx?api_key=4hjOcnZQELVq1jS1VywrNpLJfqiEpko4rVuhlRO4`
              this.loadingTrendFile = false;
              clearInterval(process);
              this.hasRetrievedExcelFile = true;
              this.nzMessageService.remove(loadingMessage.messageId);

              this.nzMessageService.success('Report Generated successfully');
            }

          }, error => {

            clearInterval(process);
            this.nzNotificationService.error(
              'Operation Failed',
              'An error occurred in generating your report, please try again',
              {
                nzPlacement: 'topLeft'
              }
            );
            this.loadingTrendFile = false;
          });
        }, 2500);



      }, error => {
          console.warn(error);
          this.nzNotificationService.error(
            'Operation Failed',
            'An error occurred in generating your report, please try again',
            {
              nzPlacement: 'topLeft'
            }
          );
          this.loadingTrendFile = false;
      });

    }else{

      this.nzNotificationService.error(
        'Invalid Dates Selected',
        'You must select a start and end date in order to generate an excel report',
        {
          nzPlacement: 'topLeft'
        }
      );

    }

 }

}
