import {Component, OnInit, NgZone, AfterViewInit, OnDestroy} from '@angular/core';
import {GenericHelpers} from '../../../utils/GenericHelpers';
import {SitesService} from '../../../services/sites/sites.service';
import {ISite} from '../../../Models/ISite';

import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

import printJS from 'print-js';
import * as es6printJS from "print-js";
import {IFuelUsage, IFuelUsageDataConsumptionInfo} from '../../../Models/Reports/IFuelUsage';
import {FuelConsumptionService} from '../../../services/reports/fuel-consumption.service';

import {Chart, ChartModule} from 'angular-highcharts';

import { DomSanitizer } from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {IClient} from '../../../Models/IClient';
import {ClientsService} from '../../../services/clients/clients.service';
import {StorageService} from '../../../services/storage/storage.service';
import {Constants} from '../../../utils/Contstants';
import {IUserSiteList} from '../../../Models/IUserSiteList';

@Component({
  selector: 'app-fuel-consumption',
  templateUrl: './fuel-consumption.component.html',
  styleUrls: ['./fuel-consumption.component.scss']
})
export class FuelConsumptionComponent implements OnInit{

  loadingPageData: boolean;

  loadingReport : boolean;
  hasLoadedReport: boolean;

  selectedSiteId: number;
  dateFrom: Date | string;
  dateTo : Date | string;

  // siteList: ISite[] = [];
  siteList: IUserSiteList[] = [];

  loadingFuelConsumption: boolean;

  consumption: IFuelUsage[];

  chart: Chart;
  chartSiteList = [];
  selectedSite: string;
  selectedChartSite: number;
  chartData = [];
  chartXAxisData : Array<string>;


  hasReadings : boolean;
  svgData : Array<string> = [];
  hasReportResult: boolean = false;
  fileUrl: any;
  isLoadingPDFReport: boolean = false;
  currentFuelLevels = [];

  reportDocumentType: string = '';

  base64ClientLogo;


  disabledEndDate = (current: Date): boolean => {

    return differenceInCalendarDays(current, new Date()) > 0;
  };

  index = 0;
  tabs = [];

  tabTableDataArray : Array<any> = [];

  clientID: number;
  selectedClientID: number;

  constructor(private siteService: SitesService,
              private message: NzMessageService,
              private fuelConsumptionService: FuelConsumptionService,
              private zone: NgZone,
              private notification: NzNotificationService,
              private sanitizer: DomSanitizer,
              private http: HttpClient,
              private authenticationService: AuthenticationService,
              private clientsService: ClientsService, private storageService: StorageService
              ) {
  }

  ngOnInit(): void {

    this.siteService.getUserSiteList().subscribe( response => {
      this.siteList = response;
      this.loadingPageData = false;
    }, error => {
     this.siteList = [];
    })

    // this.getSiteList();
    const storageConstants = new Constants().storage;
    this.clientID = Number(this.storageService.getStoredEncodedValue(storageConstants.selectedClient));
    this.getClientLogo();
    //this.initChart([]);
  }

  getClientLogo(): void{
    let clientId = this.authenticationService.getClientId();
    this.http.get(`/assets/icons/client_logos/client_${clientId}.png`, { responseType: 'blob' })
      .subscribe(res => {

        const reader = new FileReader();
        reader.onloadend = () => {
          this.base64ClientLogo = reader.result;
        }

      }, error => {
        this.http.get(`/assets/round_bms_icon.png`, { responseType: 'blob' })
          .subscribe( res => {
            const reader = new FileReader();
            reader.onloadend = () => {
              this.base64ClientLogo = reader.result;
            }
          });
      });

  }


  changeTabIndex(index): void {
    this.index = index;
  }

  onFromChange(result: Date): void {

    if(result !== null){

      let convertedDate = new Date(result.getFullYear(), result.getMonth(), 1);
      this.dateFrom = new GenericHelpers().formatDate(convertedDate);

    }else{

    }


  }

  onToChange(result: Date): void {

    if(result !== null){
      this.dateTo = new GenericHelpers().formatDate(result);

    }else{

    }

  }

  onSiteSelected(a: any) : void{
    let client_id;

    try{

      client_id = this.siteList.find( (site: IUserSiteList) => site.legacy_id === this.selectedSiteId ).client_id.client_id.toString();

    }
    catch (exception){

      client_id = this.siteList.find( (site: IUserSiteList) => site.legacy_id === this.selectedSiteId ).client_id._id;
    }

    this.selectedClientID = client_id;

    // console.log('CLIENT ID: ', response);
    // this.clientsService.getClient(client_id.toString()).subscribe( (response: IClient) => {
    //   console.log('CLIENT ID: ', response);
    //
    //
    //   this.selectedClientID = response.client_id;
    //
    // }, error => {
    //   console.error('Failed to get client details');
    // } );


    // if(exception.toString().includes("TypeError: Cannot read properties of undefined (reading 'client_id')")){
    //   console.error('Error On Site Selection process: ', this.selectedSiteId);
    //   if(this.selectedSiteId === 0){
    //     // All Sites Selected cannot get client ID from Site List
    //     console.info('All Sites Selected cannot get client ID from Site List -> Using the user\'s client instead');
    //     this.selectedClientID = this.clientID;
    //   }
    // }


  }

  getFuelConsumption(): void{


    this.tabs = [];
    this.tabTableDataArray = [];

    // Get all site fuel levels
    this.fuelConsumptionService.getClientFuelLevels().subscribe( response => {

      this.currentFuelLevels = response;

    }, error => {
      console.error(error);
    } );

    if(this.dateTo !== null &&
      this.dateTo !== undefined  &&
      this.dateFrom !== null &&
      this.dateFrom !== undefined && this.selectedSiteId !== undefined){

      this.loadingReport = true;
      this.loadingFuelConsumption = true;
      this.fuelConsumptionService.fuelUsage(this.dateTo, this.dateFrom, this.selectedSiteId, this.selectedClientID).subscribe( (response : IFuelUsage[]) => {


        // console.info("FUEL REPORT",  response);

        if(response.length !== 0 && response[0].data !== undefined){

          this.selectedSite = undefined;
          this.selectedChartSite = undefined;

          this.consumption = response;
          let readingsData = this.consumption[0].data;

          for(let i = 0; i < readingsData.length; i++){

            if(Number(readingsData[i].site_id) !== 89){

              this.tabs.push(readingsData[i].site);
              let tableData = [];


              for(let x in readingsData[i].data){
                let currentSiteReadings = readingsData[i].data[x];

                let siteConsumption = currentSiteReadings.consumption_readings;
                let siteFill = currentSiteReadings.fill_readings;

                let currentTankReadings = [];
                for(let n in siteConsumption){

                  currentTankReadings.push(
                    {
                      date: siteConsumption[n].date,
                      consumption: siteConsumption[n].consumption,
                      fill: siteFill[n].fill_amount
                    }
                  );

                }

                tableData.push({
                  tank_name: currentSiteReadings.tank_name,
                  readings_data: currentTankReadings,
                  total_consumption: currentSiteReadings.total_consumption,
                  total_fill: currentSiteReadings.total_fill,
                });

              }
              this.tabTableDataArray.push(tableData);
            }



          }

          //End Test Area
          let hasXData: boolean = false;
          let siteList = [];
          let chartSeriesData = [];
          let xAxisChartData : Array<string> = [];

          for(let i =0; i < response.length; i++){

            let currentSite = response[i].data;

            for(let x =0; x < currentSite.length; x++){

              if(currentSite[x].data.length > 0){
                siteList.push(currentSite[x].site);

                let currentSiteData = currentSite[x].data;
                let currentSiteChartData = [];

                for(let y =0; y < currentSiteData.length; y++){

                  let colors = ['#1976D2', '#303F9F', '#D32F2F'];

                  let tankName = currentSiteData[y].tank_name;
                  let currentTankData : Array<number> = [];

                  for(let o =0; o < currentSiteData[y].consumption_readings.length; o++){

                    currentTankData.push(Number(currentSiteData[y].consumption_readings[o].consumption));

                    if(!hasXData){
                      xAxisChartData.push(currentSiteData[y].consumption_readings[o].date);
                    }

                  }

                  hasXData = true;

                  currentSiteChartData.push(
                    {
                      name: tankName,
                      data: currentTankData,
                      color: colors[y]
                    }
                  );


                }

                chartSeriesData.push(currentSiteChartData);
              }


            }

          }


          this.chartSiteList = siteList;
          this.chartData = chartSeriesData;
          this.chartXAxisData = xAxisChartData;
          this.selectedSite = siteList[0];
          //Initiate Chart
          this.initChart(this.chartData[0], this.chartXAxisData);

          this.hasLoadedReport = true;
          this.loadingReport = false;
          this.loadingFuelConsumption = false;

          this.hasReadings = true;

          this.notification.info(
            'Report Generated',
            'Scroll to view more...',
            { nzPlacement: 'topLeft' }
          );

        }else{
          this.hasLoadedReport = true;
          this.loadingReport = false;
          this.hasReadings = false;
          this.loadingFuelConsumption = false;

        }
      }, error => {
        console.error(error);
        this.hasLoadedReport = true;
        this.loadingReport = false;
        this.hasReadings = false;
        this.loadingFuelConsumption = false;
      } );

    }else{
      this.message.create('error', `You must select a site as well as the start and end dates!`);
    }
  }

  reloadPage(): void{
   window.location.reload();
  }

  changeChartData(index: number): void{

    let data = this.chartData[index];
    this.selectedSite = this.chartSiteList[index];
    this.initChart(data, this.chartXAxisData);

  }

  initChart(seriesData, xAxisData): void {

    this.chart = new Chart({

      chart: {
        type: 'column',
        zoomType: 'x',
        styledMode: false,
        marginRight: 100,
      },
      exporting : {
        enabled: true,
        csv: {
          itemDelimiter: ','
        }
      },
      title: {
        text: 'Monthly Fuel Consumption'
      },
      subtitle: {
        text: 'Drag out a section of the chart with your mouse pointer to zoom in',
        style: {
          fontSize: '10px'
        }
      },
      xAxis: {
        categories: xAxisData,
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Litres (L)'
        }
      },
      tooltip: {
        style: {
          width: 400
        },
        headerFormat: '<span style="font-size:8px">{point.key}</span><table style="width:150px">',
        pointFormat: '<tr><td style="color:{series.color};padding:0; font-size:8px">{series.name}: </td>' +
          '<td style="padding:0; font-size:8px"><b>{point.y:.1f} L</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          // colorByPoint: true,
          // colors: ["#058DC7" , "#50B432"],
        },
      },
      credits: {
        enabled: false
      },
      series: seriesData,

    });


  }

  renderChartsForReportExport(type: string): void{

    if(type === "excel"){
      this.reportDocumentType = 'csv';
      this.generateCSVReport();
    }
    else{
      this.reportDocumentType = 'pdf';
      for(let i =0; i < this.tabs.length; i++){

        this.chart.ref$.subscribe( data => {

          let newData = this.chartData[i];

          data.series = newData;

          // @ts-ignore

          let svg = data.getSVG({

            chart: {
              type: 'column',
              zoomType: 'x',
              styledMode: false
            },
            exporting : {
              enabled: true,
              csv: {
                itemDelimiter: ','
              }
            },
            title: {
              text: 'Monthly Fuel Consumption'
            },
            xAxis: {
              categories: this.chartXAxisData,
              crosshair: true,
            },
            yAxis: {
              min: 0,
              title: {
                text: 'Litres (L)'
              }
            },
            tooltip: {
              style: {
                width: 400
              },
              headerFormat: '<span style="font-size:8px">{point.key}</span><table style="width:150px">',
              pointFormat: '<tr><td style="color:{series.color};padding:0; font-size:8px">{series.name}: </td>' +
                '<td style="padding:0; font-size:8px"><b>{point.y:.1f} L</b></td></tr>',
              footerFormat: '</table>',
              shared: true,
              useHTML: true
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
                // colorByPoint: true,
                // colors: ["#058DC7" , "#50B432"],
              },
            },
            credits: {
              enabled: false
            },
            series: newData,

          });

          this.svgData.push(svg);
        });

      }
      this.generatePDFInfo(type);
    }






  }

  generatePDFInfo(type: string): void{

    this.isLoadingPDFReport = true;


    let reportTableData = [];

    let clientId = this.authenticationService.getClientId();
    let siteName : string;

    for(let i = 0; i < this.siteList.length; i++){
      if(Number(this.siteList[i].legacy_id) === this.selectedSiteId){
        siteName = this.siteList[i].name;
        break;
      }
    }


    let reportMetaData = {
      siteName: (this.selectedSiteId === 0 ? 'All Sites' : siteName),
      clientName: this.consumption[0].client,
      reportType: "Fuel Report",
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      clientLogo: this.base64ClientLogo
    };

    let totalsTableData = [];

    reportTableData.push(
      {text: "Fuel Totals", style: 'header', pageBreak: 'before', tocItem: true},
      'Fuel consumption and fill totals for the period:  (' + this.dateFrom + ') to (' + this.dateTo + ')'
    );

    // FUEL TOTALS TABLE
    totalsTableData.push(
      [
        {text: 'Site', style: 'tableHeader'},
        {text: 'Total Fuel Consumed (Litres)', style: 'tableHeader'},
        {text: 'Total Fuel Filled (Litres)', style: 'tableHeader'}
      ],

    );

    let overallTotalConsumption = 0;
    let overallTotalFill = 0;

    for(let i = 0; i < this.tabTableDataArray.length; i++){

      let currentDataIndexData = this.tabTableDataArray[i];
      let consumptionTotal = 0;
      let fillTotal = 0;

      for(let x in currentDataIndexData){
        let data = currentDataIndexData[x];
        consumptionTotal += data.total_consumption;
        fillTotal += data.total_fill;
      }

      overallTotalConsumption += consumptionTotal;
      overallTotalFill += fillTotal;

      totalsTableData.push([
        `${this.tabs[i]}`, `${consumptionTotal}`, `${fillTotal}`
      ]);

    }

    totalsTableData.push(
      [
        {text: 'Overall Totals', style: 'tableFooter', 	fillColor: '#eeeeee', bold: true},
        {text:overallTotalConsumption, style: 'tableFooter', fillColor: '#eeeeee', bold: true},
        {text:overallTotalFill, style: 'tableFooter', fillColor: '#eeeeee', bold: true}
      ],
    );

    reportTableData.push(
      {
        style: 'tableExample',
        table: {
          widths: [150, 170, 150],
          body: totalsTableData
        }
      }
    );


    //CURRENT FUEL LEVELS
    reportTableData.push(
      {text: "Current Fuel Levels", style: 'header', pageBreak: 'before', tocItem: true},
      `Current Tank Fuel Levels : ${new Date().toDateString()}`
    );


    let currentLevelsData = [];

    currentLevelsData.push(
      [
        {text: 'Site Name', style: 'tableHeader'},
        {text: 'Fuel Level (Litres)', style: 'tableHeader'}
      ],
    )

    if(this.selectedSiteId === 0){
      for(let i in this.currentFuelLevels){
        currentLevelsData.push([
          `${this.currentFuelLevels[i].site_name}`, `${this.currentFuelLevels[i].site_total_fuel}`
        ]);
      }
    }

    if(this.selectedSiteId !== 0){

      for(let i in this.currentFuelLevels){
        if(Number(this.selectedSiteId) === this.currentFuelLevels[i].site_id){

          currentLevelsData.push([
            `${this.currentFuelLevels[i].site_name}`, `${this.currentFuelLevels[i].site_total_fuel}`
          ]);
          break;
        }
      }
    }

    reportTableData.push(
      {
        style: 'tableExample',
        table: {
          widths: [200, 200],
          body: currentLevelsData
        }
      }
    );


    for(let i in this.tabTableDataArray){


      reportTableData.push(
        {text: `${this.tabs[i]}`, style: 'header', pageBreak: 'before', tocItem: true},
        'Fuel consumption and fill in Litres for ' + this.tabs[i]
      );

      let formattedReadings = [];

      for(let x in this.tabTableDataArray[i]){

        let currentData = this.tabTableDataArray[i][x];


        reportTableData.push(
          {
            text: `${currentData.tank_name}`,
            margin: [ 0, 20,0,0 ],
            style: 'subheader'
          }

        );

        formattedReadings.push(
          [
            {text: 'Date', style: 'tableHeader'},
            {text: 'Fuel Consumed (Litres)', style: 'tableHeader'},
            {text: 'Fuel Filled (Litres)', style: 'tableHeader'}
          ],
        );



        for(let y in currentData.readings_data){
          let currentReadingsData = currentData.readings_data[y];

          formattedReadings.push([
            `${currentReadingsData.date}`, `${currentReadingsData.consumption}`, `${currentReadingsData.fill}`
          ]);

        }

        formattedReadings.push(
          [
            {text: 'Totals', style: 'tableFooter', 	fillColor: '#eeeeee', bold: true},
            {text: currentData.total_consumption, style: 'tableFooter', fillColor: '#eeeeee', bold: true},
            {text: currentData.total_fill, style: 'tableFooter', fillColor: '#eeeeee', bold: true}
          ],
        );

        reportTableData.push(
          {
            style: 'tableExample',
            table: {
              widths: [150, 150, 150],
              body: formattedReadings
            }
          }
        );

        formattedReadings = [];
      }

      reportTableData.push(
        {
          svg: this.svgData[i],
          width: 460,
          margin: [ 0, 40,0,0 ]
        }
      );


    }

    this.fuelConsumptionService.generateFuelReportPdf(reportTableData, reportMetaData).subscribe( response => {
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(encodeURI(`data:image/png;base64,${encodeURI(response)}`));
      this.hasReportResult = true;
      this.isLoadingPDFReport = false;
    }, error => {
      console.error('REPORT: ', error);
    } );


    // this.http.get(`/assets/icons/client_logos/client_${clientId}.png`, { responseType: 'blob' })
    //   .subscribe(res => {
    //
    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //       let base64Image = reader.result;
    //
    //
    //     };
    //
    //     reader.readAsDataURL(res);
    //
    //   }, error => {
    //
    //   });

  }

  generateCSVReport(): void {
    this.isLoadingPDFReport = true;

    console.log(this.consumption)
    let fuelData = this.consumption[0].data;

    let csvFormatted: Array<{
      site: string;
      tank: string;
      date: string;
      consumption: number;
      fill_amount: number;
    }> = [];

    for(let i = 0; i < fuelData.length; i++){

      for(let x = 0; x < fuelData[i].data.length; x++){
        let tankData = fuelData[i].data[x];
        console.log('TANK DATA :',fuelData[i].site, tankData);
        // console.log(fuelData[i].site);
        for(let y = 0; y < tankData.consumption_readings.length; y++){

          let consumptionData = tankData.consumption_readings[y];
          let fillAmountReadings = tankData.fill_readings[y];

          csvFormatted.push(
            {
              site: fuelData[i].site,
              tank: tankData.tank_name,
              date: consumptionData.date,
              consumption: consumptionData.consumption,
              fill_amount: fillAmountReadings.fill_amount
            }
          );

        }
      }
    }

    console.log('CSV DATA ::: ', csvFormatted);

    this.fuelConsumptionService.generateFuelReportCSV(csvFormatted).subscribe( response => {

      this.fileUrl = this.sanitizer
        .bypassSecurityTrustResourceUrl(`data:text/csv;charset=utf-8,${ encodeURI(response)}`);
      this.hasReportResult = true;

      this.isLoadingPDFReport = false;
    }, error => {
      console.error('REPORT: ', error);
    } );

  }

}
