import { Injectable } from '@angular/core';
import {Constants} from '../../utils/Contstants';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {StorageService} from '../storage/storage.service';
import {IAnalogDevice} from '../../Models/IAnalogDevice';
import {Observable, throwError} from 'rxjs';
import {IHvac} from '../../Models/DeviceInstances/IHvac';
import {catchError, retry} from 'rxjs/operators';
import {IAnalogDeviceInstance} from '../../Models/IAnalogDeviceInstance';

@Injectable({
  providedIn: 'root'
})
export class AnalogDevicesService {

  private baseUrl = new Constants().noderedInternal;
  private storageConstants = new Constants().storage;
  private token = this.storageService.getStoredEncodedValue(this.storageConstants.token);
  private clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);


  constructor(private http: HttpClient, private storageService: StorageService) { }

  public filterDevices(devices : IAnalogDevice[]) : IAnalogDevice[]{

    let response : IAnalogDevice[] = [];

    for(let i = 0; i < devices.length; i++){
      if(devices[i].device_type !== "Universal Controller"
        && devices[i].device_type !== "Utility"
        // && devices[i].device_type !== 'Distribution'
        && devices[i].device_type !== 'Door'
        && devices[i].device_type !== 'Site Management'
        && devices[i].device_type !== 'Gas Generator'
        && devices[i].device_type !== 'Fire Panel'
        && devices[i].device_type !== 'Water Sensor'
        && devices[i].device_type !== 'Liquid Sensor'
        && devices[i].device_type !== ''
        && devices[i].device_type !== 'Controller'){

        response.push(devices[i]);
      }
    }

    return response;

  }

  public extractDeviceInstances(devices: IAnalogDevice[]): Array<IAnalogDeviceInstance[]>{

    let instances : Array<IAnalogDeviceInstance[]> = [];

    for (let i in devices){
      instances.push(devices[i].instances);
    }

    return instances;

  }

  public siteDevicesAndInstances(siteId): Observable<IAnalogDevice[]>{

    const client = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    // return this.http.get<IAnalogDevice[]>
    // (`${this.baseUrl}devicesAndInstances/${this.token}/${client}/${siteId}`);

    // return this.http.get<IAnalogDevice[]>
    // (`${this.baseUrl}devicesAndInstances/a/b/${siteId}`);

    // return this.http.get<IAnalogDevice[]>
    // (`https://nodered.cloudbms.co.za/api/new_formatted_DeviceList?site_id=${siteId}`);

    return this.http.get<IAnalogDevice[]>
    (`${this.baseUrl}new_formatted_DeviceList_withStatus?site_id=${siteId}`);

  }

  public deviceInstanceReadings(siteId: any, deviceTypeId: number, controllerId: number,instanceId: number) :
    Observable<any>{

    const client = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    let headers = new HttpHeaders().set(
      'Keep-Alive', 'timeout=10, max=20000'
    );

    // return this.http.get<any>(`${this.baseUrl}deviceInstanceReadings/${client}/${siteId}/${deviceTypeId}/${controllerId}/${instanceId}`).pipe(
    //   retry(3), // retry a failed request up to 3 times
    //   catchError( AnalogDevicesService.handleError ), // then handle the error
    //
    // );

    return this.http.get<any>(`${this.baseUrl}analog_device_realtime?site_id=${siteId}&device_type=${deviceTypeId}&device_instance=${instanceId}&controller_id=${controllerId}`).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError( AnalogDevicesService.handleError ), // then handle the error

    );

  }
  private static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again.');
  };

}
