import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss']
})
export class CreateTicketComponent implements OnInit {

  minDate = new Date();

  ticketForm;
  editorConfig = {
    alignment: {
      options: [ 'left', 'right' ]
    },
    toolbar: [
      'heading','|',
      'bulletedList','numberedList','numberedList','undo', 'redo', '|',
      'bold','italic','|',
      'link','unlink','|',
      'insertTable'
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
