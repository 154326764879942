import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ISite, IUser} from '../../../Models/IUser';
import {IUserNotificationSettings, IUserNotificationSite} from '../../../Models/IUserNotificationSettings';
import {UserNotificationsService} from '../../../services/user_notifications/user-notifications.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-user-notification-settings',
  templateUrl: './user-notification-settings.component.html',
  styleUrls: ['./user-notification-settings.component.scss']
})
export class UserNotificationSettingsComponent implements OnInit, OnChanges, OnDestroy {

  initialising: boolean = true;

  @Input() currentUser: IUser;

  settings: IUserNotificationSettings;
  searchText: string;

  constructor(private userNotificationService : UserNotificationsService, private nzNotificationService: NzNotificationService,) { }

  ngOnInit(): void {
    // console.info("User Notification Settings Component Initialised");
  }

  ngOnDestroy(): void {
    // console.info("User Notification Settings Component Destroyed");
  }

  ngOnChanges(changes: SimpleChanges): void{
    if(changes.hasOwnProperty('currentUser')){
      // if(changes.currentUser.currentValue !== 'undefined'){
      if(changes.currentUser.currentValue !== undefined){
        this.getUserNotificationSettings();
      }
    }
  }

  getUserNotificationSettings() : void {

    this.userNotificationService.getUserNotificationSettings(this.currentUser._id).subscribe(response => {
      if(response.sites !== null){
        this.settings = response;

      }else{
        this.settings = response;
        this.settings.sites = [];
      }
      this.initialising = false;

    }, error => {

      this.settings = {
        user_id: this.currentUser._id,
        sites: [],
        silenced_alarm_notifications: []
      }
      this.initialising = false;
    });
  }

  checkSiteSelected(site_id: string) : boolean{

    const found = this.settings.sites.find((site: IUserNotificationSite) => site.id === site_id );
    return found !== undefined;

  }

  checkPrioritySelected(priority: string, site_id: string): boolean{
    const found = this.settings.sites.find((site: IUserNotificationSite) => site.id === site_id && site.priorities.includes(priority));
    return found !== undefined;
  }

  siteSwitchChanged(checked: boolean, site_id: string): void{

    if(checked){
      this.settings.sites.push({id: site_id, priorities: ['Critical', 'Urgent', 'Warning', 'Event']});
      this.updateUserNotificationSettings();

    }else{
      let newSiteList: IUserNotificationSite[] = [...this.settings.sites];
      let index = this.settings.sites.findIndex( (site: IUserNotificationSite) => site.id == site_id );
      if(index !== -1){
        newSiteList.splice(index, 1);
        this.settings.sites = newSiteList;
        this.updateUserNotificationSettings();
      }

    }


  }

  prioritySwitchChanged(checked: boolean, priority: string, site_id: string): void{
    if(checked){

      let index = this.settings.sites.findIndex( (site: IUserNotificationSite) => site.id === site_id );
      if(index !== -1){
        this.settings.sites[index].priorities.push(priority);
        this.updateUserNotificationSettings();
      }

    }else {
      let index = this.settings.sites.findIndex( (site: IUserNotificationSite) => site.id === site_id );
      if(index !== -1){
        let newPriorityList: string[] = [...this.settings.sites[index].priorities];
        let priorityIndex = this.settings.sites[index].priorities.indexOf(priority);
        if(priorityIndex != -1){
          newPriorityList.splice(priorityIndex, 1);
          this.settings.sites[index].priorities = newPriorityList;
          this.updateUserNotificationSettings();
        }
      }
    }
  }

  deselectAllEvent(): void{
    this.settings.sites = [];
    this.updateUserNotificationSettings();
  }

  selectAllEvent(): void{
    this.settings.sites = this.currentUser.sites.map((site: ISite) => ({id: site._id, priorities: ['Critical', 'Urgent', 'Warning', 'Event']}));
    this.updateUserNotificationSettings();
  }

  updateUserNotificationSettings(){
    this.userNotificationService.updateUserNotificationSettings(this.settings).subscribe(() => {
      this.nzNotificationService.success(
        'Operation Successful',
        'Your preferences have been updated',
        {
          nzPlacement: 'topRight'
        }
      );
    }, error => {
      // console.error('Error in updating alarm notification settings', error);
      this.nzNotificationService.error(
        'Operation Failed',
        'An error occurred during this process, please try again',
        {
          nzPlacement: 'topRight'
        }
      );
    });
  }


}
