import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../utils/Contstants';
import {StorageService} from '../storage/storage.service';
import {TicketComment} from '../../Models/Tickets/TicketComment';
import {RejectedTicket} from '../../Models/Tickets/RejectedTicket';
import {ClosedTicket} from '../../Models/Tickets/ClosedTicket';
import {ITicket} from '../../Models/Tickets/ITicket';
import {TicketLog} from '../../Models/Tickets/TicketLog';
import {ITicketModel} from '../../Models/Tickets/ITicketModel';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  private baseUrl = new Constants().baseUrl;
  private storageConstants = new Constants().storage;

  constructor(private http: HttpClient, private storageService: StorageService) { }

  public createTicket(ticket: ITicketModel): Observable<any>{

    // return this.http.post<any>(`http://mptdev.dedicated.co.za:8000/api/tickets/`, {
    //   ...ticket
    // });

    // let notification: any = {
    //   title: "Ticket Created And Assigned to You",
    //   body: ticket.description,
    //   is_read: false,
    //   user_id: ticket.assigned_to._id,
    // };

    // this.sendNotification(notification);

    return this.http.post<any>(`http://mptdev.dedicated.co.za:8000/api/tickets/`, {
      ...ticket
    });
  }

  private sendNotification(notification: {
    title: string;
    body: string;
    is_read: boolean;
    user_id: string;
  }): void{

    this.http.post('http://localhost:8000/api/new_notification', notification ).subscribe(res => {

    }, error => {
      console.error("Notification Error: ", error);
    });
  }

  public create(ticket): Observable<JSON>{
    return this.http.post<JSON>(`${this.baseUrl}ticket`, {
      ...ticket
    });
  }

  public update(ticket_id, data): Observable<any>{
    return this.http.put<any>(`${this.baseUrl}ticket/${ticket_id}`, data);
  }

  public siteTickets(site_id): Observable<ITicket[]>{
    return this.http.get<ITicket[]>(`${this.baseUrl}siteTickets/${site_id}`);
  }

  public clientTickets(): Observable<ITicket[]>{

    const client = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    return this.http.get<ITicket[]>(`${this.baseUrl}clientTickets/${client}`);
  }

  public getTicketDetails(id: number): Observable<ITicket>{
    return this.http.get<ITicket>(`${this.baseUrl}ticket?id=${id}`);
  }

  public read(id?, status?, urgency?, assigned_user_id?): Observable<any>{

    let filter: string = '';

    if(id){
      filter = `?id=${id}`;
    }

    if(status){
      filter = `?id=${status}`;
    }

    if(urgency){
      filter = `?id=${urgency}`;
    }

    if(assigned_user_id){
      filter = `?id=${assigned_user_id}`;
    }

    return this.http.get<any>(`${this.baseUrl}ticket${filter}`);
  }

  public createComment(comment: TicketComment): Observable<any>{

    return this.http.post<any>(`${this.baseUrl}ticketComment`, comment);

  }

  public getComments(ticket_id): Observable<TicketComment[]>{

    return this.http.get<TicketComment[]>(`${this.baseUrl}ticketComment/${ticket_id}`);

  }

  public close(closedTicket: ClosedTicket, id): Observable<any>{

    return this.http.post<any>(`${this.baseUrl}ticket/${id}`, closedTicket);

  }

  public reject(rejectedTicket: RejectedTicket, id): Observable<any>{

    return this.http.post<any>(`${this.baseUrl}ticket/reject/${id}`, rejectedTicket);

  }

  public getTicketByReferenceAndEmail(reference: string, email: string): Observable<{ticket_id : number, user_id: number}>{

    return this.http.post<{ticket_id: number, user_id: number}>(`${this.baseUrl}getTicketByReferenceAndEmail`, {
      reference: reference,
      email: email
    });

  }


  public getTicketLogs(ticket_id: number): Observable<TicketLog[]>{

    return this.http.get<TicketLog[]>(`${this.baseUrl}ticket_logs/${ticket_id}`);

  }

}
