<div class="admin-layout">
  <div class="admin-navbar"><app-header style="width: 90%;"></app-header></div>

  <div class="content-area">

    <div class="main-content-area">
      <router-outlet></router-outlet>
    </div>

<!--    <div class="notification-bar">-->
<!--      <app-notification-bar></app-notification-bar>-->
<!--    </div>-->

  </div>
  <app-footer></app-footer>
</div>


<nz-drawer [nzClosable]="false" [nzVisible]="sideNavVisible" [nzPlacement]="'left'"
           nzTitle="Cloud BMS" (nzOnClose)="closeSideNav()">

  <ul nz-menu nzMode="inline">
    <li nz-menu-item nzSelected routerLink="/admin">Dashboard</li>
    <li nz-menu-item routerLink="/admin/user-management">Users</li>
    <li nz-menu-item routerLink="/admin/tickets">Tickets</li>
    <li nz-submenu nzTitle="Reports" nzIcon="mail" nzOpen>
      <ul>
        <li nz-menu-group>
          <ul>
            <li nz-menu-item routerLink="/admin/reports/generator-report">Generator Report</li>
            <li nz-menu-item routerLink="/admin/reports/fuel-consumption">Fuel Report</li>
            <li nz-menu-item routerLink="/admin/reports/temperature-readings">Temperature Readings</li>
          </ul>
        </li>
      </ul>
    </li>

  </ul>

</nz-drawer>

<nz-modal [(nzVisible)]="sessionModalVisible" nzClosable="false" [nzFooter]="null"
          nzTitle="Session Expired">
  <ng-container *nzModalContent>
    <p>Your session has expired or you have no session. You will be redirected to Login {{count}} seconds </p>
  </ng-container>
</nz-modal>
