import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {AlarmsService} from '../../../services/alarms/alarms.service';
import {AlarmHelper} from '../../../helpers/AlarmHelper';

import {IAlarmNotification} from '../../../Models/notifications/IAlarmNotification';
import {NotificationsService} from '../../../services/notifications/notifications.service';
import {ISiteAlarmNotificationCountTotals} from '../../../Models/notifications/ISiteAlarmNotificationCount';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ITicketModel} from '../../../Models/Tickets/ITicketModel';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import { TicketService } from 'src/app/services/tickets/ticket.service';
import {WebSocketService} from '../../../services/web-sockets/web-socket.service';
import {Subscription} from 'rxjs';

import {trigger, state, style, animate, transition} from '@angular/animations';
import {UserService} from '../../../services/user/user.service';
import {IUser} from '../../../Models/IUser';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('100ms ease-out')),
      transition('default => rotated', animate('400ms ease-in'))
    ])
  ]
})
export class NotificationBarComponent implements OnInit, OnDestroy {

  //LOADING BOOLEANS
  isLoadingData: boolean = false;
  isAcknowledging: boolean = false;

  showCreateTicketModal: boolean = false;
  selectedNotification: IAlarmNotification;

  notificationDrawerVisible: boolean = false;


  subscribedSocket: Subscription;
  @ViewChild('notificationBox', { static: true }) notificationBox: TemplateRef<{}>;

  siteNotificationCounts: ISiteAlarmNotificationCountTotals;
  recentNotifications: IAlarmNotification[];
  currentMutedList: IAlarmNotification[] = [];

  openDrawerIconState: string = 'default';

  audio = new Audio("assets/sounds/notification.mp3");

  users: IUser[];

  constructor(private nzNotificationService: NzNotificationService,
              private alarmService: AlarmsService,
              private notificationsService: NotificationsService,
              private authService: AuthenticationService,
              private ticketService: TicketService,
              private webSocketService: WebSocketService,
              private userService: UserService) { }

  ngOnInit(): void {

    this.subscribedSocket = this.webSocketService
      .listen('message')
      .subscribe( (message: IAlarmNotification) =>{

        console.log('message from socket: ', message);


      // if(JSON.stringify(message).includes('client_id')){
      //   if(!message.is_muted){
      //
      //     this.audio.play();
      //
      //     this.showNotification(message);
      //     this.recentNotifications.push(message);
      //
      //     switch (message.alarm_priority){
      //       case "Critical": {
      //         this.siteNotificationCounts.totals.critical += 1;
      //         break;
      //       }
      //       case "Urgent": {
      //         this.siteNotificationCounts.totals.urgent += 1;
      //         break;
      //       }
      //       case "Warning": {
      //         this.siteNotificationCounts.totals.warning += 1;
      //         break;
      //       }
      //       case "Event": {
      //         this.siteNotificationCounts.totals.event += 1;
      //         break;
      //       }
      //     }
      //
      //   }else{
      //     this.currentMutedList.push(message);
      //   }
      // }


    }, error => {
      console.error(error);
    });



    this.getRecentNotifications();


    this.notificationsService.getClientSiteUnattendedAlarmNotificationCounts()
      .subscribe(response =>{

        this.siteNotificationCounts = response;
      }, error => {
        console.error(error);
      });



  }

  ngOnDestroy() {
    this.subscribedSocket.unsubscribe();
  }

  getRecentNotifications(): void{
    this.notificationsService.getRecentUnattendedAlarmNotifications()
      .subscribe(response => {
        this.recentNotifications = response.filter( notification => !notification.is_muted ).sort((a,b) => new Date(b.triggered_time).getTime() - new Date(a.triggered_time).getTime());
        this.currentMutedList = response.filter( notification => notification.is_muted ).sort((a,b) => new Date(b.triggered_time).getTime() - new Date(a.triggered_time).getTime());
      }, error => {
        console.error(error);
      });
  }

  showNotification(notification: IAlarmNotification): void{

   let key  = this.nzNotificationService.template(this.notificationBox,
      {
        nzData: {...notification},
        nzKey: notification._id,
        nzDuration: 0,
        nzStyle: {
          width: '650px',
          marginLeft: '-265px',
        }
      }
    );

   key.messageId = notification._id;
  }

  showNotificationDrawer(type: string): void{
    this.notificationDrawerVisible = !this.notificationDrawerVisible;
    this.openDrawerIconState = (this.openDrawerIconState === 'default' ? 'rotated' : 'default');

  }

  closeNotificationDrawer(): void{
    this.notificationDrawerVisible = false;
    this.openDrawerIconState = "default";
  }

  openCreateTicketModal(notification: IAlarmNotification): void{
    this.selectedNotification = notification;
    this.showCreateTicketModal = true;
  }

  closeTicketModal(): void{
    // this.ticketForm.reset();
    this.showCreateTicketModal = false;
  }

  acknowledgeNotification(notification: IAlarmNotification): void{
    this.isAcknowledging = true;


    this.notificationsService.acknowledgeAlarmNotification(notification)
      .subscribe(response =>{

        this.isAcknowledging = false;
        this.nzNotificationService.success(
          'Operation Successful',
          'This Alarm has been Acknowledged'
        );

        this.getRecentNotifications();
        this.nzNotificationService.remove(notification._id);

      }, error => {
        this.isAcknowledging = false;
        this.nzNotificationService.error(
          'Operation Failed',
          'Failed to Acknowledge this alarm'
        );
      });
  }

  muteNotification(notification: IAlarmNotification): void{

    this.notificationsService.muteNotification(notification)
      .subscribe(response =>{

        //Remove the object from the list and put it in the currentMutedList
        const index: number = this.recentNotifications.indexOf(notification);
        if(index > -1){
          this.recentNotifications.splice(index,1);
          this.currentMutedList.push(notification);
        }
        this.nzNotificationService.remove(notification._id);

        this.nzNotificationService.success(
          'Operation Successful',
          'This Alarm has been Muted'
        );
      }, error => {
        console.error(error);
        this.nzNotificationService.error(
          'Operation Failed',
          'Failed to Mute this alarm'
        );
      });

  }

  handleRequestRefreshEvent($event): void{
    this.getRecentNotifications();
  }


  getAlarmColor(type: string): string{
    return new AlarmHelper().getAlarmTypeColor(type);
  }
}
