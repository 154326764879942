<nz-card [nzBordered]="false"
         [nzBodyStyle]="{'padding': 0, 'height': '85vh'}">

  <div class="map match-parent" id="map">
  </div>
</nz-card>



<nz-modal [(nzVisible)]="showAlarmsModal"
          [nzTitle]="currentAlarm?.site.name"
          (nzOnCancel)="showAlarmsModal = false"
          (nzOnOk)="showAlarmsModal = false" nzWidth="600">
  <ng-container>
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-3">
        <nz-statistic [nzValue]="(currentAlarm?.alarms.critical | number)!"
                      [nzTitle]="'Critical Alarms'" [nzSuffix]="critical_suffix"></nz-statistic>

        <ng-template #critical_suffix>
          <i nz-icon nzType="exclamation-circle" nzTheme="outline" class="text-danger"></i>
        </ng-template>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <nz-statistic [nzValue]="(currentAlarm?.alarms.urgent | number)!"
                      [nzTitle]="'Urgent Alarms'" [nzSuffix]="urgent_suffix"></nz-statistic>

        <ng-template #urgent_suffix>
          <i nz-icon nzType="exclamation-circle" nzTheme="outline" style="color: #ffa000;"></i>
        </ng-template>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <nz-statistic [nzValue]="(currentAlarm?.alarms.warning | number)!"
                      [nzTitle]="'Warnings'" [nzSuffix]="warnings_suffix"></nz-statistic>

        <ng-template #warnings_suffix>
          <i nz-icon nzType="exclamation-circle" nzTheme="outline" class="text-warning"></i>
        </ng-template>
      </div>

      <div class="col-lg-3 col-md-3 col-sm-3">
        <nz-statistic [nzValue]="(currentAlarm?.alarms.event | number)!"
                      [nzTitle]="'Events'" [nzSuffix]="events_suffix"></nz-statistic>

        <ng-template #events_suffix>
          <i nz-icon nzType="exclamation-circle" nzTheme="outline" class="text-primary"></i>
        </ng-template>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <nz-table #basicTable
                  [nzPageSize]="250" [nzScroll]="{ y: '40vh' }"
                  [nzShowPagination]="false" nzSize="small">
          <thead>
            <tr>
              <th>Description</th>
              <th>Device</th>
            </tr>
          </thead>
          <tbody>

          <tr *ngFor="let data of currentAlarm?.alarmList;">
            <td>{{ data.alarm_name }}</td>
            <td>{{ data.alarm_type_name }}</td>
          </tr>
          </tbody>
        </nz-table>
      </div>
    </div>

  </ng-container>
</nz-modal>

<!--<ng-template #template let-notification>-->
<!--  <div class="ant-notification-notice-content">-->
<!--    <div>-->
<!--      <div class="ant-notification-notice-message">Error Loading Map Data</div>-->
<!--      <div class="ant-notification-notice-description">-->
<!--        An error occurred loading data on the map - Please click refresh to try again-->
<!--      </div>-->
<!--      <span class="ant-notification-notice-btn">-->
<!--            <button nz-button nzType="primary" nzSize="small" (click)="getMapData(); notification.close();">-->
<!--              <span>Retry</span>-->
<!--            </button>-->
<!--          </span>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

