<div style="background: #ffffff; padding: 30px;">
  <div nz-row>

    <div nz-col nzSpan="12">
      <h6 class="mb-3">
        <span class="text-lead" style="font-size: small;">Last Reading</span><br>
        {{currentDeviceReadings?.timestamp |  date:'medium' }}
      </h6>
    </div>

    <div nz-col nzSpan="12">
      <button nz-button nzType="primary" class="float-right" (click)="getDeviceReadings()" >
        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
        Get Current Readings
      </button>
    </div>

  </div>

  <nz-divider></nz-divider>

  <div class="container" *ngIf="!hasReading">
    <nz-result nzStatus="404" nzTitle="404"
               nzSubTitle="Sorry, it seems as though there is no data available for this device.">

    </nz-result>
  </div>

  <nz-card class="full-width" [nzLoading]="isLoadingData" nzBordered="false">
    <nz-row [nzGutter]="16" *ngIf="hasReading">
      <nz-col [nzSpan]="8">
        <nz-statistic [nzValue]="(currentDeviceReadings?.current)!"
                      [nzTitle]="'Rectifier Current'"
                      [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
      </nz-col>

      <nz-col [nzSpan]="8">
        <nz-statistic [nzValue]="(currentDeviceReadings?.voltage)!"
                      [nzTitle]="'Rectifier Voltage'"
                      [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
      </nz-col>

      <nz-col [nzSpan]="8">
        <nz-statistic [nzValue]="(currentDeviceReadings?.rated_capacity)!"
                      [nzTitle]="'Rated Capacity'"
                      [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
      </nz-col>
    </nz-row>

    <nz-row [nzGutter]="16"  class="mt-2"
            *ngIf="hasReading && currentDeviceReadings?.used_capacity != undefined">
      <nz-col [nzSpan]="8">
        <nz-statistic [nzValue]="(currentDeviceReadings?.battery_voltage)!"
                      [nzTitle]="'Battery Voltage'"
                      [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
      </nz-col>

      <nz-col [nzSpan]="8">
        <nz-statistic [nzValue]="(currentDeviceReadings?.battery_current)!"
                      [nzTitle]="'Battery Current'"
                      [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
      </nz-col>

      <nz-col [nzSpan]="8">
        <nz-statistic [nzValue]="(currentDeviceReadings?.used_capacity)!"
                      [nzTitle]="'Used Capacity'"
                      [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
      </nz-col>
    </nz-row>

    <nz-row [nzGutter]="16"  class="mt-2">

      <nz-col [nzSpan]="8">
        <nz-statistic [nzValue]="(currentDeviceReadings?.calculated_used_capacity)!"
                      [nzTitle]="'Used Capacity (kW)'"
                      [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
      </nz-col>
    </nz-row>
  </nz-card>



</div>
