import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {IFuel} from '../../../Models/DeviceInstances/IFuel';
import {interval, Subscription} from 'rxjs';
import {IGenerator} from '../../../Models/DeviceInstances/IGenerator';
import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';

@Component({
  selector: 'app-fuel-info',
  templateUrl: './fuel-info.component.html',
  styleUrls: ['./fuel-info.component.scss']
})
export class FuelInfoComponent implements OnInit, OnDestroy {

  hasReading: boolean;

  @Output() failedReading = new EventEmitter<boolean>();

  @Input() readings : IDeviceReadingsProps;

  requestInterval = interval(10000);
  intervalSubscriber: Subscription;
  currentDeviceReadings : IFuel;

  isLoadingData : boolean;

  constructor(private analogDeviceService: AnalogDevicesService) { }

  ngOnInit(): void {

    this.isLoadingData = true;
    this.getDeviceReadings();
    this.hasReading = this.readings != null;
  }

  emitReadingError(): void{

    this.failedReading.emit(true);

  }

  getDeviceReadings(): void{

    this.isLoadingData = true;

    this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId, this.readings.selectedDeviceTypeId, this.readings.controllerId, this.readings.instanceId
    ).subscribe( response => {

      this.currentDeviceReadings = response;
      if(response.timestamp === null){
        this.currentDeviceReadings.timestamp = new Date();
      }
      this.isLoadingData = false;

    }, error => {
      console.warn('Fuel', error);
    }, () => {

    });


  }

  ngOnDestroy(): void {

    //this.intervalSubscriber.unsubscribe();
  }


}
