import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationsService} from '../../../../services/notifications/notifications.service';
import {IAlarmNotification} from '../../../../Models/notifications/IAlarmNotification';
import {AlarmHelper} from '../../../../helpers/AlarmHelper';
import {ISiteAlarmNotificationCount} from '../../../../Models/notifications/ISiteAlarmNotificationCount';

@Component({
  selector: 'app-site-unattended-notifications',
  templateUrl: './site-unattended-notifications.component.html',
  styleUrls: ['./site-unattended-notifications.component.scss']
})
export class SiteUnattendedNotificationsComponent implements OnInit {

  isLoadingNotifications: boolean = false;

  @Input() details: ISiteAlarmNotificationCount;
  @Output() showSiteList: EventEmitter<boolean> = new EventEmitter<boolean>();

  notifications: IAlarmNotification[];

  constructor(private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications(): void{
    this.isLoadingNotifications = true;
    this.notificationService.getSiteUnattendedAlarmNotifications(this.details.site_id)
      .subscribe(response =>{
        this.notifications = response.filter( notification => !notification.is_muted ).sort((a,b) => new Date(b.triggered_time).getTime() - new Date(a.triggered_time).getTime());

        this.isLoadingNotifications = false;

      }, error => {
        console.error(error)
        this.isLoadingNotifications = false;
      });
  }

  goBack(): void{
    this.showSiteList.emit(true);

  }

  handleAcknowledgedNotificationEvent($event): void{
    this.getNotifications();
  }

}
