import { Component, OnInit } from '@angular/core';
import {ISite} from '../../../Models/ISite';
import {SitesService} from '../../../services/sites/sites.service';
import {HvacDashboardsService} from '../../../services/dashboards/hvacs/hvac-dashboards.service';
import {ISiteHVACData} from '../../../Models/Dashboards/ISiteHVACData';
import {IDashboardPageHeaderConfig} from '../../../Models/IDashboardPageHeaderConfig';
import {Constants} from '../../../utils/Contstants';
import {DeviceDashboardSignature} from '../../../utils/ClassSignatures/DeviceDashboardSignature';
import {IUserSiteList} from '../../../Models/IUserSiteList';

@Component({
  selector: 'app-hvac-dashboard',
  templateUrl: './hvac-dashboard.component.html',
  styleUrls: ['./hvac-dashboard.component.scss']
})
export class HvacDashboardComponent implements OnInit, DeviceDashboardSignature {

  isLoadingData: boolean;
  isLoadingHvacData:boolean;
  hasLoadedHvacData: boolean;

  // selectedSite: ISite;
  selectedSite: IUserSiteList;

  hvacData: ISiteHVACData;

  headerConfig: IDashboardPageHeaderConfig = {
    device_type: new Constants().deviceTypeIds.hvac,
    device_group: 'HVAC',
    statistics: []
  }

  constructor(private hvacDashboardService: HvacDashboardsService,
              private sitesService: SitesService) { }

  ngOnInit(): void {

    this.isLoadingData = true;

  }


  fetchSiteDeviceReadings(site_id: string): void {

    this.isLoadingHvacData = true;

    this.hvacDashboardService.getSiteHVACData(site_id).subscribe( (response: ISiteHVACData) =>{

      if(response !== null){

        this.hvacData = response;
        this.isLoadingHvacData = false;
        this.hasLoadedHvacData = true;
        this.isLoadingData = false;

      }else{
        this.isLoadingData = false;
        this.isLoadingHvacData = false;
        this.hasLoadedHvacData = false;
      }

    }, error => {
      console.error('Error Retrieving HVAC data: ', error);
      this.isLoadingData = false;
      this.isLoadingHvacData = false;
      this.hasLoadedHvacData = false;
    });

  }

  handleSiteChangeEmitter(site: IUserSiteList): void {

    this.selectedSite = site;
    if(site.legacy_id !== undefined){
      this.fetchSiteDeviceReadings(site.legacy_id.toString());
    }
    else{
      this.fetchSiteDeviceReadings(site._id);
    }

  }


}
