<nz-page-header [nzGhost]="false" class="mt-5">
  <nz-page-header-title>Generator Report</nz-page-header-title>
  <nz-page-header-content class="page-header-content">

    <div>
      <nz-select nzShowSearch nzAllowClear
                 nzPlaceHolder="Select a Site"
                 [(ngModel)]="selectedGenerator">
        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
      </nz-select>
    </div>

    <div>
      <nz-select nzShowSearch nzAllowClear
                 nzPlaceHolder="Select a Generator"
                 [(ngModel)]="selectedGenerator">
        <nz-option nzLabel="Jack" nzValue="jack"></nz-option>
        <nz-option nzLabel="Lucy" nzValue="lucy"></nz-option>
        <nz-option nzLabel="Tom" nzValue="tom"></nz-option>
      </nz-select>
    </div>

    <nz-range-picker [nzShowTime]="true"
                     [(ngModel)]="selectedRange"
                     (ngModelChange)="handleDateSelected($event)"
    ></nz-range-picker>

    <div>
      <button nz-button nzType="primary" [disabled]="!hasSelectedRange">Generate Report</button>
    </div>


  </nz-page-header-content>
</nz-page-header>
