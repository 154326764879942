import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IActiveAlarm} from '../../../Models/IActiveAlarm';
import {AlarmHelper} from '../../../helpers/AlarmHelper';

@Component({
  selector: 'app-alarm-list-item',
  templateUrl: './alarm-list-item.component.html',
  styleUrls: ['./alarm-list-item.component.scss']
})
export class AlarmListItemComponent implements OnInit {


  @Input() alarm: IActiveAlarm;
  @Input() canAction: boolean;
  @Input() isSilenced: boolean;
  @Input() canEscalate: boolean;
  @Input() hasCount: boolean;
  @Input() numberOfOccurrences: number;

  @Output() onCreationStarted: EventEmitter<IActiveAlarm> = new EventEmitter<IActiveAlarm>();
  @Output() onSilence: EventEmitter<{ alarm: IActiveAlarm, shouldSilence: boolean }> = new EventEmitter<{ alarm: IActiveAlarm, shouldSilence: boolean }>();
  @Output() onAcknowledged: EventEmitter<IActiveAlarm> = new EventEmitter<IActiveAlarm>();



  date: Date = new Date();

  constructor() { }

  ngOnInit(): void {

  }

  silenceAlarm(): void{
    if(!this.isSilenced){
      this.onSilence.emit({
        alarm: this.alarm,
        shouldSilence: true
      });
    }else{

      this.onSilence.emit({
        alarm: this.alarm,
        shouldSilence: false
      });
    }
  }

  createTask(): void{
    this.onCreationStarted.emit(this.alarm);
  }

  getAlarmColor(type: string): string{
    return new AlarmHelper().getAlarmTypeColor(type);
  }

  acknowledgeAlarm(): void{
    this.onAcknowledged.emit(this.alarm);
  }

}
