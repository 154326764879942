import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IAlarmNotification} from '../../../Models/notifications/IAlarmNotification';
import {AlarmHelper} from '../../../helpers/AlarmHelper';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {AlarmsService} from '../../../services/alarms/alarms.service';
import {NotificationsService} from '../../../services/notifications/notifications.service';
import {TicketService} from '../../../services/tickets/ticket.service';
import {ITicketModel} from '../../../Models/Tickets/ITicketModel';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {UserService} from '../../../services/user/user.service';
import {IUser} from '../../../Models/IUser';

@Component({
  selector: 'app-notification-list-item',
  templateUrl: './notification-list-item.component.html',
  styleUrls: ['./notification-list-item.component.scss']
})
export class NotificationListItemComponent implements OnInit, OnChanges {

  isLoadingData: boolean = true;
  isAcknowledging: boolean = false;


  @Input() props: IAlarmNotification[];
  @Output() acknowledgedNotification: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() requestRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  notifications: IAlarmNotification[];

  searchText: string;
  priorityFilter: string;
  statusFilter: string;


  showCreateTicketModal: boolean = false;
  selectedNotification: IAlarmNotification;

  users: IUser[];


  constructor(private nzNotificationService: NzNotificationService,
              private alarmService: AlarmsService,
              private notificationsService: NotificationsService,
              private ticketService: TicketService,
              private authService: AuthenticationService,
              private userService: UserService) { }

  ngOnChanges(changes: SimpleChanges): void {

      if(!changes.props.firstChange){
        this.notifications = changes.props.currentValue;
      }

    }

  ngOnInit(): void {


    this.notifications = this.props;
    this.isLoadingData = false;
  }

  acknowledgeNotification(notification: IAlarmNotification): void{
    this.isAcknowledging = true;
    this.notificationsService.acknowledgeAlarmNotification(notification)
      .subscribe(response =>{

        this.isAcknowledging = false;
        this.nzNotificationService.success(
          'Operation Successful',
          'This Alarm has been Acknowledged'
        );

        this.acknowledgedNotification.emit(true);

      }, error => {
        this.isAcknowledging = false;
        this.nzNotificationService.error(
          'Operation Failed',
          'Failed to Acknowledge this alarm'
        );
      });
  }

  filterByPriority(priority: string): void{
    if(priority !== "All" && priority !== null){
      this.notifications = this.notifications.filter((notification: IAlarmNotification) => notification.alarm_priority === priority);
    }
    else{
      this.notifications = this.props;
    }

  }

  filterByStatus(status: string): void{

    switch (status){
      case 'Active': {
        this.notifications = this.notifications.filter((notification: IAlarmNotification) => notification.active === true);
        break;
      }
      case 'Cleared': {
        this.notifications = this.notifications.filter((notification: IAlarmNotification) => notification.active === false);
        break;
      }
      default: {
        this.notifications = this.props;
      }
    }
  }

  clearFilters(): void{
    this.notifications = this.props;
    this.priorityFilter = 'All';
    this.statusFilter = 'All';
  }

  muteNotification(notification: IAlarmNotification): void{

    this.notificationsService.muteNotification(notification)
      .subscribe(response =>{
        this.nzNotificationService.success(
          'Operation Successful',
          'This Alarm has been Muted'
        );
      }, error => {
        console.error(error);
        this.nzNotificationService.error(
          'Operation Failed',
          'Failed to Mute this alarm'
        );
      });

  }

  unmuteNotification(notification: IAlarmNotification): void{

    this.notificationsService.unmuteNotification(notification).subscribe(response =>{


      if(response.message === 'Notification found but not changed'){
        this.nzNotificationService.warning(
          'Warning',
          response.message
        );

      }else{
        this.nzNotificationService.success(
          'Operation Successful',
          response.message
        );

        this.requestRefresh.emit(true);
      }


    }, error => {
      console.error(error);
      this.nzNotificationService.error(
        'Operation Failed',
        'Failed to Mute this alarm'
      );
    });

  }

  getAlarmColor(type: string): string{
    return new AlarmHelper().getAlarmTypeColor(type);
  }

  closeTicketModal(): void{
    this.showCreateTicketModal = false;
  }
  openCreateTicketModal(notification: IAlarmNotification): void{

    this.selectedNotification = notification;
    this.showCreateTicketModal = true;

  }


}
