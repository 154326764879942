<nz-page-header class="p-0 mb-0">
  <nz-page-header-title>

    <h5>
      <a>
        <i nz-icon nzType="left" nzTheme="outline" class="button-icon mb-2"
           (click)="goBack()"></i>
      </a>

      {{details?.site_name}}
    </h5>
  </nz-page-header-title>

</nz-page-header>
<nz-divider class="mt-0"></nz-divider>
<app-notification-list-item
  [props]="notifications"
  *ngIf="!isLoadingNotifications"
  (acknowledgedNotification)="handleAcknowledgedNotificationEvent($event)"></app-notification-list-item>

