import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IRedashQueryGeneration} from '../../Models/Redash/IRedashQueryGeneration';
import {Constants} from '../../utils/Contstants';

@Injectable({
  providedIn: 'root'
})
export class RedashService {

  private baseUrl: string = new Constants().baseUrl;
  //private token: string = "?api_key=TMi4t3WQifwoERPSy4jA99OfjZcvdbpPcNUmaCkW";

  constructor(private http: HttpClient) { }

  public generateSimpleQuery(query_id: number, site_id: number, dateStart: string, dateEnd: string): Observable<IRedashQueryGeneration>{
    // 38
    return this.http.post<IRedashQueryGeneration>(`${this.baseUrl}redash/generateRedashQuery`, {
      query_id: query_id,
      parameters: {
        site_id: site_id,
        timestamp: {
          start: dateStart,
          end: dateEnd
        }
      },
      max_age: 0
    });

  }

  public generateQuery(query_id: number, site_id: number | string, controller_id: number,
                       instance_id: number, dateStart: string, dateEnd: any): Observable<IRedashQueryGeneration>{

    return this.http.post<IRedashQueryGeneration>(`${this.baseUrl}redash/generateRedashQuery` , {
      query_id: query_id,
      parameters: {
        site_id: site_id,
        i_id: instance_id,
        c_id: controller_id,
        timestamp: {
          start: dateStart,
          end: dateEnd
        }
      },
      max_age: 0
    });

  }

  public initiateQueryJob(job_id: string): Observable<IRedashQueryGeneration>{

    return this.http.get<IRedashQueryGeneration>(`${this.baseUrl}redash/initiateQueryJob/${job_id}`);

  }

  public downloadCSVFile(query_result_id: number): Observable<any>{
    return this.http.get(`${this.baseUrl}redash/downloadCSVFile/${query_result_id}`);
  }

}
