import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';

import {IChiller} from '../../../Models/DeviceInstances/IChiller';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';
import {interval, Subscription} from 'rxjs';

import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';

@Component({
  selector: 'app-chiller-info',
  templateUrl: './chiller-info.component.html',
  styleUrls: ['./chiller-info.component.scss']
})
export class ChillerInfoComponent implements OnInit, OnDestroy {

  hasReading: boolean;

  @Output() failedReading = new EventEmitter<boolean>();

  @Input() readings: IDeviceReadingsProps;

  requestInterval = interval(10000);
  intervalSubscriber: Subscription;
  currentDeviceReadings : IChiller;

  isLoadingData : boolean;

  constructor(private analogDeviceService: AnalogDevicesService) { }

  ngOnInit(): void {
    this.isLoadingData = true;

    this.getDeviceReadings();
    this.hasReading = this.readings != null;
  }

  emitReadingError(): void{

    this.failedReading.emit(true);

  }

  getDeviceReadings(): void{

    this.isLoadingData = true;

      this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId,
        this.readings.selectedDeviceTypeId,
        this.readings.controllerId,
        this.readings.instanceId
    ).subscribe( response => {


      if(response !== null){
        this.currentDeviceReadings = response;
        this.isLoadingData = false;
      }else{
        this.hasReading = false;
        this.isLoadingData = false;
      }


    }, error => {
      console.warn('Chiller', error);
    } )


  }

  ngOnDestroy(): void {

    //this.intervalSubscriber.unsubscribe();
  }

}
