import {Component, OnInit} from '@angular/core';
import {ISite} from '../../Models/ISite';
import {IClient} from '../../Models/IClient';
import {Constants, fallbackImages} from '../../utils/Contstants';
import {SitesService} from '../../services/sites/sites.service';
import {AlarmsService} from '../../services/alarms/alarms.service';
import {StorageService} from '../../services/storage/storage.service';
import {ClientsService} from '../../services/clients/clients.service';
import {FuelConsumptionService} from '../../services/reports/fuel-consumption.service';
import {GenericHelpers} from '../../utils/GenericHelpers';
import {AlarmsDashboardService} from '../../services/dashboards/alarms/alarms-dashboard.service';
import {IActiveAlarmsNodeVersion, IClientActiveAlarms} from '../../Models/Dashboards/IClientActiveAlarms';

@Component({
  selector: 'app-client-site-list',
  templateUrl: './client-site-list.component.html',
  styleUrls: ['./client-site-list.component.scss']
})
export class ClientSiteListComponent implements OnInit {

  isLoadingData : boolean;
  loadingText: string;

  //SiteList
  siteList : ISite[];
  selectedSite: ISite;
  siteSearchText: string;

  client: IClient;
  clientIcon: string;

  isLoadingAlarms: boolean = true;
  alarms: IClientActiveAlarms[];

  siteListWithAlarms: Array<{
    site: ISite,
    alarms: {
      critical: number;
      warning: number;
      urgent: number;
      event: number;
    },
    alarmList: Array<IActiveAlarmsNodeVersion>;
  }> = [];

  private storageConstants = new Constants().storage;

  constructor(private siteService: SitesService,
              private alarmsService: AlarmsService,
              private storageService: StorageService,
              private clientService: ClientsService,
              private fuelConsumptionService: FuelConsumptionService,
              private alarmsDashboardService: AlarmsDashboardService,) { }

  ngOnDestroy(): void {



  }

  ngOnInit(): void {

    this.isLoadingData = true;
    this.loadingText = 'Loading Sites and Alarms';

    this.clientService
      .getClient(this.storageService
      .getStoredEncodedValue(this.storageConstants.newClientId)).subscribe( response =>{
      this.client = response;

      //Get The Client Logo
      // if(JSON.stringify(response).includes('logo')){
      //   this.clientIcon = new Constants().logoUrl + response.logo;
      // }else{
      //   this.clientIcon = fallbackImages.client_logo;
      // }

      this.clientService.getClientLogo(response._id).subscribe(response => {
        this.clientIcon = 'data:image/jpg;base64,' + response.data;

      }, error => {
        console.info('Could not get client Logo: ');

      });

    }, error => {
        console.error(error);

    });

    // this.clientIcon = new GenericHelpers().clientIcon(Number(this.clientService.getLegacyClientId()));
    this.siteService.getClientSites().subscribe( (sites: ISite[]) => {

      //Temporary fix:::
      const client = this.storageService.getStoredEncodedValue(this.storageConstants.newClientId);


      if(client === "602d082dae84fb2258664570"){
        this.siteList = sites;
        // this.siteList = sites.filter((e: ISite) => e.is_visible === true);

      }else{
        this.siteList = sites.filter(site => site.client_id === client);
      }



      // this.siteList = sites;

      // console.log('SITE OBJECT: ', this.siteList[0] );

      this.storageService.encodeAndStore(new Constants().storage.clientSites, JSON.stringify(sites));

      this.loadingText = 'Loading Alarms...';
      this.isLoadingData = false;
      this.getAlarms();

    }, error => {

      console.error('Error Retrieving Site List', error);
      this.isLoadingData = false;

    });


  }

  getAlarms(): void{
    this.isLoadingAlarms = true;
    this.alarmsDashboardService.getClientActiveAlarms().subscribe( (alarms: IClientActiveAlarms[]) => {
      this.alarms = alarms;
      this.loadingText = 'Finalising...';

      for(let i  = 0; i < this.siteList.length; i++){

        let currentSite: ISite = this.siteList[i];

        let tempObj = {
          site: currentSite,
          alarms: {
            critical: 0,
            urgent: 0,
            warning: 0,
            event: 0,
          },
          alarmList: []
        };

        for(let x = 0; x < alarms.length; x++){

          if(alarms[x].site_id === currentSite.legacy_id || alarms[x].site_id === currentSite._id){

            tempObj.alarms = {
              critical: alarms[x].critical_alarms,
              urgent: alarms[x].urgent_alarms,
              warning: alarms[x].warning_alarms,
              event: alarms[x].event_alarms,
            };

            // tempObj.alarmList = [
            //   ...alarms[x].active_alarms
            // ]
          }
        }

        this.siteListWithAlarms.push(tempObj);

      }

      this.isLoadingAlarms = false;



    }, error => {
      console.error(error);
      this.isLoadingAlarms = false;
      this.getAlarms();
    } );
  }

  ngAfterViewInit(): void {


  }

  noIconLoaded(data): void{
    this.clientIcon = 'assets/round_bms_icon.png';
  }

  getSiteAlarm(site_id: string): number{
    // console.log(site_id);

    let num = this.alarms.findIndex( alarm => alarm.site_id.toString() === site_id.toString());

    if(parseInt(site_id) === 4000){
      // console.log(typeof site_id, ' - alarms:', num);
    }

    return num;

  }

}
