<!--<div style="width: 100%; padding: 30px 30px 0;" *ngIf="hasReading">-->

<!--  <div nz-row>-->

<!--    <div nz-col nzSpan="12">-->
<!--      <h6 class="mb-3" *ngIf="!isLoadingData">-->
<!--        <span class="text-lead" style="font-size: small;">Last Reading</span><br>-->
<!--        {{currentDeviceReadings?.timestamp |  date:'medium' }}-->
<!--      </h6>-->
<!--    </div>-->

<!--    <div nz-col nzSpan="12">-->
<!--      <button nz-button nzType="primary" class="float-right" (click)="getDeviceReadings()" >-->
<!--        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>-->
<!--        Get Current Readings-->
<!--      </button>-->
<!--    </div>-->

<!--  </div>-->

<!--  <nz-divider></nz-divider>-->
<!--  <p style="font-size: small; font-weight: bold" class="text-muted">{{currentDeviceReadings?.battery_data.length}} Batteries</p>-->
<!--</div>-->

<!--<div class="container" *ngIf="!hasReading">-->
<!--  <nz-result nzStatus="404" nzTitle="404"-->
<!--             nzSubTitle="Sorry, it seems as though there is no data available for this device.">-->

<!--  </nz-result>-->
<!--</div>-->

<!--<nz-card class="full-width" [nzLoading]="isLoadingData" nzBordered="false">-->
<!--  <div style="height: 40vh; overflow: scroll; width: 100%;padding:0 30px;" *ngIf="hasReading">-->

<!--    <nz-list [nzNoResult]="' '" [nzPagination]="">-->
<!--      <div nz-row [nzGutter]="16">-->
<!--        <div nz-col [nzSpan]="6" *ngFor="let item of currentDeviceReadings?.battery_data">-->
<!--          <nz-list-item>-->
<!--            <nz-card nzBordered="false">-->

<!--              <div class="row">-->
<!--                <div class="col-12">-->
<!--&lt;!&ndash;                  <img  src="assets/icons/Battery/Battery_blue.png" alt="" style="height: 60px; width: 100%;">&ndash;&gt;-->
<!--                  <img *ngIf="checkVoltage(item?.voltage)"  src="assets/icons/Battery/Device/Battery_device.png"-->
<!--                       alt=""-->
<!--                       style="height: 60px; width: 60%; margin-left: 20%; margin-right: 20%;">-->
<!--                  <img *ngIf="!checkVoltage(item?.voltage)" src="assets/icons/Battery/Device/Battery_device_red.png"-->
<!--                       alt=""-->
<!--                       style="height: 60px; width: 60%; margin-left: 20%; margin-right: 20%;">-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="col-12 text-center">-->
<!--                  <h6 style="font-size: small" class="pt-2">{{'Battery ' + (item?.alarm_BMS_element + 1)}}</h6>-->
<!--                  <p class="reading-text">{{item?.voltage}} @ {{item?.temperature}}</p>-->

<!--                </div>-->
<!--              </div>-->
<!--            </nz-card>-->
<!--          </nz-list-item>-->

<!--        </div>-->

<!--      </div>-->
<!--    </nz-list>-->
<!--    <nz-divider></nz-divider>-->
<!--  </div>-->
<!--</nz-card>-->

<div class="container-fluid">
  <div class="row">

    <div class="col-6">
      <h6 class="mb-0 text-left mt-0 pt-0" *ngIf="!isLoadingData">
        <span class="text-lead" style="font-size: smaller;">Last Reading</span><br>
        {{currentDeviceReadings?.timestamp |  date:'medium' }}
      </h6>
    </div>

    <div class="col-6 text-right">
      <button nz-button class="mr-3" (click)="openModal()">
        <i nz-icon nzType="info-circle" nzTheme="outline" class="button-icon"></i>
        Bank Information
      </button>

      <button nz-button nzType="primary" class="float-right" (click)="getDeviceReadings()" >
        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
        Get Current Readings
      </button>
    </div>



  </div>

  <div class="row mt-1">
    <div class="col-12">
      <nz-collapse nzAccordion>
        <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
          <figure class="highcharts-figure" *ngIf="panel.name === 'Temperature'">
            <div [chart]="batteryTemperaturesChart"></div>
          </figure>

          <figure class="highcharts-figure" *ngIf="panel.name === 'Voltage'">
            <div [chart]="batteryVoltagesChart"></div>
          </figure>

          <figure class="highcharts-figure" *ngIf="panel.name === 'State of Charge'">
            <div [chart]="batterySOCChart"></div>
          </figure>

          <figure class="highcharts-figure" *ngIf="panel.name === 'State of Health'">
            <div [chart]="batterySOHChart"></div>
          </figure>

          <figure class="highcharts-figure" *ngIf="panel.name === 'Internal Resistance'">
            <div [chart]="batteryInternalResistanceChart"></div>
          </figure>

          <figure class="highcharts-figure" *ngIf="panel.name === 'Internal Resistance Deviation'">
            <div [chart]="batteryInternalResistanceDeviationChart"></div>
          </figure>

        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>

</div>

<nz-modal [(nzVisible)]="showBatteryBankModal"
          [nzTitle]="null"
          nzClosable="false"
          [nzCancelText]="null"
          (nzOnOk)="closeModal()"
          nzCancelDisabled
          nzOkText="Ok" nzWidth="50%">
  <ng-container *nzModalContent>
    <nz-page-header [nzTitle]="readings.deviceName"
                    nzSubtitle="Battery Bank Information">

    </nz-page-header>
<!--    <h4>Battery Bank Info: {{readings.deviceName}}</h4>-->
    <div class="container-fluid">
      <nz-divider></nz-divider>
      <div class="row">
        <div class="col-4" *ngIf="!currentDeviceReadings?.avarage_battery_voltage.includes('undefined')">
          <nz-statistic [nzValue]="( currentDeviceReadings?.avarage_battery_voltage)"
                        [nzTitle]="'Average Battery Voltage'"></nz-statistic>
        </div>

        <div class="col-4" *ngIf="!currentDeviceReadings?.avarage_cell_voltage.includes('undefined')">
          <nz-statistic [nzValue]="(currentDeviceReadings?.avarage_cell_voltage)!"
                        [nzTitle]="'Average Cell Voltage'"></nz-statistic>
        </div>

        <div class="col-4">
          <nz-statistic [nzValue]="(currentDeviceReadings?.battery_data.length | number)!"
                        [nzTitle]="'Battery Count'"></nz-statistic>
        </div>

      </div>
      <nz-divider></nz-divider>
      <div class="row">
        <div class="col-4">
          <nz-statistic [nzValue]="(currentDeviceReadings?.bank_current)!"
                        [nzTitle]="'Bank Current'"></nz-statistic>
        </div>

        <div class="col-4">
          <nz-statistic [nzValue]="(currentDeviceReadings?.bank_voltage)!"
                        [nzTitle]="'Bank Voltage'"></nz-statistic>
        </div>
      </div>
    </div>


  </ng-container>
</nz-modal>
