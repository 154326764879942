import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IUser} from '../../../Models/IUser';
import {StorageService} from '../../../services/storage/storage.service';

@Component({
  selector: 'app-settings-panel',
  templateUrl: './settings-panel.component.html',
  styleUrls: ['./settings-panel.component.scss']
})
export class SettingsPanelComponent implements OnInit {

  @Input() isOpen: boolean;
  @Input() currentUser: IUser;

  @Output() closePanel: EventEmitter<boolean> = new EventEmitter();
  @Output() toggleNotifications: EventEmitter<boolean> = new EventEmitter<boolean>();

  alertsEnabled: boolean = true;

  constructor(private storageService: StorageService,) { }

  ngOnInit(): void {
    this.checkAlertsEnabledSetting();
  }

  checkAlertsEnabledSetting(): void{
    let popupsDisabled = this.storageService.getLocalStorage('popupsDisabled');
    if(popupsDisabled !== 'null' && popupsDisabled !== 'false'){
      this.alertsEnabled = false;
    }

  }

  handleAlertsEnabled(checked: boolean): void{
    this.toggleNotifications.emit(checked);
  }

  emitClosePanel(): void{
    this.closePanel.emit(false);
  }

}
