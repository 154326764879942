import { Injectable } from '@angular/core';
import {Constants} from '../../../utils/Contstants';
import {HttpClient} from '@angular/common/http';
import {StorageService} from '../../storage/storage.service';
import {Observable} from 'rxjs';
import {ISiteHVACData} from '../../../Models/Dashboards/ISiteHVACData';

@Injectable({
  providedIn: 'root'
})
export class HvacDashboardsService {

  private baseUrl = new Constants().noderedInternal;
  private storageConstants = new Constants().storage;

  constructor(private http: HttpClient, private storageService: StorageService) { }

  public getSiteHVACData(site_id: string): Observable<ISiteHVACData>{

    // return this.http.get<ISiteHVACData>(`${this.baseUrl}dashboards/hvac/siteHVACData/${site_id}`);
    return this.http.get<ISiteHVACData>(`${this.baseUrl}dashboards/hvac/site_hvacs?site_id=${site_id}`);

  }

}
