<div class="container-fluid">

  <div class="row">
    <nz-card class="col-lg-12 col-md-12 col-sm-12" [nzBodyStyle]="{'padding': 0, 'height': '150px'}">
      <nz-page-header nzBackIcon nzTitle="Alarm Summary">
      </nz-page-header>

      <nz-page-header-content>
        <div class="container ml-4">
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12">
              <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text"
                       nz-input placeholder="Search..."
                       [(ngModel)]="searchText" autocomplete="off" />
              </nz-input-group>
              <span nz-typography nzType="secondary">Use the search box above to filter the list below</span>
              <ng-template #suffixIconSearch>
                <i nz-icon nzType="search"></i>
              </ng-template>
            </div>
          </div>
        </div>
      </nz-page-header-content>

    </nz-card>

  </div>

  <div class="row">
    <nz-card class="col-lg-12 col-md-12 col-sm-12" [nzBodyStyle]="{'padding': 0, 'height': '100%'}"
             [nzLoading]="isLoadingData">

      <nz-table #nzTable [nzData]="clientAlarms"
                [nzPageSize]="5000" [nzScroll]="{ y: '50vh' }"
                [nzShowPagination]="false"
                nzSize="small"  nzFooter="{{clientAlarms?.length}} Active Alarms in Total"
      >
        <thead>
        <tr>
          <th
            *ngFor="let column of listOfColumns"
            [nzSortOrder]="column.sortOrder"
            [nzSortFn]="column.sortFn"
            [nzSortDirections]="column.sortDirections"
            [nzFilterMultiple]="column.filterMultiple"
            [nzFilters]="column.listOfFilter"
            [nzFilterFn]="column.filterFn"
          >
            {{ column.name }}
          </th>

        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let data of nzTable.data | filter:searchText; let i = index">
          <tr
          >
            <!--          <td [nzExpand]="expandSet.has(data.id)" (nzExpandChange)="onExpandChange(data.id, $event)"></td>-->
            <td>{{ data?.site_name }}</td>
            <td>{{ data?.device_name }}</td>
            <td>{{ data?.description }}</td>
            <td>
              <nz-tag nzColor="red" class="priority" *ngIf="data.priority === 'Critical' ||  data.priority === 'MPT Alarm'">
                Critical
              </nz-tag>
              <nz-tag nzColor="#ffa000" class="priority" *ngIf="data.priority === 'Urgent'">
                Urgent
              </nz-tag>
              <nz-tag nzColor="warning" class="priority" *ngIf="data.priority === 'Warning'">
                Warning
              </nz-tag>
              <nz-tag nzColor="blue" class="priority" *ngIf="data.priority === 'Event'">
                Event
              </nz-tag>
            </td>

            <td>{{ data?.triggered_at | date : 'medium' }}</td>
            <!--          <td>-->

            <!--              <span *ngIf="checkIfString(data?.cleared_at); else isDate">-->
            <!--                  {{ data?.cleared_at }}-->
            <!--              </span>-->

            <!--            <ng-template #isDate>-->
            <!--              {{ data?.cleared_at | date: 'medium'}}-->
            <!--            </ng-template>-->

            <!--          </td>-->
            <td>{{ data?.duration }}</td>
            <!--          <td>{{ data?.is_cleared }}</td>-->
            <!--          <td nzBreakWord>{{ data?.description }}</td>-->

          </tr>


        </ng-container>
        </tbody>
      </nz-table>

    </nz-card>
  </div>


</div>

<app-overlay-loader [isLoading]="isLoadingData" [loadingText]="'Retrieving Alarm Summary'"></app-overlay-loader>
