import { Component, OnInit } from '@angular/core';
import {IGenerator} from '../../../Models/DeviceInstances/IGenerator';
import {ISite} from '../../../Models/ISite';

@Component({
  selector: 'app-generator-report',
  templateUrl: './generator-report.component.html',
  styleUrls: ['./generator-report.component.scss']
})
export class GeneratorReportComponent implements OnInit {

  selectedSite: ISite;
  selectedGenerator: IGenerator;
  selectedRange: Array<Date>;
  hasSelectedRange: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  handleDateSelected(data): void{

  }

}
