import { Injectable } from '@angular/core';
import {Constants} from '../../../utils/Contstants';
import {HttpClient} from '@angular/common/http';
import {StorageService} from '../../storage/storage.service';
import {Observable} from 'rxjs';
import {IClientPowerTotals} from '../../../Models/Dashboards/IClientPowerTotals';
import {IClientSitePowerTotals} from '../../../Models/Dashboards/IClientSitePowerTotals';


@Injectable({
  providedIn: 'root'
})
export class PowerMeterDashboardsService {

  private baseUrl = new Constants().noderedInternal;
  private storageConstants = new Constants().storage;

  constructor(private http: HttpClient, private storageService: StorageService) { }

  public clientPowerTotals(): Observable<Array<IClientPowerTotals>>{
    const client = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    // return this.http.get<Array<IClientPowerTotals>>(`${this.baseUrl}dashboards/power_meter/client_totals/${client}`);
    return this.http.get<Array<IClientPowerTotals>>(`https://nodered.cloudbms.co.za/api/dashboards/power_meter/client_totals?client_id=${client}`);

  }

  public clientSitePowerTotals(site_id: any): Observable<IClientSitePowerTotals>{

    // return this.http.get<IClientSitePowerTotals>(`${this.baseUrl}dashboards/power_meter/clientSitePowerMeterStats/${site_id}`);
    return this.http.get<IClientSitePowerTotals>(`${this.baseUrl}dashboards/power_meter/site_powermeters?site_id=${site_id}`);

  }


}
