import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';
import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';
import {GenericHelpers} from '../../../utils/GenericHelpers';
import {IDistribution} from '../../../Models/DeviceInstances/IDistribution';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {AuthenticationService} from '../../../services/authentication/authentication.service';

@Component({
  selector: 'app-distribution',
  templateUrl: './distribution.component.html',
  styleUrls: ['./distribution.component.scss']
})
export class DistributionComponent implements OnInit, AfterViewInit {

  isLoadingData: boolean = false;
  hasReading: boolean = false;
  divScrollable: boolean;

  clientId: number;

  @Input() readings: IDeviceReadingsProps;

  //Canvas container

  divWidth: number;
  divHeight: number;

  //Canvas Related
  @ViewChild('canvas', { static: false })
  canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;

  //Containing Div
  @ViewChild('containerDiv', {static: false})
  divContainer: ElementRef;



  devices = [];

  currentColumnStart: boolean;

  //Data related
  data : IDistribution;

  columns;

  //Columns and Dimensions

  columnWidth: number;

  imageWidth: number;
  imageHeight: number;

  errorMessage: string;

  // @HostListener('window:mousemove', ['$event'])
  // handleKeyDown(event: MouseEvent) {
  //   this.handleMouseOver(event);
  // }

  horizontalBusYPos: number;

  constructor(private analogDeviceService: AnalogDevicesService,
              private http: HttpClient,
              private authenticationService: AuthenticationService) { }

  ngOnInit(): void {



   this.clientId =  this.authenticationService.getClientId();


    this.hasReading = true;
    //this.getDeviceReadings();

  }

  ngAfterViewInit(){


    this.ctx = this.canvas.nativeElement.getContext('2d');

    this.divWidth = this.divContainer.nativeElement.clientWidth;
    this.divHeight = this.divContainer.nativeElement.clientHeight;
    this.getDeviceReadings();
  }

  getDeviceReadings(): void {

    this.isLoadingData = true;

    this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId, this.readings.selectedDeviceTypeId, this.readings.controllerId, this.readings.instanceId
    ).subscribe( (response ) => {

      if(response.message){
        this.errorMessage = response.message;
        this.hasReading = false;
        this.isLoadingData = false;
      }else{
        // this.isLoadingData = false;

        this.data = response;
        this.config();
        this.renderRows();
        this.isLoadingData = false;

      }



    }, error => {
      console.error(error);
    }, () => {

    } )


  }

  config(): void{



    // this.divWidth = this.divContainer.nativeElement.clientWidth;
    // this.divHeight = this.divContainer.nativeElement.clientHeight;

    this.columns = this.data.columns;

    this.columnWidth = this.divWidth / this.columns.length;


    if(this.columnWidth < 100){
      this.columnWidth = 150;

      this.canvas.nativeElement.width = this.columnWidth * this.columns.length ;
      this.canvas.nativeElement.height = this.divHeight;
      this.divScrollable = true;

    }
    else if(this.columnWidth > 200){
      this.columnWidth = 150;

      this.canvas.nativeElement.width = this.columnWidth * this.columns.length ;
      this.canvas.nativeElement.height = this.divHeight;
      this.divScrollable = true;
    }
    else{
      this.divScrollable = false;
      this.canvas.nativeElement.width = this.divWidth;
      this.canvas.nativeElement.height = this.divHeight;
    }

    this.imageWidth = this.columnWidth / 2; //20px so we can have margin (0 10)
    this.imageHeight = this.columnWidth / 2;





  }

  renderRows(): void{

    let x_pos = 0;
    let y_pos = 0;


    let previousBlankCount = 0;

    for(let i: number = 0; i < this.columns.length; i++){

      let blankCount= 0;
      let horizontalBusRendered = false;

      for(let x: number = 0; x <  this.columns[i].length; x++){

        if(x == 0) this.currentColumnStart = true;

        switch (this.columns[i][x].device_id) {
          case -2 : {

            if(i == 0){
              this.horizontalBusYPos = y_pos;
            }


            this.renderBottomBusLine(x_pos, this.horizontalBusYPos);
            horizontalBusRendered = true;
            y_pos = this.horizontalBusYPos;
            break;
          }
          case -1: {
            // Bus Line


            if(i === (this.columns.length -1)){



             // y_pos = this.horizontalBusYPos;
            }
            this.renderDownwardBusLine(x_pos, y_pos);
            y_pos += this.imageHeight / 2;
            break;
          }
          case 30: {
            // Breaker


            this.devices.push({
              x_pos: (x_pos - ( this.imageWidth / 2 ) + 10),
              y_pos: (y_pos - ( this.imageHeight / 4 )),
              text: this.columns[i][x].device_name
            });

            if(this.columns[i][x].status == "Closed"){
              this.renderClosedBreaker(x_pos, y_pos, this.columns[i][x].colour);
            }else{
              this.renderOpenBreaker(x_pos, y_pos, this.columns[i][x].colour);
            }
            y_pos += (this.imageHeight / 2);
            break;
          }
          case null: {
            // Don't Render but leave a space


            if(previousBlankCount == 1 && blankCount == 2 ){

              y_pos += this.imageHeight + (this.imageHeight / 1.2);
              blankCount += 1;
              break;
            }

            if(previousBlankCount == 3 && blankCount == 2 ){

              y_pos = this.horizontalBusYPos;
              blankCount += 1;
              break;
            }

            if(blankCount == 2 && previousBlankCount == 2){
              y_pos += this.imageHeight + (this.imageHeight / 3);
            }
            else{
              y_pos += this.imageHeight + (this.imageHeight / 6);
            }


            blankCount += 1;
            break;
          }
          default: {
            // Actual Device

            this.devices.push({
              x_pos: x_pos,
              y_pos: y_pos,
              text: this.columns[i][x].device_name
            });


            if(this.columns[i][x].status == -1){
              this.renderDeviceImage(this.columns[i][x].device_id, x_pos, y_pos - (this.imageHeight / 1.2));
              this.renderBottomBusLine(x_pos, this.horizontalBusYPos);
            }else{
              this.renderDeviceImage(this.columns[i][x].device_id, x_pos, y_pos);
            }
            y_pos += this.imageHeight;
            break;
          }


        }


      }

      x_pos += this.columnWidth;
      y_pos = 0;
      previousBlankCount = blankCount;

    }

    this.drawDeviceLabels(this.devices, this.imageWidth, this.imageHeight);
  }

  renderDeviceImage(device_id: number, x_pos : number, y_pos: number): void{


    try{
      let deviceImageUrl = new GenericHelpers().deviceImages(device_id);

      let deviceImage = new Image();

      deviceImage.alt = 'assets/icons/Devices/unknown_device.png';


      this.http.get(deviceImageUrl,  { observe: 'response', responseType: 'blob' }).subscribe( response => {

        deviceImage.src = deviceImageUrl;

      }, error => {
        //console.error(error);
        deviceImage.src = 'assets/icons/Devices/unknown_device.png';
      } );

      deviceImage.onload = () => {
        this.ctx.drawImage(deviceImage, x_pos, y_pos, this.imageWidth, this.imageHeight);


        // deviceImage.addEventListener('mouseenter', (e : MouseEvent) => {

        // });

      }
    }catch (e) {
      console.error(e);
    }


  }

  renderDownwardBusLine(x_pos: number, y_pos: number): void{
    let midXValue = (x_pos + (this.imageWidth / 2) );

    this.ctx.lineWidth = 2;
    this.ctx.beginPath();
    this.ctx.moveTo( midXValue, y_pos);
    this.ctx.lineTo(midXValue, (y_pos + (this.imageHeight / 2)));
    this.ctx.stroke();

  }

  renderOpenBreaker(x_pos: number, y_pos: number, colour): void{


    let start_x = x_pos + (this.imageWidth / 2) / 2;
    let end_x = (x_pos + (this.imageWidth / 2));
    const end_y = y_pos + (this.imageWidth / 2);



    this.ctx.font = "100 25px Arial";
    this.ctx.fillText("x", (x_pos + (this.imageWidth/ 2)) - 6.5 , (y_pos + 6) );


    if(this.clientId !== 24){
      if(colour === null || colour === "white"){
        this.ctx.strokeStyle = "black";

      }else{
        this.ctx.strokeStyle = colour;
      }

    }else{

      this.ctx.strokeStyle = "red";

    }



    this.ctx.beginPath();
    this.ctx.moveTo(start_x, y_pos);
    this.ctx.lineTo(end_x, end_y);
    this.ctx.stroke();

    this.ctx.lineWidth = 2;
    this.ctx.beginPath();
    this.ctx.arc(end_x, end_y, 5, 0, 2 * Math.PI );
    this.ctx.fill();
    this.ctx.stroke();

    this.ctx.strokeStyle = "black";
  }

  renderClosedBreaker(x_pos: number, y_pos: number, colour): void{
    let start_x = x_pos + (this.imageWidth / 2);
    let end_x = (x_pos + (this.imageWidth / 2));
    const end_y = y_pos + (this.imageWidth / 2);

    this.ctx.font = "100 25px Arial";
    this.ctx.fillText("x", (x_pos + (this.imageWidth/ 2)) - 6.5 , (y_pos + 6) );

    if(this.clientId !== 24){
      if(colour === null || colour === "white"){
        this.ctx.strokeStyle = "black";

      }else{
        this.ctx.strokeStyle = colour;
      }

    }else{
      this.ctx.strokeStyle = "green";
    }

    this.ctx.beginPath();
    this.ctx.moveTo(start_x, y_pos);
    this.ctx.lineTo(end_x, end_y);
    this.ctx.stroke();

    this.ctx.lineWidth = 2;
    this.ctx.beginPath();
    this.ctx.arc(end_x, end_y, 5, 0, 2 * Math.PI );
    this.ctx.fill();
    this.ctx.stroke();

    this.ctx.strokeStyle = "black";
  }

  renderBottomBusLine(x_pos: number, y_pos: number): void{

    let end_x = (x_pos + this.columnWidth) + (this.imageWidth / 2);

    this.ctx.lineWidth = 2;
    this.ctx.beginPath();
    this.ctx.moveTo((x_pos + (this.imageWidth / 2) ), y_pos);
    this.ctx.lineTo(end_x, y_pos);
    this.ctx.stroke();


  }

  drawDeviceLabels(devices : Array<any>, columnWidth: number, imageHeight: number): void{


    for(let i in devices){

      this.ctx.font = '10px Arial';
      this.ctx.fillText(devices[i].text, (devices[i].x_pos + (columnWidth + 1)), (devices[i].y_pos + (imageHeight /2)));

      // if(e.offsetX >= devices[i].x_pos && e.offsetX <= (devices[i].x_pos + columnWidth) ){
      //
      //     if(e.offsetY >= devices[i].y_pos && e.offsetY <= (devices[i].y_pos + imageHeight)){

      //
      //
      //       this.ctx.font = '15px Arial';
      //       this.ctx.fillText('Hello world', (devices[i].x_pos + columnWidth), (devices[i].y_pos + (imageHeight /2)));
      //
      //     }
      //
      // }

    }




  }

}
