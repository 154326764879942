import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IUserNotificationSettings} from '../../Models/IUserNotificationSettings';
import {IActiveAlarm} from '../../Models/IActiveAlarm';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsService {

  // private notificationSettingsService: string = "http://localhost:8004/api/";
  private notificationSettingsService: string = "https://usernotifications.service.cloudbms.co.za/api/";


  constructor(private http: HttpClient) { }

  public getUserNotificationSettings(userID: string): Observable<IUserNotificationSettings>{
    // console.log('user id: ', userID);
    return this.http.get<IUserNotificationSettings>(this.notificationSettingsService + `notifications/${userID}`);
  }

  public updateUserNotificationSettings(alarmNotificationSettings: IUserNotificationSettings,): Observable<any>{

    return this.http.put(this.notificationSettingsService + `notifications/${alarmNotificationSettings.user_id}`, {
      ...alarmNotificationSettings
    });

  }

  public getSavedNotifications(userID: string): Observable<IActiveAlarm[]>{
    return this.http.get<IActiveAlarm[]>(`${this.notificationSettingsService}notifications/getUserSavedAlarmNotifications/${userID}`);
  }

  public acknowledgeNotification(userID: string, notification: IActiveAlarm): Observable<{msg: string}>{
    return this.http.put<{msg: string}>(`${this.notificationSettingsService}notifications/acknowledge/${userID}`, notification);
  }
}
