<div style="background: #ffffff; padding: 30px;overflow-x: scroll;">
  <div class="row">
    <div class="col-md-6 col-lg-6 col-sm-12">
      <h6 class="mb-3">
        <span class="text-lead" style="font-size: small;">Last Reading</span><br>
        {{currentDeviceReadings?.timestamp |  date:'medium' }}
      </h6>
    </div>
    <div class="col-md-6 col-lg-6 col-sm-12">
      <button nz-button nzType="primary" class="float-right" (click)="getDeviceReadings()" >
        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
        Get Current Readings
      </button>
    </div>
  </div>


  <nz-divider></nz-divider>

  <div class="container" *ngIf="!hasReading">
    <nz-result nzStatus="404" nzTitle="404"
               nzSubTitle="Sorry, it seems as though there is no data available for this device.">

    </nz-result>
  </div>

  <nz-card class="full-width" [nzLoading]="isLoadingData" nzBordered="false">
    <div class="full-width" *ngIf="hasReading">

      <div class="row">
        <div class="col-md-4 col-lg-4 col-sm-4">
          <nz-card nzBordered="false">
            <h6 class="heading">Oil Pressure</h6>
            <h6 class="reading-text">
              {{currentDeviceReadings?.oil_pressure}}
            </h6>
          </nz-card>
        </div>
        <div class="col-md-4 col-lg-4 col-sm-4">
          <nz-card nzBordered="false">
            <h6 class="heading">Oil Temperature</h6>
            <h6 class="reading-text" nz-typography >
              {{currentDeviceReadings?.oil_temperature}}</h6>
          </nz-card>
        </div>
        <div class="col-md-4 col-lg-4 col-sm-4">
          <nz-card nzBordered="false">
            <h6 class="heading">Coolant Temperature</h6>
            <h6 class="reading-text">
              {{currentDeviceReadings?.coolant_temperature}}
            </h6>
          </nz-card>
        </div>

      </div>

      <div class="row">
        <div class="col-md-4 col-lg-4 col-sm-4">
          <nz-card nzBordered="false">
            <h6 class="heading">Engine Speed</h6>
            <h6 class="reading-text">
              {{ currentDeviceReadings?.engine_speed }}
            </h6>
          </nz-card>
        </div>
        <div class="col-md-4 col-lg-4 col-sm-4">
          <nz-card nzBordered="false">
            <h6 class="heading">Alternator Charge</h6>
            <h6 class="reading-text">
              {{currentDeviceReadings?.alternator_charge_voltage}}
            </h6>
          </nz-card>
        </div>
        <div class="col-md-4 col-lg-4 col-sm-4">
          <nz-card nzBordered="false">
            <h6 class="heading">Battery Voltage</h6>
            <h6 class="reading-text">
             {{currentDeviceReadings?.battery_voltage}}
            </h6>
          </nz-card>
        </div>
      </div>


<!--      <div class="row">-->
<!--        <div class="col-md-4 col-lg-4 col-sm-4">-->
<!--          <nz-card nzBordered="false">-->
<!--            <h6 class="heading">Fuel Level</h6>-->
<!--            <h6 class="reading-text">-->
<!--              {{ (currentDeviceReadings?.f != undefined)-->
<!--              ? currentDeviceReadings?.Fuel_Level_Percentage : 'N/A'-->
<!--              }}-->
<!--            </h6>-->
<!--          </nz-card>-->
<!--        </div>-->
<!--      </div>-->
<!--      <nz-row [nzGutter]="16">-->
<!--        <nz-col [nzSpan]="8">-->
<!--          <nz-card>-->
<!--            <h6 class="heading">Oil Pressure</h6>-->
<!--            <h6 class="reading-text">{{(currentDeviceReadings?.Oil_Pressure != undefined)-->
<!--              ? currentDeviceReadings?.Oil_Pressure : 'N/A' }}-->
<!--            </h6>-->
<!--          </nz-card>-->
<!--        </nz-col>-->

<!--        <nz-col [nzSpan]="8">-->
<!--          <nz-card>-->
<!--            <h6 class="heading">Oil Temperature</h6>-->
<!--            <h6 class="reading-text" nz-typography >{{-->
<!--              (currentDeviceReadings?.Oil_temperature != undefined) ? currentDeviceReadings?.Oil_temperature :-->
<!--                'N/A'-->
<!--              }}</h6>-->
<!--          </nz-card>-->
<!--        </nz-col>-->

<!--        <nz-col [nzSpan]="8">-->
<!--          <nz-card>-->
<!--            <h6 class="heading">Coolant Temperature</h6>-->
<!--            <h6 class="reading-text">{{-->
<!--              (currentDeviceReadings?.Coolant_Temperature != undefined) ? currentDeviceReadings?.Coolant_Temperature :-->
<!--                'N/A'-->
<!--              }}-->
<!--            </h6>-->
<!--          </nz-card>-->
<!--        </nz-col>-->

<!--      </nz-row>-->
<!--      <br>-->
<!--      <nz-row [nzGutter]="16">-->
<!--        <nz-col [nzSpan]="8">-->
<!--          <nz-card>-->
<!--            <h6 class="heading">Engine Speed</h6>-->
<!--            <h6 class="reading-text">-->
<!--              {{ (currentDeviceReadings?.Engine_speed != undefined) ? currentDeviceReadings?.Engine_speed : 'N/A' }}-->
<!--            </h6>-->
<!--          </nz-card>-->
<!--        </nz-col>-->

<!--        <nz-col [nzSpan]="8">-->
<!--          <nz-card>-->
<!--            <h6 class="heading">Alternator Charge</h6>-->
<!--            <h6 class="reading-text">-->
<!--              {{ (currentDeviceReadings?.Alternator_charge_voltage != undefined)-->
<!--              ? currentDeviceReadings?.Alternator_charge_voltage : 'N/A'}}-->
<!--            </h6>-->
<!--          </nz-card>-->
<!--        </nz-col>-->

<!--        <nz-col [nzSpan]="8">-->
<!--          <nz-card>-->
<!--            <h6 class="heading">Battery Voltage</h6>-->
<!--            <h6 class="reading-text">-->
<!--              {{-->
<!--              (currentDeviceReadings?.Battery_voltage != undefined) ? currentDeviceReadings?.Battery_voltage : 'N/A'-->
<!--              }}-->
<!--            </h6>-->
<!--          </nz-card>-->
<!--        </nz-col>-->

<!--      </nz-row>-->
<!--      <br>-->
<!--      <nz-row [nzGutter]="16">-->

<!--        <nz-col [nzSpan]="8">-->
<!--          <nz-card>-->
<!--            <h6 class="heading">Fuel Level</h6>-->
<!--            <h6 class="reading-text">-->
<!--              {{ (currentDeviceReadings?.Fuel_Level_Percentage != undefined)-->
<!--              ? currentDeviceReadings?.Fuel_Level_Percentage : 'N/A'-->
<!--              }}-->
<!--            </h6>-->
<!--          </nz-card>-->
<!--        </nz-col>-->

<!--      </nz-row>-->
    </div>
  </nz-card>




</div>
