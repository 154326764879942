import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../utils/Contstants';
import {StorageService} from '../storage/storage.service';
import {ISiteAlarmsCount} from '../../Models/ISiteAlarmsCount';
import {Observable} from 'rxjs';
import {IActiveAlarm} from '../../Models/IActiveAlarm';
import {IActiveAlarmLegacy} from '../../Models/IActiveAlarmLegacy';
import {ISiteAlarmTotal} from '../../Models/ISiteAlarmTotal';
import {IDeviceStatusAlarm} from '../../Models/IDeviceStatusAlarm';
import {IClientSiteAlarmCounts} from '../../Models/IClientSiteAlarmCounts';
import {IActiveAlarmHistoryCsvData} from '../../Models/Reports/IActiveAlarmHistoryCsvData';
import * as jwt_decode from 'jwt-decode';
import {map} from 'rxjs/operators';
import {IUserSiteListWithAlarms} from '../../Models/IUserSiteList';
@Injectable({
  providedIn: 'root'
})
export class AlarmsService {

  private baseUrl = new Constants().baseUrl;
  private noderedInternal = new Constants().noderedInternal;
  private storageConstants = new Constants().storage;

  constructor(private http: HttpClient, private storageService: StorageService) {


  }

  public siteAlarmCounts(site_id: any): Observable<ISiteAlarmsCount>{

    const token = this.storageService.getStoredEncodedValue(this.storageConstants.token);
    const clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    return this.http.get<ISiteAlarmsCount>
    (`${this.baseUrl}siteActiveAlarmCounts/token/${site_id}/${clientId}`);
  }

  public siteActiveAlarms(site_id: any): Observable<any>{

    const token = this.storageService.getStoredEncodedValue(this.storageConstants.token);
    const clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    // return this.http.get<IActiveAlarm[]>
    // (`${this.baseUrl}siteActiveAlarms/token/${site_id}/${clientId}`);

    return this.http.get<any>
    (`${this.noderedInternal}dashboards/alarms/site_active2?site_id=${site_id}`);

  }

  public instanceActiveAlarms(site_id: any, controller_id: number, instance_id: number, device_type: number): Observable<IActiveAlarm[]>{
    const token = this.storageService.getStoredEncodedValue(this.storageConstants.token);
    const clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    // return this.http.get<IActiveAlarm[]>
    // (`${this.baseUrl}instanceActiveAlarms/token/${site_id}/${controller_id}/${instance_id}/${device_type}/${clientId}`);

    return this.http.get<IActiveAlarm[]>
    (`${this.noderedInternal}dashboards/alarms/device_active?site_id=${site_id}&controller_id=${controller_id}&device_instance=${instance_id}&device_type=${device_type}&element=0`);
  }

  public siteAlarmHistory(siteId: any, dateFrom : string, dateTo: string) : Observable<any>{

    const clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    const token = this.storageService.getStoredEncodedValue(this.storageConstants.token);

    // return this.http.get<IActiveAlarm[]>
    // (`${this.baseUrl}siteAlarmHistory/token/${siteId}/${clientId}/${dateFrom}`);

    return this.http.get<IActiveAlarm[]>
    (`${this.noderedInternal}dashboards/alarms/site_logs_new?site_id=${siteId}&date_from=${dateFrom}&date_to=${dateTo}`);

  }

  public clientAlarmSummary(): Observable<IActiveAlarm[]>{

    const token = this.storageService.getStoredEncodedValue(this.storageConstants.token);
    const clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);
    return this.http.get<IActiveAlarm[]>
    (`${this.baseUrl}clientAlarmsSummary/token/${clientId}`);

  }

  public allSitesAlarmsTotals(siteList): Observable<ISiteAlarmTotal[]>{

    const clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    const token = this.storageService.getStoredEncodedValue(this.storageConstants.token);

    return this.http.post<ISiteAlarmTotal[]>
    (`${this.baseUrl}allSitesAlarmsTotals/token/${clientId}`, {
      sites: siteList
    });

  }

  public getClientSiteAlarmSummary() : Observable<IActiveAlarm[]>{
    const clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);
    let id: string = "";

    if(clientId !== "null"){
      id = clientId;
    }else{
      id = this.storageService.getStoredEncodedValue(this.storageConstants.newClientId);
      console.log(this.storageService.getStoredEncodedValue(this.storageConstants.newClientId))
    }


    return this.http.get<IActiveAlarm[]>
    (`https://nodered.cloudbms.co.za/api/dashboards/alarms/client_active_only?client_id=${id}`);


  }

  public getAllActiveAlarmsByPriority(priority : number) : Observable<IActiveAlarm[]>{
    const token = this.storageService.getStoredEncodedValue(this.storageConstants.token);
    const clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    return this.http.get<IActiveAlarm[]>
    (`${this.baseUrl}getAllActiveAlarmsByPriority/token/${priority}/${clientId}`);
  }

  public getDeviceActiveAlarms(siteId: any, deviceTypeIds: Array<number>): Observable<IDeviceStatusAlarm[]>{

    const token = this.storageService.getStoredEncodedValue(this.storageConstants.token);
    const clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    return this.http.post<IDeviceStatusAlarm[]>
    (`${this.baseUrl}getDeviceActiveAlarms/replacement/${clientId}/${siteId}`, {
      deviceIds: deviceTypeIds
    });
  }

  public clientSiteAlarmCounts(sites : Array<string>): Observable<IClientSiteAlarmCounts[]>{
    const token = this.storageService.getStoredEncodedValue(this.storageConstants.token);
    const clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    return this.http.post<IClientSiteAlarmCounts[]>(`${this.baseUrl}clientAlarmCounts/token/${clientId}`, {
      sites: sites
    });

  }

  public getActiveAlarmHistoryCsv(data: IActiveAlarmHistoryCsvData[]): Observable<string>{

    const endpoint: string = 'https://reports.cloudbms.co.za/excel';

    return this.http.post<string>(`${endpoint}`, {
      obj: data
    },
      {
        responseType: 'text' as 'json'
      });

  }

  public getUserSiteAlarms(): Observable<IUserSiteListWithAlarms[]>{
    const token = this.storageService.getStoredEncodedValue(new Constants().storage.token);
    const decoded = jwt_decode(token);
    const userID = decoded['user']['id'];

    return this.http.get<IUserSiteListWithAlarms[]>(`${this.noderedInternal}dashboards/alarms/user_active?user_id=${userID}`);

  }

}
