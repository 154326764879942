<div class="container-fluid mt-3">
  <div class="row">
    <nz-card class="col-lg-12 col-md-12 col-sm-12" [nzBodyStyle]="{'padding': 0, 'height': '150px'}">
      <nz-page-header nzBackIcon nzTitle="Fuel Consumption">

        <nz-page-header-extra>

        </nz-page-header-extra>

        <nz-page-header-subtitle>

          <p>
            <i nz-icon nzType="info-circle" nzTheme="twotone" class="button-icon text-info"></i>
            Select a <i class="font-weight-bold">site</i>
            as well as the <i class="font-weight-bold">start</i> and <i class="font-weight-bold">end</i>  dates
          </p>

        </nz-page-header-subtitle>

        <nz-breadcrumb nz-page-header-breadcrumb>
          <nz-breadcrumb-item>Reports</nz-breadcrumb-item>
          <nz-breadcrumb-item>
            Fuel Consumption
          </nz-breadcrumb-item>
        </nz-breadcrumb>

        <nz-page-header-content>

          <div class="row">
            <div class="col-md-3 col-lg-3">
              <nz-select nzShowSearch nzAllowClear
                         nzPlaceHolder=" * Select a site"
                         [(ngModel)]="selectedSiteId" (ngModelChange)="onSiteSelected($event)">
<!--                <nz-option nzLabel="All Sites" *ngIf="clientID !== 32767" [nzValue]="0"></nz-option>-->

                <nz-option *ngFor="let site of siteList"
                           [nzLabel]="site.name"
                           [nzValue]="site?.legacy_id || site._id"></nz-option>

              </nz-select>
            </div>
            <div class="col-md-6 col-lg-6">
              <nz-month-picker
                [(ngModel)]="dateFrom" (ngModelChange)="onFromChange($event)"
                [nzFormat]="'yyyy-MM-dd'"
                nzPlaceHolder="Start Date"
                class="mr-4" [nzDisabledDate]="disabledEndDate"></nz-month-picker>

              <nz-month-picker [(ngModel)]="dateTo" (ngModelChange)="onToChange($event)"
                               [nzFormat]="'yyyy-MM-dd'"
                               nzPlaceHolder="End Date"
                               class="mr-4" [nzDisabledDate]="disabledEndDate"></nz-month-picker>

              <button nz-button nzType="primary"
                      (click)="getFuelConsumption()"
                      [nzLoading]="loadingFuelConsumption">
                Get Consumption
                <i nz-icon nzType="right" class="button-icon"></i>
              </button>

            </div>

            <div class="col-md-3 col-lg-3">

            </div>

          </div>



        </nz-page-header-content>

      </nz-page-header>
    </nz-card>
  </div>

  <div class="row">
    <nz-card class="col-lg-12 col-md-12 col-sm-12" [nzBodyStyle]="{'padding': 0}">

      <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!hasReadings && hasLoadedReport">
        <nz-card style="width: 100%" [nzBordered]="false">
          <nz-result nzStatus="404" nzTitle="Oops.."
                     nzSubTitle="Sorry, the data you requested could not be found">
          </nz-result>
        </nz-card>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12" >
        <nz-card *ngIf="!hasLoadedReport && !hasReadings && !hasReportResult;  else elseBlock"
                 style="width: 100%" [nzBordered]="false" [nzBodyStyle]="{'height': '50vh'}">
          <nz-empty nzNotFoundContent="Please select a site as well as the start and end dates to view a report"></nz-empty>
        </nz-card>
      </div>
      <ng-template  #elseBlock style="height: 65vh;" class="p-2">

        <ng-template #mainCardHeader>
          <nz-page-header nzTitle="Fuel Report" nzSubtitle="Scroll to view more" *ngIf="hasReadings">
            <nz-page-header-extra>
              <button nz-button nzType="primary" (click)="renderChartsForReportExport('excel')">Generate CSV Report</button>
              <button nz-button nzType="primary" (click)="renderChartsForReportExport('pdf')">Generate PDF Report</button>
            </nz-page-header-extra>
          </nz-page-header>
        </ng-template>

        <nz-card class="col-md-12 col-lg-12 col-sm-12" *ngIf="!hasReportResult"
                 [nzTitle]="mainCardHeader"
                 nzBordered="false" [nzLoading]="loadingFuelConsumption || isLoadingPDFReport">
          <nz-card-tab>
            <nz-tabset  [nzSelectedIndex]="index"
                        (nzSelectChange)="changeTabIndex($event.index);
                 changeChartData($event.index)">

              <nz-tab *ngFor="let tab of tabs" [nzTitle]="titleTemplate" >
                <ng-template #titleTemplate>
                  <div>{{ tab }}</div>
                </ng-template>
              </nz-tab>
            </nz-tabset>
          </nz-card-tab>
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12" style="height: 50vh; overflow-y: scroll;">

                <nz-table  *ngFor="let tank of tabTableDataArray[index]"
                           [nzTitle]="tableTitle"
                           nzTemplateMode class="mb-4">
                  <thead>
                  <ng-template #tableTitle>
                    <h6 class="font-weight-bold">{{tank.tank_name}}</h6>
                  </ng-template>
                  <tr>
                    <th>Period</th>
                    <th>Fuel Consumed</th>
                    <th>Fuel Filled</th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr *ngFor="let data of tank.readings_data">
                    <td>{{ data.date }}</td>
                    <td>{{ data.consumption }}</td>
                    <td>{{ data.fill }}</td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td class="font-weight-bolder">Totals</td>
                    <td class="font-weight-bolder">{{tank.total_consumption}} Litres</td>
                    <td class="font-weight-bolder">{{tank.total_fill}} Litres</td>
                  </tr>
                  </tfoot>

                </nz-table>



              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 full-width" style="overflow-x: scroll;">
                <div [chart]="chart"></div>
              </div>
            </div>
          </div>
        </nz-card>


      </ng-template>

      <div class="col-md-12 col-lg-12 col-sm-12" *ngIf="hasReportResult">
        <nz-card nzBordered="false" [nzBodyStyle]="{'height': '60vh'}">
          <nz-result
            nzStatus="success"
            nzTitle="Successfully Generated PDF Fuel Report"
            nzSubTitle="Click the 'Download Report' button below to download your report."
          >
            <div nz-result-extra>
              <a nz-button nzType="primary" [href]="fileUrl" download="Fuel_Report( From : {{dateFrom}} To: {{dateTo}}).{{reportDocumentType}}">Download Report</a>
              <button nz-button (click)="reloadPage()">New Report</button>
            </div>
          </nz-result>
        </nz-card>

      </div>


    </nz-card>
  </div>

</div>


<app-overlay-loader [isLoading]="loadingPageData" [loadingText]="'Loading...'"></app-overlay-loader>
