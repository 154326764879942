<div class="container-fluid">

  <div class="row">
    <app-dashboard-page-header
      class="col-lg-12 col-md-12 col-sm-12"
      [config]="headerConfig" (selectedSiteChange)="handleSiteChangeEmitter($event)">
    </app-dashboard-page-header>
  </div>

  <div class="row" *ngIf="!hasLoadedData && !isInitialising">
    <nz-card nzBordered="false"
             [nzBodyStyle]="{'padding': 0}"
             class="col-lg-12 col-md-12 col-sm-12"
             [nzLoading]="isLoadingData">

        <div class="col-lg-12 col-md-12 col-sm-12">
          <nz-result nzStatus="404" [nzTitle]="'No Data Found For '+ selectedSite?.name"
                     nzSubTitle="Click the button below to try again">
            <div nz-result-extra>
              <button nz-button nzType="primary"
                      (click)="fetchSiteDeviceReadings(selectedSite?.legacy_id)">Retry</button>
            </div>
          </nz-result>
      </div>
    </nz-card>

  </div>


  <div class="row" *ngIf="hasLoadedData && !isInitialising">
    <nz-card nzBordered="false"
             *ngFor="let device of upsData?.devices;"
             class="border-bottom mb-2 col-lg-12 col-md-12 col-sm-12"
             [nzLoading]="isLoadingData"
             [nzBodyStyle]="chartCardStyle">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-3 p-0">
            <div class="container-fluid p-0">
              <nz-descriptions [nzTitle]="descriptionTitle"
                               nzBordered nzSize="small" nzLayout="vertical">
                <nz-descriptions-item nzTitle="Battery Time Remaining" nzSpan="3">
<!--                  <nz-badge [nzStatus]="( ) "></nz-badge>-->
                  <h5 nz-typography class="font-weight-bold">
                    <i nz-icon nzType="clock-circle" nzTheme="outline" class="button-icon"></i>
                    {{device.device_values.batterytimeremaining.value | number}}
                    {{device.device_values.batterytimeremaining.unit | titlecase}}
                  </h5>
                </nz-descriptions-item>

                <nz-descriptions-item nzTitle="Battery Temperature" nzSpan="3">
                  <h5 nz-typography class="font-weight-bold">
                    {{device.device_values.batterytemperature.value | number}}
                    {{device.device_values.batterytemperature.unit}}
                  </h5>
                </nz-descriptions-item>

                <nz-descriptions-item nzTitle="Battery Voltage" nzSpan="3">
                  <h5 nz-typography class="font-weight-bold">
                    {{device.device_values.batteryvoltage.value | number}}
                    {{device.device_values.batteryvoltage.unit}}
                  </h5>
                </nz-descriptions-item>

              </nz-descriptions>

              <ng-template #descriptionTitle>
                <h3 nz-typography>{{device.device_name}}</h3>
              </ng-template>

            </div>

          </div>

          <nz-card class="col-lg-9 col-md-9 col-sm-9"
                   nzBordered="false" style="width: 100%" [nzBodyStyle]="chartCardStyle">
            <nz-card-tab>
              <nz-tabset nzType="card">

                <nz-tab nzTitle="System Input">
                  <ng-template nz-tab>
                    <h4 nz-typography class="text-center">System Input Voltage</h4>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Voltage A',
                            series_data: device.device_values.systeminputvoltagea.value,
                            value_suffix: device.device_values.systeminputvoltagea.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Voltage B',
                            series_data: device.device_values.systeminputvoltageb.value,
                            value_suffix: device.device_values.systeminputvoltageb.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Voltage C',
                            series_data: device.device_values.systeminputvoltagec.value,
                            value_suffix: device.device_values.systeminputvoltagec.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Current A',
                            series_data: device.device_values.systeminputcurrenta.value,
                            value_suffix: device.device_values.systeminputcurrenta.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Current B',
                            series_data: device.device_values.systeminputcurrentb.value,
                            value_suffix: device.device_values.systeminputcurrentb.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Current C',
                            series_data: device.device_values.systeminputcurrentc.value,
                            value_suffix: device.device_values.systeminputcurrentc.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                    </div>

                  </ng-template>
                </nz-tab>

                <nz-tab nzTitle="Output Voltage">
                  <ng-template nz-tab>
                    <h4 nz-typography class="text-center">System Output Voltage</h4>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Voltage A',
                            series_data: device.device_values.systemoutputvoltagea.value,
                            value_suffix: device.device_values.systemoutputvoltagea.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Voltage B',
                            series_data: device.device_values.systemoutputvoltagea.value,
                            value_suffix: device.device_values.systemoutputvoltagea.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Voltage C',
                            series_data: device.device_values.systemoutputvoltagea.value,
                            value_suffix: device.device_values.systemoutputvoltagea.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                    </div>
                  </ng-template>
                </nz-tab>

                <nz-tab nzTitle="Bypass Input Voltage">
                  <ng-template nz-tab>
                    <h4 nz-typography class="text-center">System Bypass Input Voltage</h4>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Bypass A',
                            series_data: device.device_values.bypassinputvoltagea.value,
                            value_suffix: device.device_values.bypassinputvoltagea.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Bypass B',
                            series_data: device.device_values.bypassinputvoltageb.value,
                            value_suffix: device.device_values.bypassinputvoltageb.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                      <div class="col-lg-4 col-md-4 col-sm-4">
                        <app-solid-gauge-chart
                          [chartData]="{
                            series_name: 'Bypass C',
                            series_data: device.device_values.bypassinputvoltagec.value,
                            value_suffix: device.device_values.bypassinputvoltagec.unit
                            }"

                          [optionsType]="'ups_voltage'"></app-solid-gauge-chart>
                      </div>
                    </div>
                  </ng-template>
                </nz-tab>

              </nz-tabset>


            </nz-card-tab>


          </nz-card>


        </div>

      </div>

    </nz-card>

  </div>

</div>
