<div class="container-fluid">
  <div style="width: 100%; height: 100%">

    <div class="row">

      <div class="col-md-6 col-lg-6 col-sm-12">
        <h6 class="mb-3">
          <span class="text-lead" style="font-size: small;">Last Reading</span><br>
          {{currentDeviceReadings?.timestamp |  date:'medium' }}
        </h6>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12">
        <button nz-button nzType="primary" class="float-md-right float-lg-right float-sm-none" (click)="getDeviceReadings()" >
          <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
          Get Current Readings
        </button>
      </div>

    </div>

    <nz-divider></nz-divider>

    <div class="container" *ngIf="!hasReading">
      <nz-result nzStatus="404" nzTitle="404"
                 nzSubTitle="Sorry, it seems as though there is no data available for this device.">

      </nz-result>
    </div>

    <nz-card class="full-width" [nzLoading]="isLoadingData" nzBordered="false">
      <div class="container" *ngIf="hasReading">
        <div class="row mb-5">
          <div class="col-md-3 col-lg-3 col-sm-3">
            <nz-statistic
              [nzValue]="(currentDeviceReadings?.supply_temperature)"
              [nzTitle]="'SupplyTemperature'"

              [nzValueStyle]="{ color: '#1890ff' }"
            >
            </nz-statistic>
            <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-3">
            <nz-statistic
              [nzValue]="currentDeviceReadings?.return_temperature"
              [nzTitle]="'Return Temperature'"
              [nzValueStyle]="{ color: '#1890ff' }"
            >
            </nz-statistic>
            <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-3">
            <nz-statistic
              [nzValue]="currentDeviceReadings?.ambient_temperature"
              [nzTitle]="'Ambient Temperature'"
              [nzValueStyle]="{ color: '#1890ff' }"
            >
            </nz-statistic>
            <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-3">
            <nz-statistic
              [nzValue]="currentDeviceReadings?.return_humidity"
              [nzTitle]="'Return Humidity'"

              [nzValueStyle]="{ color: '#1890ff' }"
            >
            </nz-statistic>
            <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
          </div>

        </div>
        <div class="row mb-5">
          <div class="col-md-3 col-lg-3 col-sm-3">
            <nz-statistic
              [nzValue]="currentDeviceReadings?.airflow"
              [nzTitle]="'Airflow'"

              [nzValueStyle]="{ color: '#1890ff' }"
            >
            </nz-statistic>
            <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-3">
            <nz-statistic
              [nzValue]="currentDeviceReadings?.free_cooling_demand"
              [nzTitle]="'Free Cooling Demand'"
              [nzValueStyle]="{ color: '#1890ff' }"
            >
            </nz-statistic>
            <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-3">
            <nz-statistic
              [nzValue]="currentDeviceReadings?.circuit_1_pressure"
              [nzTitle]="'Circuit 1 Pressure'"
              [nzValueStyle]="{ color: '#1890ff' }"
            >
            </nz-statistic>
            <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-3">
            <nz-statistic
              [nzValue]="currentDeviceReadings?.circuit_2_pressure"
              [nzTitle]="'Circuit 2 Pressure'"
              [nzValueStyle]="{ color: '#1890ff' }"
            >
            </nz-statistic>
            <ng-template #prefixTplOne><i nz-icon nzType="arrow-up"></i></ng-template>
          </div>

        </div>
      </div>
    </nz-card>

  </div>
</div>


