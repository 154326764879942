<header id="home" class="hero-area-2" style="height: 95%">
  <div class="overlay"></div>
  <nav class="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar" style="background: #020d26;">
    <div class="container">

      <p class="navbar-brand font-weight-bold">
        <img src="assets/round_bms_icon.png" alt="Cloud BMS"
             class="img-fluid d-inline-block align-top" height="30" width="30">
        Cloud BMS
      </p>

      <button class="navbar-toggler" type="button" data-toggle="collapse"
              data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <i class="lni-menu"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">

        <ul class="navbar-nav mr-auto w-100 justify-content-end">


        </ul>

      </div>
    </div>
  </nav>
  <div class="container">

    <div class="row space-100">
      <div class="col-lg-7 col-md-12 col-xs-12">
        <div class="contents">
          <h2 class="head-title"><span style="color: #60c6c4" class="shadow">Welcome Back!</span>
          </h2>
          <img src="assets/website/security_on.svg" alt="" style="width: 60%">
        </div>
      </div>
      <nz-card nzBordered="false"
               style="background: transparent !important;height: 100%;"
               class="col-lg-5 col-md-12 col-xs-12">
        <div class="intro-img pl-4">

          <h6 class="text-light">
            Select a CLIENT to continue
          </h6>

          <div class="text-light">
            <nz-select nzShowSearch nzAllowClear
                       nzPlaceHolder="Select a client" [nzLoading]="isLoading" [(ngModel)]="selectedClient"
                       style="width: 100%; margin-bottom: 25px">
              <nz-option *ngFor="let client of clients"
                         nzLabel="{{client.Client_name}}"
                         nzValue="{{client.id_client}}"
              ></nz-option>
            </nz-select>
            <span class="text-light small">You can also type in the box above to search</span>
            <br>
            <button nz-button nzType="primary" class="mb-3 mt-3" (click)="setClient()">Continue</button>

            <ng-template #prefixTemplateUser><i nz-icon nzType="user"></i></ng-template>
            <ng-template #prefixTemplateLock><i nz-icon nzType="lock"></i></ng-template>

          </div>


        </div>

      </nz-card>
    </div>
  </div>
</header>

<!-- Copyright Start  -->
<div class="copyright mt-0">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="site-info float-left">
          <p>&copy; 1999 - 2020
            <a href="http://www.kva.co.za" rel="nofollow">Master Power Technologies.</a> All rights reserved.</p>
        </div>
        <div class="float-right">
          <ul class="footer-social">
            <li>
              <a class="facebook" href="https://web.facebook.com/masterpowertechnologies/" target="_blank">
                <i class="lni lni-facebook-filled"></i>
              </a>
            </li>
            <li>
              <a class="twitter" href="https://twitter.com/MasterPowerTech" target="_blank">
                <i class="lni lni-twitter-filled"></i>
              </a>
            </li>
            <li>
              <a class="linkedin" href="http://www.linkedin.com/company/118985?trk=tyah&trkInfo=tarId%3A1401959351751%2Ctas%3Amaster%20power%20techn%2Cidx%3A1-1-1" target="_blank">
                <i class="lni lni-linkedin-original"></i>
              </a>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Copyright End -->


