<div style="background: #ffffff; padding: 30px 10px;width:100%">

  <div nz-row>

    <div nz-col nzSpan="12">
      <h6 class="mb-3">
        <span class="text-lead" style="font-size: small;">Last Reading</span><br>
        {{currentDeviceReadings?.timestamp |  date:'medium' }}
      </h6>
    </div>

    <div nz-col nzSpan="12">
      <button nz-button nzType="primary" class="float-right" (click)="getDeviceReadings()" >
        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
        Get Current Readings
      </button>
    </div>

  </div>

  <nz-divider></nz-divider>

  <div class="container" *ngIf="!hasReading">
    <nz-result nzStatus="404" nzTitle="404"
               nzSubTitle="Sorry, it seems as though there is no data available for this device.">

    </nz-result>
  </div>

  <nz-card class="full-width" [nzLoading]="isLoadingData" nzBordered="false" [nzBodyStyle]="{'padding': 0}">
    <div style="width: 100%; height: 350px;overflow-x: hidden" class="scrollable-div" *ngIf="hasReading">
      <h6 nz-typography class="mb-3">Power Meter Readings
      </h6>
      <nz-divider></nz-divider>

      <nz-tabset [nzTabPosition]="'left'">

        <!--      HARMONIC DISTORTION TAB-->

        <nz-tab nzTitle="Harmonic Distortion">
          <p class="small-text"><strong>Total Harmonic Distortion</strong></p>
          <nz-row [nzGutter]="16" class="mb-4">
            <nz-col [nzSpan]="8">
              <nz-statistic
                [nzValueTemplate]="thdv"
                [nzTitle]="'THD Voltage'"></nz-statistic>
            </nz-col>

            <nz-col [nzSpan]="8">
              <nz-statistic
                [nzValueTemplate]="thdi"
                [nzTitle]="'THD Current'"></nz-statistic>
            </nz-col>

            <ng-template #thdv>
              <nz-statistic
                [nzValue]="(currentDeviceReadings?.vrms_harmonics)"
                [nzValueStyle]="{ color: '#1890ff' }"
              >
              </nz-statistic>
            </ng-template>

            <ng-template #thdi>

              <nz-statistic
                [nzValue]="(currentDeviceReadings?.irms_harmonics)"
                [nzValueStyle]="{ color: '#1890ff' }"
              >
              </nz-statistic>
            </ng-template>

          </nz-row>

        </nz-tab>

        <!--      VOLTAGE TAB-->

        <nz-tab nzTitle="Voltage">
          <nz-row [nzGutter]="16" class="mb-4">
            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.l1_voltage)!"
                            [nzTitle]="'L1 Voltage'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>

            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.l2_voltage)!"
                            [nzTitle]="'L2 Voltage'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>

            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.l3_voltage)!"
                            [nzTitle]="'L3 Voltage'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>
          </nz-row>
          <nz-divider></nz-divider>
          <nz-row [nzGutter]="16" class="mb-4">
            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.frequency)!"
                            [nzTitle]="'Frequency'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>
          </nz-row>
        </nz-tab>

        <!--      CURRENT TAB-->

        <nz-tab nzTitle="Current">
          <nz-row [nzGutter]="16" class="mb-4">
            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.l1_current)!"
                            [nzTitle]="'L1 Current'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>

            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.l2_current)!"
                            [nzTitle]="'L2 Current'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>

            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.l3_current)!"
                            [nzTitle]="'L3 Current'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>
          </nz-row>
          <nz-divider></nz-divider>
          <nz-row [nzGutter]="16" class="mb-4">
            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.neutral_current)!"
                            [nzTitle]="'Neutral Current'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>
          </nz-row>
        </nz-tab>

        <!--      POWER TAB-->

        <nz-tab nzTitle="Power">
          <nz-row [nzGutter]="16" class="mb-4">
            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.apparent_power)!"
                            [nzTitle]="'Apparent Power'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>

            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.active_power)!"
                            [nzTitle]="'Active Power'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>

            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.power_factor)!"
                            [nzTitle]="'Power Factor'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>
          </nz-row>
          <nz-divider></nz-divider>
          <nz-row [nzGutter]="16" class="mb-4">
            <nz-col [nzSpan]="8">
              <nz-statistic [nzValue]="(currentDeviceReadings?.energy)!"
                            [nzTitle]="'Energy'"
                            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
            </nz-col>
          </nz-row>
        </nz-tab>
      </nz-tabset>
    </div>
  </nz-card>

</div>
