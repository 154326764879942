import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../../utils/Contstants';
import {StorageService} from '../../storage/storage.service';
import {Observable} from 'rxjs';
import {ISiteRectifier} from '../../../Models/Dashboards/ISiteRectifier';

@Injectable({
  providedIn: 'root'
})
export class RectifierDashboardsService {

  private baseUrl = new Constants().noderedInternal;
  private storageConstants = new Constants().storage;

  constructor(private http: HttpClient, private storageService: StorageService) { }

  public getSiteRectifierData(site_id: string): Observable<ISiteRectifier>{

    // return this.http.get<ISiteRectifier>(`${this.baseUrl}dashboards/rectifier/siteRectifierData/${site_id}`);
    return this.http.get<ISiteRectifier>(`${this.baseUrl}dashboards/rectifier/site_rectifiers?site_id=${site_id}`);

  }

}
