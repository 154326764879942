import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ClientsService} from '../../../services/clients/clients.service';
import {IClient} from '../../../Models/IClient';
import {StorageService} from '../../../services/storage/storage.service';
import {Constants} from '../../../utils/Contstants';
import {Router, ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {IUserDetails} from '../../../Models/IUserDetails';
import {AlarmsService} from '../../../services/alarms/alarms.service';
import {IFireBaseMessage} from '../../../Models/Realtime/IFireBaseMessage';
import {HttpClient} from '@angular/common/http';
import {WebSocketService} from '../../../services/web-sockets/web-socket.service';
import {Subscription} from 'rxjs';
import {AlarmHelper} from '../../../helpers/AlarmHelper';
import {IActiveAlarm} from '../../../Models/IActiveAlarm';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NzDrawerPlacement} from 'ng-zorro-antd/drawer';
import {UserNotificationsService} from '../../../services/user_notifications/user-notifications.service';
import {IUserNotificationSettings} from '../../../Models/IUserNotificationSettings';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  selectedClient: IClient;
  clientList : IClient[];

  currentUser;

  visible = false;
  placement: NzDrawerPlacement = 'left';

  //Settings Panel
  settingsPanelOpen: boolean = false;

  showReadings : boolean;
  message : Array<IFireBaseMessage> = [];
  messageLength: number = 0;

  canShowUserManagement: boolean = false;

  isLoadingData: boolean;

  // logoUrl: string = new Constants().clientZoneBackendUrl + 'clients/logo/';
  logoUrl: string = new Constants().clientZoneBackendUrl + 'clients/picture/';
  clientLogo;

  silencedAlarms: IActiveAlarm[] = [];
  userNotificationSettings: IUserNotificationSettings;
  muteAllNotifications: boolean = false;

  subscribedSocket: Subscription;
  audio = new Audio("assets/sounds/notification.mp3");
  @ViewChild('notificationBox', { static: true }) notificationBox: TemplateRef<{}>;

  notificationCounts: number = 0;
  notifications: IActiveAlarm[] = [];

  constructor(private clientService: ClientsService,
              private storageService: StorageService,
              private  router: Router,
              private authenticationService: AuthenticationService,
              private notification: NzNotificationService,
              private activatedRoute: ActivatedRoute,
              private alarmsService: AlarmsService,
              private http: HttpClient,
              private webSocketService: WebSocketService,
              private nzNotificationService: NzNotificationService,
              private userNotificationService: UserNotificationsService
              ) {

  }

  getCurrentUserRole(): void{

    this.authenticationService.getSignedInUserDetails().subscribe(response => {

      let role = response.role.name;
      if(role === 'Administrator'){
        this.canShowUserManagement = true;
      }

    }, error =>{
      console.error(error);

    });
  }

  showNotification(notification: IActiveAlarm): void{

    let key  = this.nzNotificationService.template(this.notificationBox,
      {
        nzData: {...notification},
        nzKey: notification.AlarmLogID.toString(),
        nzDuration: 3000,
        nzPlacement: 'topLeft',
        nzStyle: {
          width: '400px',
          background: 'transparent',
          padding: '0'
          // marginLeft: '-265px',
        }
      },
    );

    key.messageId = notification.AlarmLogID.toString();

  }

  removeAllNotifications(): void{
    this.nzNotificationService.remove();
  }

  ngOnInit(): void {

    // this.notifications = [
    //   {
    //     "AlarmLogID": 2,
    //     "AlarmID": 10,
    //     "description": "Utility On Load",
    //     "device_name": "TCO A",
    //     "priority_num": 0,
    //     "priority": "Event",
    //     "device_type": 3,
    //     "triggered_at": new Date(),
    //     "cleared_at": new Date(),
    //     "ClearedTime": 0,
    //     "TriggeredTime": 1659526546000,
    //     "device_instance": 1,
    //     "is_cleared": false,
    //     "controller_id": 1,
    //     "client_id": 2,
    //     "site_id": 8,
    //     "site_name": "Vereeniging CUBE",
    //     "device_element": 0,
    //     "duration": "23hrs, 14mins, and 21sec"
    //   },
    //   {
    //     "AlarmLogID": 3,
    //     "AlarmID": 24,
    //     "description": "Non-critical Alarm",
    //     "device_name": "PCU1",
    //     "priority_num": 1,
    //     "priority": "Warning",
    //     "device_type": 5,
    //     "triggered_at": new Date(),
    //     "cleared_at": new Date(),
    //     "ClearedTime": 0,
    //     "TriggeredTime": 1659526549000,
    //     "device_instance": 1,
    //     "is_cleared": false,
    //     "controller_id": 2,
    //     "client_id": 2,
    //     "site_id": 8,
    //     "site_name": "Vereeniging CUBE",
    //     "device_element": 0,
    //     "duration": "23hrs, 14mins, and 18sec"
    //   },
    //   {
    //     "AlarmLogID": 2,
    //     "AlarmID": 41,
    //     "description": "Fire System Fault",
    //     "device_name": "Fire",
    //     "priority_num": 3,
    //     "priority": "Critical",
    //     "device_type": 13,
    //     "triggered_at": new Date(),
    //     "cleared_at": new Date(),
    //     "ClearedTime": 0,
    //     "TriggeredTime": 1659526549000,
    //     "device_instance": 1,
    //     "is_cleared": false,
    //     "controller_id": 2,
    //     "client_id": 2,
    //     "site_id": 8,
    //     "site_name": "Vereeniging CUBE",
    //     "device_element": 0,
    //     "duration": "23hrs, 14mins, and 18sec"
    //   }
    // ]
    //
    // this.showNotification({
    //   "AlarmLogID": 2,
    //   "AlarmID": 41,
    //   "description": "Fire System Fault",
    //   "device_name": "Fire",
    //   "priority_num": 3,
    //   "priority": "Critical",
    //   "device_type": 13,
    //   "triggered_at": new Date(),
    //   "cleared_at": new Date(),
    //   "ClearedTime": 0,
    //   "TriggeredTime": 1659526549000,
    //   "device_instance": 1,
    //   "is_cleared": false,
    //   "controller_id": 2,
    //   "client_id": 2,
    //   "site_id": 8,
    //   "site_name": "Vereeniging CUBE",
    //   "device_element": 0,
    //   "duration": "23hrs, 14mins, and 18sec"
    // });

    this.notificationCounts = this.notifications.length;

    // Check storage for popup disable flag : popupsDisabled
    let popupsDisabled = this.storageService.getLocalStorage('popupsDisabled');
    if(popupsDisabled !== 'null' && popupsDisabled !== 'false'){
      this.muteAllNotifications = true;
    }

    this.clientList = this.getClientList();


      // this.http.get(this.clientIcon).subscribe(res => {
      //   this.clientIcon = new GenericHelpers().clientIcon(Number(this.clientService.getLegacyClientId()));
      // }, error => {
      //   this.clientIcon = this.fallbackIcon;
      // });

    this.getCurrentUserRole();
    this.getUserDetails();

    //.listen('message').subscribe((message: IAlarmNotification) => {

    this.subscribedSocket = this.webSocketService
      .listen('message').subscribe((message: IActiveAlarm) => {


        if(JSON.stringify(message).includes('client_id')
          && JSON.stringify(message).includes('AlarmLogID')){

          let foundMutedAlarm: IActiveAlarm = this.silencedAlarms.find( alarm =>
            alarm.site_id === message.site_id
            && alarm.description === message.description
            && alarm.device_name === message.device_name
            && alarm.is_cleared === message.is_cleared
            && alarm.controller_id === message.controller_id
            && alarm.AlarmLogID.toString() === message.AlarmLogID.toString()
          );
          // alarm.client_id === message.client_id
          //             && alarm.site_id === message.site_id
          //             && alarm.description === message.description
          //             && alarm.device_name === message.device_name
          //             && alarm.is_cleared === message.is_cleared

          if(foundMutedAlarm !== undefined || this.muteAllNotifications){

          }else{
            this.audio.play();
            this.showNotification(message);

          }


          this.notificationCounts += 1;
          let oldNotifications = this.notifications;
          oldNotifications.push(message);
          this.notifications = [...oldNotifications];
        }


      });

  }

  ngOnDestroy() {
    this.subscribedSocket.unsubscribe();
    // console.info("Socket Unsubscribed");
  }

  getUserDetails(): IUserDetails{

    let data;
    this.authenticationService.getSignedInUserDetails().subscribe(response => {

      this.currentUser = response;
      data = response;

      this.userNotificationService.getUserNotificationSettings(this.currentUser._id).subscribe(response => {
        this.userNotificationSettings = response;
        this.silencedAlarms = response.silenced_alarm_notifications;
      }, error => {

        this.userNotificationSettings = {
          user_id: this.currentUser._id,
          sites: [],
          silenced_alarm_notifications: []
        }
      });

      // GET SAVED ALARM NOTIFICATIONS :::::
      this.userNotificationService.getSavedNotifications(this.currentUser._id).subscribe( (e: IActiveAlarm[]) => {
        this.notifications = [...e];

        this.notificationCounts = this.notifications.length;

      }, error => {
        console.error('Failed to get saved alarm notifications', error);
      })

      this.clientService.getClient(response.client_id).subscribe( response =>{
        this.selectedClient = response;
      });

      this.clientService.getClientLogo(response.client_id).subscribe(response => {

        this.clientLogo = 'data:image/jpg;base64,' + response.data;

      }, error => {
        console.info('Could not get client Logo: ');
        // this.clientLogo = fallbackImages.client_logo;
      });

    }, error => {
      console.error('Failed to get signed in user data', error);
    });


    return data;
  }

  getClientList(): IClient[]{
    return JSON.parse(this.storageService.
    getStoredEncodedValue(
      new Constants().storage.clients)
    );
  }

  initiateClientChange(client : IClient): void{

   if( this.clientService.setCurrentClient(client) ){

    this.selectedClient = client;


     //If we are not on the dashboard -> route to dashboard

     if(this.router.url !== '/admin'){
       this.router.navigate(['/admin/']).then(r => {
         window.location.reload();
       });
     }else{

       window.location.reload();

     }

   }

  }

  signOut(): void{

    this.authenticationService.signOut();
    this.router.navigate(['/']).then( data =>{
      console.clear();
      //window.location.reload();
    });

  }

  handleToggleNotificationPopupsEvent(value: boolean): void{
    this.muteAllNotifications = !value;
    this.storageService.setLocalStorage('popupsDisabled',  String(this.muteAllNotifications));
    if(this.muteAllNotifications){
      this.nzNotificationService.success(
        'Notifications Muted',
        'Notification will not popup, however will be visible in the AMP',
        {
          nzPlacement: 'topLeft'
        }
      );
    }else {
      this.nzNotificationService.success(
        'Notifications Active',
        'Notification will now be displayed as a popup and will be visible in the AMP',
        {
          nzPlacement: 'topLeft'
        }
      );
    }
  }

  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }

  toggleSettingsPanel(): void{
    this.settingsPanelOpen = !this.settingsPanelOpen;
  }

  clearNotifications(): void{
    this.message = [];
    this.messageLength = 0;
  }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {
  //   this.showAlarmSummary = false;
  // }

  noIconLoaded(data): void{
    this.clientLogo = 'assets/round_bms_icon.png';
  }

  handleAlarmChangeEvent(count: number){
    // this.messageLength = count;
    this.notificationCounts = count;
  }


  getAlarmColor(type: string): string{
    return new AlarmHelper().getAlarmTypeColor(type);
  }


  getNotificationColor(priority: string): any{
    switch (priority){
      case 'Critical':
        return 'error';
      case 'Urgent':
        return 'warning'
      case 'Warning':
        return 'warning'
      case 'Event':
        return 'info'
    }

  }

  handleAlarmAcknowledgedEvent(alarm: IActiveAlarm): void{

    let newNotifications = [...this.notifications];
    // this.notifications = newNotifications
    //   .filter( (a: IActiveAlarm) => a.site_id !== alarm.site_id
    //     && (a.description !== alarm.description)
    //     && (a.device_name !== alarm.device_name));

    this.notifications = newNotifications
      .filter( (a: IActiveAlarm) => !(a.description === alarm.description && a.device_name === alarm.device_name && a.site_id === alarm.site_id) );


    // let newNotifications = this.notifications.filter( (a: IActiveAlarm) => a !== alarm );
    // this.notifications = [...newNotifications];
    this.notificationCounts = this.notifications.length;

  }
}
