import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Constants} from '../../utils/Contstants';
import {StorageService} from '../storage/storage.service';
import {Observable} from 'rxjs';
import {IClient} from '../../Models/IClient';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  private baseUrl = new Constants().clientZoneBackendUrl;
  private storageConstants = new Constants().storage;

  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {

    this.headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.storageService.getStoredEncodedValue(this.storageConstants.token)
    });

  }

  public getClient(_id: string): Observable<IClient>{


    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.storageService.getStoredEncodedValue(this.storageConstants.token)
    });

    // console.log('GEt CLIENT: ', `${this.baseUrl}clients/${_id}`);
    return this.http.get<IClient>(`${this.baseUrl}clients/${_id}`, {
      headers: headers
    });

  }

  getLegacyClientId(): string{
    return this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);
  }

  getClients():Observable<IClient[]>{

    return this.http.get<IClient[]>
    (`${this.baseUrl}clients/${this.storageService.getStoredEncodedValue(this.storageConstants.token)}`);

  }

  getCurrentClient() : any{

    let clientId = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    let clientList : IClient[] = JSON.parse(this.storageService.getStoredEncodedValue(this.storageConstants.clients));

    // for (let i in clientList){
    //   if( Number(clientList[i].id_client) === Number(clientId) ){
    //     return {
    //       id_client: Number(clientList[i].id_client),
    //       Client_name: clientList[i].Client_name
    //     };
    //   }
    // }

  }

  setCurrentClient(client: IClient) : boolean{

    try{
      this.storageService.
      encodeAndStore(this.storageConstants.selectedClient, client.client_id.toString() );

      this.storageService.
      encodeAndStore(this.storageConstants.selectedClientName, client.name);

      return true;
    }
    catch (e) {
      console.error(e);
      return false;
    }

  }

  getClientLogo(client_id: string): Observable<any>{
    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.storageService.getStoredEncodedValue(this.storageConstants.token)
    });

    // return this.http.get<IClient>(`https://clientzonebackend.cloudbms.co.za/api/clients/logo/${file_name}`, {
    //   headers: headers
    // });

    return this.http.get<IClient>(`${this.baseUrl}clients/picture/${client_id}`, {
      headers: headers
    });
  }

}
