<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 col-lg-6 col-sm-12">
      <h6 class="mb-3">
        <span class="text-lead" style="font-size: small;">Last Reading</span><br>
        {{currentDeviceReadings?.timestamp |  date:'medium' }}
      </h6>
    </div>
    <div class="col-md-6 col-lg-6 col-sm-12">
      <button nz-button nzType="primary" class="float-lg-right float-sm-none float-md-right" (click)="getDeviceReadings()" >
        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
        Get Current Readings
      </button>
    </div>
  </div>
</div>


<nz-divider></nz-divider>

<div style="background: #ffffff;width: 100%;">

  <nz-card style="width: 100%;" [nzLoading]="isLoadingData" nzBordered="false">
    <nz-tabset [nzTabPosition]="nzTabPosition" class="ups-tab">

      <!--    ************SYSTEM INPUT TAB************* -->
      <nz-tab nzTitle="System Input">
        <h4 nz-typography class="mb-3">System Input Readings
        </h4>
        <div style="width: 100%;height: 500px">
          <nz-tabset [nzTabPosition]="'left'">
            <nz-tab nzTitle="Input Frequency">
              <nz-row [nzGutter]="16">
                <nz-col [nzSpan]="8">
                  <nz-statistic
                    [nzValue]="(currentDeviceReadings?.systeminputfrequency)!"
                    [nzTitle]="'Input Frequency'" [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic [nzValue]="(currentDeviceReadings?.bypassinputfrequency)!"
                                [nzTitle]="'Bypass Frequency'"
                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

              </nz-row>
            </nz-tab>
            <nz-tab nzTitle="Input Current">
              <nz-row [nzGutter]="16">
                <nz-col [nzSpan]="8">
                  <nz-statistic
                    [nzValue]="(currentDeviceReadings?.systeminputcurrenta)!"
                    [nzTitle]="'Current A'" [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic [nzValue]="(currentDeviceReadings?.systeminputcurrentb)!"
                                [nzTitle]="'Current B'"
                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic [nzValue]="(currentDeviceReadings?.systeminputcurrentc)!"
                                [nzTitle]="'Current C'"
                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>
              </nz-row>
            </nz-tab>
            <nz-tab nzTitle="Input Voltage">
              <nz-row [nzGutter]="16">
                <nz-col [nzSpan]="8">
                  <nz-statistic
                    [nzValue]="(currentDeviceReadings?.systeminputvoltagea)!"
                    [nzTitle]="'Voltage A'" [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic [nzValue]="(currentDeviceReadings?.systeminputvoltageb)!"
                                [nzTitle]="'Voltage B'"
                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic [nzValue]="(currentDeviceReadings?.systeminputvoltagec)!"
                                [nzTitle]="'Voltage C'"
                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>
              </nz-row>
            </nz-tab>
<!--            <nz-tab nzTitle="Input PF">-->
<!--              <nz-row [nzGutter]="16">-->
<!--                <nz-col [nzSpan]="8">-->
<!--                  <nz-statistic-->
<!--                    [nzValue]="(currentDeviceReadings?.SystemPF_A)!"-->
<!--                    [nzTitle]="'Input PF A'" [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>-->
<!--                </nz-col>-->

<!--                <nz-col [nzSpan]="8">-->
<!--                  <nz-statistic [nzValue]="(currentDeviceReadings?.SystemPF_B)!"-->
<!--                                [nzTitle]="'Input PF B'"-->
<!--                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>-->
<!--                </nz-col>-->

<!--                <nz-col [nzSpan]="8">-->
<!--                  <nz-statistic [nzValue]="(currentDeviceReadings?.SystemPF_C)!"-->
<!--                                [nzTitle]="'Input PF C'"-->
<!--                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>-->
<!--                </nz-col>-->
<!--              </nz-row>-->
<!--            </nz-tab>-->
            <nz-tab nzTitle="System Bypass Voltage">
              <nz-row [nzGutter]="16">
                <nz-col [nzSpan]="8">
                  <nz-statistic
                    [nzValue]="(currentDeviceReadings?.bypassinputvoltagea)!"
                    [nzTitle]="'Bypass Voltage A'" [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic [nzValue]="(currentDeviceReadings?.bypassinputvoltageb)!"
                                [nzTitle]="'Bypass Voltage B'"
                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic [nzValue]="(currentDeviceReadings?.bypassinputvoltagec)!"
                                [nzTitle]="'Bypass Voltage C'"
                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>
              </nz-row>
            </nz-tab>
          </nz-tabset>

        </div>

      </nz-tab>

      <!--    ************SYSTEM Battery TAB************* -->
      <nz-tab nzTitle="System Battery">
        <h4 nz-typography class="mb-3">
          System Battery Readings
        </h4>
        <div  style="width: 100%; height: 500px">

          <div class="statistics-row">

            <p class="small-text"><strong>Battery</strong></p>
            <nz-row [nzGutter]="16">
              <nz-col [nzSpan]="8">
                <nz-statistic
                  [nzValue]="(currentDeviceReadings?.batterytimeremaining)!"
                  [nzTitle]="'Time Remaining'"
                  [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
              </nz-col>

              <nz-col [nzSpan]="8">
                <nz-statistic [nzValue]="(currentDeviceReadings?.batteryvoltage)!"
                              [nzTitle]="'Battery Voltage'"
                              [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
              </nz-col>

              <nz-col [nzSpan]="8">
                <nz-statistic [nzValue]="(currentDeviceReadings?.batterytemperature)!"
                              [nzTitle]="'Battery Temperature'"
                              [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
              </nz-col>
            </nz-row>
            <nz-divider></nz-divider>
          </div>

          <div class="statistics-row">

            <p class="small-text"><strong>Inlet & DC Buss</strong></p>

            <nz-row [nzGutter]="16">
              <nz-col [nzSpan]="8">
                <nz-statistic
                  [nzValue]="(currentDeviceReadings?.inletairtemperature)!"
                  [nzTitle]="'Inlet Air Temperature'"
                  [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
              </nz-col>

              <nz-col [nzSpan]="8">
                <nz-statistic [nzValue]="(currentDeviceReadings?.dcbuscurrent)!"
                              [nzTitle]="'DC Bus Current'"
                              [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
              </nz-col>

            </nz-row>

            <nz-divider></nz-divider>

          </div>
        </div>
      </nz-tab>

      <!--    ************SYSTEM Output TAB************* -->
      <nz-tab nzTitle="System Output">
        <h4 nz-typography class="mb-3">System Output Readings
        </h4>
        <div  style="width: 100%; height: 500px;" >

          <nz-tabset [nzTabPosition]="'left'">
            <nz-tab nzTitle="Output Frequency">
              <nz-row [nzGutter]="16">
                <nz-col [nzSpan]="12">
                  <nz-statistic
                    [nzValue]="(currentDeviceReadings?.systemoutputfrequency)!"
                    [nzTitle]="'Output Frequency'" [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>
              </nz-row>
            </nz-tab>
            <nz-tab nzTitle="Output Power">
              <nz-row [nzGutter]="16">
                <nz-col [nzSpan]="12">
                  <nz-statistic
                    [nzValue]="(currentDeviceReadings?.systemoutputpower)!"
                    [nzTitle]="'System Output Power'" [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="12">
                  <nz-statistic
                    [nzValue]="(currentDeviceReadings?.systemoutputapparentpower)!"
                    [nzTitle]="'System Output Apparent Power'" [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

              </nz-row>
            </nz-tab>
            <nz-tab nzTitle="Output Load">
              <nz-row [nzGutter]="16">
                <nz-col [nzSpan]="8">
                  <nz-statistic
                    [nzValue]="currentDeviceReadings['systemoutput%load-a']"
                    [nzTitle]="'Output Load A'"
                    [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic
                    [nzValue]="currentDeviceReadings['systemoutput%load-b']"
                    [nzTitle]="'Output Load B'"
                    [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic
                    [nzValue]="currentDeviceReadings['systemoutput%load-c']"
                    [nzTitle]="'Output Load C'"
                    [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>
              </nz-row>
            </nz-tab>
            <nz-tab nzTitle="Output Current">
              <nz-row [nzGutter]="16">
                <nz-col [nzSpan]="8">
                  <nz-statistic
                    [nzValue]="(currentDeviceReadings?.systemoutputcurrenta)!"
                    [nzTitle]="'Current A'"
                    [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic [nzValue]="(currentDeviceReadings?.systemoutputcurrentb)!"
                                [nzTitle]="'Current B'"
                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic [nzValue]="(currentDeviceReadings?.systemoutputcurrentc)!"
                                [nzTitle]="'Current C'"
                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>
              </nz-row>
            </nz-tab>
            <nz-tab nzTitle="Output Voltage">
              <nz-row [nzGutter]="16">
                <nz-col [nzSpan]="8">
                  <nz-statistic
                    [nzValue]="(currentDeviceReadings?.systemoutputvoltagea)!"
                    [nzTitle]="'Voltage A'"
                    [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic [nzValue]="(currentDeviceReadings?.systemoutputvoltageb)!"
                                [nzTitle]="'Voltage B'"
                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>

                <nz-col [nzSpan]="8">
                  <nz-statistic [nzValue]="(currentDeviceReadings?.systemoutputvoltagec)!"
                                [nzTitle]="'Voltage C'"
                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
                </nz-col>
              </nz-row>
            </nz-tab>
<!--            <nz-tab nzTitle="Output PF">-->
<!--              <nz-row [nzGutter]="16">-->
<!--                <nz-col [nzSpan]="8">-->
<!--                  <nz-statistic-->
<!--                    [nzValue]="(currentDeviceReadings?.outp)!"-->
<!--                    [nzTitle]="'Output PF A'"-->
<!--                    [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>-->
<!--                </nz-col>-->

<!--                <nz-col [nzSpan]="8">-->
<!--                  <nz-statistic [nzValue]="(currentDeviceReadings?.SystemOutputPF_B)!"-->
<!--                                [nzTitle]="'Output PF B'"-->
<!--                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>-->
<!--                </nz-col>-->

<!--                <nz-col [nzSpan]="8">-->
<!--                  <nz-statistic [nzValue]="(currentDeviceReadings?.SystemOutputPF_C)!"-->
<!--                                [nzTitle]="'Output PF C'"-->
<!--                                [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>-->
<!--                </nz-col>-->
<!--              </nz-row>-->
<!--            </nz-tab>-->
          </nz-tabset>

        </div>
      </nz-tab>
    </nz-tabset>
  </nz-card>




</div>
