<nz-drawer
  [nzBodyStyle]="{ overflow: 'hidden', padding: '0' }"
  [nzMaskClosable]="false"
  [nzWidth]="720"
  [nzVisible]="isOpen"
  [nzTitle]="taskDrawerHeader"
  [nzExtra]="taskDrawerExtra"
  [nzFooter]="taskFooterTemplate"
  [nzCloseIcon]="drawerCloseIcon"
  (nzOnClose)="toggleTasksDrawer()"
>
  <ng-container *nzDrawerContent>

    <div style="width:100%; height: 100%" *ngIf="isReady && silencedAlarms !== undefined; else ampLoader">

      <div class="task-tab-container" *ngIf="currentMode === 'management-mode'; else taskCreationScreen">

        <nz-tabset nzType="card" nzSize="small">

          <nz-tab [nzTitle]="alarmsTabTitle">
            <small class="ml-4 mr-4 mt-0 pt-0 pb-0" nz-typography nzType="secondary">There are a total of <span class="font-weight-bolder">{{alarmList.length}}</span> alarms reported. The list below shows each individual alarm and the number of occurrences</small>
            <!--  ******************* SEARCH AND FILTER CONTAINER ******************* -->
            <div class="row p-3 mb-0">
              <div class="col-8">
                <nz-input-group [nzSuffix]="suffixIconSearch">
                  <input type="text" nz-input placeholder="Search..." [(ngModel)]="searchText"/>
                </nz-input-group>
                <ng-template #suffixIconSearch>
                  <i nz-icon nzType="search"></i>
                </ng-template>
              </div>

              <div class="col-4">
                <nz-input-group [nzPrefix]="filterPrefixIcon">
                  <nz-select
                    nzBorderless
                    nzSize="small"
                    style="width: 100%"
                    nzShowSearch
                    nzAllowClear
                    nzPlaceHolder="Alarm Severity"
                    [(ngModel)]="priorityFilter" (ngModelChange)="onPriorityFilterChange($event)">
                    <nz-option nzLabel="All Alarm Severities" nzValue="All" nzHide></nz-option>
                    <nz-option nzCustomContent nzLabel="All Levels" nzValue="All">
                      <nz-badge nzStatus="default" nzText="All Levels"></nz-badge>
                    </nz-option>
                    <nz-option nzCustomContent nzLabel="Critical" nzValue="Critical">
                      <nz-badge nzStatus="error" nzText="Critical Alarms"></nz-badge>
                    </nz-option>
                    <nz-option nzCustomContent nzLabel="Urgent" nzValue="Urgent">
                      <nz-badge nzStatus="warning" nzText="Urgent Alarms"></nz-badge>
                    </nz-option>
                    <nz-option nzCustomContent nzLabel="Warnings" nzValue="Warning">
                      <nz-badge [nzColor]="'yellow'" [nzText]="'Warnings'"></nz-badge>
                    </nz-option>
                    <nz-option nzCustomContent nzLabel="Events" nzValue="Event">
                      <nz-badge nzStatus="processing" nzText="Event Notifications"></nz-badge>
                    </nz-option>
                  </nz-select>
                </nz-input-group>
                <ng-template #filterPrefixIcon>
                  <i nz-icon nzType="funnel-plot"></i>
                </ng-template>

              </div>

            </div>
            <small class="ml-4 mr-4 mt-0 pt-0 pb-0" nz-typography nzType="secondary">Search by alarm name or use the priority filter</small>
            <!--  ******************* END SEARCH AND FILTER CONTAINER ******************* -->

            <nz-divider nzPlain [nzText]="'Alarms (' + groupedAlarms?.length + ')'" nzOrientation="left" style="margin: auto 0 0 0"></nz-divider>

            <!--  ******************* ALARM LIST ******************* -->
            <div class="alarm-list-container" *ngIf="alarmList !== undefined && alarmList.length > 0; else noAlarmsTemplate">
              <app-alarm-list-item *ngFor="let alarm of groupedAlarms | filter: searchText"
                                   [alarm]="alarm.mainAlarm"
                                   (onCreationStarted)="handleCreationStartedEvent($event)"
                                   [canAction]="true"
                                   [isSilenced]="isSilencedAlarm(alarm.mainAlarm)"
                                   (onSilence)="handleAlarmSilencingEvent($event)"
                                   (onAcknowledged)="onAcknowledgeEvent($event)"
                                   [canEscalate]="userCanEscalate"
                                   [hasCount]="true"
                                   [numberOfOccurrences]="alarm.occurrences"></app-alarm-list-item>
            </div>

            <ng-template #noAlarmsTemplate>
              <nz-empty
                nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                [nzNotFoundContent]="contentTpl"
              >
                <ng-template #contentTpl>
                <span>
                  There are currently no alarms to display
                </span>
                </ng-template>
              </nz-empty>
            </ng-template>
            <!--  ******************* END ALARM LIST ******************* -->

          </nz-tab>


          <!--  ******************* DEVICE ALARM LIST ******************* -->
          <nz-tab [nzTitle]="deviceTabTitle">
            <nz-divider
              nzPlain
              [nzText]=" 'Device / Equipment types (' + devicePanels.length + ')' "
              nzOrientation="left" *ngIf="!isDisplayingDeviceAlarms"></nz-divider>

            <div class="device-list-container" *ngIf="!isDisplayingDeviceAlarms; else deviceAlarmListComponent">

              <div
                class="card"
                *ngFor="let panel of devicePanels"
              >
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex justify-content-between align-items-center">

                    <div class="list-item-title-container">
                      <div class="alarm-count-indicator"
                           [ngStyle]="{
                            'background-color': panel.alarms > 10 ? 'crimson' : '#04256b'
                            }"
                      >
                        <span>{{panel.alarms}}</span>
                      </div>
                      <h6>{{panel.name}}</h6>
                    </div>
                    <span>
                      <button
                        nz-button
                        [nzSize]="'small'"
                        nzType="link"
                        (click)="onDevicePanelSelected(true, panel.type)">
                        <span nz-icon nzType="right" nzTheme="outline"></span>
                      </button>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <ng-template #deviceAlarmListComponent>
              <nz-page-header
                (nzBack)="onDevicePanelSelected(false, 0)"
                style="padding-bottom: 0"
                nzBackIcon
              >
                <nz-page-header-title>
                  {{selectedDeviceTypeName}}
                </nz-page-header-title>
              </nz-page-header>
              <nz-divider nzPlain [nzText]=" 'Alarms (' + selectedDeviceAlarms.length + ')'" nzOrientation="left"></nz-divider>
              <div class="device-list-container">
                <app-alarm-list-item *ngFor="let alarm of selectedDeviceAlarms"
                                     [alarm]="alarm"
                                     (onCreationStarted)="handleCreationStartedEvent($event)"
                                     [canAction]="true"
                                     [isSilenced]="isSilencedAlarm(alarm)"
                                     (onSilence)="handleAlarmSilencingEvent($event)"
                                     (onAcknowledged)="onAcknowledgeEvent($event)"
                                     [canEscalate]="userCanEscalate"></app-alarm-list-item>
              </div>

            </ng-template>

          </nz-tab>
          <!--  ******************* END ALARM LIST ******************* -->

          <!--  ******************* SITE ALARM LIST ******************* -->
          <nz-tab [nzTitle]="siteTabTitle">
            <nz-divider
              nzPlain
              [nzText]=" 'Sites with alarms (' + sitePanels.length + ')' "
              nzOrientation="left" *ngIf="!isDisplayingSiteAlarms"></nz-divider>

            <div class="device-list-container" *ngIf="!isDisplayingSiteAlarms; else siteAlarmListComponent">

              <div
                class="card"
                *ngFor="let panel of sitePanels"
              >
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex justify-content-between align-items-center">

                    <div class="list-item-title-container">
                      <div class="alarm-count-indicator"
                           [ngStyle]="{
                            'background-color': panel.alarms > 10 ? 'crimson' : '#04256b'
                            }"
                      >
                        <span>{{panel.alarms}}</span>
                      </div>
                      <h6>{{panel.name}}</h6>
                    </div>
                    <span>
                      <button
                        nz-button
                        [nzSize]="'small'"
                        nzType="link"
                        (click)="onSitePanelSelected(true, panel.name)">
                        <span nz-icon nzType="right" nzTheme="outline"></span>
                      </button>
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <ng-template #siteAlarmListComponent>
              <nz-page-header
                (nzBack)="onSitePanelSelected(false, '')"
                style="padding-bottom: 0"
                nzBackIcon
              >
                <nz-page-header-title>
                 {{selectedSiteName}}
                </nz-page-header-title>
              </nz-page-header>
              <nz-divider nzPlain [nzText]=" 'Alarms (' + sitePanelAlarms.length + ')'" nzOrientation="left"></nz-divider>
              <div class="device-list-container">
                <app-alarm-list-item *ngFor="let alarm of sitePanelAlarms"
                                     [alarm]="alarm"
                                     (onCreationStarted)="handleCreationStartedEvent($event)"
                                     [canAction]="true"
                                     [isSilenced]="isSilencedAlarm(alarm)"
                                     (onSilence)="handleAlarmSilencingEvent($event)"
                                     (onAcknowledged)="onAcknowledgeEvent($event)"
                                     [canEscalate]="userCanEscalate"></app-alarm-list-item>
              </div>

            </ng-template>

          </nz-tab>

          <!--  ******************* END SITE ALARM LIST ******************* -->

          <nz-tab [nzTitle]="silencedTabTitle">
            <nz-divider nzPlain nzText="Silenced Alarms and Event notifications" nzOrientation="left" style="margin: auto 0 0 0"></nz-divider>

            <!--  ******************* SILENCED ALARM LIST ******************* -->
            <div class="alarm-list-container">
              <app-alarm-list-item *ngFor="let alarm of this.silencedList"
                                   [alarm]="alarm"
                                   (onCreationStarted)="handleCreationStartedEvent($event)"
                                   [canAction]="true"
                                   [isSilenced]="isSilencedAlarm(alarm)"
                                   (onSilence)="handleAlarmSilencingEvent($event)"
                                   (onAcknowledged)="onAcknowledgeEvent($event)"
                                   [canEscalate]="userCanEscalate"></app-alarm-list-item>
            </div>
          </nz-tab>

        </nz-tabset>
      </div>
    </div>

    <!--  TASK CREATION SCREEN  -->
    <ng-template #taskCreationScreen>
      <app-create-service-desk-ticket
        [selectedNotification]="selectedAlarm"
        (ticketCreatedEvent)="handleTaskCreatedEvent()"
        [currentUser]="currentUser"
        [clientDetails]="clientDetails"
        (acknowledgeAlarm)="onAcknowledgeEvent($event)"></app-create-service-desk-ticket>
    </ng-template>
    <!-- END TASK CREATION SCREEN  -->

  </ng-container>



</nz-drawer>

<ng-template #drawerCloseIcon>
  <i nz-icon nzType="menu-unfold" nzTheme="outline"></i>
</ng-template>

<ng-template #taskDrawerHeader>
  <nz-page-header style="padding: 0">
    <nz-page-header-title>
      Alarm Management Panel
    </nz-page-header-title>
    <nz-page-header-subtitle></nz-page-header-subtitle>
  </nz-page-header>
</ng-template>

<ng-template #taskDrawerExtra>
  <nz-page-header-extra>
<!--    <button nz-button nzType="default" nzShape="circle">-->
<!--      <span nz-icon nzType="setting" nzTheme="outline" style="margin-top: 3px; vertical-align: top;"></span>-->
<!--    </button>-->
  </nz-page-header-extra>
</ng-template>

<ng-template #taskFooterTemplate>
  <div style="float: right">
    <button nz-button nzType="link" nzDanger (click)="toggleTasksDrawer()">Close Panel</button>
  </div>
</ng-template>

<ng-template #alarmsTabTitle>
  <span><i class="lni lni-alarm mr-1"></i> Alarms ({{groupedAlarms?.length}})</span>
</ng-template>

<ng-template #deviceTabTitle>
  <span><i class="lni lni-list mr-1"></i> Devices Types ({{devicePanels?.length}})</span>
</ng-template>

<ng-template #siteTabTitle>
  <span><i class="lni lni-list mr-1"></i> Sites ({{sitePanels?.length}})</span>
</ng-template>

<ng-template #silencedTabTitle>
  <span><i class="lni lni-volume-mute mr-1"></i> Silenced ({{silencedList?.length}})</span>
</ng-template>

<ng-template  #ampLoader>
  <div class="amp-loader">
    <ng-template #indicatorTemplate><i nz-icon nzType="loading" style="color: #fff; font-size: 30px"></i></ng-template>

    <h3 class="animate__animated animate__pulse animate__infinite	infinite"> Initialising Panel <nz-spin
      nzSimple
      [nzIndicator]="indicatorTemplate" nzSize="large"></nz-spin></h3>
  </div>
</ng-template>


