export class AlarmHelper{

  getAlarmTypeColor(type: string): string{

    let response: string = '#616161';

    switch (type){
      case 'Critical': {
        response = '#D32F2F';
        break;
      }
      case 'Urgent': {
        response = '#FFA000';
        break;
      }
      case 'Warning': {
        // response = '#FFA000';
        response = '#ffc107';
        break;
      }
      case 'Event': {
        response = '#1976D2';
        break;
      }
      default: {
        response = '#616161';
        break;
      }
    }

    return response;

  }

}
