<div class="container-fluid">

  <div class="row">
    <nz-card class="col-lg-6 col-md-6 col-sm-12" [nzBodyStyle]="{'padding': 0, 'height': '150px'}">
      <nz-page-header nzBackIcon [nzGhost]="false" class="page-header-main">
        <nz-page-header-title>Alarms</nz-page-header-title>
        <nz-page-header-subtitle class="font-weight-bold">
          <i class="mdi mdi-map-marker-radius"></i>
          {{siteName}}
        </nz-page-header-subtitle>
        <nz-page-header-tags>
          <nz-tag nzColor="blue" *ngIf="alarmTypeCounts?.Critical < 10">Normal</nz-tag>
          <nz-tag nzColor="red" *ngIf="alarmTypeCounts?.Critical > 10">Critical</nz-tag>
        </nz-page-header-tags>
        <nz-page-header-extra>

          <button nz-button nzType="primary" class="ml-4" (click)="showHistory = true;">
            <i nz-icon nzType="history" class="button-icon"></i>
            Alarm History
          </button>

          <button nz-button routerLink="/admin/devices/{{selectedSiteId}}">
            <i nz-icon nzType="control" nzTheme="twotone" class="button-icon"></i>
            Devices
          </button>

        </nz-page-header-extra>

        <nz-page-header-content>
          <div class="container-fluid">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text" nz-input placeholder="Search..." [(ngModel)]="tableSearchText" />
              </nz-input-group>
              <span nz-typography nzType="secondary">Use the search box above to filter the list below</span>
              <ng-template #suffixIconSearch>
                <i nz-icon nzType="search"></i>
              </ng-template>
            </div>
          </div>
        </nz-page-header-content>


      </nz-page-header>
    </nz-card>
    <nz-card class="col-lg-6 col-md-6 col-sm-12" [nzBodyStyle]="{'height': '150px'}">
      <div class="row">
        <div class="col">
          <nz-statistic [nzValue]="(alarmTypeCounts?.Critical | number)!"
                        [nzTitle]="'Critical Alarms'" [nzSuffix]="critical_suffix"></nz-statistic>

          <ng-template #critical_suffix>
            <i nz-icon nzType="exclamation-circle" nzTheme="outline" class="text-danger"></i>
          </ng-template>
        </div>
        <div class="col">
          <nz-statistic [nzValue]="(alarmTypeCounts?.Urgent | number)!"
                        [nzTitle]="'Urgent Alarms'" [nzSuffix]="urgent_suffix"></nz-statistic>

          <ng-template #urgent_suffix>
            <i nz-icon nzType="exclamation-circle" nzTheme="outline" style="color: #ffa000;"></i>
          </ng-template>
        </div>
        <div class="col">
          <nz-statistic [nzValue]="(alarmTypeCounts?.Warning | number)!"
                        [nzTitle]="'Warnings'" [nzSuffix]="warnings_suffix"></nz-statistic>

          <ng-template #warnings_suffix>
            <i nz-icon nzType="exclamation-circle" nzTheme="outline" class="text-warning"></i>
          </ng-template>
        </div>
        <div class="col">
          <nz-statistic [nzValue]="(alarmTypeCounts?.Event | number)!"
                        [nzTitle]="'Events'" [nzSuffix]="events_suffix"></nz-statistic>

          <ng-template #events_suffix>
            <i nz-icon nzType="exclamation-circle" nzTheme="outline" class="text-info"></i>
          </ng-template>
        </div>
      </div>
    </nz-card>
  </div>

  <div class="row">
    <nz-card class="col-lg-12 col-md-12 col-sm-12"
             [nzBordered]="false"
             [nzBodyStyle]="{ 'padding': 0, 'height': '70vh' }" [nzLoading]="loadingAlarms">


      <nz-table #nzTable [nzData]="activeAlarms"
                [nzPageSize]="5000" [nzScroll]="{ y: '50vh' }"
                [nzShowPagination]="false"
                [nzLoading]="loadingAlarms"  nzSize="small"  nzFooter="{{activeAlarms?.length}} Active Alarms in Total"
                style="width: 100%;"
      >
        <thead>
        <tr>
          <th
            *ngFor="let column of listOfColumns"
            [nzSortOrder]="column.sortOrder"
            [nzSortFn]="column.sortFn"
            [nzSortDirections]="column.sortDirections"
            [nzFilterMultiple]="column.filterMultiple"
            [nzFilters]="column.listOfFilter"
            [nzFilterFn]="column.filterFn"
          >
            {{ column.name }}
          </th>

        </tr>
        </thead>
        <tbody>
        <ng-container>
          <tr  *ngFor="let data of nzTable.data| filter:tableSearchText; let i = index"
          >
            <td>{{ data?.device_name }}</td>
            <td>
              <nz-tag nzColor="red" class="priority"
                      *ngIf="data.priority === 'Critical' || data.priority === 'MPT Alarm'">
                Critical
              </nz-tag>
              <nz-tag nzColor="#ffa000" class="priority" *ngIf="data.priority === 'Urgent'">
                Urgent
              </nz-tag>
              <nz-tag nzColor="warning" class="priority" *ngIf="data.priority === 'Warning'">
                Warning
              </nz-tag>
              <nz-tag nzColor="blue" class="priority" *ngIf="data.priority === 'Event'">
                Event
              </nz-tag>
            </td>
            <td>{{ data?.description }}</td>
            <td>{{ data?.triggered_at | date : 'medium' }}</td>
            <td>{{ data?.duration }}</td>
            <!--            <td>{{ data?.is_cleared }}</td>-->
          </tr>

        </ng-container>
        </tbody>
      </nz-table>




    </nz-card>
  </div>

</div>


<app-overlay-loader [isLoading]="pageLoading" [loadingText]="loaderText"></app-overlay-loader>

<nz-drawer [nzClosable]="false"
           [nzVisible]="showHistory"
           [nzPlacement]="placement"

           (nzOnClose)="showHistory = false;" [nzWidth]="'90%'" [nzBodyStyle]="{'padding': 0}">

  <ng-container *nzDrawerContent>
    <div *ngIf="!hasGeneratedCsvReport" class="mt-5">
      <nz-page-header class="site-page-header" nzTitle="Active Alarm History">

        <nz-page-header-subtitle>
          <i class="mdi mdi-map-marker-radius"></i>
          {{siteName}}
        </nz-page-header-subtitle>
        <nz-page-header-extra>

          <button nz-button nzType="default"
                  (click)="getSiteAlarmHistoryCSV()"
                  [disabled]="!hasGeneratedAlarmHistory">
            Generate CSV
          </button>

        </nz-page-header-extra>
        <nz-page-header-content>

          <div nz-col nzSpan="24">
            <nz-row nzJustify="start" nzAlign="middle" class="header-row">

              <nz-col nzSpan="4" class="mr-4">
                <strong>Start Date</strong>
                <nz-date-picker class="date-picker" [nzAutoFocus]="true"
                                [nzDefaultPickerValue]="currentDate" [nzDisabledDate]="disabledDate"
                                [nzFormat]="'yyyy-MM-dd'" nzPlaceHolder="Select a start date"
                                [(ngModel)]="dateFrom" (ngModelChange)="onDateChange($event, 0)">

                </nz-date-picker>
              </nz-col>

              <nz-col nzSpan="4">
                <strong>End Date</strong>
                <nz-date-picker class="date-picker" [nzAutoFocus]="false"
                                [nzDefaultPickerValue]="currentDate" [nzDisabledDate]="disabledDate"
                                [nzFormat]="'yyyy-MM-dd'" nzPlaceHolder="Select an end date"
                                [(ngModel)]="dateTo" (ngModelChange)="onDateChange($event, 1)">

                </nz-date-picker>
              </nz-col>



              <nz-col nzSpan="10">
                <button nz-button nzType="primary" class="ml-4 mt-4" (click)="getSiteAlarmHistory()">
                  <i nz-icon nzType="alert" class="button-icon"></i>
                  Get Alarm History
                </button>
              </nz-col>
            </nz-row>
          </div>

        </nz-page-header-content>
      </nz-page-header>
      <nz-divider></nz-divider>

      <nz-card style="width:100%;"
               [nzBordered]="false"
               [nzBodyStyle]="{ 'padding': 0 }" [nzLoading]="loadingAlarmHistory">

        <div class="container-fluid mb-4">
          <div class="row">
            <div class="col-4">
              <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text" nz-input placeholder="Search..." [(ngModel)]="historySearchText" />
              </nz-input-group>
            </div>
          </div>

        </div>

       <div class="container-fluid">
         <nz-table #nzHistoryTable [nzData]="activeAlarmsHistory" nzTableLayout="fixed" nzShowSizeChanger
                   nzFooter="{{activeAlarmsHistory?.length}} Active Alarms in Total"
                   [nzPageSize]="10" [nzScroll]="{ y: '50vh' }"
                   [nzShowPagination]="true"
                   [nzLoading]="loadingAlarms"  nzSize="small"
         >
           <thead>
           <tr>
             <th
               *ngFor="let col of historyColumns"
               [nzSortOrder]="col.sortOrder"
               [nzSortFn]="col.sortFn"
               [nzSortDirections]="col.sortDirections"
               [nzFilterMultiple]="col.filterMultiple"
               [nzFilters]="col.listOfFilter"
               [nzFilterFn]="col.filterFn"
             >
               {{ col.name }}
             </th>

           </tr>
           </thead>
           <tbody>
           <ng-container>
             <tr  *ngFor="let data of nzHistoryTable.data | filter: historySearchText; let i = index "
             >
               <td>{{ data?.device_name }}</td>
               <td>
                 <nz-tag nzColor="red" class="priority" *ngIf="data.priority === 'Critical' ||  data.priority === 'MPT Alarm'">
                   Critical
                 </nz-tag>
                 <nz-tag nzColor="#ffa000" class="priority" *ngIf="data.priority === 'Urgent'">
                   Urgent
                 </nz-tag>
                 <nz-tag nzColor="warning" class="priority" *ngIf="data.priority === 'Warning'">
                   Warning
                 </nz-tag>
                 <nz-tag nzColor="blue" class="priority" *ngIf="data.priority === 'Event'">
                   Event
                 </nz-tag>
               </td>
               <td>{{ data?.description }}</td>
               <td>{{ data?.triggered_at | date : 'medium' }}</td>
               <td>

                 <!--              <span *ngIf="checkIfString(data?.cleared_at); else isDate">-->
                 <!--                  {{ data?.cleared_at}}-->
                 <!--              </span> -->

                 <span>
                  {{ data?.cleared_at | date: 'medium'}}
              </span>

                 <ng-template #isDate>
                   {{ data?.cleared_at | date: 'medium'}}
                 </ng-template>

               </td>
               <td class="small-text">{{ data?.duration }}</td>
               <td>{{ data?.is_cleared ? 'Cleared' : 'Active' }}</td>
             </tr>

           </ng-container>
           </tbody>
         </nz-table>
       </div>

      </nz-card>
    </div>

    <div class="container mt-5" *ngIf="hasGeneratedCsvReport">
      <nz-result
        nzStatus="success"
        nzTitle="Successfully Generated CSV"
        nzSubTitle="Click the download button, your download should begin"
      >
        <div nz-result-extra>
          <a nz-button nzType="primary" [href]="csv"
             download="{{siteName + '_' + dateFrom}}_ActiveAlarmHistory.csv"
             (click)="hasClickedDownload = true; csvDownloadClicked()"
             [nzLoading]="hasClickedDownload">
            Download CSV
          </a>


          <button nz-button (click)="showHistory = false;
        hasClickedDownload = false; hasGeneratedAlarmHistory = false;">Close</button>
        </div>
      </nz-result>
    </div>
  </ng-container>


</nz-drawer>
