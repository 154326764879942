<nz-drawer
  [nzBodyStyle]="{ overflow: 'hidden', padding: '0' }"
  [nzMaskClosable]="false"
  [nzWidth]="720"
  [nzVisible]="isOpen"
  [nzTitle]="settingsPanelHeader"

  [nzFooter]="taskFooterTemplate"
  [nzCloseIcon]="drawerCloseIcon"
  (nzOnClose)="emitClosePanel()"
>
  <ng-container *nzDrawerContent>

    <nz-tabset nzType="card" nzSize="small">
      <nz-tab [nzTitle]="generalSettingsTitle">
        <div class="main-settings-container">
          <nz-page-header
            [nzGhost]="false"
            nzTitle="General Settings" class="border-bottom">
          </nz-page-header>

          <div class="general-settings-container">
            <ul class="list-group list-group-flush">

<!--              <li class="list-group-item d-flex justify-content-between align-items-center border-bottom">-->
<!--                <h6>Enable Alerts <span><small>And some small print.</small></span></h6>-->
<!--                <nz-switch [(ngModel)]="alertsEnabled"></nz-switch>-->
<!--              </li>-->

              <li class="list-group-item list-group-item-action border-bottom">
                <div class="d-flex w-100 justify-content-between">
                  <h6 class="mb-1">Display Alert Notifications</h6>
                  <nz-switch [(ngModel)]="alertsEnabled" (ngModelChange)="handleAlertsEnabled($event)"></nz-switch>
                </div>
                <p class="mb-1">Enable or disable notification pop-ups from appearing</p>
              </li>

            </ul>
          </div>

        </div>
      </nz-tab>

      <nz-tab [nzTitle]="notificationSettingsTitle">
        <div class="main-settings-container">
          <app-user-notification-settings
            [currentUser]="currentUser"></app-user-notification-settings>
        </div>

      </nz-tab>

    </nz-tabset>


  </ng-container>

</nz-drawer>

<ng-template #settingsPanelHeader>
  <nz-page-header style="padding: 0">
    <nz-page-header-title>
      Settings
    </nz-page-header-title>
    <nz-page-header-subtitle></nz-page-header-subtitle>
  </nz-page-header>
</ng-template>

<ng-template #taskFooterTemplate>
  <div style="float: right">
    <button nz-button nzType="link" nzDanger (click)="emitClosePanel()">Close Panel</button>
  </div>
</ng-template>

<ng-template #drawerCloseIcon>
  <i nz-icon nzType="menu-unfold" nzTheme="outline"></i>
</ng-template>

<ng-template #generalSettingsTitle>
  <span><i class="lni lni-cogs mr-1"></i>General</span>
</ng-template>

<ng-template #notificationSettingsTitle>
  <span><i class="lni lni-alarm mr-1"></i>Notification Preferences</span>
</ng-template>
