import { Component, OnInit } from '@angular/core';
import {ISite} from '../../../Models/ISite';

import {UpsDashboardService} from '../../../services/dashboards/ups/ups-dashboard.service';
import {IDashboardPageHeaderConfig} from '../../../Models/IDashboardPageHeaderConfig';
import {Constants} from '../../../utils/Contstants';
import {DeviceDashboardSignature} from '../../../utils/ClassSignatures/DeviceDashboardSignature';
import {ISiteUpsData} from '../../../Models/Dashboards/ISiteUpsData';
import {IUserSiteList} from '../../../Models/IUserSiteList';

@Component({
  selector: 'app-ups-dashboard',
  templateUrl: './ups-dashboard.component.html',
  styleUrls: ['./ups-dashboard.component.scss']
})
export class UpsDashboardComponent implements OnInit, DeviceDashboardSignature {

  isInitialising: boolean;
  isLoadingData: boolean;
  hasLoadedData: boolean;
  upsData: ISiteUpsData;
  // selectedSite: ISite;
  selectedSite: IUserSiteList;

  chartCardStyle = new Constants().nzCardBodyNoPaddingStyle;

  headerConfig: IDashboardPageHeaderConfig = {
    device_type: new Constants().deviceTypeIds.ups,
    device_group: 'UPS',
    statistics: []
  }

  constructor(private upsDashboardService: UpsDashboardService) {

  }

  ngOnInit(): void {

    this.isInitialising = true;

  }

  handleSiteChangeEmitter(site: IUserSiteList): void {
    this.isInitialising = false;
    this.isLoadingData = true;
    this.selectedSite = site;

    if(site.legacy_id !== undefined){
      this.fetchSiteDeviceReadings(site.legacy_id.toString());
    }
    else{
      this.fetchSiteDeviceReadings(site._id);
    }
  }

  fetchSiteDeviceReadings(site_id: string): void {
    this.hasLoadedData = false;
    this.upsDashboardService.getSiteUpsData(site_id).subscribe( (response: ISiteUpsData) => {

      if(response !== null){

        this.upsData = response;
        this.isLoadingData = false;
        this.hasLoadedData = true;

      }else{

        this.isLoadingData = false;
        this.hasLoadedData = false;

      }

    }, error => {
      console.error('Error Fetching Data: ', error);
      this.isLoadingData = false;
    } )

  }

}
