<div class="notifications-bar-container">
  <div class="container-fluid indicator-holder">

    <div class="notification-indicator">

      <i class="lni lni-alarm"
         [ngStyle]="{'color': getAlarmColor('Critical')}"></i>
      <small>Critical</small>
      <span class="badge badge-dark count-indicator">{{siteNotificationCounts?.totals.critical}}</span>
    </div>


    <div class="notification-indicator">
      <i class="lni lni-alarm"
         [ngStyle]="{'color': getAlarmColor('Urgent')}"></i>
      <small>Urgent</small>
      <span class="badge badge-dark count-indicator">{{siteNotificationCounts?.totals.urgent}}</span>
    </div>

    <div class="notification-indicator">
      <i class="lni lni-alarm"
         [ngStyle]="{'color': getAlarmColor('Warning')}"></i>
      <small>Warnings</small>
      <span class="badge badge-dark count-indicator">{{siteNotificationCounts?.totals.warning}}</span>
    </div>

    <div class="notification-indicator">
      <i class="lni lni-alarm"
         [ngStyle]="{'color': getAlarmColor('Event')}"></i>
      <small>Events</small>
      <span class="badge badge-dark count-indicator">{{siteNotificationCounts?.totals.event}}</span>

    </div>

    <div class="notification-indicator clickable-element" (click)="showNotificationDrawer('Critical')">
      <i class="lni lni-chevron-left" [@rotatedState]='openDrawerIconState'></i>
    </div>

  </div>
</div>


<nz-drawer
  [nzVisible]="notificationDrawerVisible"
  [nzPlacement]="'right'"
  [nzTitle]="drawerTitle"
  (nzOnClose)="closeNotificationDrawer()"
  [nzClosable]="true"
  [nzWidth]="'50%'"
  [nzOffsetX]="73"
  [nzMaskClosable]="false" [nzBodyStyle]="{'padding': 0}"
>
  <ng-container>

    <ng-template #drawerTitle>
      <nz-page-header class="p-0">
        <nz-page-header-title>
          Alarms & Notifications
        </nz-page-header-title>

      </nz-page-header>
    </ng-template>

    <nz-tabset [nzTabPosition]="'top'" [nzType]="'card'">

      <nz-tab nzTitle="Active Today">
        <ng-template nz-tab>
          <app-notification-list-item
            [props]="recentNotifications"
            *ngIf="!isLoadingData"
            (acknowledgedNotification)="getRecentNotifications()"></app-notification-list-item>
        </ng-template>
      </nz-tab>

      <nz-tab nzTitle="All Unattended">
        <app-unattended-notifications></app-unattended-notifications>
      </nz-tab>

      <nz-tab nzTitle="Muted Alarms">
        <ng-template nz-tab>
          <app-notification-list-item
            [props]="currentMutedList"
            *ngIf="!isLoadingData"
            (acknowledgedNotification)="getRecentNotifications()"
            (requestRefresh)="handleRequestRefreshEvent($event)"></app-notification-list-item>
        </ng-template>

      </nz-tab>
    </nz-tabset>

  </ng-container>
</nz-drawer>

<ng-template #notificationBox let-alarm="data">
  <nz-card nzBordered="false"
           [nzActions]="[actionSetting, actionEdit, actionEllipsis]"
           [nzBodyStyle]="{'padding': 0}">

    <div class="notification-list-item-container m-0">
      <div [ngStyle]="{'background-color': getAlarmColor(alarm?.alarm_priority)}"></div>
      <div class="card mb-2 border-0">
        <div class="row no-gutters">
          <div class="col-md-12">
            <div class="card-body">
              <div class="d-flex w-100 justify-content-between mb-0">
                <h5 class="h6 card-title mb-0"> {{alarm.id}} {{alarm?.alarm_name}}</h5>
                <h5 class="h6 card-title mb-0"> {{alarm?.device_name}}</h5>
              </div>
              <div class="d-flex w-100 justify-content-between mt-0 mb-0">
                <span class="card-text mb-0">{{alarm?.site_name}}</span>
                <small class="card-text mb-0 mr-0"><nz-tag class="float-right" [ngStyle]="{'color': (!alarm?.active)? 'green': 'red'}">{{!alarm?.active ? 'CLEARED': 'ACTIVE'}}</nz-tag></small>
              </div>

              <small class="text-muted card-text">{{alarm?.triggered_time | date: 'medium'}}</small>
            </div>


          </div>
        </div>
      </div>
    </div>

    <ng-template #actionSetting>
            <a href="javascript:void(0)"
               (click)="acknowledgeNotification(alarm);"
               nz-button nzType="link" [nzLoading]="isAcknowledging">
              <i class="lni lni-checkmark-circle"></i>
              Acknowledge
            </a>
    </ng-template>

    <ng-template #actionEdit>
      <a href="javascript:void(0)" (click)="openCreateTicketModal(alarm); selectedNotification = alarm">
        <i class="lni lni-ticket"></i>
        Generate Ticket
      </a>
    </ng-template>
    <ng-template #actionEllipsis>
            <a href="javascript:void(0)"
               (click)="muteNotification(alarm)">
              <i class="lni lni-volume-mute"></i>
              Mute
            </a>
    </ng-template>
  </nz-card>
</ng-template>


<!--
*********
CREATE TICKET MODAL
**********
-->

<nz-modal [(nzVisible)]="showCreateTicketModal"
          [nzTitle]="createTicketModalTitle"
          [nzFooter]="null"
          (nzOnOk)="closeTicketModal()"
          (nzOnCancel)="closeTicketModal()" nzWidth="60%">

  <ng-template #createTicketModalTitle>
    <h5>Generate Ticket</h5>
  </ng-template>

  <ng-container *ngIf="showCreateTicketModal">
    <app-create-service-desk-ticket
      [selectedNotification]="selectedNotification"
      (ticketCreatedEvent)="closeTicketModal()"></app-create-service-desk-ticket>
  </ng-container>


</nz-modal>
