import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../utils/Contstants';
import {StorageService} from '../storage/storage.service';
import {Observable} from 'rxjs';
import {IFuelUsage} from '../../Models/Reports/IFuelUsage';
import {IClientSiteTankLevel} from '../../Models/Reports/IClientSiteTankLevel';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class FuelConsumptionService {

  private baseUrl = new Constants().noderedInternal;
  private storageConstants = new Constants().storage;
  private clientSiteFuelTankLevelsEndpoint: string;

  constructor(private http: HttpClient, private storageService: StorageService) {

    this.clientSiteFuelTankLevelsEndpoint = new Constants().clientSiteFuelTankEndpoint;

  }

  public fuelUsage(date_to: any, date_from: any, site_id: number, clientID: number):Observable<IFuelUsage[]>{

    //const client = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    // return this.http.post<IFuelUsage[]>(`https://bmsgateway.cloudbms.co.za/api/reports/fuel_usage`, {
    //   client_id: client,
    //   site_id: site_id,
    //   date_to: date_to,
    //   date_from: date_from,
    // });

    // return this.http.get<IFuelUsage[]>(`https://appservices.cloudbms.co.za/api/reports?client_id=${client}&site_id=${site_id}&date_from=${date_from}&date_to=${date_to}&tank_name=bulk&period=monthly&cloud_bms=true&algorithm=drop_and_remain,rise_and_remain`);
    return this.http.get<IFuelUsage[]>(`https://appservices.cloudbms.co.za/api/reports?client_id=${clientID}&site_id=${site_id}&date_from=${date_from}&date_to=${date_to}`);

  }

  public generateFuelReportPdf(content, metaData){

    return this.http.post(` https://reports.cloudbms.co.za/pdf`, {
      obj: {
        content: content,
        ...metaData
      }
    }, {responseType: 'text'});

  }

  public generateFuelReportCSV(content){

    return this.http.post(` https://reports.cloudbms.co.za/excel`, {
      obj: [...content]
    }, {responseType: 'text'});

  }


  public getUserSiteFuelTotals(): Observable<Array<{site_id: number ,site_name: string, site_total_fuel: number}>>{
    const token = this.storageService.getStoredEncodedValue(new Constants().storage.token);
    const decoded = jwt_decode(token);
    const userID = decoded['user']['id'];

    return this.http.get<Array<{site_id: number, site_name: string, site_total_fuel: number}>>
    (`${this.baseUrl}dashboards/fuel/user_totals?user_id=${userID}`);


  }
  public getClientFuelLevels(): Observable<Array<{site_id: number ,site_name: string, site_total_fuel: number}>>{
    let clientID: string = '';

    const client = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);
    console.log(typeof client);
    if(client === 'null'){
      clientID = this.storageService.getStoredEncodedValue(this.storageConstants.newClientId);
    }else{
      clientID = client;
    }
    // return this.http.get<Array<{site_id: number, site_name: string, site_total_fuel: number}>>(
    //   `${this.baseUrl}reports/client_fuel_levels/${client}`);

    return this.http.get<Array<{site_id: number, site_name: string, site_total_fuel: number}>>(
      `${this.baseUrl}dashboards/fuel/client_totals?client_id=${clientID}`);
  }


  public getClientSiteTankLevels(site_id: number): Observable<IClientSiteTankLevel>{
    const client = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);

    // return this.http.get<IClientSiteTankLevel>(`${this.baseUrl}reports/ClientSiteTankLevels/${client}/${site_id}`);

    return this.http.get<IClientSiteTankLevel>(`${this.baseUrl}dashboards/fuel/site_totals?site_id=${site_id}`);
  }

  public getSpecificTimeConsumptionForSite(siteID: number, date: string): Observable<{ value: number }>{

    return this.http
      .get<{ value: number }>
      (`${this.baseUrl}analytics/site_total_fuel?date_from=${date}&site_id=${siteID}`);

  }

}
