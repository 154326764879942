<div style="height: 100%">
  <nz-table #nzTable [nzData]="readings"
            nzTableLayout="fixed" [nzPageSize]="10" [nzScroll]="{ y: '350px' }">
    <thead>
    <tr>
      <th
        *ngFor="let column of listOfColumns"
        [nzSortOrder]="column.sortOrder"
        [nzSortFn]="column.sortFn"
        [nzSortDirections]="column.sortDirections"
        [nzFilterMultiple]="column.filterMultiple"
        [nzFilters]="column.listOfFilter"
        [nzFilterFn]="column.filterFn"
      >
        {{ column.name }}
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let data of nzTable.data">
      <tr >
        <td>{{ data?.device_name }}</td>
        <td>
          <nz-tag nzColor="red" *ngIf="data.priority === 'Critical'">
            Critical
          </nz-tag>
          <nz-tag nzColor="#ffa000" *ngIf="data.priority === 'Urgent'">
            Urgent
          </nz-tag>
          <nz-tag nzColor="warning" *ngIf="data.priority === 'Warning'">
            Warning
          </nz-tag>
          <nz-tag nzColor="blue" *ngIf="data.priority === 'Event'">
            Event
          </nz-tag>
        </td>
        <td>{{data?.description}}</td>
        <td>{{ data.triggered_at | date: 'medium' }}</td>
        <td>{{ data.duration}}</td>
      </tr>
      <tr [nzExpand]="expandSet.has(data.id)">
        {{ data.description}}
      </tr>
    </ng-container>
    </tbody>
  </nz-table>

</div>
