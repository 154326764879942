import { NgModule } from '@angular/core';

import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCardModule } from 'ng-zorro-antd/card';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import {NzModalModule} from 'ng-zorro-antd/modal';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import {NzStatisticModule} from 'ng-zorro-antd/statistic';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import {NzSkeletonModule} from 'ng-zorro-antd/skeleton';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {NzDrawerModule} from 'ng-zorro-antd/drawer';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzUploadModule} from 'ng-zorro-antd/upload';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';


@NgModule({
  declarations: [],
  exports: [
    NzPageHeaderModule,
    NzLayoutModule,
    NzIconModule,
    NzButtonModule,
    NzTypographyModule,
    NzStepsModule,
    NzInputModule,
    NzFormModule,
    NzAlertModule,
    NzResultModule,
    NzNotificationModule,
    NzTableModule,
    NzSelectModule,
    NzListModule,
    NzCardModule,
    NzCheckboxModule,
    NzModalModule,
    NzMessageModule,
    NzDividerModule,
    NzTabsModule,
    NzDescriptionsModule,
    NzCollapseModule,
    NzAvatarModule,
    NzSwitchModule,
    NzPopconfirmModule,
    NzTagModule,
    NzDropDownModule,
    NzStatisticModule,
    NzInputNumberModule,
    NzSkeletonModule,
    NzBreadCrumbModule,
    NzPaginationModule,
    NzDrawerModule,
    NzRadioModule,
    NzEmptyModule,
    NzUploadModule,
    NzDatePickerModule,
  ],
  imports: [],
  providers: []
})
export class AntDesignModule { }
