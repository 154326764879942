<div class="container-fluid">

  <div class="row" *ngIf="hasErrorLoading">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <nz-result nzStatus="404" nzTitle="No Data Found"
                 nzSubTitle="Reload by clicking Try again">
        <div nz-result-extra>
          <button nz-button nzType="primary"
                  (click)="initializeComponent()">
            Try Again
          </button>
        </div>
      </nz-result>
    </div>
  </div>

  <div class="row" *ngIf="!hasErrorLoading">
    <nz-card class="col-lg-12 col-md-12 col-sm-12"
             nzBordered="false"
             [nzBodyStyle]="{'padding': '0 5'}" [nzLoading]="isLoading">

      <ng-template #MainTitle>
        <nz-page-header class="mb-0 border-bottom p-0">
          <nz-page-header-title>{{client.name}}</nz-page-header-title>
          <nz-page-header-subtitle>Site Fuel Totals</nz-page-header-subtitle>
        </nz-page-header>
      </ng-template>

      <div class="container-fluid">

        <div class="row mb-1">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <nz-spin nzTip="Loading..." [nzSpinning]="isLoadingSiteData" style="width: 100%;">
              <figure class="highcharts-figure" id="figure">
                <div [chart]="clientSiteFuelLevelsChart" style="width: 90vw"></div>
              </figure>
            </nz-spin>
          </div>
        </div>



      </div>

    </nz-card>
  </div>


</div>

<nz-drawer [nzClosable]="false"
           [nzVisible]="showSiteFuelModal"
           [nzPlacement]="'left'"
           (nzOnClose)="showSiteFuelModal = false; disposeSpecificFuelData();"
           [nzWidth]="'90%'" [nzBodyStyle]="{'padding': 0}">
  <ng-container *nzDrawerContent>
    <div class="container-fluid" style="padding-top: 20px">

      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-4">
          <nz-statistic [nzValueTemplate]="currentSiteValue"
                        [nzTitle]="currentSiteTitle">
          </nz-statistic>

          <ng-template #currentSiteTitle>
            <h5>Fuel Readings for</h5>
          </ng-template>

          <ng-template #currentSiteValue>

            <h3 nz-typography>
              <nz-badge nzStatus="processing"></nz-badge>
              {{selectedSiteDetails?.site.site_name}}
              <!--              <span><small>Litres</small></span>-->
            </h3>
          </ng-template>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4">
          <nz-statistic [nzValueTemplate]="currentTotalLitresValue"
                        [nzTitle]="currentTotalLitresTitle">
          </nz-statistic>

          <ng-template #currentTotalLitresTitle>
            <h5>Total Litres Available</h5>
          </ng-template>

          <ng-template #currentTotalLitresValue>

            <h3 nz-typography>
              <nz-badge nzStatus="processing"></nz-badge>
              {{selectedSiteDetails?.site.total_liters}}
              <span><small>Litres</small></span></h3>
          </ng-template>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-4">
          <nz-statistic [nzValueTemplate]="currentTanksValue"
                        [nzTitle]="currentTanksTitle">
          </nz-statistic>

          <ng-template #currentTanksTitle>
            <h5>Tanks Available</h5>
          </ng-template>

          <ng-template #currentTanksValue>

            <h3 nz-typography>
              <nz-badge nzStatus="processing"></nz-badge>
              {{selectedSiteDetails?.site.device_count}}
            </h3>
          </ng-template>

        </div>
      </div>
      <nz-divider></nz-divider>


      <nz-tabset nzType="card">
        <nz-tab [nzTitle]="tankTabTitle">

          <ng-template #tankTabTitle>
            <h5> <i nz-icon nzType="experiment" nzTheme="outline"></i> Tanks</h5>
          </ng-template>

          <div class="row">

            <div class="col-lg-4 col-md-4 col-sm-12 border-right"
                 style="overflow-y: scroll; height: 60vh;">
              <nz-card nzBordered="false"
                       style="width: 100%; margin-bottom: 8px;"
                       *ngFor="let tank of selectedSiteDetails?.tanks"
                       [nzBodyStyle]="{'padding': 0}">
                <nz-descriptions
                  [nzTitle]="tankTitle"
                  nzBordered [nzSize]="'small'">

                  <ng-template #tankTitle>
                    <h5 class="font-weight-bold">{{tank.tank_name}}</h5>
                    <small>{{tank.time_stamp | date: 'short'}}</small>
                  </ng-template>

                  <nz-descriptions-item nzTitle="Litres" [nzSpan]="3">
                    {{tank.litres}} L
                  </nz-descriptions-item>
                  <nz-descriptions-item nzTitle="Percentage" [nzSpan]="3">
                    {{tank.percentage}} %
                  </nz-descriptions-item>
                  <nz-descriptions-item nzTitle="Max Capacity" [nzSpan]="3">
                    {{tank.capacity}} L
                  </nz-descriptions-item>
                </nz-descriptions>
              </nz-card>
            </div>

            <div class="col-lg-8 col-md-8 col-sm-12">
              <figure class="highcharts-figure-in-modal">
                <div [chart]="siteFuelFuelTankLevelsChart"></div>
              </figure>
            </div>

          </div>
        </nz-tab>
        <nz-tab [nzTitle]="trendTabTitle">
          <ng-template #trendTabTitle>
            <h5> <i nz-icon nzType="line-chart" nzTheme="outline"></i> Trend</h5>
          </ng-template>
          <div class="row">
            <div class="col-6">
              <nz-form-item>
                <nz-form-label [nzSpan]="24">Select Time Range</nz-form-label>
                <nz-form-control [nzSpan]="24" nzExtra="Select a range from the dropdown and the graphs will change">
                  <nz-select nzPlaceHolder="Select a range"
                             [(ngModel)]="selectedTrendTimeRange"
                             (ngModelChange)="changeDeviceTrendRange()">
                    <nz-option *ngFor="let range of trendTimeRanges"
                               [nzLabel]="range.name"
                               [nzValue]="range"></nz-option>
                    <nz-option nzLabel="This month so far" nzValue="This month so far" nzHide></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item >
            </div>
            <div class="col-6">
              <h5 class="mt-5"><i nz-icon nzType="calendar" nzTheme="outline" style="margin-right: 10px"></i> Selected Range: <span style="font-weight: bold">{{selectedTrendTimeRange.name}}</span></h5>
            </div>
          </div>
          <iframe
            [src]="currentRedashSrc" frameborder="0" height="400"></iframe>
        </nz-tab>

        <nz-tab [nzTitle]="fuelTimeTabTitle">

          <ng-template #fuelTimeTabTitle>
            <h5> <i nz-icon nzType="experiment" nzTheme="outline"></i>Fuel By Time</h5>
          </ng-template>
          <nz-page-header
            nzTitle="Fuel Consumption By Time"
            nzSubtitle="">

            <nz-page-header-content>
              <div class="row">
                <div class="col-md-4 col-lg-4">
                  <nz-date-picker
                    style="width: 100%"
                    [(ngModel)]="fuelSpecificDate"
                    (ngModelChange)="fuelTimeDateChange($event)"
                    nzFormat="yyyy-MM-dd HH:mm"
                    [nzDisabledDate]="disabledEndDate"
                    [nzDisabledTime]="disabledDateTime"
                    nzShowTime
                  ></nz-date-picker>
                </div>

                <div class="col-md-4 col-lg-4">
                  <button nz-button nzType="default" (click)="getSpecificFuelTimeConsumptionValue()">Get Consumption</button>
<!--                  <nz-time-picker [(ngModel)]="fuelSpecificTime" nzFormat="HH:mm"-->
<!--                  [nzDisabledHours]="test"-->
<!--                                  [disabled]="!canSetTime"-->
<!--                  ></nz-time-picker>-->
                </div>

              </div>
            </nz-page-header-content>

          </nz-page-header>
          <div class="row">
            <div class="col-md-3">

            </div>
            <div class="col-md-6">

              <nz-result nzStatus="info"
                         nzTitle="Select the date and time in order to get the consumption"
              *ngIf="!canDisplaySpecificReading">

              </nz-result>

              <nz-result
                nzStatus="success"
                [nzTitle]="'Fuel Reading for ' +  this.selectedSiteDetails.site.site_name"
                [nzSubTitle]="specificFuelDate" *ngIf="canDisplaySpecificReading"
              >

                <ng-template #specificFuelDate>
                  <h3 style="text-align: center">
                    For date :  {{this.formattedDateForDisplay}} at time :  {{this.fuelSpecificDate.getHours()}}:{{this.fuelSpecificDate.getMinutes()}}
                  </h3>
                </ng-template>

                <div nz-result-extra>
                  <h1>{{specificFuelReading}} Litres</h1>
                  <button nz-button nzType="default" (click)="copyFunction()">
                    <i nz-icon nzType="copy" nzTheme="outline"></i> Copy Value
                  </button>
                </div>
              </nz-result>
            </div>
            <div class="col-md-3">

            </div>
          </div>


        </nz-tab>

      </nz-tabset>



    </div>
  </ng-container>
</nz-drawer>


