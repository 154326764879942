import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageService} from '../storage/storage.service';
import {Constants} from '../../utils/Contstants';
import {IAlarmNotification, IClientSiteAlarmNotification} from '../../Models/notifications/IAlarmNotification';
import {AuthenticationService} from '../authentication/authentication.service';
import {ISiteAlarmNotificationCountTotals} from '../../Models/notifications/ISiteAlarmNotificationCount';
import {IMutedAlarm} from '../../Models/notifications/IMutedAlarm';
import {IMutedNotification} from '../../Models/notifications/IMutedNotification';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {


  private baseUrl = new Constants().baseUrl;
  private clientZone: string = new Constants().clientZoneBackendUrl;
  private storageConstants = new Constants().storage;

  constructor(private http: HttpClient,
              private storageService: StorageService,
                  private authenticationService: AuthenticationService) { }

  getUnattendedAlarms(): Observable<IClientSiteAlarmNotification[]>{
    const clientId = this.authenticationService.getSignedInUserDetails().client_id;

    return this.http.get<IClientSiteAlarmNotification[]>
    (`https://nodered.cloudbms.co.za/api/alarms/unattended/grouped?client_id=${clientId}`);
  }



  //Methods Being Used
  getClientSiteUnattendedAlarmNotificationCounts(): Observable<ISiteAlarmNotificationCountTotals>{
    const clientId = this.authenticationService.getSignedInUserDetails().client_id;


    return this.http.get<ISiteAlarmNotificationCountTotals>
    (`https://nodered.cloudbms.co.za/api/alarms/unattended/numbers?client_id=${clientId}`);

  }

  getSiteUnattendedAlarmNotifications(site_id: string): Observable<IAlarmNotification[]>{
    const clientId = this.authenticationService.getSignedInUserDetails().client_id;

    return this.http.get<IAlarmNotification[]>
    (`https://nodered.cloudbms.co.za/api/alarms/unattended/site?client_id=${clientId}&site_id=${site_id}`);

  }

  getRecentUnattendedAlarmNotifications(): Observable<IAlarmNotification[]>{
    const clientId = this.authenticationService.getSignedInUserDetails().client_id;
    return this.http.get<IAlarmNotification[]>
    (`https://nodered.cloudbms.co.za/api/alarms/unattended/today?client_id=${clientId}`);
  }

  acknowledgeAlarmNotification(notification: IAlarmNotification): Observable<any>{

    return this.http.put<any>(`https://nodered.cloudbms.co.za/api/alarms/ack/`, {
      site_id: notification.site_id,
      client_id: notification.client_id,
      controller_id: notification.legacy_data.controller_id,
      alarm_name: notification.alarm_name
    });

  }

  muteNotification(notification: IAlarmNotification): Observable<any>{

    const token = this.storageService.getStoredEncodedValue(new Constants().storage.token);
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token
    });

    return this.http.post(`${this.clientZone}sites/mute_notification/${notification.site_id}`, {
      description: notification.alarm_name,
      device_name: notification.device_name,
      device_type: notification.device_type,
      priority: notification.alarm_priority,
      active: notification.active,
    }, {
      headers
    });
  }

  getMutedNotifications(): Observable<IMutedNotification[]>{
    const clientId = this.authenticationService.getSignedInUserDetails().client_id;
    const token = this.storageService.getStoredEncodedValue(new Constants().storage.token);
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + token
    });
    return this.http.get<IMutedNotification[]>(`${this.clientZone}sites/muted_notifications/by_client/${clientId}`, {
      headers
    });
  }

  unmuteNotification(mutedNotification: IAlarmNotification): Observable<{message: string}>{

    return this.http.put<{message: string}>(`https://nodered.cloudbms.co.za/updateDB/Alarms/muted`,{
      site_id: mutedNotification.site_id,
      client_id: mutedNotification.client_id,
      controller_id: mutedNotification.legacy_data.controller_id,
      alarm_name: mutedNotification.alarm_name,
      is_muted: !mutedNotification.is_muted
    });

  }


}
