import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../../utils/Contstants';
import {StorageService} from '../../storage/storage.service';
import {Observable} from 'rxjs';
import {ISiteHVACData} from '../../../Models/Dashboards/ISiteHVACData';
import {IClient} from '../../../Models/IClient';
import {IClientActiveAlarms} from '../../../Models/Dashboards/IClientActiveAlarms';

@Injectable({
  providedIn: 'root'
})
export class AlarmsDashboardService {

  private baseUrl = new Constants().noderedInternal;
  private storageConstants = new Constants().storage;

  constructor(private http: HttpClient, private storageService: StorageService) { }

  public getClientActiveAlarms(): Observable<IClientActiveAlarms[]>{
    const client = this.storageService.getStoredEncodedValue(this.storageConstants.selectedClient);
    // return this.http.get<IClientActiveAlarms[]>(`${this.baseUrl}dashboards/alarms/clientActiveAlarms/${client}`);
    return this.http.get<IClientActiveAlarms[]>(`${this.baseUrl}dashboards/alarms/client_active_new?client_id=${client}`);

  }
}
