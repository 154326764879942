<router-outlet></router-outlet>


<nz-modal [(nzVisible)]="popiaModalVisible"
          [nzTitle]=""
          [nzWidth]="'50%'"
          nzClosable="false"
          [nzFooter]="tplFooter">
  <ng-container>
    <div style="padding: 10px">
      <h2>
        <i nz-icon
           nzType="exclamation-circle"
           nzTheme="outline"
           class="button-icon text-danger"></i>
        Privacy Notice
      </h2>
      <nz-divider></nz-divider>
      <p style="color: #0e1928">
        We being Master Power Technologies wish to advise and confirm that by accessing or using our website, you acknowledge that you have read all of the privacy terms of our POPI privacy policy wherein you understand and agree that all information provided, whether personal or otherwise, may be used and processed by Master Power Technologies and such use may include placing such information in the public domain.
      </p>
      <p style="color: #0e1928">
        Further by continuing to access this website you specifically agree that Master Power Technologies use such information provided by you, irrespective of the nature of such information. However Master Power Technologies will use its best endeavours and take all reasonable precautions to ensure that any information provided, is only used for the purposes it has been provided.
      </p>
      <p style="color: #0e1928">Please note that by submitting information via this Website, you consent to the collection, collation, processing, and storing of such information and the use and disclosure of such information in accordance with Master Power Technologies privacy policy.</p>

      <nz-divider></nz-divider>
      <p style="color: #0e1928; text-decoration: underline;">
        If you do not agree to be bound by these terms, please exit this page and do not access or use the website.
      </p>
    </div>
  </ng-container>
</nz-modal>

<ng-template #POIPIA_TITLE>
  <h3>
    <img src="assets/round_bms_icon.png" alt="Cloud BMS"
         class="img-fluid d-inline-block align-top" height="30" width="30">
    Cloud BMS
  </h3>
</ng-template>

<ng-template #tplFooter let-ref="modalRef">

  <button nz-button nzType="primary" (click)="handlePOPIAConfirm()">Accept & Continue</button>
  <!--  <button nz-button nzType="primary" (click)="destroyTplModal(ref)" [nzLoading]="tplModalButtonLoading">Close after submit</button>-->
</ng-template>
