import {Component, Input, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import {ChartConfigurations} from '../../../utils/ChartConfigurations';
import {IDynamicChartData} from '../../../Models/Charts/IDynamicChartData';

@Component({
  selector: 'app-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent implements OnInit {

  @Input() chartData: IDynamicChartData;
  @Input() optionsType: string;

  chart: Chart;

  chartOptions;

  constructor() { }

  ngOnInit(): void {


    if(this.optionsType === 'voltage'){
      this.chartOptions = new ChartConfigurations().RectifierOutputVoltageChartOptions;
    }

    if(this.optionsType === 'used_capacity'){
      this.chartOptions = new ChartConfigurations().RectifierOutputCurrentChartOptions;
    }

    if(this.optionsType === 'hvac_temperature_percentage'){
      this.chartOptions = new ChartConfigurations().HVACTemperatureDegreesChartOptions;
      this.chartOptions.title.text = this.chartData.series_name + " °C";
    }
    if(this.optionsType === 'ups_voltage'){
      this.chartOptions = new ChartConfigurations().upsVoltageChartOptions;
      this.chartOptions.title.text = this.chartData.series_name;
    }

    this.renderChartData();

  }

  renderChartData(): void{

    let series = [];

    if(this.optionsType === 'ups_voltage'){
      series = [
        {
          name: 'Voltage A',
          data: [Number(this.chartData.series_data[0])],
          yAxis: 0
        },
        {
          name: 'Voltage B',
          data: [Number(this.chartData.series_data[1])],
          yAxis: 1
        },
        {
          name: 'Voltage C',
          data: [Number(this.chartData.series_data[2])],
          yAxis: 2
        },
      ];
    }else{
      series = [{
        name: this.chartData.series_name,
        //@ts-ignore
        data: [Number(this.chartData.series_data)],
        tooltip: {
          valueSuffix: ` ${this.chartData.value_suffix}`
        }
      }];
    }

    this.chart = new Chart(Highcharts.merge(this.chartOptions,{
      series: series
    }));

  }

}
