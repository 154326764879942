<ng-container class="user-notification-settings-container" *ngIf="!initialising; else ampLoader">
  <nz-page-header
    [nzGhost]="false"
    class="border-bottom"
    nzTitle="Notification Preferences"
    nzSubtitle=""
  ></nz-page-header>

<!--  <nz-divider nzPlain nzText="Notification Priorities" nzOrientation="left"></nz-divider>-->

<!--  <ul class="list-group list-group-horizontal">-->
<!--    <li class="list-group-item d-flex w-100 justify-content-between flex-fill">-->
<!--      Critical <nz-switch [ngModel]="checkPrioritySelected('Critical')" (ngModelChange)="prioritySwitchChanged($event, 'Critical')"></nz-switch>-->
<!--    </li>-->

<!--    <li class="list-group-item d-flex w-100 justify-content-between flex-fill">-->
<!--      Urgent <nz-switch [ngModel]="checkPrioritySelected('Urgent')" (ngModelChange)="prioritySwitchChanged($event, 'Urgent')"></nz-switch>-->
<!--    </li>-->

<!--    <li class="list-group-item d-flex w-100 justify-content-between flex-fill">-->
<!--      Warning <nz-switch [ngModel]="checkPrioritySelected('Warning')" (ngModelChange)="prioritySwitchChanged($event, 'Warning')"></nz-switch>-->
<!--    </li>-->

<!--    <li class="list-group-item d-flex w-100 justify-content-between flex-fill">-->
<!--      Event <nz-switch [ngModel]="checkPrioritySelected('Event')" (ngModelChange)="prioritySwitchChanged($event, 'Event')"></nz-switch>-->
<!--    </li>-->

<!--  </ul>-->

<!--  <nz-divider nzPlain nzText="Selected Sites" nzOrientation="left"></nz-divider>-->
  <div class="row p-3 mb-0">

    <div class="col-8">
      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input type="text" nz-input placeholder="Search..." [(ngModel)]="searchText"/>
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <i nz-icon nzType="search"></i>
      </ng-template>
    </div>

    <div class="col-4 row">
      <button nz-button nzType="link" (click)="selectAllEvent()">Select All</button>
      <button nz-button nzType="link" nzDanger (click)="deselectAllEvent()">Deselect All</button>
    </div>
  </div>

  <div class="user-site-list">
    <div class="list-group">

      <a class="list-group-item list-group-item-action" *ngFor="let site of currentUser.sites | filter: searchText">
        <div class="d-flex w-100 justify-content-between">
          <h6 class="mb-1">{{site.name}}</h6>
          <nz-switch [ngModel]="checkSiteSelected(site._id)" (ngModelChange)="siteSwitchChanged($event, site._id)"></nz-switch>
        </div>

        <small class="text-muted mb-0">{{site.client_id.name}}</small>

        <ul class="list-group list-group-horizontal bg-transparent">
          <li class="list-group-item d-flex w-100 justify-content-between flex-fill border-0">
            Critical <nz-switch nzSize="small"
                                [ngModel]="checkPrioritySelected('Critical', site._id)"
                                (ngModelChange)="prioritySwitchChanged($event, 'Critical', site._id)"
                                [disabled]="!checkSiteSelected(site._id)"></nz-switch>
          </li>

          <li class="list-group-item d-flex w-100 justify-content-between flex-fill border-0">
            Urgent <nz-switch nzSize="small"
                              [ngModel]="checkPrioritySelected('Urgent', site._id)"
                              (ngModelChange)="prioritySwitchChanged($event, 'Urgent', site._id)"
                              [disabled]="!checkSiteSelected(site._id)"></nz-switch>
          </li>

          <li class="list-group-item d-flex w-100 justify-content-between flex-fill border-0">
            Warning <nz-switch nzSize="small"
                               [ngModel]="checkPrioritySelected('Warning', site._id)"
                               (ngModelChange)="prioritySwitchChanged($event, 'Warning', site._id)"
                               [disabled]="!checkSiteSelected(site._id)"></nz-switch>
          </li>

          <li class="list-group-item d-flex w-100 justify-content-between flex-fill border-0">
            Event <nz-switch nzSize="small"
                             [ngModel]="checkPrioritySelected('Event', site._id)"
                             (ngModelChange)="prioritySwitchChanged($event, 'Event', site._id)"
                             [disabled]="!checkSiteSelected(site._id)"></nz-switch>
          </li>

        </ul>
      </a>

    </div>
  </div>

  <nz-divider class="mb-0"></nz-divider>

</ng-container>

<ng-template  #ampLoader>
  <div class="amp-loader">
    <ng-template #indicatorTemplate><i nz-icon nzType="loading" style="color: #fff; font-size: 30px"></i></ng-template>

    <h3 class="animate__animated animate__pulse animate__infinite	infinite"> Initialising Panel <nz-spin
      nzSimple
      [nzIndicator]="indicatorTemplate" nzSize="large"></nz-spin></h3>
  </div>
</ng-template>
