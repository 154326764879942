import { Component, OnInit } from '@angular/core';
import {SitesService} from '../../../services/sites/sites.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {DomSanitizer} from '@angular/platform-browser';
import {ISite} from '../../../Models/ISite';
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import {GenericHelpers} from '../../../utils/GenericHelpers';
import {TemperatureService} from '../../../services/reports/temperature.service';
import {ISiteTemperatureReadings} from '../../../Models/ISiteTemperatureReadings';
import {Chart} from 'angular-highcharts';
import {IUserSiteList} from '../../../Models/IUserSiteList';

@Component({
  selector: 'app-temperature-readings',
  templateUrl: './temperature-readings.component.html',
  styleUrls: ['./temperature-readings.component.scss']
})
export class TemperatureReadingsComponent implements OnInit {

  isLoadingPage: boolean = true;
  isLoadingCsvData: boolean = false;

  canGenerateReport: boolean = false;

  csvDownloaded: boolean = false;
  hasErrorDownloading: boolean = false;

  siteList: IUserSiteList[];
  selectedSiteId: any;
  dateFrom: string;

  fileUrl: any;

  temperatureData: ISiteTemperatureReadings[];
  siteTemperatureReadingsChart: Chart;

  canGenerateNewReport: boolean = true;

  hasFailed: boolean = false;

  constructor(private siteService: SitesService,
              private message: NzMessageService,
              private sanitizer: DomSanitizer,
              private temperatureService: TemperatureService) { }

  ngOnInit(): void {

    this.siteService.getUserSiteList().subscribe( response =>{
      this.siteList = response;
      this.isLoadingPage = false;
    }, error => {
      console.error('[TemperatureReadingsComponent]: ','Failed to get User Site List');
      this.siteList = [];
      this.isLoadingPage = false;
    } )
  }

  setCanGenerateNewReport(){
    this.canGenerateNewReport = true;
  }

  disabledEndDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, new Date()) > 0;
  };


  onDateChange(result: Date): void {

    if(result !== null){
      let convertedDate = new Date(result.getFullYear(), result.getMonth(), result.getDate());
      this.dateFrom = new GenericHelpers().formatDate(convertedDate);
    }else{

    }
  }

  getTemperatureCSV(): void{

    if(this.selectedSiteId !== undefined && this.dateFrom !== undefined){

      this.isLoadingCsvData = true;

      let id = this.message.loading('Please be patient whilst your report is being generated',
        {
          nzDuration: 0
        }).messageId;

      this.temperatureService.getTemperatureValuesCSV(this.dateFrom, this.selectedSiteId)
        .subscribe( response =>{

          this.isLoadingCsvData = false;
          this.hasErrorDownloading = false;

          //this.fileUrl = `data:application/octet-stream;base64,${response}`;
          this.fileUrl = this.sanitizer.bypassSecurityTrustUrl(`data:text/csv;charset=utf-8,${ encodeURI(response)}`) ;
          this.csvDownloaded = true;

          this.message.remove(id);

        },error => {
          this.isLoadingCsvData = false;
          this.hasErrorDownloading = true;
          this.csvDownloaded = true;
          this.message.remove(id);
        });

    }else{

      this.message.error('You must select a site as well as a start date to generate a report');

    }


  }

  getTemperatureJson(): void{
    this.hasFailed = false;
    this.temperatureService.getSiteTemperatureJsonValues(this.dateFrom, this.selectedSiteId).subscribe(response => {
      this.temperatureData = response;
      this.renderTemperatureChart();
      this.isLoadingCsvData = false;
      this.hasErrorDownloading = false;
    }, error => {
      console.error('Failed to get temperature data:: ', error);
      this.isLoadingCsvData = false;
      this.hasErrorDownloading = true;
    });
  }

  renderTemperatureChart(): void{
    console.log(this.temperatureData);
    try{
      let categories: string[] = [...new Set(this.temperatureData[0].data.map( data => data.date.toString()))];

      let dateData = [{
        name: 'Temperature Averages',
        colorByPoint: false,
        data: []
      }];

      let drillDownSeries = [];

      const siteName = this.siteList
        .find( (e) =>
          e.legacy_id === this.selectedSiteId ||  e._id === this.selectedSiteId
        ).name;

      for(let i =0; i < categories.length; i++) {

        let seriesData = {
          name: categories[i],
          y: 0,
          drilldown: categories[i]
        };



        let dataForDate = [];
        let drillDownVals = []
        for (let x = 0; x < this.temperatureData.length; x++) {

         try{
           dataForDate.push(
             this.temperatureData[x].data[i].value
           );
         }
         catch (e){
           dataForDate.push(
             // this.temperatureData[x].data[i].value
             0
           );
         }

          // let deviceName: string =  this.temperatureData[x].sensor;
          drillDownVals.push(
            [
              this.temperatureData[x].sensor,
              this.temperatureData[x].data[i]?.value !== undefined ?  this.temperatureData[x].data[i].value : 0
            ]
          );

        }

        let drillDownData = {
          name: categories[i],
          id: categories[i],
          data: [...drillDownVals]
        };

        seriesData.y = Number( ( dataForDate.reduce( (prev, current) => prev + current)  / dataForDate.length ).toFixed(2));
        dateData[0].data.push(seriesData);
        drillDownSeries.push(drillDownData);
      }

      this.siteTemperatureReadingsChart = new Chart({
        chart: {
          type: 'column',
          spacingBottom: 0,
          spacingTop: 15,
          spacingLeft: 0,
          spacingRight: 0,
          // width: 1000,
          height: 600
        },
        title: {
          text: `Temperature Readings for ${siteName} from ${categories[0]} to ${new GenericHelpers().formatDate(new Date())}`,
          style: {
            fontWeight: 'bolder',
            textAlign: 'left'
          }
        },
        subtitle: {
          align: 'center',
          style: {
            fontSize: '15px'
          },
          text: 'Click the columns to enter the drill-down view, and see particular sensor readings on the day</a>'
        },
        // xAxis: {
        //   categories: categories,
        //   labels:{
        //     rotation: 0
        //   }
        // },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Temperature (°C)',

          }
        },
        // tooltip: {
        //   pointFormat: '<b>{point.y:,.0f} °C'
        // },
        // tooltip: {
        //   headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        //   pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        //     '<td style="padding:0"><b>{point.y:,.0f} °C</b></td></tr>',
        //   footerFormat: '</table>',
        //   shared: true,
        //   useHTML: true
        // },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>Average {point.y:.2f}</b> °C<br/>'
        },

        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        // plotOptions:{
        //   column: {
        //     dataLabels:{
        //       enabled: true,
        //     },
        //     colorByPoint: true,
        //     colors: ['#1976D2', '#303F9F', '#D32F2F'],
        //   },
        // },
        // series: [{
        //   name: 'Temperature Readings',
        //   // @ts-ignore
        //   // data: levels,
        //   data: data,
        //   color: '#1976D2'
        // }],
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: '{point.y:.1f} °C'
            },
          },

        },
        //@ts-ignore
        series: dateData,
        drilldown: {
          //@ts-ignore
          // breadcrumbs: {
          //   position: {
          //     align: 'right'
          //   }
          // },
          series: [
            ...drillDownSeries
          ]
        }
      });
    }
    catch (e){
      console.error(e);
      this.hasFailed = true;
    }
  }

}
