import {GeneratorInfoComponent} from '../components/devices/generator-info/generator-info.component';
import {ChangeOverInfoComponent} from '../components/devices/change-over-info/change-over-info.component';
import {HvacInfoComponent} from '../components/devices/hvac-info/hvac-info.component';
import {UpsInfoComponent} from '../components/devices/ups-info/ups-info.component';
import {RectifierInfoComponent} from '../components/devices/rectifier-info/rectifier-info.component';
import {AcPowerMeterInfoComponent} from '../components/devices/ac-power-meter-info/ac-power-meter-info.component';
import {DcPowerMeterInfoComponent} from '../components/devices/dc-power-meter-info/dc-power-meter-info.component';
import {BatteriesInfoComponent} from '../components/devices/batteries-info/batteries-info.component';
import {FuelInfoComponent} from '../components/devices/fuel-info/fuel-info.component';
import {ChillerInfoComponent} from '../components/devices/chiller-info/chiller-info.component';
import {InstanceComponentListItem} from '../Models/InstanceComponentList';
import {TemperatureSensorInfoComponent} from '../components/devices/temperature-sensor-info/temperature-sensor-info.component';
import {DistributionComponent} from '../components/devices/distribution/distribution.component';
import {PduComponent} from '../components/devices/pdu/pdu.component';
import {WaterTankInfoComponent} from '../components/devices/water-tank-info/water-tank-info.component';

export class InstanceComponentList {


  // @ts-ignore
  private DEVICE_LIST: InstanceComponentListItem[] = [
    {
      name : 'Universal Controller',
      id: 0,
      component: null,
      trendName: 'universal_controller_id',
      metaBaseDashboard: null,
      redashDashboard: null,
      query_id: 0,
    },
    {
      name : 'Generator',
      id: 2,
      component: GeneratorInfoComponent,
      trendName: 'gen_id',
      metaBaseDashboard: 7,
      redashDashboard: '7kcGG7TfHYsEJga79rtlsrjTOoiS6flWKc16PvIF',
      query_id: 9,
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/p9WSiqd7z/generator-trending?orgId=1&theme=light&",
      grafana_panels: [
        '&panelId=8',
        "&panelId=7",
        "&panelId=5",
        "&panelId=4",
      ]
    },
    {
      name : 'Change Over',
      id: 3,
      component: ChangeOverInfoComponent,
      trendName: 'changeover_id',
      metaBaseDashboard: null,
      redashDashboard: null,
      query_id: 0
    },
    {
      name : 'Distribution',
      id: 4,
      component: DistributionComponent,
      trendName: 'changeover_id',
      metaBaseDashboard: null,
      redashDashboard: null,
      query_id: 0
    },
    {
      name : 'HVAC',
      id: 5,
      component: HvacInfoComponent,
      trendName: 'hvac_id',
      metaBaseDashboard: 5,
      redashDashboard: 'TBW39KfAkqlocOFmJArE8a4VyClG7by7dfHOV1PH',
      query_id: 10,
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/rX7GE8N7z/hvac-trending?orgId=1&theme=light&",
      grafana_panels: [
        "&panelId=4",
      ]
    },
    {
      name : 'UPS',
      id: 6,
      component: UpsInfoComponent,
      trendName: 'ups_id',
      metaBaseDashboard: 9,
      redashDashboard: '5sKV3zIsdNrzQq2RpOCmVr5yWHtmVsyaQi6bIo1J',
      query_id: 13,
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/uLPmfqOnz/ups-trending?orgId=1&theme=light&",
      grafana_panels: [
        "&panelId=9",
        "&panelId=6",
        "&panelId=2",
        "&panelId=4",
        "&panelId=5",
        "&panelId=3",
        "&panelId=7"
      ]
    },
    {
      name : 'Rectifier',
      id: 7,
      component: RectifierInfoComponent,
      trendName: 'rectifier_id',
      metaBaseDashboard: 8,
      redashDashboard: 'tiFajQFSWpoU3aVt128dTg52zNnrOjkj7uEVL2J0',
      query_id: 14,
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/t36FbyF7k/rectifier-trends?orgId=1&theme=light&",
      grafana_panels: [
        "&panelId=2",
        "&panelId=3",
        "&panelId=4",
      ]
    },
    {
      name : 'Power Meter',
      id: 8,
      component: AcPowerMeterInfoComponent,
      trendName: 'power_meter_id',
      metaBaseDashboard: 6,
      redashDashboard: 'zJrcdnC6pDfojNOfG0IwDEKbN3dwoGc3Iz6V8YBq',
      query_id: 11,
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/A2FvNuN7z/power-meter-trends?orgId=1&theme=light&",
      grafana_panels: [
        "&panelId=2",
        "&panelId=3",
        "&panelId=4",
        "&panelId=5",
        "&panelId=6",
        "&panelId=7",
      ]
    },
    {
      name : 'DC Meter',
      id: 9,
      component: DcPowerMeterInfoComponent,
      trendName: 'dc_power_id',
      metaBaseDashboard: 11,
      redashDashboard: 'DJyj2L2M7KTqxRRd4r75fmyG1Fe6bJdepNUrEici',
      query_id: 7,
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/omrf-yKnz/dc-meter-trends?orgId=1&theme=light&",
      grafana_panels: [
        "&panelId=2",
        "&panelId=3",
      ]
    },
    {
      name : 'Batteries',
      id: 10,
      component: BatteriesInfoComponent,
      trendName: 'battery_id',
      metaBaseDashboard: 16,
      redashDashboard: 'BhxalKltrq7Vv5FYTYWMTcBvSBO9J8ehKcQnqVHQ',
      query_id: 15,
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/wpyr-sKnk/battery-trends?orgId=1&theme=light&",
      grafana_panels: [
        "&panelId=2",
        "&panelId=3",
        "&panelId=4",
        "&panelId=5",
        "&panelId=6",
        "&panelId=7",
        "&panelId=8",
        "&panelId=9",
      ]
    },
    {
      name : 'Fire Panel',
      id: 13,
      component: null,
      trendName: 'batteries_id',
      metaBaseDashboard: null,
      redashDashboard: null,
      query_id: 0
    },
    {
      name : 'Temperature Sensor',
      id: 14,
      component: TemperatureSensorInfoComponent,
      trendName: 'temp_humid_id',
      metaBaseDashboard: 15,
      redashDashboard: 'guKtLDtI0YhnmpvH1P6UpcVz2tBVo05sVKNTxe8y',
      query_id: 12,
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/eVNVXyFnk/temp-and-humid-trends?orgId=1&theme=light&",
      grafana_panels: [
        "&panelId=3",
        "&panelId=4",
      ]
    },
    {
      name : 'Fuel',
      id: 20,
      component: FuelInfoComponent,
      trendName: 'fuel_id',
      metaBaseDashboard: 10,
      // redashDashboard: 'uwWfUuUhSUEmo6JWTBBzC8RsEwSwFjznDH6YIeuI',
      redashDashboard: 'yOg28DCTQwv2wO3lzgV7Gjt1bD7WqVGZVx4cUzp6',
      query_id: 1,
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/xfVPsyF7z/fuel-trends?orgId=1&theme=light&",
      grafana_panels: [
        "&panelId=3",
      ]
    },
    {
      name : 'Water Tank',
      id: 54,
      component: WaterTankInfoComponent,
      trendName: 'water_tank_id',
      metaBaseDashboard: 10,
      // redashDashboard: 'uwWfUuUhSUEmo6JWTBBzC8RsEwSwFjznDH6YIeuI',
      redashDashboard: 'yOg28DCTQwv2wO3lzgV7Gjt1bD7WqVGZVx4cUzp6',
      query_id: 1,
      // grafana_url: "https://grafana.cloudbms.co.za/d-solo/xfVPsyF7z/fuel-trends?orgId=1&theme=light&",
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/ElT_odJVk/water-trends?orgId=1&theme=light&",
      grafana_panels: [
        "&panelId=3",
      ]
    },
    {
      name : 'Chiller',
      id: 34,
      component: ChillerInfoComponent,
      trendName: 'chiller_id',
      metaBaseDashboard: null,
      redashDashboard: 'itAHKZCu8dOSpVBlOVrHjksmdS083rYlUYJVGcAB',
      query_id: 8,
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/HfM4EsK7z/chiller-trends?orgId=1&theme=light&",
      grafana_panels: [
        "&panelId=2",
      ]
    },
    {
      name : 'PDU',
      id: 29,
      component: PduComponent,
      trendName: 'pdu_id',
      metaBaseDashboard: null,
      redashDashboard: 'itAHKZCu8dOSpVBlOVrHjksmdS083rYlUYJVGcAB',
      query_id: 8,
      // grafana_url: "https://grafana.cloudbms.co.za/d-solo/HfM4EsK7z/chiller-trends?orgId=1&theme=light&",
      grafana_url: "https://grafana.cloudbms.co.za/d-solo/ufPOOPvVk/pdu-trends?orgId=1&theme=light&",
      grafana_panels: [
        "&panelId=3",
        "&panelId=2",
        "&panelId=4",
      ]
    },
  ];


  public getDeviceList(): InstanceComponentListItem[]{

    return this.DEVICE_LIST;

  }


}
