import {Component, Input, OnInit} from '@angular/core';
import {IDynamicChartData} from '../../../Models/Charts/IDynamicChartData';
import {Chart} from 'angular-highcharts';
import {ChartConfigurations} from '../../../utils/ChartConfigurations';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-solid-gauge-chart',
  templateUrl: './solid-gauge-chart.component.html',
  styleUrls: ['./solid-gauge-chart.component.scss']
})
export class SolidGaugeChartComponent implements OnInit {

  @Input() chartData: IDynamicChartData;
  @Input() optionsType: string;

  chart: Chart;
  chartOptions;

  constructor() { }

  ngOnInit(): void {

    this.chartOptions = new ChartConfigurations().UpsChartOptions;
    this.chartOptions.title.text = this.chartData.series_name;

    this.renderChart();

  }

  renderChart(): void{

    this.chart = new Chart(Highcharts.merge(this.chartOptions, {
      yAxis: {
        min: 0,
        max: 400,
        // title: {
        //   text: this.chartData.series_name
        // }
      },

      credits: {
        enabled: false
      },

      series: [{
        name: this.chartData.series_name,
        data: [Number(this.chartData.series_data)],
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y}</span><br/>' +
            `<span style="font-size:12px;opacity:0.4">${this.chartData.value_suffix}</span>` +
            '</div>'
        },
        tooltip: {
          valueSuffix: ' '+ this.chartData.value_suffix
        }
      }]
    }))

  }

}
