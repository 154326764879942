import { Component, OnInit } from '@angular/core';
import {ISite} from '../../../Models/ISite';
import {IDashboardPageHeaderConfig} from '../../../Models/IDashboardPageHeaderConfig';
import {Constants} from '../../../utils/Contstants';
import {TemperatureService} from '../../../services/reports/temperature.service';
import {GenericHelpers} from '../../../utils/GenericHelpers';
import {Chart} from 'angular-highcharts';
import {ISiteTemperatureReadings} from '../../../Models/ISiteTemperatureReadings';
import {IUserSiteList} from '../../../Models/IUserSiteList';

@Component({
  selector: 'app-temperature-sensor-dashboard',
  templateUrl: './temperature-sensor-dashboard.component.html',
  styleUrls: ['./temperature-sensor-dashboard.component.scss']
})
export class TemperatureSensorDashboardComponent implements OnInit {

  isLoadingData: boolean;
  isLoadingDeviceData: boolean ;
  // selectedSite: ISite;
  selectedSite: IUserSiteList;

  deviceData: ISiteTemperatureReadings[];

  siteTemperatureReadingsChart: Chart;

  headerConfig: IDashboardPageHeaderConfig = {
    device_type: new Constants().deviceTypeIds.temperature_sensor,
    device_group: 'Temperature',
    statistics: []
  }

  constructor(private temperatureService: TemperatureService) { }

  ngOnInit(): void {

    this.isLoadingData = true;
  }

  fetchSiteDeviceReadings(site_id: string): void {
    this.isLoadingDeviceData = true;
    let date = new GenericHelpers().formatDate(new Date());
    this.temperatureService.getSiteTemperatureJsonValues(date, site_id).subscribe(response => {
      this.deviceData = response;
      this.renderTemperatureChart();
      this.isLoadingDeviceData = false;
    }, error => {
      console.error('Failed to get temperature data:: ', error);
      this.isLoadingDeviceData = false;
    });

  }

  renderTemperatureChart(): void {
    let categories: string[] = [];
    let seriesData: { y: number, color: string }[] = [];

    for (let i = 0; i < this.deviceData.length; i++) {
      categories.push(this.deviceData[i].sensor);
      const value = this.deviceData[i].data[0].value;
      const color = this.determineColor(value);
      seriesData.push({ y: value, color });
    }

    this.siteTemperatureReadingsChart = new Chart({
      chart: {
        type: 'column',  // Ensure you have defined the type of the chart here
        spacingBottom: 0,
        spacingTop: 15,
        spacingLeft: 0,
        spacingRight: 0,
        height: 500
      },
      title: {
        text: this.selectedSite.name + ' Temperature Readings',
        style: {
          fontWeight: 'bolder',
          textAlign: 'left'
        }
      },
      xAxis: {
        categories,
        labels: {
          rotation: 0
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Temperature (°C)'
        }
      },
      tooltip: {
        pointFormat: '<b>{point.y:,.0f} °C</b>'
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
          colorByPoint: true
        },
      },
      series: [{
        name: 'Temperature Readings',
        type: 'column',  // Specify the type here as well
        data: seriesData,
        colorByPoint: true
      }]
    });
  }


  private determineColor(temp: number): string {
    if (temp >= 26) {
      return '#D32F2F'; // Red
    } else if (temp >= 24 && temp < 26) {
      return '#FFA000'; // Amber
    } else if (temp >= 20 && temp < 24) {
      return '#388E3C'; // Green
    } else {
      return '#303F9F'; // Blue
    }
  }

  handleSiteChangeEmitter(site: IUserSiteList): void {

    this.selectedSite = site;
    if (site.legacy_id !== undefined){
      this.fetchSiteDeviceReadings(site.legacy_id.toString());
    }
    else{
      this.fetchSiteDeviceReadings(site._id);
    }

  }

}
