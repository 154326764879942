<div style="background: #ffffff; padding: 30px;">
  <div nz-row>

    <div nz-col nzSpan="12">
      <h6 class="mb-3">
        <span class="text-lead" style="font-size: small;">Last Reading</span><br>
        {{currentDeviceReadings?.timestamp |  date:'medium' }}
      </h6>
    </div>

    <div nz-col nzSpan="12">
      <button nz-button nzType="primary" class="float-right" (click)="getDeviceReadings()" >
        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
        Get Current Readings
      </button>
    </div>

  </div>

  <nz-divider></nz-divider>

  <div class="container" *ngIf="!hasReading">
    <nz-result nzStatus="404" nzTitle="404"
               nzSubTitle="Sorry, it seems as though there is no data available for this device.">

    </nz-result>
  </div>

  <nz-card class="full-width" [nzLoading]="isLoadingData" nzBordered="false">
    <div class="full-width" *ngIf="hasReading">
      <div class="row">
        <div class="col-md-3">
          <nz-statistic [nzValue]="(currentDeviceReadings?.litres_in_tank)!"
                        [nzTitle]="'Litres In Tank'"
                        [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
        </div>
        <div class="col-md-6">
          <div class="tank-container">
<!--            <div class="progress tank-progress">-->
<!--              <div class="progress-bar progress-bar-striped progress-bar-animated"-->
<!--                   role="progressbar"-->
<!--                   aria-valuemin="0"-->
<!--                   aria-valuemax="100"-->
<!--                   [ngStyle]="{'width': tankPercentage + '%'}"></div>-->
<!--            </div>-->

            <div class="tank">
              <div class="tank-inner" [ngStyle]="{'height': tankPercentage + '%'}">

              </div>
            </div>
          </div>

        </div>
        <div class="col-md-3">
          <nz-statistic [nzValue]=" (currentDeviceReadings?.capacity != undefined)
      ? (currentDeviceReadings?.capacity) : 'N/A'"
                        [nzTitle]="'Capacity'"
                        [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
        </div>

      </div>
    </div>
  </nz-card>

</div>
