import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {AuthenticationService} from '../../../services/authentication/authentication.service';
import {TicketService} from '../../../services/tickets/ticket.service';
import {UserService} from '../../../services/user/user.service';
import {ISimpleUser, IUser} from '../../../Models/IUser';
import {IActiveAlarm} from '../../../Models/IActiveAlarm';
import {IClient} from '../../../Models/IClient';
import {ITask} from '../../../Models/tasks/ITask';
import {SitesService} from '../../../services/sites/sites.service';
import {ISite} from '../../../Models/ISite';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../../utils/Contstants';

@Component({
  selector: 'app-create-service-desk-ticket',
  templateUrl: './create-service-desk-ticket.component.html',
  styleUrls: ['./create-service-desk-ticket.component.scss']
})
export class CreateServiceDeskTicketComponent implements OnInit {

  @Input() currentUser: IUser;
  @Input() clientDetails: IClient;

  @Input() selectedNotification: IActiveAlarm;
  @Output() ticketCreatedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() acknowledgeAlarm: EventEmitter<IActiveAlarm> = new EventEmitter<IActiveAlarm>();

  isLoading: boolean = false;


  taskForm: FormGroup;
  users: IUser[] = [];
  siteDetails: ISite;

  hasTask: boolean = false;
  taskDetails: ITask;
  _tasksUrl = new Constants().tasksServicesURL;
  _chatUrl = new Constants().chatServiceURL;

  constructor(private nzNotificationService: NzNotificationService,
              private authService: AuthenticationService,
              private ticketService: TicketService,
              private userService: UserService,
              private sitesService: SitesService,
              private httpClient: HttpClient) { }

  ngOnInit(): void {

    this.getUsers();
    this.getTaskDetails();

    this.sitesService.getLegacySiteDetails(this.selectedNotification.site_id).
      subscribe( (response: ISite) => {
        this.siteDetails = response[0];
      // console.log('SITE DETAILS: ', this.siteDetails[0]);

    }, error => {
        console.error('[Request Error]: ', error );
        // this.nzNotificationService.error(
        //   'Operation failed',
        //   'Failed to get site details',
        //   {
        //     nzPlacement: 'topLeft'
        //   }
        // );
    });

    this.taskForm = new FormGroup({
      description: new FormControl(null, Validators.required),
      assigned_to: new FormControl(null, Validators.required),
    });

  }

  getUsers(): void{
    this.users = [];
    this.userService.getClientUsers(this.clientDetails._id)
      .subscribe( (response: IUser[]) => {
        this.users.push(...response);
      }, error => {
        console.error('[ Failed to get Users ]', error);
    });

    this.userService.getClientUsers('602d082dae84fb2258664570')
      .subscribe( (response: IUser[]) => {
        this.users.push(...response);
      }, error => {
        console.error('[ Failed to get Users ]', error);
      });


  }

  getTaskDetails(): void{


    // this.httpClient.post(`http://mpt.dedicated.co.za:8003/api/alarms/findTask`, {
    this.httpClient.post(`${this._tasksUrl}alarms/findTask`, {
      description: this.selectedNotification.description,
      site_id: this.selectedNotification.site_id,
      client_id: this.selectedNotification.client_id,
      device_name: this.selectedNotification.device_name,
      is_cleared : this.selectedNotification.is_cleared,
    }).subscribe( (response: ITask | null)  => {

      if(response !== null){
        this.hasTask = true;
        this.taskDetails = response;
      }else{
        this.hasTask = false;
      }

    }, error => {
      console.error('[Get Task From Alarm] ', error);
    });

  }

  sendMessageOnExistingTask(): void{

    this.isLoading = true;

    this.httpClient.get(`${this._chatUrl}chats/task/${this.taskDetails._id}`)
      .subscribe( (chat: any) => {
        const chatID: string = chat._id;

        const message = {
          from: {
            id: this.currentUser._id,
            user_name: `${this.currentUser.name} ${this.currentUser.surname}`,
            email_address: this.currentUser.email_address
          },
          chat_id: chatID,
          message: this.taskForm.value.description,
          created_at: new Date(),
        }

        // ************************************************************ SEND TASK MESSAGE ******
        this.httpClient.post(`${this._chatUrl}messages/`, message)
          .subscribe( (messageResponse: any) => {

            this.nzNotificationService.success(
              'Task Created',
              'Users have been notified',
              {
                nzPlacement: 'topLeft'
              }
            );

            this.acknowledgeAlarm.emit(this.selectedNotification);
            this.ticketCreatedEvent.emit(true);
            this.isLoading = false;


          }, error =>  {
            console.error('[Task Message Error]: ', error );
            this.isLoading = false;
          });

      }, error =>  {
        console.error('[Task Message Error]: ', error );
        this.isLoading = false;
      });


  }


  onFormSubmitted(): void{

    if(!this.hasTask){
      const title: string = `${this.selectedNotification.description} reported by ${this.selectedNotification.device_name}`;
      const description: string = `Triggered at ${this.selectedNotification.site_name} on ${this.selectedNotification.triggered_at} \n\nDispatched from CloudBMS`;

      let priority: string = 'High';
      let type: string = 'Problem'

      if(this.selectedNotification.priority === 'Warning'){
        priority = 'Medium';
        type = 'Incident';
      }else if(this.selectedNotification.priority === 'Event'){
        priority = 'Low';
        type = 'Incident'
      }

      const task: ITask = {
        _id: '',
        alarm_details: [
          this.selectedNotification
        ],
        assigned_to: {
          id: this.taskForm.value.assigned_to._id,
          user_name: this.taskForm.value.assigned_to.name + ' ' + this.taskForm.value.assigned_to.surname,
          email_address: this.taskForm.value.assigned_to.email_address
        },
        attachments: [],
        client: {
          _id: this.clientDetails._id,
          name: this.clientDetails.name
        },
        collaborators: [], //TODO::
        created_at: new Date(),
        creator: {
          id: this.currentUser._id,
          user_name: this.currentUser.name + ' ' + this.currentUser.surname,
          email_address: this.currentUser.email_address
        },
        description: description,
        due_date: new Date(),
        logs: [],
        priority: priority,
        site: {
          _id: this.siteDetails !== undefined ? this.siteDetails._id : this.selectedNotification.site_id.toString() ,
          name: this.siteDetails !== undefined ? this.siteDetails.name : this.selectedNotification.site_name
        },
        status: 'Pending',
        title: title,
        type: type
      }

      this.createTask(task);
    }else{

      this.sendMessageOnExistingTask();
    }

  }

  createTask(task: ITask): void{
    this.isLoading = true;
    this.httpClient.post(`${this._tasksUrl}tasks/`, task)
      .subscribe( (response: {msg: string}) => {

        // ************************************************************ GET TASK CHAT DETAILS ******
        this.httpClient.get(`${this._chatUrl}chats/task/${response.msg}`)
          .subscribe( (chatDetails: any) => {
            const chatID: string = chatDetails._id;


            const message = {
              from: task.creator,
              chat_id: chatID,
              message: this.taskForm.value.description,
              created_at: new Date(),
            }

            // ************************************************************ SEND TASK MESSAGE ******
            this.httpClient.post(`${this._chatUrl}messages/`, message)
              .subscribe( (messageResponse: any) => {
                this.nzNotificationService.success(
                  'Task Created',
                  'Users have been notified',
                  {
                    nzPlacement: 'topLeft'
                  }
                );
                this.acknowledgeAlarm.emit(this.selectedNotification);
                this.ticketCreatedEvent.emit(true);
                this.isLoading = false;

              }, error =>  {
                console.error('[Task Message Error]: ', error );
                this.isLoading = false;
              });

          }, error =>  {
            console.error('[Task Message Error]: ', error );
            this.isLoading = false;
          });

      }, error => {
        console.error('[Task Creation Error]: ', error );
        this.nzNotificationService.error(
          'Operation failed',
          'Failed to create task',
          {
            nzPlacement: 'topLeft'
          }
        );
        this.isLoading = false;
      });

  }

}
