<div class="container-fluid mt-3">

  <div class="row">

    <nz-card class="col-lg-6 col-md-12 col-sm-12" [nzBodyStyle]="{'padding': '10px', 'height': '70vh'}">
      <nz-page-header nzBackIcon nzTitle="Ticket User Management" nzSubtitle="This is a subtitle">

        <nz-page-header-content>
          <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-12">
              <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text"
                       nz-input placeholder="Search..."
                       autocomplete="off" />
              </nz-input-group>
              <span nz-typography nzType="secondary">Use the search box above to filter the list below</span>
            </div>
          </div>
        </nz-page-header-content>

      </nz-page-header>

      <nz-divider></nz-divider>
      <nz-table #nzTable [nzData]="users" nzTableLayout="fixed"
                [nzPageSize]="1000" [nzScroll]="{ y: '70vh'}"
                [nzShowPagination]="false"
                [nzLoading]="isLoading"  nzSize="small"
      >
        <thead>
        <tr>
          <th>Full Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of users">
          <td>{{user.full_name}}</td>
          <td>{{user.email}}</td>
          <td *ngIf="user.role == 0">Super User</td>
          <td  *ngIf="user.role == 1">Admin User</td>
          <td  *ngIf="user.role == 2">Regular User</td>
          <td>
            <button nz-button nzType="primary" class="mr-2" (click)="setEditDetails(user)">Edit</button>
            <button nz-button nzType="danger" (click)="showConfirm(user.id)" >Delete</button>
          </td>
        </tr>
        </tbody>
      </nz-table>

    </nz-card>

    <nz-card class="col-lg-6 col-md-12 col-sm-12" [nzBodyStyle]="{'padding': 0}">
      <nz-page-header [nzTitle]="(!isInEditState) ? 'New User' : 'Edit Details'"></nz-page-header>
      <nz-divider></nz-divider>
      <form class="container p-5" [formGroup]="form" (ngSubmit)="submitForm(form.value)" *ngIf="!isInEditState">


        <label> <span class="text-danger">*</span> Full Name</label>
        <nz-input-group class="mb-3"  [nzPrefix]="prefixTemplateUser">

          <input type="text" nz-input placeholder="Full Name"
                 formControlName="full_name" value="" />
        </nz-input-group>

        <label> <span class="text-danger">*</span>Email Address </label>
        <nz-input-group class="mb-3"  [nzPrefix]="prefixTemplateUser">

          <input type="text" nz-input placeholder="Email Address"
                 formControlName="email" value="" />
        </nz-input-group>

        <label> <span class="text-danger">*</span>Role</label>
        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a Role" formControlName="role"
                   style="width: 100%;"  class="mb-3">
          <nz-option nzLabel="Admin" nzValue="1"></nz-option>
          <nz-option nzLabel="Normal User" nzValue="2"></nz-option>
        </nz-select>

        <label> <span class="text-danger">*</span>Site</label>
        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Assign a Site" formControlName="site_id"
                   style="width: 100%;" class="mb-3">
          <nz-option nzLabel="All Sites" nzValue="0"></nz-option>
          <nz-option *ngFor="let site of clientSites" [nzLabel]="site.Site_name" [nzValue]="site.legacy_id"></nz-option>

        </nz-select>

        <button nz-button nzType="primary" type="submit" class="mt-4">
          <i nz-icon nzType="plus" nzTheme="outline" class="button-icon mr-2"></i>
          Add User
        </button>

      </form>

      <form class="container p-5" [formGroup]="form" (ngSubmit)="submitForm(form.value)" *ngIf="isInEditState">


        <label> <span class="text-danger">*</span> Full Name</label>
        <nz-input-group class="mb-3"  [nzPrefix]="prefixTemplateUser">

          <input type="text" nz-input placeholder="Full Name"
                 formControlName="full_name" value="" />
        </nz-input-group>

        <label> <span class="text-danger">*</span>Email Address </label>
        <nz-input-group class="mb-3"  [nzPrefix]="prefixTemplateUser">

          <input type="text" nz-input placeholder="Email Address"
                 formControlName="email" value="" />
        </nz-input-group>

        <label> <span class="text-danger">*</span>Role</label>
        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Assign a Site" formControlName="role"
                   style="width: 100%;" class="mb-3">
          <nz-option nzLabel="Admin" nzValue="1"></nz-option>
          <nz-option nzLabel="Normal User" nzValue="2"></nz-option>

        </nz-select>

        <label> <span class="text-danger">*</span>Site</label>
        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a Role" formControlName="site_id"
                   style="width: 100%;" class="mb-3">
          <nz-option nzLabel="All Sites" nzValue="0"></nz-option>
          <nz-option *ngFor="let site of clientSites" [nzLabel]="site.Site_name" [nzValue]="site.legacy_id"></nz-option>

        </nz-select>


        <!--        <button nz-button nzType="primary" type="submit" class="mt-4">Add User</button>-->
        <button nz-button nzType="dashed" type="submit" nzDanger class="mt-4">
          <i nz-icon nzType="edit" nzTheme="outline" class="button-icon mr-2"></i>
          Save Changes
        </button>

        <button nz-button nzType="danger" (click)="isInEditState = false; form.reset();">Cancel Edit</button>

      </form>

    </nz-card>

  </div>

</div>


<ng-template #suffixIconSearch>
  <i nz-icon nzType="search"></i>
</ng-template>
<ng-template #prefixTemplateUser><i nz-icon nzType="user"></i></ng-template>
<ng-template #prefixTemplateLock><i nz-icon nzType="lock"></i></ng-template>

<app-overlay-loader [isLoading]="isLoading" [loadingText]="'Retrieving User List...'"></app-overlay-loader>
