
  <nz-card nzBordered="false" [nzLoading]="isLoadingData">
    <div class="container-div"  #containerDiv
         [ngClass]="{'vertical-scrollable-div': divScrollable == true}">

      <div nz-row>

        <div nz-col nzSpan="24">
          <button nz-button nzType="primary" class="float-right" (click)="getDeviceReadings()" >
            <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
            Get Current Readings
          </button>
        </div>

      </div>
      <nz-divider></nz-divider>
      <nz-card class="full-width scrollable-div" [nzLoading]="isLoadingData" nzBordered="false">
        <canvas #canvas  [hidden]="!hasReading"></canvas>
      </nz-card>


    </div>

    <!--  <div class="container-div" *ngIf="!hasReading && !isLoadingData">-->
    <!--    <nz-result nzStatus="404" nzTitle="404" nzSubTitle="Sorry, {{errorMessage}}">-->
    <!--      <div nz-result-extra>-->
    <!--        <button nz-button nzType="primary" (click)="getDeviceReadings()">Try Again</button>-->
    <!--      </div>-->
    <!--    </nz-result>-->
    <!--  </div>-->

  </nz-card>



