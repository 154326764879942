import { Component, OnInit } from '@angular/core';
import {RectifierDashboardsService} from '../../../services/dashboards/rectifiers/rectifier-dashboards.service';
import {SitesService} from '../../../services/sites/sites.service';
import {ISite} from '../../../Models/ISite';
import {ISiteRectifier} from '../../../Models/Dashboards/ISiteRectifier';
import {IDashboardPageHeaderConfig} from '../../../Models/IDashboardPageHeaderConfig';
import {Constants} from '../../../utils/Contstants';
import {DeviceDashboardSignature} from '../../../utils/ClassSignatures/DeviceDashboardSignature';
import {IUserSiteList} from '../../../Models/IUserSiteList';

@Component({
  selector: 'app-rectifier-dashboards',
  templateUrl: './rectifier-dashboards.component.html',
  styleUrls: ['./rectifier-dashboards.component.scss']
})
export class RectifierDashboardsComponent implements OnInit, DeviceDashboardSignature {

  isInitialising: boolean;
  isLoadingRectifierData: boolean;
  hasInitialised: boolean;
  hasRectifierData: boolean;

  // selectedSite: ISite;
  selectedSite: IUserSiteList;

  rectifierData: ISiteRectifier;


  headerConfig: IDashboardPageHeaderConfig = {
    device_type: new Constants().deviceTypeIds.rectifier,
    device_group: 'RECTIFIER',
    statistics: []
  }

  constructor(private rectifierDashboardsService: RectifierDashboardsService,
              private sitesService: SitesService) {}

  ngOnInit(): void {

    this.isInitialising = true;

  }



  fetchSiteDeviceReadings(site_id: string): void {

    this.isLoadingRectifierData = true;
    this.rectifierDashboardsService.getSiteRectifierData(site_id).subscribe( (response: ISiteRectifier) => {

      if(response !== null){
        this.isInitialising = false;
        this.rectifierData = response;
        this.hasInitialised = true;
        this.hasRectifierData = true;
        this.isLoadingRectifierData = false;

      }else{
        this.isInitialising = false;
        this.hasRectifierData = false;
        this.isLoadingRectifierData = false;
      }

    }, error => {
      console.error('Error Loading Rectifier Data: ', error);
      this.isInitialising = false;
      this.hasRectifierData = false;
      this.isLoadingRectifierData = false;
    } );

  }

  handleSiteChangeEmitter(site: IUserSiteList): void {
    this.isLoadingRectifierData = true;
    this.selectedSite = site;

    if(site.legacy_id !== undefined){
      this.fetchSiteDeviceReadings(site.legacy_id.toString());
    }
    else{
      this.fetchSiteDeviceReadings(site._id);
    }

  }


}
