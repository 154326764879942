import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IActiveAlarm} from '../../Models/IActiveAlarm';
import {GenericHelpers} from '../../utils/GenericHelpers';
import {IUser} from '../../Models/IUser';
import {IClient} from '../../Models/IClient';
import {UserNotificationsService} from '../../services/user_notifications/user-notifications.service';
import {IUserNotificationSettings} from '../../Models/IUserNotificationSettings';

@Component({
  selector: 'app-alarm-management-panel',
  templateUrl: './alarm-management-panel.component.html',
  styleUrls: ['./alarm-management-panel.component.scss']
})
export class AlarmManagementPanelComponent implements OnInit, OnChanges {

  userCanEscalate: boolean = false;
  isReady: boolean = false;
  // If the component is in management or task creation mode
  currentMode: string = 'management-mode';

  // [ PROPS ]
  @Input() isOpen: boolean;
  @Input() alarms: IActiveAlarm[];
  @Input() currentUser: IUser;
  @Input() clientDetails: IClient;

  // [ Events ]
  @Output() closePanel: EventEmitter<boolean> = new EventEmitter();
  @Output() onSilenceAlarm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAlarmAcknowledged: EventEmitter<IActiveAlarm> = new EventEmitter<IActiveAlarm>();

  // Object STYLES
  panelStyle = {
    background: '#f7f7f7',
    borderBottom: '1px solid #ececec ',
  }

  // [ ALARM LIST SECTION ]
  alarmList: IActiveAlarm[];

  // Alarm list grouped by occurrence
  groupedAlarms: Array<{mainAlarm: IActiveAlarm, occurrences: number}> = [];

  // Device Tab Vars
  devicePanels: Array<
    {
      name: string,
      alarms: number,
      type: number
    }
> = [];
  selectedDeviceAlarms: IActiveAlarm[] = [];

  selectedDeviceTypeName: string;
  isDisplayingDeviceAlarms: boolean = false;

  //********END  Device Tab Vars

  // Site organisation tab vars
  sitePanels : Array<{name: string, alarms: number} > = [];
  sitePanelAlarms: IActiveAlarm[] = [];

  selectedSiteName: string;
  isDisplayingSiteAlarms: boolean = false;

  //********* END Site organisation tab vars

  searchText: string;
  priorityFilter: string = 'All';

  // Task Creation vars
  selectedAlarm: IActiveAlarm;

  // ********** [ USER ALARM NOTIFICATION SETTINGS ] **********
  silencedAlarms: IActiveAlarm[];
  userNotificationSettings: IUserNotificationSettings;
  silencedList: IActiveAlarm[] = [];

  constructor( private userNotificationService : UserNotificationsService,) { }

  ngOnInit(): void {


  }

  groupAlarms(): void{

    let grouped: Array<{mainAlarm: IActiveAlarm, occurrences: number}> = [];

    let uniqueAlarmList : Array<{
      description: string,
      site_id: number,
      device_name: string
    }> = this.alarmList
      .map( (alarm: IActiveAlarm) =>
        ({description: alarm.description, site_id: alarm.site_id, device_name: alarm.device_name})
      );

    let list: Array<{
      description: string,
      site_id: number,
      device_name: string
    }> = [];

    let stringifiedList: string[] = uniqueAlarmList.map( (item) => (JSON.stringify(item)) );
    let uniqueStringifiedList = [...new Set(stringifiedList)];

    for(let i in uniqueStringifiedList){
      list.push(JSON.parse(uniqueStringifiedList[i]));
    }

    // console.log('Unique Alarm List ', list);
    for(let i in list){
      let mainAlarm: IActiveAlarm = this.alarmList
        .find( (alarm: IActiveAlarm) => alarm.device_name === list[i].device_name &&
          alarm.site_id === list[i].site_id &&
          alarm.description === list[i].description
        );



      let groupedAlarm: {mainAlarm: IActiveAlarm, occurrences:number} = {
        mainAlarm: mainAlarm,
        occurrences: this.alarmList
          .filter( (alarm: IActiveAlarm) =>
            alarm.device_name === list[i].device_name &&
            alarm.site_id === list[i].site_id &&
            alarm.description === list[i].description
          ).length
      };
      grouped.push(groupedAlarm);
    }

    this.groupedAlarms = grouped;
  }

  setPermissions(): void{

    if(this.currentUser !== undefined){
      //TODO:: Master Power Technologies and MPT BMS hack needs to be removed
      this.userCanEscalate = this.currentUser.client_id === "602d082dae84fb2258664570" || this.currentUser.client_id === "5ffdca5c2324a41997887912";
    }
  }

  populateAlarmList(data: IActiveAlarm[]): void{

    if(this.silencedAlarms !== undefined && this.isReady){

      let silenced: IActiveAlarm[] = [];
      let newAlarmList: IActiveAlarm[] = [];

      for(let i = 0; i < data.length; i++){
        // console.log('Populate alarms: ', this.isSilencedAlarm(data[i]), data[i].description);
        if(!this.isSilencedAlarm(data[i])){
          newAlarmList.push(data[i]);
        }
        else{
          silenced.push(data[i]);
        }
      }

      this.alarmList = [...newAlarmList].reverse();
      this.silencedList = [...silenced].reverse();
      this.groupAlarms();
      this.onPriorityFilterChange(this.priorityFilter);
      this.sortDeviceTypes();
      this.sortAlarmsBySite();

    }else{
      this.alarmList = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    try{

      if(changes.hasOwnProperty('clientDetails')){

        if(typeof changes.clientDetails.currentValue !== 'undefined'){
          this.getUserNotificationSettings();
          this.isReady = true;
        }

      }

      if(changes.hasOwnProperty('alarms')){

        if(changes.alarms.currentValue !== 'undefined'){
          this.populateAlarmList([...changes.alarms.currentValue])
        }

      }

      if(changes.hasOwnProperty('currentUser')){
        if(changes.currentUser.currentValue !== 'undefined'){
          this.setPermissions();
        }
      }

    }
    catch (exception){
      console.log('CHANGE DETECTION ERROR: ', changes);
      console.error('CHANGE DETECTION ERROR: ', exception);
    }
  }
  /*
  * ******************* [ USER NOTIFICATION SETTINGS CONFIG SECTION ] *******************
  * */

  getUserNotificationSettings() : void {

    this.userNotificationService.getUserNotificationSettings(this.currentUser._id).subscribe(response => {
      this.userNotificationSettings = response;
      this.silencedAlarms = [...new Set(response.silenced_alarm_notifications)];
      this.populateAlarmList(this.alarms);

    }, error => {
      // console.info('USER NOTIFICATIONS SETTINGS ERROR: ', error);
      this.userNotificationSettings = {
        user_id: this.currentUser._id,
        sites: [],
        silenced_alarm_notifications: []
      }

      this.populateAlarmList(this.alarms);
      this.silencedAlarms = [];
    });
  }

  handleAlarmSilencingEvent( data: { alarm: IActiveAlarm, shouldSilence: boolean } ): void{
    if(data.shouldSilence){
      this.silenceNotification(data.alarm);
    }else{
      this.unSilenceNotification(data.alarm);
    }
  }

  private silenceNotification(alarm: IActiveAlarm): void{
    this.silencedAlarms.push(alarm);
    this.silencedAlarms = [...new Set(this.silencedAlarms)];
    this.userNotificationSettings.silenced_alarm_notifications.push(alarm);
    this.userNotificationService.updateUserNotificationSettings(this.userNotificationSettings).subscribe(response => {
      this.populateAlarmList(this.alarms);
      this.tabListCleanup(alarm);
    }, error => {
      console.error('Error in updating alarm notification settings', error);
    });
  }

  private unSilenceNotification(alarm: IActiveAlarm): void{

    // let newSilencedList = this.silencedAlarms.filter( a => JSON.stringify(a) !== JSON.stringify(alarm) );

    let alarmIndex: number = this.silencedAlarms.findIndex( (a: IActiveAlarm) =>
      a.site_id === alarm.site_id
      && a.description === alarm.description
      && a.device_name === alarm.device_name
      && a.is_cleared === alarm.is_cleared
      && a.controller_id === alarm.controller_id
      && a.AlarmLogID.toString() === alarm.AlarmLogID.toString());

    if(alarmIndex !== -1){
      console.log('ALARM INDEX : ', alarmIndex);
      this.silencedAlarms.splice(alarmIndex, 1);
      // console.log('new silenced list from unsilence event: ', newSilencedList);
      this.userNotificationSettings.silenced_alarm_notifications = [...new Set(this.silencedAlarms)];
      this.userNotificationService.updateUserNotificationSettings(this.userNotificationSettings)
        .subscribe(response => {
          this.populateAlarmList(this.alarms);
        }, error => {
          console.error('Error in updating alarm notification settings', error);
        });

    }

  }

  // Priority filter functions
  onPriorityFilterChange(value: string): void{
    if(value !== 'All' && value !== null){
      // console.log('Priority filter value: ', [...this.alarms.filter( (alarm: IActiveAlarm) => alarm.priority === value)]);
      let newAlarmList = this.alarms.filter((alarm: IActiveAlarm) => alarm.priority === value && !this.isSilencedAlarm(alarm));
      newAlarmList.reverse();
      this.alarmList = [...newAlarmList];
      this.groupAlarms();

      // this.populateAlarmList([...this.alarms.filter( (alarm: IActiveAlarm) => alarm.priority === value)]);
    }else{
      // this.populateAlarmList([...this.alarms]);
      let newAlarmList = this.alarms.filter((alarm: IActiveAlarm) => !this.isSilencedAlarm(alarm));
      this.alarmList = [...newAlarmList].reverse();
      this.groupAlarms();
    }
  }
  // END Priority filter functions

  // Device Tab Functions
  sortDeviceTypes() : void {
    this.devicePanels = [];
    let deviceTypes: number[] = this.alarmList.map( (alarm: IActiveAlarm) => alarm.device_type);
    deviceTypes = [...new Set(deviceTypes)];

    for(let i in deviceTypes){
      let deviceName: string = new GenericHelpers().getDeviceNameFromType(deviceTypes[i]);
      let alarms: number = this.alarmList.filter( (alarm: IActiveAlarm) => alarm.device_type === deviceTypes[i]).length

      this.devicePanels.push({
        name: deviceName,
        alarms: alarms,
        type: deviceTypes[i]
      });

    }

  }

  onDevicePanelSelected(active: boolean, type: number): void{

    this.isDisplayingDeviceAlarms = true;
    this.selectedDeviceTypeName = new GenericHelpers().getDeviceNameFromType(type);

    if(active){
      this.selectedDeviceAlarms = this.alarmList.filter( (alarm: IActiveAlarm) => alarm.device_type === type );
    }else{
      this.isDisplayingDeviceAlarms = false;
    }
  }
  // END Device Tab Functions

  // Site Alarm List Tab function
  sortAlarmsBySite() : void{
    this.sitePanels = [];

    let siteNames: string[] = this.alarmList.map( (alarm: IActiveAlarm) => alarm.site_name );
    siteNames = [...new Set(siteNames)];

    for(let i in siteNames){
      this.sitePanels.push({
        name: siteNames[i],
        alarms: this.alarmList.filter( (alarm: IActiveAlarm) => alarm.site_name === siteNames[i] ).length,
      });
    }

  }

  onSitePanelSelected(active: boolean, siteName: string): void{

    this.isDisplayingSiteAlarms = true;
    this.selectedSiteName = siteName;

    if(active){
      this.sitePanelAlarms = this.alarms.filter( (alarm: IActiveAlarm) => alarm.site_name === siteName ).reverse();

    }else{
      this.isDisplayingSiteAlarms = false;
    }
  }


  // END Site Tab Functions

  toggleTasksDrawer(): void{
    this.onModeChange(false);
    this.closePanel.emit(false);
  }

  onModeChange(isCreation: boolean) : void{
    if(isCreation){
      this.currentMode = 'creation-mode';
    }
    else{
      this.currentMode = 'management-mode';
    }
  }

  handleCreationStartedEvent(alarm: IActiveAlarm): void{
    this.onModeChange(true);
    this.selectedAlarm = alarm;
  }

  handleTaskCreatedEvent(): void{

    this.onModeChange(false);
  }

  isSilencedAlarm(alarm: IActiveAlarm): boolean{

    let result: boolean = false;
    try{
      let foundAlarm = this.silencedAlarms.find( a =>
        a.site_id === alarm.site_id
        && a.description === alarm.description
        && a.device_name === alarm.device_name
        && a.is_cleared === alarm.is_cleared
        && a.controller_id === alarm.controller_id
        && a.AlarmLogID.toString() === alarm.AlarmLogID.toString()
      );

      result = foundAlarm !== undefined;
    }
    catch (exception){
      console.error('Error in operation: ', exception);
    }

    return result;
  }

  onAcknowledgeEvent(alarm: IActiveAlarm): void{
    //Send Acknowledge request to API
    this.userNotificationService.acknowledgeNotification(this.currentUser._id, alarm).subscribe( response => {
      console.log('Notification Acknowledged: ', response);
    }, error => {
      console.error('Could not acknowledge alarm: ', error);
    })

    this.onAlarmAcknowledged.emit(alarm);
    this.tabListCleanup(alarm);
  }

  tabListCleanup(alarm: IActiveAlarm): void{
    // Remove from device tab list
    let newDeviceAlarmsList = [...this.selectedDeviceAlarms];
    this.selectedDeviceAlarms = newDeviceAlarmsList
      .filter( (a: IActiveAlarm) => !(a.description === alarm.description && a.device_name === alarm.device_name && a.site_id === alarm.site_id));

    // Remove from site tab list

    let newSiteList = [...this.sitePanelAlarms];
    this.sitePanelAlarms = newSiteList
      .filter( (a: IActiveAlarm) => !(a.description === alarm.description && a.device_name === alarm.device_name && a.site_id === alarm.site_id));
  }

}
