import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {AuthenticationService} from '../../services/authentication/authentication.service';
import {StorageService} from '../../services/storage/storage.service';
import {Constants} from '../../utils/Contstants';
import {TicketService} from '../../services/tickets/ticket.service';
import {ClientsService} from '../../services/clients/clients.service';
import {NzNotificationPlacement, NzNotificationService} from 'ng-zorro-antd/notification';


@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  form;
  ticketForm;
  resetPasswordForm;

  isVisible = false;
  passwordModalIsVisible: boolean = false;

  passwordResetRequestSent: boolean = false;
  passwordResetUserNotFound: boolean = false;
  isSendingPasswordResetRequest: boolean = false;

  isPerformingLogin: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private notification: NzNotificationService,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private ticketService: TicketService,
    private clientService: ClientsService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required]
    });

    this.ticketForm = this.formBuilder.group({
      reference: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]]
    });

    this.resetPasswordForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]]
    })

  }

  performLogin(formData){

    if (this.form.dirty && this.form.valid) {
      this.isPerformingLogin = true;
      formData.email = formData.email.toLowerCase().trim();

      const authData = {
        email: formData.email,
        password: formData.password,
        product: 'Remote Monitoring'
      };

      this.authenticationService.getToken(authData).subscribe(response => {

        if(!response.token){

          this.notification.error(
            'Login Failed!',
            'The credentials provided are invalid, please double check and try again'
          );

          this.isPerformingLogin = false;

        }else{
          {
            this.storageService.encodeAndStore(
              new Constants().storage.token,
              response.token
            );
            this.isPerformingLogin = false;

            this.authenticationService.getSignedInUserDetails().subscribe(response => {


              this.clientService.getClient(response.client_id)
                .subscribe( response =>{


                  if(response){

                    this.clientService.setCurrentClient(response);

                    this.storageService.encodeAndStore(new Constants().storage.newClientId, response._id);


                    this.router.navigate(['/admin/']).then(data =>{

                    }).catch(error =>{
                      console.error("Failed to sign in");
                    });

                  }else{
                    this.notification.error(
                      'Login Failed!',
                      'The credentials provided are invalid, please double check and try again'
                    );
                    this.isPerformingLogin = false;
                  }


                }, error => {

                  this.notification.error(
                    'Login Failed!',
                    error.message
                  );
                  this.isPerformingLogin = false;
                })

            }, error => {
              console.error("Sign In Falied");
            });


            // this.router.navigate(['/auth-client']);


          }
        }


      }, error => {
        console.error("Sign In Failed");

        if(error.error.errors[0].msg === 'Invalid Credentials' ){
          this.notification.error(
            'Invalid Credentials',
            'Your email or password is incorrect'
          );
          this.isPerformingLogin = false;
        }

        if(error.status === 401){
          this.notification.error(
            'Login Failed',
            error.error.message
          );
          this.isPerformingLogin = false;
        }

        if(error.status === 500){
          this.notification.error(
            'Server Error',
            'An problem occurred during this operation, please check your internet connection, refresh and try again.'
          );
          this.isPerformingLogin = false;
        }
      });

    }else{

      this.createBasicNotification('topRight');
    }
  }


  createBasicNotification(position: NzNotificationPlacement): void {
    this.notification.create(
      'error',
      'Invalid Input!',
      'Prove your Email Address and Password',
      { nzPlacement: position }
    );
  }


  showModal(): void {
    this.isVisible = true;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  getTicketInfo(formData): void{

    if (this.ticketForm.dirty && this.ticketForm.valid) {

      this.ticketService.getTicketByReferenceAndEmail(formData.reference, formData.email)
        .subscribe( (response) => {

          this.router.navigate([`/tickets/${response.ticket_id}/${response.user_id}`]);

        }, error => {

          this.notification.create(
            'error',
            'Invalid Data Provided!',
              `${error.error['error']}`,
            { nzPlacement: 'bottomRight' }
          );

        } );


    }else{
      this.notification.create(
        'error',
        'Invalid Input!',
        'Provide the reference number and your email address.',
        { nzPlacement: 'bottomRight' }
      );
    }

  }

  requestPasswordChange(formData): void{
    this.passwordResetUserNotFound = false;
    if (this.resetPasswordForm.dirty && this.resetPasswordForm.valid) {

      this.isSendingPasswordResetRequest = true;

      this.authenticationService.forgotPassword(formData.email.toLowerCase().trim()).subscribe( (response)=> {

        this.notification.success(
          'Password Reset Request Sent!',
          '',
          { nzPlacement: 'topRight' }
        );

        this.isSendingPasswordResetRequest = false;
        this.passwordResetRequestSent = true;


      }, error => {
        console.error(error);

        this.notification.error(
          'Invalid Email Address',
          'A user with the email address provided was not found',
          { nzPlacement: 'bottomRight' }
        );

        this.isSendingPasswordResetRequest = false;
        this.passwordResetUserNotFound = true;

      } )


    }else {
      this.notification.create(
        'error',
        'Invalid Input!',
        'Provide a valid email address.',
        { nzPlacement: 'bottomRight' }
      );
    }
  }

  openPasswordModal():void{
    this.passwordModalIsVisible = true;
  }

  closePasswordModal():void{
    this.passwordModalIsVisible = false;
  }

}
