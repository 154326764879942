<div style="background: #ffffff; padding: 30px;">

  <div nz-row>

    <div nz-col nzSpan="12">
      <h6 class="mb-3">
        <span class="text-lead" style="font-size: small;">Last Reading</span><br>
        {{currentDeviceReadings?.timestamp |  date:'medium' }}
      </h6>
    </div>

    <div nz-col nzSpan="12">
      <button nz-button nzType="primary" class="float-right" (click)="getDeviceReadings()" >
        <i nz-icon nzType="redo" nzTheme="outline" class="button-icon"></i>
        Get Current Readings
      </button>
    </div>

  </div>

  <nz-divider></nz-divider>

  <div class="container" *ngIf="!hasReading">
    <nz-result nzStatus="404" nzTitle="404"
               nzSubTitle="Sorry, it seems as though there is no data available for this device.">

    </nz-result>
  </div>

  <nz-card class="full-width" [nzLoading]="isLoadingData" nzBordered="false">

    <div class="full-width" *ngIf="hasReading">
      <nz-row [nzGutter]="16">

<!--s-->

        <nz-col [nzSpan]="8">
          <nz-statistic [nzValue]="(currentDeviceReadings?.litres_in_tank)!"
                        [nzTitle]="'Litres In Tank'"
                        [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
        </nz-col>

        <nz-col [nzSpan]="8">
          <nz-statistic [nzValue]=" (currentDeviceReadings?.capacity != undefined)
      ? (currentDeviceReadings?.capacity) : 'N/A'"
                        [nzTitle]="'Capacity'"
                        [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
        </nz-col>
      </nz-row>

      <br>

      <nz-row [nzGutter]="16">

<!--        <nz-col [nzSpan]="8">-->
<!--          <nz-statistic-->

<!--            [nzValueTemplate]="fuelLostTemplate"-->
<!--            [nzTitle]="'Fuel Lost'"></nz-statistic>-->
<!--        </nz-col>-->

<!--        <nz-col [nzSpan]="8">-->
<!--          <nz-statistic-->
<!--            [nzValueTemplate]="fuelLostThreshold"-->
<!--            [nzTitle]="'Fuel Lost Threshold'"-->
<!--            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>-->
<!--        </nz-col>-->

        <nz-col [nzSpan]="8">
          <nz-statistic
            [nzValueTemplate]="lastFillAmount"
            [nzTitle]="'Last Fill Amt'"
            [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
        </nz-col>
      </nz-row>

      <br>

      <nz-row [nzGutter]="16">
        <nz-col [nzSpan]="8">
          <nz-statistic [nzValue]="(currentDeviceReadings?.last_used_amount != undefined)
      ? currentDeviceReadings?.last_used_amount: 'N/A'"
                        [nzTitle]="'Last Usage'"
                        [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
        </nz-col>

        <nz-col [nzSpan]="8">
          <nz-statistic [nzValue]="(currentDeviceReadings?.run_time_remaining != undefined)
      ? currentDeviceReadings?.run_time_remaining : 'N/A'"
                        [nzTitle]="'Runtime Remaining'"
                        [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
        </nz-col>

        <nz-col [nzSpan]="8">
          <nz-statistic [nzValue]="(currentDeviceReadings?.generator_consumption != undefined)
      ? currentDeviceReadings?.generator_consumption : 'N/A'"
                        [nzTitle]="'Generator Consumption'"
                        [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>
        </nz-col>
      </nz-row>

<!--      <br>-->
<!--      <nz-row [nzGutter]="16">-->
<!--        <nz-col [nzSpan]="8">-->
<!--          <nz-statistic [nzValue]="(currentDeviceReadings?.feeds_to != 'undefined ')-->
<!--      ? currentDeviceReadings?.feeds_to : 'Unavailable' "-->
<!--                        [nzTitle]="'Feeds To'"-->
<!--                        [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>-->
<!--        </nz-col>-->

<!--        <nz-col [nzSpan]="8">-->
<!--          <nz-statistic [nzValue]="(currentDeviceReadings?.fed_from != 'undefined ')-->
<!--       ? currentDeviceReadings?.fed_from : 'Unavailable'"-->
<!--                        [nzTitle]="'Fed From'"-->
<!--                        [nzValueStyle]="{ color: '#1890ff' }"></nz-statistic>-->
<!--        </nz-col>-->
<!--      </nz-row>-->
      <br>
      <p style="font-size: x-small; font-weight: bold" class="text-muted">{{currentDeviceReadings?.capacity | uppercase}}</p>

<!--      <ng-template #temp>-->

<!--        <nz-progress-->
<!--          *ngIf="currentDeviceReadings?.Fuel_Percentage != undefined"-->
<!--          nzPercent="{{  currentDeviceReadings?.Fuel_Percentage * 100 | number}}" [nzSteps]="10"-->
<!--          nzStrokeColor="#1890ff"></nz-progress>-->
<!--        <p *ngIf="currentDeviceReadings?.Fuel_Percentage == undefined">N/A</p>-->

<!--      </ng-template>-->

<!--      <ng-template #fuelLostTemplate>-->
<!--        <p class="display-5 reading-text mb-0" nz-popover nzPopoverTitle="Date"-->
<!--           nzPopoverContent="{{(currentDeviceReadings?.lbl_fuel_lost_amount === 'Unavailable') ?-->
<!--            currentDeviceReadings?.lbl_fuel_lost_amount_date  :-->
<!--            currentDeviceReadings?.lbl_fuel_lost_amount_date | date: 'medium' }}">-->
<!--          {{(currentDeviceReadings?.lbl_fuel_lost_amount != undefined)-->
<!--          ? currentDeviceReadings?.lbl_fuel_lost_amount: 'N/A'}}-->
<!--        </p>-->
<!--      </ng-template>-->

<!--      <ng-template #fuelLostThreshold>-->
<!--        <p class="display-5 reading-text mb-0" nz-popover nzPopoverTitle="Date"-->
<!--           nzPopoverContent="{{currentDeviceReadings?.lbl_fuel_lost_threshold_date | date: 'medium' }}">-->
<!--          {{(currentDeviceReadings?.lbl_fuel_lost_threshold != undefined)-->
<!--          ? currentDeviceReadings?.lbl_fuel_lost_threshold : 'N/A'}}-->
<!--        </p>-->
<!--      </ng-template>-->

      <ng-template #lastFillAmount>
        <p class="display-5 reading-text mb-0">
          {{ (currentDeviceReadings?.last_fill_amount != undefined)
          ? currentDeviceReadings?.last_fill_amount : 'N/A' }}
        </p>
      </ng-template>
    </div>
  </nz-card>


</div>


