import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AlarmsService} from '../../services/alarms/alarms.service';
import {IActiveAlarm} from '../../Models/IActiveAlarm';
import {ClientsService} from '../../services/clients/clients.service';
import {IClient} from '../../Models/IClient';
import {TableColumnItem, TableDataItem} from '../../Models/IAlarmTableInterfaces';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {Constants} from '../../utils/Contstants';
import {interval, Observable, Subscription} from 'rxjs';
import {IUserSiteListWithAlarms} from '../../Models/IUserSiteList';

@Component({
  selector: 'app-client-alarm-summary',
  templateUrl: './client-alarm-summary.component.html',
  styleUrls: ['./client-alarm-summary.component.scss']
})
export class ClientAlarmSummaryComponent implements OnInit, AfterViewInit, OnDestroy {

  clientAlarms : IActiveAlarm[];
  selectedClient: IClient;
  searchText: string;

  isLoadingData: boolean = false;

  listOfColumns: TableColumnItem[] = [
    // {
    //   name: '',
    // },
    {
      name: 'Site Name',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) => a.site_name.localeCompare(b.site_name)
    },
    {
      name: 'Device Group',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) => a.device_name.localeCompare(b.device_name)
    },
    {
      name: 'Description',
    },
    {
      name: 'Priority',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) =>
        a.priority.localeCompare(b.priority),
      filterMultiple: true,
      listOfFilter: [
        { text: 'Critical', value: 'Critical'},
        { text: 'Urgent', value: 'Urgent'},
        { text: 'Warning', value: 'Warning'},
        { text: 'Event', value: 'Event' }
      ],
      filterFn: (list: string[], item: TableDataItem) =>
      {

        for(let i in list){

          if(item.priority === list[i]){
            return true;
          }

        }

        return false;
      }
    },

    {
      name: 'Date Triggered',
      sortOrder: 'desc',
      sortFn: (a: TableDataItem, b: TableDataItem) => a.triggered_at.localeCompare(b.triggered_at),
      sortDirections: ['desc', null]
    },
    // {
    //   name: 'Date Cleared',
    //   sortOrder: null,
    //   sortFn: (a: TableDataItem, b: TableDataItem) => a.cleared_at.localeCompare(b.cleared_at)
    // },
    {
      name: 'Duration',
      sortOrder: null,
      sortFn: (a: TableDataItem, b: TableDataItem) => a.duration.localeCompare(b.duration)
    },
    // {
    //   name: 'Status',
    //   sortOrder: null,
    //   sortFn: (a: TableDataItem, b: TableDataItem) => a.is_cleared.localeCompare(b.is_cleared)
    // },

  ];

  timer = interval(60000);
  subscription: Subscription;

  data: IActiveAlarm[];

  constructor(private alarmsService: AlarmsService,) { }

  ngOnInit(): void {

    this.isLoadingData = true;

    // this.authenticationService.getSignedInUserDetails().subscribe(res => {
    //   this.clientService.getClient(res.client_id).subscribe( response =>{
    //
    //     this.selectedClient = response;
    //     this.getAlarmSummary();
    //   });
    // }, error => {
    //   console.error('Failed to get Signed in user details: ', error);
    // });
    this.getData();
  }

  getData(){

    this.alarmsService.getUserSiteAlarms().subscribe( (response: IUserSiteListWithAlarms[] ) => {

     this.clientAlarms = [];
     response.forEach( (item) => {

       item.active_alarms.forEach( (alarm: IActiveAlarm) => {
         this.clientAlarms.push( alarm  );
       } )
     })

      this.clientAlarms.sort( (a, b) => a.triggered_at > b.triggered_at ? 1 : -1 );

      this.isLoadingData = false;
    }, error => {
      console.error('[Client Alarm Summary]: ', error);
      this.clientAlarms = [];
      this.isLoadingData = false;
    });

  }


  ngAfterViewInit(): void {
    this.subscription = this.timer.subscribe(x => {

      this.getData();
    }, error => {
      console.warn("Error in timing subscription", error);
    })
  }

  ngOnDestroy(): void {

    this.subscription.unsubscribe();

  }

}
