import { Component, OnInit } from '@angular/core';
import {ClientsService} from '../../services/clients/clients.service';
import {IClient} from '../../Models/IClient';
import {StorageService} from '../../services/storage/storage.service';
import {Constants} from '../../utils/Contstants';
import {Router} from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
  selector: 'app-client-selection',
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.scss']
})
export class ClientSelectionComponent implements OnInit {

  storageConstants = new Constants().storage;
  clients: IClient[];
  selectedClient;

  isLoading: boolean = false;

  constructor(
    private clientsService: ClientsService,
    private storageService: StorageService,
    private router: Router, private notification: NzNotificationService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.clientsService.getClients().subscribe(response => {

      this.storageService.encodeAndStore(this.storageConstants.clients,
        JSON.stringify(response));

      this.clients = response;
      this.isLoading = false;
    }, error => {
      console.error(error);
      this.isLoading = false;
    });

  }

  setClient():void{

    // this.storageService.encodeAndStore(this.storageConstants.selectedClient, this.selectedClient );

    if(this.selectedClient == undefined){

      this.notification.error(
        'Invalid Selection',
        'Please select a client before you attempt to continue!',
        { nzDuration: 0 }
      );

    }else{

      for (let i in this.clients){

        if(this.clients[i].client_id === this.selectedClient){
          this.clientsService.setCurrentClient(this.clients[i]);
          break;
        }

      }

      this.router.navigate(['/admin/']);
    }


  }

}
