import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ITemperatureSensor} from '../../../Models/DeviceInstances/ITemperatureSensor';
import {interval, Subscription} from 'rxjs';
import {AnalogDevicesService} from '../../../services/analogDevices/analog-devices.service';
import {IDeviceReadingsProps} from '../../../Models/IDeviceReadingsProps';

@Component({
  selector: 'app-temperature-sensor-info',
  templateUrl: './temperature-sensor-info.component.html',
  styleUrls: ['./temperature-sensor-info.component.scss']
})
export class TemperatureSensorInfoComponent implements OnInit, OnDestroy {

  hasReading: boolean;

  @Output() failedReading = new EventEmitter<boolean>();

  @Input() readings: IDeviceReadingsProps;
  requestInterval = interval(10000);
  intervalSubscriber: Subscription;
  currentDeviceReadings : ITemperatureSensor;

  isLoadingData : boolean;

  constructor(private analogDeviceService: AnalogDevicesService) { }


  ngOnInit(): void {

    this.getDeviceReadings();
    this.hasReading = this.readings != null;
    this.isLoadingData = true;

  }


  emitReadingError(): void{
    this.failedReading.emit(true);

  }

  getDeviceReadings(): void{

    this.isLoadingData = true;

    this.analogDeviceService.deviceInstanceReadings(
      this.readings.selectedSiteId, this.readings.selectedDeviceTypeId, this.readings.controllerId, this.readings.instanceId
    ).subscribe( response => {

      this.currentDeviceReadings = response;
      this.isLoadingData = false;

    }, error => {
      console.warn('Temp', error);
    } )


  }

  ngOnDestroy(): void {

    //this.intervalSubscriber.unsubscribe();
  }

}
