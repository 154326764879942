<div class="container-fluid">
  <nz-page-header nzBackIcon>
    <nz-page-header-title>Create A ITicket</nz-page-header-title>
          <nz-page-header-subtitle>Fill in the form below to create a new ticket</nz-page-header-subtitle>
    <nz-page-header-extra>

      <button nz-button routerLink="/admin">
        <i nz-icon nzType="bell" nzTheme="twotone" class="button-icon"></i>
        Alarms
      </button>

    </nz-page-header-extra>
  </nz-page-header>
</div>
