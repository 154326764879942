import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PowerSystemsService {

  constructor(private http: HttpClient) { }

  getPowerSystemPDF(dateFrom: string, dateTo: string, deviceType: string, clientID: number, siteName: string): Observable<string>{

    let baseUrl: string = `https://reports.service.cloudbms.co.za/api/reports/`;
    // let baseUrl: string = `http://localhost:5202/api/reports/`;
    switch (deviceType){
      case 'Power Meter':
        baseUrl += `power/powerMeterActivePowerReport`;
        break;

      case 'Rectifier':
        baseUrl += `power/rectifierUsedCapacityReport`;
        break;

      case 'UPS':
        baseUrl += `power/upsSystemOutputReport`;
        break;

      case 'Temperature':
        baseUrl += `temperature/temperatureSensorReport`;
        break;

        case 'FuelLevels':
        baseUrl += `fuel/fuelLevelsReport`;
        break;

      case 'Combined':
        baseUrl += `power/combinedSystemsReport`;
        break;

      case 'Site Availability':
        baseUrl += `grafana/siteAvailability`;
        break;

    }

    return this.http.post(baseUrl,
      {
        clientID: clientID,
        "startDate": dateFrom,
        "endDate": dateTo,
        "site": siteName
    },
      {responseType: 'text'});

  }

  getChangeoverReport(dateFrom: string, dateTo: string, clientID: number, siteId: string, clientName: string): Observable<string>{
    let baseUrl: string = `https://reports.service.cloudbms.co.za/api/reports/utility/time_on_utility`;

    return this.http.post(baseUrl,
      {
        clientID: clientID,
        "startDate": dateFrom,
        "endDate": dateTo,
        "site": siteId,
        "client_name": clientName
      },
      {responseType: 'text'});

  }

  getPowerSystemCsv(dateFrom: string, dateTo: string, siteId: number, deviceType: string): Observable<any>{

    let baseUrl: string = `https://nodered.cloudbms.co.za/api/analytics/`;

    switch (deviceType){
      case 'Power Meter':
        baseUrl += `Powermeters_avarage_csv?site_id=${siteId}&date_from=${dateFrom}&date_to=${dateTo}`;

        break;
      case 'Rectifier':
        baseUrl += `rectifiers_avarage_csv?site_id=${siteId}&date_from=${dateFrom}&date_to=${dateTo}`;
        break;

      case 'UPS':
        baseUrl += `UPSs_avarage_csv?site_id=${siteId}&date_from=${dateFrom}&date_to=${dateTo}`;
        break;
        case 'Temperature':
        baseUrl += `temperatures_avarage_csv?site_id=${siteId}&date_from=${dateFrom}&date_to=${dateTo}`;
        break;
    }

    return this.http.get(baseUrl,
      {responseType: 'text'});
  }

  getPowerSystemCsvAllSites(dateFrom: string, dateTo: string, clientID: number, deviceType: string): Observable<any>{

    let baseUrl: string = `https://nodered.cloudbms.co.za/api/analytics/`;

    switch (deviceType){
      case 'Power Meter':
        baseUrl += `Powermeters_avarage_csv?client_id=${clientID}&date_from=${dateFrom}&date_to=${dateTo}`;

        break;
      case 'Rectifier':
        baseUrl += `rectifiers_avarage_csv?client_id=${clientID}&date_from=${dateFrom}&date_to=${dateTo}`;
        break;

      case 'UPS':
        baseUrl += `UPSs_avarage_csv?client_id=${clientID}&date_from=${dateFrom}&date_to=${dateTo}`;
        break;
      case 'Temperature':
        baseUrl += `temperatures_avarage_csv?client_id=${clientID}&date_from=${dateFrom}&date_to=${dateTo}`;
        break;
    }

    return this.http.get(baseUrl,
      {responseType: 'text'});
  }

}
