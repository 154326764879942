<div class="container-fluid">

  <div class="row">

    <nz-card class="col-lg-6 col-md-6 col-sm-12" [nzBodyStyle]="{'height': 150, 'padding': 0}">
      <nz-page-header nzBackIcon [nzGhost]="false" class="mb-3">
        <nz-page-header-title>Tickets</nz-page-header-title>
        <nz-page-header-subtitle>A list of all alarms as tickets</nz-page-header-subtitle>

        <nz-page-header-content>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12">
              <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text"
                       nz-input placeholder="Search..."
                       [(ngModel)]="searchText" autocomplete="off" />
              </nz-input-group>
              <span nz-typography nzType="secondary">Use the search box above to filter the list below</span>
              <ng-template #suffixIconSearch>
                <i nz-icon nzType="search"></i>
              </ng-template>
            </div>
          </div>
        </nz-page-header-content>

      </nz-page-header>
    </nz-card>

    <nz-card class="col-lg-6 col-md-6 col-sm-12" [nzBodyStyle]="{'height': 150}">
      <div class="row">
        <div class="col">
          <nz-statistic [nzValue]="( tickets?.length | number)!"
                        [nzTitle]="'Total Tickets'" [nzSuffix]="ticket_suffix"></nz-statistic>
          <ng-template #ticket_suffix>
            <i nz-icon nzType="file" nzTheme="outline" class="text-danger"></i>
          </ng-template>

        </div>
        <div class="col">
          <nz-statistic [nzValue]="( totalCriticalTickets | number)!"
                        [nzTitle]="'Critical Tickets'" [nzSuffix]="critical_suffix"></nz-statistic>
          <ng-template #critical_suffix>
            <i nz-icon nzType="exclamation-circle" nzTheme="outline" class="text-danger"></i>
          </ng-template>
        </div>
        <div class="col">
          <nz-statistic [nzValue]="( totalOpenTickets | number)!"
                        [nzTitle]="'Open Tickets'" [nzSuffix]="urgent_suffix"></nz-statistic>
          <ng-template #urgent_suffix>
            <i nz-icon nzType="exclamation-circle" nzTheme="outline" style="color: #ffa000;"></i>
          </ng-template>
        </div>
      </div>
    </nz-card>

  </div>

  <div class="row">

    <nz-card class="col-lg-12 col-md-12 col-sm-12">

      <nz-card class="col-12"
               [nzBordered]="false"
               [nzBodyStyle]="{ 'padding': 0, 'height': '60vh' }" [nzLoading]="loadingTickets">

        <nz-table #nzTable [nzData]="tickets" nzTableLayout="fixed"
                  [nzPageSize]="10" [nzScroll]="{ y: '50vh', x: '1150px' }"
                  [nzShowPagination]="true" nzShowSizeChanger [nzPageSizeOptions]="[10,20,30,40,50, 100]"
                  nzSize="small"
        >
          <thead>
          <tr>
            <!--          <th colspan="2">Reference</th>-->
            <!--&lt;!&ndash;          <th>Subject</th>&ndash;&gt;-->
            <th
              *ngFor="let column of listOfColumns"
              [nzSortOrder]="column.sortOrder"
              [nzSortFn]="column.sortFn"
              [nzSortDirections]="column.sortDirections"
              [nzFilterMultiple]="column.filterMultiple"
              [nzFilters]="column.listOfFilter"
              [nzFilterFn]="column.filterFn"
            >
              {{ column.name }}
            </th>

          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let data of nzTable.data | filter:searchText; let i = index">

            <tr
            >
              <td nzBreakWord>{{data?.reference}}</td>
              <td>{{data?.subject}}</td>
              <td nzBreakWord>{{data?.site_name}}</td>
              <td>
                <nz-tag nzColor="red" class="priority" *ngIf="data?.urgency === 'Critical'">
                  Critical
                </nz-tag>
                <nz-tag nzColor="#ffa000" class="priority" *ngIf="data?.urgency === 'Urgent'">
                  Urgent
                </nz-tag>
                <nz-tag  nzColor="warning" class="priority" *ngIf="data?.urgency === 'Warning'">
                  Warning
                </nz-tag>
                <nz-tag nzColor="blue" class="priority" *ngIf="data?.urgency === 'Event'">
                  Event
                </nz-tag>
              </td>
              <td>

                <nz-tag nzColor="red" class="priority" *ngIf="data?.status === 'Rejected'">
                  {{ data?.status }}
                </nz-tag>
                <nz-tag nzColor="#ffa000" class="priority" *ngIf="data?.status === 'Unassigned'">
                  {{ data?.status }}
                </nz-tag>

                <nz-tag nzColor="warning" class="priority" *ngIf="data?.status === 'Re-Opened'">
                  {{ data?.status }}
                </nz-tag>

                <nz-tag  nzColor="success" class="priority" *ngIf="data?.status === 'Closed'">
                  {{ data?.status }}
                </nz-tag>
                <nz-tag nzColor="blue" class="priority" *ngIf="data?.status === 'In Progress'">
                  {{ data?.status }}
                </nz-tag>

              </td>
              <td>{{ data?.due_date | date : 'medium' }}</td>
              <td>{{ (data?.assigned_full_name !== null) ? data?.assigned_full_name : 'N/A' }}</td>
              <td>{{ data?.created_at | date: 'medium' }}</td>
              <td>
                <button nz-button nzType="primary"
                        routerLink="/admin/tickets/{{data?.id}}">View</button>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </nz-table>


      </nz-card>
    </nz-card>

  </div>


</div>

<div class="row">

<div class="container-fluid">




</div>
</div>
<app-overlay-loader [isLoading]="loadingTickets" [loadingText]="'Retrieving Tickets...'"></app-overlay-loader>
